import UrlHashHandler from './urlHashHandle'
import emitter from './emitter'
import UrlHandler from '@/utils/urlHandler'
import { getLocale } from '@/utils/locale'
import { getLocalStorage } from '@/utils/localStorage'
import sim from '@/utils/sim'

const TYPES = {
  LOGIN: 'login',
  LOGOUT: 'logout',
}

// 提供util判断是否为H5
const isH5 = document.body.clientWidth <= 768

class PassportMonitor {
  constructor() {
    this.beforeLoginFnList = []
    this.afterLoginFnList = []
    this.beforeLogoutFnList = []
    this.afterLogoutFnList = []
    this.loginFnList = []
    this.logoutFnList = []
    this.loadedFnList = []
  }

  login() {
    this.beforeLogin()
    if (isH5) {
      const urlHashHandler = new UrlHashHandler(location.href)
      urlHashHandler.set(TYPES.LOGIN)
      location.href = urlHashHandler.getUrl()
    }
    this._login()
  }

  get _homeHref() {
    const urlHandler = new UrlHandler(location.href)
    /**
     * 如果是在brand页，或者从brand跳转到的页面，则需要在登出的时候，跳转到brand页面(除了store页面)
     */
    const pathName = location.pathname
    const segments = pathName.split('/')
    const cleanedSegments = segments.filter(segment => segment !== '')
    const isSimArr = sim.isSim() ? 3 : 2
    const hasBrandInFirstTwoSegments = cleanedSegments.slice(0, isSimArr).includes('brand')
    if (hasBrandInFirstTwoSegments) {
      const brandTicket = decodeURI(getLocalStorage('brandTicket'))
      const targetPath1 = sim.isSim()
        ? `${getLocale()}/sailing-fe-c-services-sf/brand/brandName/${brandTicket}/`
        : `${getLocale()}/brand/brandName/${brandTicket}/`
      urlHandler.set({ pathname: targetPath1 })
      return urlHandler.getUrl()
    }

    const storeShopSecret = decodeURI(getLocalStorage('storeShopSecret'))
    const targetPath = sim.isSim()
      ? `${getLocale()}/sailing-fe-c-services-sf/store/${storeShopSecret}/`
      : `${getLocale()}/store/${storeShopSecret}/`
    urlHandler.set({ pathname: targetPath })
    return urlHandler.getUrl()
  }

  logout() {
    this.beforeLogout()
    if (isH5) {
      const urlHashHandler = new UrlHashHandler(this._homeHref)
      urlHashHandler.set(TYPES.LOGOUT)
      location.href = urlHashHandler.getUrl()
    }
    this._logout()
  }

  _existFn(list, fn) {
    return list.indexOf(fn) !== -1
  }

  registerLogin(fn) {
    if (this._existFn(this.loginFnList, fn)) {
      return
    }
    this.loginFnList.push(fn)
  }

  registerLogout(fn) {
    if (this._existFn(this.logoutFnList, fn)) {
      return
    }
    this.logoutFnList.push(fn)
  }

  registerLoaded(fn) {
    if (this._existFn(this.loadedFnList, fn)) {
      return
    }
    this.loadedFnList.push(fn)
  }

  registerBeforeLogin(fn) {
    if (this._existFn(this.beforeLoginFnList, fn)) {
      return
    }
    this.beforeLoginFnList.push(fn)
  }

  registerAfterLogin(fn) {
    if (this._existFn(this.afterLoginFnList, fn)) {
      return
    }
    this.afterLoginFnList.push(fn)
  }

  registerBeforeLogout(fn) {
    if (this._existFn(this.beforeLogoutFnList, fn)) {
      return
    }
    this.beforeLogoutFnList.push(fn)
  }

  registerAfterLogout(fn) {
    if (this._existFn(this.afterLogoutFnList, fn)) {
      return
    }
    this.afterLogoutFnList.push(fn)
  }

  _login() {
    this.loginFnList.forEach(fn => {
      fn()
    })
  }

  _logout() {
    this.logoutFnList.forEach(fn => {
      fn()
    })
  }

  beforeLogin() {
    this.beforeLoginFnList.forEach(fn => {
      fn()
    })
  }

  afterLogin(res) {
    this.afterLoginFnList.forEach(fn => {
      fn(res)
    })
  }

  beforeLogout() {
    this.beforeLogoutFnList.forEach(fn => {
      fn()
    })
  }

  afterLogout() {
    this.afterLogoutFnList.forEach(fn => {
      fn()
    })
  }

  loaded() {
    this.loadedFnList.forEach(fn => {
      fn()
    })
  }

  monitor() {
    const urlHashHandler = new UrlHashHandler(location.href)
    if (urlHashHandler.exist()) {
      const type = urlHashHandler.get()
      urlHashHandler.clear()
      // 直接设置，会触发浏览器刷新
      // location.href = urlHashHandler.getUrl();
      // 会在URL末尾加个#；这里有个副作用，会把原来的URL中的hash丢掉，不过问题不大
      location.hash = ''
      if (type?.includes(TYPES.LOGIN)) {
        this.afterLogin()
      } else {
        this.afterLogout()
      }
    } else {
      console.log('=========== 普通刷新 ===========')
      this.loaded()
    }
  }

  monitorH5(res) {
    const urlHashHandler = new UrlHashHandler(location.href)
    if (urlHashHandler.exist()) {
      const type = urlHashHandler.get()
      urlHashHandler.clear()
      // 直接设置，会触发浏览器刷新
      // location.href = urlHashHandler.getUrl();
      // 会在URL末尾加个#；这里有个副作用，会把原来的URL中的hash丢掉，不过问题不大
      location.hash = ''
      if (type?.includes(TYPES.LOGIN)) {
        this.afterLogin(res)
      } else {
        this.afterLogout()
      }
    } else {
      console.log('=========== 普通刷新 ===========')
      this.loaded()
    }
  }
}

const passportMonitor = new PassportMonitor()

// 监听PC登录逻辑
window.addEventListener('load', () => {
  passportMonitor.monitor()
})

// 监听H5登录逻辑
emitter.on('h5-login', res => {
  passportMonitor.monitorH5(res)
})

emitter.on('h5-logout', () => {
  passportMonitor.monitorH5()
})

export default passportMonitor
