import i18n from '@/i18n/locale'
// 菜品状态 1: '销售中',2: '已售罄',3: '待售',
export const CATE_STATUS_MAP = {
  1: '',
  2: i18n.t('已售罄'),
  3: i18n.t('待售'),
}
// 菜品非营业状态
export const CATE_DISABLED_STATUS_LIST = [2, 3]

// 菜品规格来源 配置 (目前key 必须和name一致)
export const CATE_SOURCE = {
  STORE: {
    // desc: '商家页'
    name: 'STORE',
    minCopies: 1,
    maxCopies: Infinity,
    btnName: i18n.t('添加'),
  },
  BILL: {
    // desc: '提单页'
    name: 'BILL',
    minCopies: 0,
    maxCopies: Infinity,
    btnName: i18n.t('更新'),
  },
  CART: {
    // desc: '购物车'
    name: 'CART',
    minCopies: 0,
    maxCopies: Infinity,
    btnName: i18n.t('更新'),
  },
  EFO: {
    // desc: '新人特惠菜'
    name: 'EFO',
    minCopies: 1,
    maxCopies: 1,
    btnName: i18n.t('去结算'),
  },
  OUO: {
    // desc: '老客特惠菜'
    name: 'OUO',
    minCopies: 1,
    maxCopies: Infinity,
    btnName: i18n.t('去结算'),
  },
}
// 菜品活动类型配置
export const CATE_ACT_TYPE_CONFIG = {
  SPECIAL: {
    // 特价
    type: 2,
    dataName: 'special', // 活动类型对应activityInfo数据字段匹配
  },
  BUY_GIFT: {
    // 买赠
    type: 2,
    dataName: 'buyGift',
  },
  EFO: {
    // 新人特惠
    type: 100,
    dataName: 'platSpecial',
  },
  OUO: {
    // 老客特惠
    type: 101,
    dataName: 'platSpecial',
  },
}
// 菜品活动类型 可以匹配来源
export const CATE_ACTTYPE_TO_SOURCE = {
  [CATE_ACT_TYPE_CONFIG.EFO.type]: CATE_SOURCE.EFO.name,
  [CATE_ACT_TYPE_CONFIG.OUO.type]: CATE_SOURCE.OUO.name,
}

// itemdetail 所需的 source 对应 规格来源 // 老客 2 EFO 1 普通 0
export const ITEM_DETAIL_SOURCE = {
  [CATE_SOURCE.EFO.name]: 1,
  [CATE_SOURCE.OUO.name]: 2,
}
