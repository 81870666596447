<template>
  <div class="cate-tips-wrapper" :class="{'disabled': disabled}">
    <div
      class="cate-tip"
      v-for="(tip, index) in tips"
      :key="index"
    >
      <i :class="tip.icon || 'icon-filled_special_r'"></i>
      <span class="content">{{ tip.content + (moretext ? ', ' + moretext : '') }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CateTips',
  props: {
    tips: Array,
    moretext: String,
    disabled: Boolean,
  },
  data() {
    return {
      copies: 1,
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped >
.cate-tip {
  position: relative;
  padding-left: 20px;
  font-family: 'Aspira Medium';
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  @include font-color();
}
.cate-tip i,
.content {
  vertical-align: middle;
}
.cate-tip i {
  position: absolute;
  left: 0;
  width: 16px;
  font-size: 16px;
}
.disabled {
  .cate-tip {
    color: #cfcfcf;
  }
}
@media screen and (max-width: 768px){
  .cate-tip {
    padding-left: rem(32);
    font-size: rem(28);
    line-height: rem(28);
  }
  .cate-tip i {
    width: rem(28);
    font-size: rem(28);
  }
}
</style>