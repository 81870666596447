const countryOptions = [
  {
    value: 'Mexico',
    label: 'México',
  },
  {
    value: 'CostaRica',
    label: 'Costa Rica',
  },
  {
    value: 'Colombia',
    label: 'Colombia',
  },
  {
    value: 'Peru',
    label: 'Peru',
  },
  {
    value: 'DominicanRepublic',
    label: 'Dominican Republic',
  },
  {
    value: 'Chile',
    label: 'Chile',
  },
]

const languageOptionsForCountry = {
  MX: [
    {
      value: 'es-MX',
      label: 'Español',
    },
  ],
  CR: [
    {
      value: 'es-CR',
      label: 'Español',
    },
  ],
  CO: [
    {
      value: 'es-CO',
      label: 'Español',
    },
  ],
  PE: [
    {
      value: 'es-PE',
      label: 'Español',
    },
  ],
  CL: [
    {
      value: 'es-CL',
      label: 'Español',
    },
  ],
  DO: [
    {
      value: 'es-DO',
      label: 'Español',
    },
  ],
}

function getLanguageOptionsForCountry(country) {
  const commonOptions = [
    {
      value: 'zh-CN',
      label: '简体中文',
    },
    {
      value: 'en-US',
      label: 'English',
    },
  ]
  const countryOptions = languageOptionsForCountry[country] || []
  return [...commonOptions, ...countryOptions]
}

export { countryOptions, getLanguageOptionsForCountry }
