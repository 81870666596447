import * as service from './list/service'
import * as brand from './list/brand'
import * as customerService from './list/customer-service'
import * as login from './list/login'
import * as sealPic from './list/seal-pic'

function configMixin(targetConfig = {}) {
  const defaultCountry = 'MX'
  const finalConfig = {}
  // eslint-disable-next-line array-callback-return
  Object.keys(targetConfig).map(key => {
    finalConfig[key] = targetCountry => {
      return targetConfig[key][targetCountry] || targetConfig[key][defaultCountry]
    }
  })
  return finalConfig
}

export default {
  service: configMixin(service),
  brand: configMixin(brand),
  customerService: configMixin(customerService),
  login: configMixin(login),
  sealPic: configMixin(sealPic),
}
