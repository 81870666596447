<template>
  <div v-if="info" class="cate-wrapper">
    <div class="close-wrapper" :class="{ 'no-head-img': !isH5 && !hasHeadImg }" @click="close">
      <i class="icon-outline_close"></i>
    </div>
    <CateHeadImg v-if="!isH5" :head-img="info.headImg" :title="getSlodTitle(info)" />
    <TreeCate
      v-if="info"
      ref="treeCate"
      :style="{ flex: 1 }"
      :info="info"
      :source="source"
      :disabled="disabled"
      :has-head-img="hasHeadImg"
      :cate-item-num="cateItemNum"
      @add-shopping-cart="addShoppingCart"
    />
  </div>
</template>

<script>
import { getSlodTitle } from './methods.js'
import CateHeadImg from './cateHeadImg.vue'
import TreeCate from '@/components/Tree/treeCate.vue'

export default {
  name: 'Cate',
  components: {
    CateHeadImg,
    TreeCate,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    source: String,
    info: {
      type: Object,
      required: true,
    },
    isH5: Boolean,
    disabled: Boolean,
    hasHeadImg: Boolean,
    // eslint-disable-next-line vue/require-default-prop
    cateItemNum: Number,
  },
  data() {
    return {}
  },
  methods: {
    getSlodTitle,
    close() {
      const hasChange = this.$refs.treeCate?.hasChange
      this.$emit('close', hasChange)
    },
    addShoppingCart(data) {
      this.$emit('add-shopping-cart', data)
    },
  },
}
</script>

<style lang="scss" scoped>
.cate-wrapper {
  position: relative;
  display: flex;
  height: 100%;
  overflow: hidden;
}
.close-wrapper {
  position: absolute;
  top: 18px;
  left: 18px;
  z-index: 10;
  padding: 6px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  cursor: pointer;
  font-size: 0;
  // 去除h5 active时 蓝色块
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  -webkit-user-select: none;
  -moz-user-focus: none;
  -moz-user-select: none;
}
.close-wrapper i {
  font-size: 24px;
  color: #000;
}
.no-head-img {
  top: 18px;
  left: 18px;
}

.left-box {
  flex: 1;
}
.left-box {
  position: relative;
  font-size: 0;
  text-align: center;
}
.center-box {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 350px;
  height: 260px;
}
.center-box img {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
  text-align: center;
}
.item-hide {
  position: absolute;
  top: 0;
  left: 0;
  padding: 0 10px;
  display: flex;
  width: 100%;
  height: 100%;
  text-align: center;
  font-family: 'Aspira Medium';
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 8px;
}
.item-hide .text-box {
  padding: 8px;
  color: rgba(0, 0, 0, 0.7);
  background: #fff;
  border-radius: 3px;
}
@media screen and (max-width: 768px) {
  .cate-wrapper {
    display: block;
    overflow-y: scroll;
  }
  // .cate-wrapper-scroll {

  // }
  .close-wrapper {
    top: rem(32);
    left: rem(24);
    z-index: 10;
    padding: rem(9);
    width: rem(66);
    height: rem(66);
    background: #fff;
  }
  .close-wrapper i {
    font-size: rem(48);
  }
}
</style>
