import { cart, shop } from '@/api/apiConstants'

const {
  CART_INFO,
  GLOBAL_CART_CLEAR,
  CART_SETAMOUNT,
  CART_SETITEM
} = cart;

const {
  ITEM_DETAIL
} = shop
/** @desc 需要商家Poi的接口列表 */
export const needShopPoiApiList = [
  CART_INFO,
  GLOBAL_CART_CLEAR,
  CART_SETAMOUNT,
  CART_SETITEM,
  ITEM_DETAIL
]
