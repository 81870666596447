/**
 * @Author: amywulan
 * @Date: 2021-07-07 12:00:00
 */
// import api from '@/api'
const state = {
  cancelOrder: {
    updateCancelSuccessText: '',
    updateCancelSuccessTip: '',
  },
}
const mutations = {
  updateCancelOrderInfo: function (state, payload) {
    state.cancelOrder = {
      ...state.cancelOrder,
      ...payload,
    }
  },
}
const actions = {}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
