// 子节点选中情况
import ta from './tree-algorithm'
import CONSTANTS from './constants'

class Strategy {
  match(item) {
    // 不同的分组，命中策略，demo里暂时简单考虑
    // 一层一层进入的交互形式，只需要判断直接子节点的状态
    let match = true
    // 每个分组都可用，
    item.contentList.forEach(group => {
      if (!this.matchGroup(group)) {
        match = false
      }
    })
    return match
  }

  isLeaf(item) {
    return !(item.contentList && item.contentList.length > 0)
  }

  isMust(group) {
    const { isMust } = group
    return isMust === 1
  }

  isSelectType(group) {
    const { minItemNum, maxItemNum } = group
    return minItemNum === maxItemNum
  }

  isLimitType(group) {
    return !this.isSelectType(group)
  }

  isCounter(group) {
    const { buyMode } = group
    // 0不允许 1允许；允许时，非叶子节点为计数器
    return buyMode === 1
  }

  isCheckbox(group) {
    const { maxItemNum } = group
    return !this.isCounter(group) && maxItemNum > 1
  }

  isRadio(group) {
    const { maxItemNum } = group
    return !this.isCounter(group) && maxItemNum === 1
  }

  // 禁用状态，与命中状态的区别在于，Limit(最多可选)逻辑下，命中和禁用逻辑不同
  shouldDisableRest(group) {
    const { maxItemNum, subItemList } = group
    // 计数器，逻辑与复选框一致
    if (this.isCounter(group)) {
      return this.hasCounterSelectCount(subItemList, maxItemNum)
    }

    // 复选
    if (this.isCheckbox(group)) {
      return this.hasSelectCount(subItemList, maxItemNum)
    }

    // 单选
    if (this.isRadio(group)) {
      return false
    }
  }

  // 命中状态
  matchGroup(group) {
    const { maxItemNum, subItemList } = group
    // 复选
    if (this.isCheckbox(group)) {
      // 固定数量
      if (this.isSelectType(group)) {
        return (
          (!this.isMust(group) &&
            (this.hasNoSelect(subItemList) || this.hasSelectCount(subItemList, maxItemNum))) ||
          (this.isMust(group) && this.hasSelectCount(subItemList, maxItemNum))
        )
      } else {
        // 最多数量
        return (
          (!this.isMust(group) &&
            (this.hasNoSelect(subItemList) || this.hasLimitCount(subItemList, maxItemNum))) ||
          (this.isMust(group) && this.hasLimitCount(subItemList, maxItemNum))
        )
      }
    }

    // 单选
    if (this.isRadio(group)) {
      const { subItemList } = group
      return !this.isMust(group) || this.hasSelectCount(subItemList, 1)
    }

    // 计数器
    if (this.isCounter(group)) {
      // 固定数量
      if (this.isSelectType(group)) {
        return (
          (!this.isMust(group) &&
            (this.hasCounterNoSelect(subItemList) ||
              this.hasCounterSelectCount(subItemList, maxItemNum))) ||
          (this.isMust(group) && this.hasCounterSelectCount(subItemList, maxItemNum))
        )
      } else {
        // 最多数量
        return (
          (!this.isMust(group) &&
            (this.hasCounterNoSelect(subItemList) ||
              this.hasCounterLimitCount(subItemList, maxItemNum))) ||
          (this.isMust(group) && this.hasCounterLimitCount(subItemList, maxItemNum))
        )
      }
    }
  }

  hasNoSelect(list) {
    let sum = 0
    list.forEach(_ => {
      if (this.isSelected(_)) {
        sum++
      }
    })
    return sum === 0
  }

  //  select
  hasSelectCount(list, x) {
    let sum = 0
    list.forEach(_ => {
      if (this.isSelected(_)) {
        sum++
      }
    })
    return sum === x
  }

  // limit
  hasLimitCount(list, count) {
    let sum = 0
    list.forEach(_ => {
      if (this.isSelected(_)) {
        sum++
      }
    })
    return sum > 0 && sum <= count
  }

  hasCounterNoSelect(list) {
    let sum = 0
    list.forEach(_ => {
      sum += this.getItemCounter(_)
    })
    return sum === 0
  }

  //  计数器 select
  hasCounterSelectCount(list, x) {
    let sum = 0
    list.forEach(_ => {
      sum += this.getItemCounter(_)
    })
    return sum === x
  }

  // 计数器 limit
  hasCounterLimitCount(list, count) {
    let sum = 0
    list.forEach(_ => {
      sum += this.getItemCounter(_)
    })
    return sum > 0 && sum <= count
  }

  isSelected(item) {
    if (item[CONSTANTS.SELECTED_TAG.RADIO_AND_CHECKBOX.TEMPORARY] === CONSTANTS.UNMODIFIED_VALUE) {
      return !!item[CONSTANTS.SELECTED_TAG.RADIO_AND_CHECKBOX.PERMANENT]
    } else {
      return !!item[CONSTANTS.SELECTED_TAG.RADIO_AND_CHECKBOX.TEMPORARY]
    }
  }

  isCounterSelected(item) {
    return this.getItemCounter(item) > 0
  }

  getItemCounter(item) {
    let val
    if (item[CONSTANTS.SELECTED_TAG.COUNTER.TEMPORARY] !== CONSTANTS.UNMODIFIED_VALUE) {
      val = item[CONSTANTS.SELECTED_TAG.COUNTER.TEMPORARY]
    } else {
      val = item[CONSTANTS.SELECTED_TAG.COUNTER.PERMANENT]
    }
    if (val === CONSTANTS.UNMODIFIED_VALUE) {
      val = 0
    }
    return val
  }

  inRadioGroup(tree, node) {
    const parent = ta.getParentNode(tree, node)
    if (parent) {
      const group = this.getItemGroupByParent(parent, node)
      if (group === null) {
        return false
      } else {
        return this.isRadio(group)
      }
    } else {
      console.log('=========== inRadioGroup 在顶层 ===========')
    }
  }

  getSelectedItemInRadioGroup(group) {
    const list = group.subItemList
    for (let i = 0, len = list.length - 1; i < len; i++) {
      if (this.isSelected(list[i])) {
        return list[i]
      }
    }
    return null
  }

  getItemGroupByTree(tree, node) {
    const parent = ta.getParentNode(tree, node)
    if (parent) {
      return this.getItemGroupByParent(parent, node)
    } else {
      console.log('=========== inRadioGroup 在顶层 ===========')
    }
  }

  getItemGroupByParent(parent, item) {
    let res = null
    parent.contentList.forEach(group => {
      group.subItemList.forEach(_ => {
        if (item.itemId === _.itemId) {
          res = group
        }
      })
    })
    return res
  }

  reduceItemForDisplay(item, type) {
    const subArray = item[CONSTANTS.DISPLAY_TAG.ARRAY_KEY] || []
    const currentItem = {
      [CONSTANTS.DISPLAY_TAG.NAME]: item.itemName,
      [CONSTANTS.DISPLAY_TAG.PRICE]: item.price,
      [CONSTANTS.DISPLAY_TAG.NUMBER]: type === 'counter' ? this.getItemCounter(item) : 1,
    }

    return subArray.reduce((sum, cur) => {
      return {
        [CONSTANTS.DISPLAY_TAG.NAME]:
          this.formatDisplayName(
            sum[CONSTANTS.DISPLAY_TAG.NAME],
            sum[CONSTANTS.DISPLAY_TAG.NUMBER],
          ) +
          ' / ' +
          this.formatDisplayName(
            cur[CONSTANTS.DISPLAY_TAG.NAME],
            cur[CONSTANTS.DISPLAY_TAG.NUMBER],
          ),
        [CONSTANTS.DISPLAY_TAG.PRICE]:
          this.sumDisplayPrice(
            sum[CONSTANTS.DISPLAY_TAG.PRICE],
            sum[CONSTANTS.DISPLAY_TAG.NUMBER],
          ) +
          this.sumDisplayPrice(cur[CONSTANTS.DISPLAY_TAG.PRICE], cur[CONSTANTS.DISPLAY_TAG.NUMBER]),
        [CONSTANTS.DISPLAY_TAG.NUMBER]: 1,
      }
    }, currentItem)
  }

  formatDisplayName(name, number) {
    if (number === 1) {
      return name
    }
    return name + ' × ' + number
  }

  sumDisplayPrice(price, number) {
    return price * number
  }

  getSelectedSubItems(item) {
    const arr = []
    item.contentList.forEach(group => {
      group.subItemList.forEach(subItem => {
        if ((this.isRadio(group) || this.isCheckbox(group)) && this.isSelected(subItem)) {
          arr.push(this.reduceItemForDisplay(subItem, 'radioOrCheckbox'))
        }
        if (this.isCounter(group) && this.isCounterSelected(subItem)) {
          arr.push(this.reduceItemForDisplay(subItem, 'counter'))
        }
      })
    })
    return arr
  }

  getSelectedLeafByPreOrder(tree, cb) {
    const parent = tree
    const isNotLeaf = parent && parent.contentList && parent.contentList.length > 0
    const isSelected = this.isSelected(parent)
    if (isSelected) {
      if (isNotLeaf) {
        parent.contentList.forEach(group => {
          group.subItemList.forEach(_ => {
            this.getSelectedLeafByPreOrder(_, cb)
          })
        })
      } else {
        cb(parent)
      }
    }
  }

  mergeTag(act, label = {}) {
    const tag = act.map(item => ({
      ...item,
      style: item.style || 1, // 1代表要带背景色
    }))
    if (label.limitLabelStr) {
      const labelContent = {
        style: 2,
        content: `&em#{"text":"${label.limitLabelStr}","color":"#89898C"}&em#`,
      }
      tag.push(labelContent)
    }
    return tag
  }
}

const s = new Strategy()
export default s
