// 售后提交图片
const sealPic = {
  MX: 'https://img0.didiglobal.com/static/gstar/img/iM0o8YyyRN1617701623019.png',
  BR: 'https://img0.didiglobal.com/static/gstar/img/mr59SG8ha71617701673189.png',
  JP: 'https://img0.didiglobal.com/static/gstar/img/lopPkRe69s1617701659761.png',
  CR: 'https://img0.didiglobal.com/static/gstar/img/iM0o8YyyRN1617701623019.png',
  CO: 'https://img0.didiglobal.com/static/gstar/img/iM0o8YyyRN1617701623019.png',
  DO: 'https://img0.didiglobal.com/static/gstar/img/iM0o8YyyRN1617701623019.png',
}

export default sealPic
