import { post } from '@/lib/request/index'
import { coupon } from '../apiConstants'

// 查询券
export function fetchCouponList(param) {
  return post(coupon.FETCH_COUPONlIST, { param })
}
// 兑换码
export function cartRedeem(param) {
  return post(coupon.CART_REDEEM, { param })
}

// 获取券包列表
export function productList(param) {
  return post(coupon.PRODUCT_LIST, { param })
}

// 获取侧边栏券列表
export function getWalletCoupons(param) {
  return post(coupon.GET_WALLET_COUPONS, { param })
}

// 侧边栏兑换码
export function sidebarExchange(param) {
  return post(coupon.SIDEBAR_EXCHANGE_COUPONS, { param })
}
