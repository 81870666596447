<template>
  <span :style="style" />
</template>

<script>
export default {
  name: 'TextPart',
  props: {
    data: Object,
  },
  computed: {
    style() {
      const { width } = this.data
      return {
        paddingLeft: width + 'px',
      }
    },
  },
}
</script>
