export default [
  // 首页
  {
    path: '/bill/:shopId?/:cartId?',
    name: 'bill',
    meta: {
      title: '提单页',
    },
    component: () =>
      import(
        /* webpackChunkName: "bill" */
        '@/views/Bill/index'
      ),
  },
];
