<template>
  <div
    class="stepper-number-box"
    :class="{ disabled: disabled, 'rule-disabled': max === 1 && min === 1 }"
  >
    <div class="minus-sign" :class="{ action: copies > minCopies }" @click="reduceCopies">
      <svg
        width="20px"
        height="20px"
        viewBox="0 0 36 36"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <title>规格页减购icon</title>
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g class="Group-3-class" fill="#CCCCCC" fill-rule="nonzero">
            <rect class="rectangle-class" opacity="0" x="0" y="0" width="36" height="36"></rect>
            <rect class="rectangle-class" x="4" y="16.5" width="28" height="3" rx="1.5"></rect>
          </g>
        </g>
      </svg>
    </div>
    <div class="copies-num" v-text="copies"></div>
    <div class="plus-sign" :class="{ action: copies < maxCopies }" @click="addCopies">
      <svg
        width="20px"
        height="20px"
        viewBox="0 0 36 36"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <title>规格页加购icon</title>
        <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
          <g class="Group-3-class" fill="#CCCCCC" fill-rule="nonzero">
            <rect opacity="0" x="0" y="0" width="36" height="36"></rect>
            <path
              d="M18,4 C18.8284271,4 19.5,4.67157288 19.5,5.5 L19.5,16.5 L30.5,16.5 C31.3284271,16.5 32,17.1715729 32,18 C32,18.8284271 31.3284271,19.5 30.5,19.5 L19.5,19.5 L19.5,30.5 C19.5,31.3284271 18.8284271,32 18,32 C17.1715729,32 16.5,31.3284271 16.5,30.5 L16.5,19.5 L5.5,19.5 C4.67157288,19.5 4,18.8284271 4,18 C4,17.1715729 4.67157288,16.5 5.5,16.5 L16.5,16.5 L16.5,5.5 C16.5,4.67157288 17.1715729,4 18,4 Z"
            ></path>
          </g>
        </g>
      </svg>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CateAddStepper',
  props: {
    disabled: Boolean,
    value: {
      type: Number,
      default: 1,
    },
    min: {
      type: Number,
      default: 1,
    },
    max: {
      type: Number,
      default: Infinity,
    },
  },
  data() {
    return {
      copies: 1,
      minCopies: 1,
      maxCopies: Infinity,
    }
  },
  watch: {
    value(nVal) {
      this.copies = nVal
    },
    min(nVal) {
      this.minCopies = nVal
    },
    max(nVal) {
      this.maxCopies = nVal
    },
  },
  mounted() {
    this.copies = this.value
    this.minCopies = this.min
    this.maxCopies = this.max
  },
  methods: {
    reduceCopies() {
      if (this.copies <= this.minCopies) return
      this.copies--
      this.$emit('input', this.copies)
    },
    addCopies() {
      if (this.disabled || this.copies >= this.maxCopies) return
      this.copies++
      this.$emit('input', this.copies)
    },
  },
}
</script>

<style lang="scss" scoped>
.stepper-number-box {
  display: flex;
  padding: 10px 8px;
  width: 128px;
  height: 48px;
  text-align: center;
  background: #ffffff;
  border: 0.89px solid #999999;
  border-radius: 4px;
}
.minus-sign,
.plus-sign {
  width: 28px;
  height: 28px;
  // line-height: 28px;
  padding: 4px;
  font-size: 20px;
  color: #cccccc;
  cursor: pointer;
  user-select: none;
  svg {
    display: block;
  }
  .Group-3-class {
    fill: #ccc;
  }
}
.minus-sign.action,
.plus-sign.action {
  .Group-3-class {
    @include svg-fill();
  }
}
.copies-num {
  flex: 1;
  height: 28px;
  line-height: 28px;
  font-family: 'Aspira Medium';
  font-weight: 500;
  font-size: 18px;
  color: #000000;
}

.stepper-number-box.disabled {
  border-color: #cccccc;
  .copies-num {
    color: #999;
  }
  .minus-sign,
  .plus-sign {
    color: #cccccc !important;
    .Group-3-class {
      fill: #cccccc !important;
    }
  }
}
.stepper-number-box.rule-disabled {
  border-color: #cccccc;
}
@media screen and (max-width: 768px) {
  .stepper-number-box {
    padding: rem(30) rem(40);
    width: rem(262);
    height: auto;
    border: none;
    box-shadow: 0px rem(12) rem(30) 0px rgba(0, 0, 0, 0.08);
    border-radius: rem(16);
  }
  .minus-sign,
  .plus-sign {
    padding: 0;
    width: 20px;
    height: 20px;
    font-size: 18px;
  }
  .copies-num {
    height: 20px;
    line-height: 20px;
    font-size: 20px;
  }
}
</style>
