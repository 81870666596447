const appNameRegExps = {
  messenger: {
    regex: /\bFB[\w_]+\/(Messenger|MESSENGER)/,
    name: 'Facebook Messenger',
  },
  facebook: {
    regex: /\bFB[\w_]+\//,
    name: 'Facebook',
  },
  twitter: {
    regex: /\bTwitter/i,
    name: 'Twitter',
  },
  line: {
    regex: /\bLine\//i,
    name: 'Line',
  },
  wechat: {
    regex: /\bMicroMessenger\//i,
    name: 'WeChat',
  },
  instagram: {
    regex: /\bInstagram/i,
    name: 'Instagram',
  },
  tiktok: {
    regex: /musical_ly|Bytedance/i,
    name: 'TikTok',
  },
  snapchat: {
    regex: /Snapchat/i,
    name: 'Snapchat',
  },
  whatsApp: {
    regex: /WhatsApp/i,
    name: 'WhatsApp',
  },
  google: {
    regex: /Google/i,
    name: 'Google',
  },
}
const appKeys = Object.keys(appNameRegExps)
const getAppKey = ua => {
  return appKeys.find(appName => appNameRegExps[appName].regex.test(ua))
}
export const inAppSdk = () => {
  try {
    const userAgent =
      window?.navigator?.userAgent || window?.navigator?.vendor || window?.opera || ''
    const appKey = getAppKey(userAgent) || ''
    const appName = appKey ? appNameRegExps[appKey]?.name : 'H5'
    return { appKey, appName }
  } catch (err) {
    return { appKey: '', appName: 'unknown' }
  }
}
