<template>
  <div id="app">
    <div v-if="isMobile">
      <div class="view">
        <router-view></router-view>
      </div>
      <!-- 尾部 -->
      <FooterComponent v-if="$route.meta.showFooter" />
    </div>
    <!-- PC模式未开通 -->
    <div v-else class="page-pc">
      <sfQrCode />
      <FooterComponent />
    </div>
  </div>
</template>

<script>
import FooterComponent from '@/components/Footer/index.vue'
import sfQrCode from '@/components/DownloadApp/sfQrCode.vue'
import { techPageMonitorLog } from '@/const/logs'
import { delLocalStorage, getLocalStorage, setLocalStorage } from '@/utils/localStorage'
import { setSessionStorage, delSessionStorage } from '@/utils/sessionStorage'
import passportMonitor from './utils/passportMonitor'
import { mapActions, mapMutations, mapState } from 'vuex'
import passportH5 from './utils/passportH5'
import emitter from './utils/emitter'
import { is_mobile } from '@/utils/os'
import { sailing_c_x_sf_startpage_startup_sw } from '@/utils/track/common'
import { sailing_c_x_sf_login_sw, sailing_c_x_sf_login_result_sw } from '@/utils/track/login'
import { sleep } from '@/utils/tools'
import { isEmpty } from 'lodash-es'
export default {
  components: {
    sfQrCode,
    FooterComponent,
  },
  data() {
    return {
      hasRegisterPassportH5: false,
    }
  },
  computed: {
    ...mapState({
      isH5: ({ common }) => common.isH5,
      config: ({ common }) => common.config,
      cartData: ({ cart }) => cart.cartInfo,
      isCheckBillLogin: ({ common }) => common.isCheckBillLogin,
      isLoginPopShowBefore: ({ common }) => common.isLoginPopShowBefore,
    }),
    isLogin() {
      return getLocalStorage('isLogin')
    },
    isMobile() {
      return is_mobile()
    },
  },
  watch: {
    $route: {
      handler(to, from) {
        const hasPathChanged = to.path !== from.path
        const fromRootPath = from.path === '/'
        if (!fromRootPath && hasPathChanged && this.isMobile) {
          this._checkLogin()
        }
      },
      deep: true,
    },
  },
  created() {
    sailing_c_x_sf_startpage_startup_sw()
    techPageMonitorLog()
  },
  mounted() {
    sailing_c_x_sf_login_result_sw()
    this.registerPassport()
  },
  methods: {
    ...mapActions('common', ['getSidebar', 'getToken', 'getCountry', 'getRequestAddressInfo']),
    ...mapMutations('common', ['setIsLogin', 'setCheckBillLogin', 'setLoginPopShowBefore']),

    _checkLogin() {
      this._checkUserInfo()
    },
    _needLoginInfo(routeName) {
      const notNeedLoginInfoRouteName = ['store', 'brand'] // CKA页面也不强制登陆
      const { sourceEntry } = this.$route.query
      const isWhatsApp = sourceEntry === 'whatsApp'
      // 如果是whatsApp来源，也可以不强制登录
      if (isWhatsApp) {
        notNeedLoginInfoRouteName.push('orderDetail')
      }
      return !notNeedLoginInfoRouteName.includes(routeName)
    },
    _checkUserInfo() {
      const name = this.$route?.name
      const needLogin = this._needLoginInfo(name)
      const isLogin = getLocalStorage('isLogin')
      if (needLogin && !isLogin) {
        passportMonitor.login()
      }
    },
    _clearLoginInfo() {
      delLocalStorage('isLogin')
      delLocalStorage('lastPoiAid')
      delLocalStorage('userInfo')
      delLocalStorage('ticket')
      delLocalStorage('uid')
      delLocalStorage('wineConfirmVisible')
      delSessionStorage('afterLogin')
      delSessionStorage('cityId')
    },
    _initLoginInfo() {
      setLocalStorage('isLogin', true)
      setSessionStorage('afterLogin', true)
      this.getSidebar()
      this.getToken()
      this.setIsLogin(true)
    },
    _initH5LoginInfo(res) {
      const { ticket = '', uid_str = '' } = res || {}
      setLocalStorage('isLogin', true)
      setLocalStorage('ticket', ticket)
      setLocalStorage('uid', uid_str)
      setSessionStorage('afterLogin', true)

      // this.getSidebar()
      this.setIsLogin(true)
    },
    registerPassport() {
      passportMonitor.registerBeforeLogin(() => {
        // @desc 唤起登录埋点
        sailing_c_x_sf_login_sw()

        this._clearLoginInfo()
        /** @desc 增加1s loading **/
        const load = this.$rLoading()
        setTimeout(() => {
          load.close()
        }, 1000)
        console.log('=========== before login ===========')
      })
      passportMonitor.registerAfterLogin(async res => {
        // this.$rLoading()
        /** @desc 增加1s loading **/
        const load1 = this.$rLoading()
        setTimeout(() => {
          load1.close()
        }, 1000)
        await this._initH5LoginInfo(res)
        await sleep(150)

        console.log('=========== after login ===========', res)
        const isCheckBill = this.isCheckBillLogin
        this.setCheckBillLogin(false) // 登录成功后清除提单页来源
        this.setLoginPopShowBefore(false) // 展示登录引导模块
        // 如果是点击的提单页的话，跳转到提单页
        if (isCheckBill) {
          sailing_c_x_sf_login_result_sw()
          // 登录后进入提单页之前，先触发一下地址相关的逻辑
          await this.getRequestAddressInfo()
          // 如果store内没有config,重新请求
          if (isEmpty(this.config)) {
            await this.getCountry()
          }
          this.$router.push(`/bill/${this.cartData?.shopId}/${this.cartData?.cartId}`)
        } else {
          location.reload()
        }
        // setTimeout(() => {
        //   load.close()
        // }, 1000)
      })

      passportMonitor.registerBeforeLogout(() => {
        this._clearLoginInfo()
        console.log('=========== before logout ===========')
      })

      passportMonitor.registerAfterLogout(async () => {
        console.log('=========== after logout ===========')
        await sleep(100)
        location.reload()
      })

      passportMonitor.registerLogin(() => {
        if (!this.hasRegisterPassportH5) {
          this.hasRegisterPassportH5 = true
          emitter.on('h5-login-err', () => {
            this.setLoginPopShowBefore(false) // 展示登录引导模块
            this.setCheckBillLogin(false) // 关闭的时候也删除isCheckBillLogin
            this.hasRegisterPassportH5 = false
          })
          passportH5.login()
        }
      })
      passportMonitor.registerLogout(() => {
        emitter.on('h5-logout-err', () => {
          location.reload() // 退出登录失败以后重新加载页面
        })
        passportH5.logout()
      })
      passportMonitor.registerLoaded(() => {
        this._checkLogin()
      })
    },
  },
}
</script>

<style lang="scss">
body,
html {
  background: #f5f5f7;
  scroll-behaviour: smooth;
  font-family: 'Aspira Regular';
}

.view {
  min-height: 80vh;
}

.stop-scrolling {
  height: 100%;
  overflow: hidden;
}
.page-pc {
  .footer {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
  }
}

@media (max-height: 800px) {
  .page-pc {
    .sf-code {
      .code {
        margin-top: 50px;
      }
    }
  }
}
</style>
