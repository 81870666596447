import sim from '@/utils/sim'
import { getLocale } from '@/utils/locale'
import { getUrlOrigin } from '@/utils/baseUtil'

/*
 * @Author: amywulan
 * @description: 获取路由基础路径规则
 */
export function getRouterBasePath(locale = '', invokeType = '') {
  // sim环境基于加塔兰域名增加/sailing-fe-c-services-sf/路径
  const localeInfo = locale || getLocale()
  let basePath = '/'

  if (sim.isSim()) {
    const path = location.pathname.split('/')
    const simLocale = path[1] || ''
    const simPath = path[2] || '' // 看一下是不是brand的，如果是的话，不要兜底跳转到store
    basePath = `${localeInfo}/sailing-fe-c-services-sf/` || 'sailing-fe-c-services-sf/'

    if (simLocale === 'sailing-fe-c-services-sf' && simPath === 'brand') {
      basePath = 'sailing-fe-c-services-sf/'
    }
    if (invokeType === 'updateLocale') {
      // 语言切换场景
      basePath = `${localeInfo}/sailing-fe-c-services-sf` || 'sailing-fe-c-services-sf'
    }
  } else {
    basePath = localeInfo || '/'
  }

  return basePath
}

/**
 * @description 更新语言
 */
export const updateLocaleUrl = (locale, fullPath) => {
  const info = getRouterBasePath(locale, 'updateLocale')
  return `${getUrlOrigin()}/${info}${fullPath}`
}
