import i18n from '@/i18n/locale'

export const TITLE_PREFIX = 'DiDi Food | '
export const META = {
  feed: {
    title: i18n.t('FoodC_Website_DiDi_FoodDiDi_wtqn'),
  },
  store: {
    title: i18n.t('FoodC_Website_From_shop_Jatv'),
  },
  search: {
    title: i18n.t('FoodC_Website_DiDi_FoodDiDi_Search_AAAA'),
  },
}
