export default {
  isDev: process.env.NODE_ENV === 'development' || process.env.STORYBOOK === 'true',
  isTest: process.env.NODE_ENV === 'production' && process.env.BUILD_ENV === 'development',
  isPre:
    (process.env.NODE_ENV === 'production' &&
      process.env.BUILD_ENV !== 'development' &&
      location.host.indexOf('pre') !== -1) ||
    process.env.BUILD_ENV === 'pre',
  isPro: process.env.NODE_ENV === 'production' && process.env.BUILD_ENV !== 'development',
  isDiDi: location.host.indexOf('didi-food') !== -1,
  // is99: location.host.indexOf('99app') !== -1,
  is99: location.host.indexOf('99app') !== -1 || location.pathname.split('/')[1] === 'pt-BR',
}
