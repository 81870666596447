/**
 * 公共埋点
 */
import { trackEvent } from '@/lib/omega'

/** @desc 外部服务异常监控 */
export const tech_error_external_service_monitoring_reporting = (data = {}) => {
  trackEvent({
    eventId: 'tech_error_external_service_monitoring_reporting',
    eventLabel: '外部服务异常监控',
    attrs: {
      // 🔼 默认值 可被业务传入覆盖
      ...data,
    },
  })
}
/** @desc 全局曝光 */
export const sailing_c_x_sf_startpage_startup_sw = () => {
  trackEvent({
    eventId: 'sailing_c_x_sf_startpage_startup_sw',
    eventLabel: '每次进入Storefront页面时上报',
  })
}

/** @desc Storefront联系商家按钮点击 */
export const sailing_c_x_sf_shop_contact_button_ck = (data = {}) => {
  trackEvent({
    eventId: 'sailing_c_x_sf_shop_contact_button_ck',
    eventLabel: '联系商家按钮点击',
    attrs: {
      order_id: -999,
      order_status: -999,
      // 🔼 默认值 可被业务传入覆盖
      ...data,
    },
  })
}
/** @desc SF个人中心页面-条目点击 */
export const sailing_c_x_sf_profile_bar_ck = (data = {}) => {
  trackEvent({
    eventId: 'sailing_c_x_sf_profile_bar_ck',
    eventLabel: 'SF个人中心页面-条目点击',
    attrs: {
      ...data,
    },
  })
}
