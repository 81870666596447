import env from './env'
import getCountryConfig from '@/config/countryConfig/index'
import sim from '@/utils/sim'
import { getCountry } from '@/utils/getLocalStorageCountry'
// import { isH5 } from '@/utils/tools'
import { getLanguageLong } from '@/utils/locale'
const getLanguage = getLanguageLong() === 'es-419' ? 'es-MX' : getLanguageLong()

const configuration = {
  pre: {
    cApi: 'https://pre-c.didi-food.com',
    actApi: 'https://pre-act-api.didi-food.com',
    cashierApi: 'https://cashier-pre.didiglobal.com',
    payApi: 'https://pay-pre.didiglobal.com',
    h5LoginUrl: '//static.didiglobal.com/common-biz/global-login/2.0.9/login.global.min.js',
    passportUrl: 'https://page.didiglobal.com/public-biz/pc-login/3.0.8/index.html',
    fulfillmentApi: 'https://pre-fulfillment.didi-food.com',
  },
  sim: {
    // cApi: 'https://rlab-sim060-soda-c-api.rlab.sim.intra.xiaojukeji.com',
    // actApi: 'https://rlab-sim060-soda_c_act_api.rlab.sim.intra.xiaojukeji.com',

    cApi: sim.getSimCApi(),
    actApi: sim.getSimActApi(),
    cashierApi: sim.getSimCashierApi(),
    payApi: sim.getSimPayApi(),
    fulfillmentApi: sim.getSimFulfillmentApi(),
    h5LoginUrl: '//static.didiglobal.com/common-biz/global-login/2.0.9/login.global.min.js',
    passportUrl: 'https://page.didiglobal.com/public-biz/pc-login/3.0.8/index.html',
  },
  dev: {
    cApi: '/DEV_PROXY_C_API',
    actApi: '/DEV_PROXY_ACT_API',
    cashierApi: '/DEV_PROXY_CASHIER_API',
    fulfillmentApi: '/DEV_PROXY_FULFILLMENT_API',
    // payApi: '',
    // cApi: sim.getSimCApi(),
    // actApi: sim.getSimActApi(),
    // cashierApi: sim.getSimCashierApi(),
    payApi: sim.getSimPayApi(),
    h5LoginUrl: '//static.didiglobal.com/common-biz/global-login/2.0.9/login.global.min.js',
    // '//passport-test.didichuxing.com/static/trinity-login/2.0.7/login.test.js',
    passportUrl: 'https://page.didiglobal.com/public-biz/pc-login/3.0.8/index.html',
  },
}

let currentConfiguration

if (env.isSIM) {
  currentConfiguration = configuration.sim
} else if (env.isPre) {
  currentConfiguration = configuration.pre
} else {
  currentConfiguration = configuration.dev
}

const cApiBase = currentConfiguration.cApi

const config = {
  omega: {
    uploadHost: 'omgup.didiglobal.com',
    appKey: 'devOmegaKey', // 测试环境不上报数据
    browserRouterEnable: true,
    autoPosition: false,
  },

  cApiBase,
  actApiBase: currentConfiguration.actApi,
  cashierApiBase: currentConfiguration.cashierApi,
  fulfillmentApiBase: currentConfiguration.fulfillmentApi,
  passport: {
    h5LoginUrl: currentConfiguration.h5LoginUrl,
    passportUrl: currentConfiguration.passportUrl,

    setCookieUrl: `${cApiBase}/login/callback`,
    appId: 200073,
    source: 70001, // 根据国家区分
    role: 1,
    lang: getLanguage,
    law_url: getCountryConfig.login.lawUrlH5(getCountry),
    country_id: getCountryConfig.login.countryId(getCountry),
    country_calling_code: getCountryConfig.login.countryCallingCode(getCountry),
    city_id: 0,
    canonical_country_code: getCountryConfig.login.canonicalCountryCode(getCountry),
    // eslint-disable-next-line no-void
    theme: env.is99 ? 'yellow' : void 0,
  },
}

export default config
