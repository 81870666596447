class UrlHandler {
  constructor(str) {
    this.url = document.createElement('a')
    this.url.href = str
  }

  set({ hash, pathname }) {
    if (hash !== undefined) {
      this.url.hash = hash === '' ? '' : '#' + hash
    }
    if (pathname !== undefined) {
      this.url.pathname = pathname === '' ? '' : '/' + pathname
    }
  }

  getUrl() {
    return this.url.href
  }

  getHash() {
    return this.url.hash
  }

  clear() {
    this.url = null
  }
}

export default UrlHandler
