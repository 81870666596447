/**
 * 业务成功返回 result.data（不包含result.errno）
 * 业务异常返回 result
 */
export function isBusinessError(result) {
  return result.errno !== undefined;
}

/**
 * 将result.data同级数据挂载到result.data._meta上
 */
export function getMetaInfo(data) {
  return data?._meta;
}
