export default {
  '你最多可以下单MX$900.00的货到付款单':
    'El monto máximo para pedidos con pago en la entrega puede ser hasta MX$900.00',
  重置筛选: 'Eliminar filtros',
  试试减少筛选条件: 'Intenta usar menos filtros',
  删除: 'Eliminar',
  '确定删除地址吗？': '¿Eliminar dirección?',
  你当前的送餐地址: 'Dirección de entrega actual',
  公司: 'Oficina',
  送餐上门: 'Entregar en la puerta',
  家: 'Casa',
  设为常用地点: 'Marcar como ubicación frecuente',
  编辑: 'Editar',
  无法保存: 'No se puede guardar',
  找不到地址: 'No se encontró la dirección',
  '无法获取您的位置信息，请输入送餐地址':
    'Imposible obtener la ubicación; ingresa la dirección de entrega',
  开启: 'Habilitar',
  输入地址: 'Ingresa una dirección',
  我想手动输入地址: 'Quiero ingresar manualmente la dirección',
  无法找到您的位置: 'No podemos encontrar tu ubicación',
  请重试: 'Intenta de nuevo',
  刷新: 'Actualizar',
  无法定位: 'No se pudo obtener la ubicación',
  保存的地址: 'Dirección guardada',
  开启定位权限获得更好服务: 'Habilitar los servicios de ubicación',
  未开启定位权限: 'Servicio de ubicación desactivado',
  开启定位服务获得更好服务: 'Para una experiencia óptima, activa los servicios de localización',
  未开启定位服务: 'Los servicios de localización están desactivados',
  当前定位城市: 'Ubicación actual',
  '登录以选择地址\\t': 'Inicia sesión para elegir tu dirección',
  '有保存的地址吗？': '¿Tienes una dirección guardada?',
  保存成功: 'Se guardó correctamente',
  因为您现在选择送餐到这里: 'Esta es tu dirección de entrega actual.',
  不能删除这个地址: 'No se puede eliminar.',
  待支付: 'En espera del pago',
  提交订单: 'Enviar pedido',
  添加备注: 'Agregar una nota',
  最大字数限制为200字: 'Máximo 200 caracteres',
  小费超出额度限制: 'La propina supera el límite',
  '给骑手小费%s': 'Darle una propina al repartidor de %s',
  输入备注信息: 'Agregar comentarios',
  '添加备注（口味、偏好、酱汁等方面的要求）':
    'Agregar comentarios (sobre sabores, preferencias, salsas, etc.)',
  配送: 'Entregar en',
  免配送费: 'Entrega gratuita',
  配送方式: 'Opción de entrega',
  地址: 'Dirección',
  营业时间: 'Horario de operación',
  商家联系方式: 'Información de contacto',
  法规: 'Notificación',
  活动: 'Promociones',
  已关闭: 'Cerrada',
  营业中: 'Abierto',
  暂停配送: 'No disponible ahora',
  查看: 'Ver',
  更换我的地址: 'Cambiar mi dirección',
  '店铺已关闭，预计%s开业': 'La tienda está cerrada y reabrirá a las %s',
  当前配送地址: 'Dirección de entrega actual',
  查看更多推荐店铺: 'Buscar otras tiendas',
  店铺已超出配送范围: 'Tienda fuera del rango de entrega',
  '%1$s配送': '%1$s Repartidor',
  达到字数上限: 'Se alcanzó el límite máximo de palabras',
  免费: 'Gratis',
  商品已售罄: 'Producto agotado',
  无法添加到购物车: 'No se puede agregar al carrito',
  '选择%s': 'Seleccionar %s',
  请在购物车中删除多规格商品: 'Elimina los productos que tienen varias opciones del carrito',
  商家自配送: 'Entrega de tienda',
  '休 息 中': 'C E R R A D A',
  休息中: 'CERRADA',
  '开始营业%s': 'Abre\\n%s',
  '营业时间：%s': 'Abre %s',
  '即将\\n营业': 'Abrirá\\nmuy pronto',
  即将营业: 'Abrirá muy pronto',
  '超出\\n配送\\n范围': 'Fuera del\\nrango de\\nentrega',
  超出配送范围: 'Fuera del rango de entrega',
  礼物: 'Regalo',
  呼叫: 'Llamar',
  将使用以下号码加密呼出: 'Se utilizará este número privado para realizar la llamada',
  '号码保护服务异常，将使用您的真实号码呼出':
    'Las llamadas anónimas no están disponibles en este momento. Si llamas ahora, tu número real será visible.',
  '99Pay': '99Pay',
  去添加: 'Agregar ahora',
  '添加大楼名称以及门牌号，可以帮助配送员更快找你的送餐位置':
    'Para ayudar a que el repartidor pueda encontrarte más rápido, especifica el número de edificio y apartamento.',
  忽略: 'Omitir',
  补充详细地址信息: 'Agrega detalles de tu dirección',
  请添加地址: 'Agrega tu dirección',
  店铺关门: 'La tienda no está disponible',
  其他: 'Otros',
  '抱歉，您最多可加购15个菜品': 'Lo sentimos, solo puedes agregar hasta 15 productos en tu carro',
  修改取餐人: 'Cambiar destinatario',
  名字: 'Nombre',
  请输入名字: 'Ingresa nombre',
  姓氏: 'Apellido(s)',
  请输入姓氏: 'Ingresa apellido(s)',
  电话号码: 'Nº de teléfono',
  请输入电话号码: 'Ingresa el número de teléfono',
  请填写正确的手机号码: 'Ingresa un número de celular válido',
  取餐人信息: 'Información del destinatario',
  业务暂未开通: 'Actualmente no disponible',
  暂未使用: 'Actualmente no se utiliza',
  错误提示: 'Error',
  预计配送时间: 'Entrega estimada',
  请输入备注: 'Ingresar comentarios',
  立即下单: 'Pedir ahora',
  购物车已清空: 'El carrito se ha vaciado',
  POS: 'Maquininha',
  省: 'ahorras',
  小计: 'Subtotal',
  小费金额: 'Importe de la propina',
  选择小费金额: 'Selecciona el importe para la propina',
  '红包金额=订单总价*红包比例': 'Importe de la propina = total de la orden x porcentaje de propina',
  感谢红包将全部支付给骑手: 'El repartidor recibirá el importe total de la propina.',
  输入小费比例: 'Ingresa el porcentaje de propina',
  输入小费金额: 'Ingresa el importe para la propina',
  小费比例不得超过: 'El porcentaje de propina no puede exceder',
  感谢红包金额不能超过: 'El importe de la propina no puede exceder',
  '您曾支付过%s的感谢红包': 'Ya pagaste una propina de %s',
  您需要支付小费: 'Debes pagar una propina',
  '在使用我们的任何产品或服务前，请仔细阅读并确保完全了解我们的《使用条款》和《隐私政策》。点击“接受”并使用我们的产品或服务，即表明您确认了解并同意所有的%1$s外卖条款及条件，同时同意与%2$s外卖分享您的账号信息':
    'Antes de utilizar cualquiera de nuestros productos o servicios, lee atentamente y asegúrate de que entiendes completamente los Términos de Uso y la Política de Privacidad. Al pulsar Aceptar y utilizar nuestros productos o servicios, afirmas que entiendes y aceptas todos los términos y condiciones de %1$s Food y das tu consentimiento para compartir la información de tu cuenta con %2$s Food.',
  使用条款及隐私政策: 'Términos de uso y política de privacidad',
  '在使用服务之前请先同意用户协议。':
    'Acepta los Términos y Condiciones actualizados para continuar.',
  '亲爱的用户，我们最近有更新用户协议，请您仔细阅读并接受我们新的用户协议，欢迎您继续使用「%1$s Food」。':
    'Recientemente actualizamos nuestros Términos y Condiciones. Por favor tómate un tiempo para leer y aceptar los términos y condiciones actualizados. ¡Gracias por elegir %1$s Food!',
  已更新用户协议: 'Términos y Condiciones actualizados',
  重要变更: 'Actualización importante',
  在使用服务之前请先同意最新的用户协议:
    'Acepta los Términos y Condiciones actualizados para continuar',
  查看更多店铺: 'Buscar tiendas',
  '加载中…': 'Cargando…',
  没有更多了: 'No hay más resultados',
  好的: 'Aceptar',
  点击重试: 'Haz clic para volver a intentar',
  确认: 'Confirmar',
  待支付订单: 'Orden pendiente de pago',
  '此现金订单还未支付。为避免无法正常使用平台服务，请您及时支付。如有疑问请联系客服':
    'Este pedido con pago en efectivo aún no se ha pagado. Para garantizar el acceso continuo a la aplicación, paga pronto. Si tienes alguna pregunta, contacta a Soporte DiDi',
  '你有一笔现金订单还未支付。请确认支付':
    'Tienes un pedido con pago en efectivo sin pagar. Por favor, confirma y realiza el pago.',
  '你有一个现金订单支付，请及时支付。支付后才能继续下单':
    'Tienes un pedido con pago en efectivo sin pagar. Por favor, completa el pago antes de realizar otro pedido',
  '您在%s已完成现金单的支付': 'Tu pedido con pago en efectivo se pagó a las %s',
  支付本订单: 'Pagar el pedido',
  '补款支付已提交，请您耐心等待支付结果':
    'Pago enviado. Espera un momento para el resultado del pago',
  此店铺中的商品将被全部清空: 'Se eliminarán todos los productos de esta tienda',
  '确定删除吗？': '¿Confirmar eliminación?',
  此商品将被删除: 'Se eliminará este producto',
  同意: 'Aceptar',
  申请: 'Aplicar',
  取消: 'Cancelar',
  下单: 'Hacer este pedido',
  登录: 'Iniciar sesión',
  '前往“设置”': 'Ir a configuración',
  收货地址: 'Dirección de entrega',
  暂无服务: 'Ningún servicio disponible',
  获取订单失败: 'No se puede obtener la orden',
  您没有收藏的店铺: 'No tienes tiendas favoritas todavía',
  快去收藏喜欢的店铺吧: 'Agregar tiendas que te gustan a Favoritos',
  '已收藏，您可在“个人中心-我的收藏”中查看': '¡Nuevo favorito! Para ver, ve a Cuenta > Favoritos',
  '操作太快啦，请稍后尝试': 'Demasiados intentos. Intenta de nuevo más tarde.',
  已购菜品: 'Productos seleccionados',
  立即登录: 'Iniciar sesión ahora',
  查看更多店铺和优惠: 'Ver más tiendas y ofertas',
  没有更多店铺了: 'No hay más tiendas',
  '未找到匹配地址，请尝试搜索。':
    'No se encontraron direcciones que coincidan, prueba la función de búsqueda',
  更多商家入驻中: 'Diario se añaden más restaurantes',
  无法获取数据: 'Imposible recuperar los datos',
  '出错了，请重试': 'Hubo un problema. Inténtalo de nuevo.',
  添加: 'Agregar',
  去结算: 'Pagar',
  收起: 'Collapse',
  购物车已空: 'El carrito está vacío',
  请去商店加购菜品:
    'Abre la página de una tienda y luego agrega a tu carrito los artículos que deseas pedir',
  每份: 'c/u',
  全部: 'Todos',
  您好: 'Hola',
  '配送：': 'Entregar en : ',
  正在加载地址: 'Dirección en carga',
  更多: 'Más',
  寻找餐厅和美食: 'Buscar restaurantes o comida',
  查看全部: 'Ver todos',
  价格: 'Precio',
  推荐排序: 'Recomendados',
  时间: 'Tiempo',
  附近热门商家: 'Tiendas populares cercanas',
  暂无数据: 'No hay datos disponibles',
  必选: 'Obligatorio',
  查看详情: 'Ver detalles',
  已售罄: 'Agotado',
  多买多赠: 'Compra más, recibe más',
  您必须年满18岁且意识清醒: 'Debe tener al menos 18 años y estar mentalmente despejado',
  '您必须年满18周岁且处于清醒状态，未成年人不能购买酒精类饮品':
    'Debe tener al menos 18 años y estar mentalmente despejado. No se vende bebidas alcohólicas a los menores.',
  注意: 'Atención',
  无法获取商品正确信息: 'No recibió la información correcta de este producto',
  '您查看的商品暂未售卖，去看看其它商品吧':
    'El producto que estás viendo aún no está disponible, intenta pedir otros productos',
  可定制: 'Personalizar',
  更新: 'Actualizar',
  收起全部已售罄菜品: 'Ocultar todos los productos agotados',
  再来一单: 'Repedir',
  已完成: 'Finalizado',
  最近: 'Recientes',
  暂无订单: 'Todavía no hay pedidos',
  '预计配送时间：': 'Tiempo de entrega estimado:',
  进行中: 'En progreso',
  历史记录: 'Historial',
  我的订单: 'Mis pedidos',
  '合计：%s': 'Total: %s',
  重置: 'Restablecer',
  开启定位权限: 'Abrir configuración',
  去系统中开启定位权限:
    'Puedes habilitar los permisos de localización en la configuración de tu dispositivo',
  获取不到您的定位: 'No se pudo encontrar tu ubicación',
  开启定位服务: 'Habilitar los servicios de ubicación',
  可去系统中设置定位服务:
    'Puedes activar los servicios de localización en la configuración de tu dispositivo',
  '您未开启定位服务，无法获取定位': 'Servicios de localización deshabilitados',
  请输入手机号码: 'Ingresa un número de teléfono celular',
  进行中的订单: 'Pedidos en curso',
  请先同意使用条款和隐私政策: 'Por favor, acepta los Términos de uso y política de privacidad',
  请输入接收通知的手机号: 'Ingresa tu número de teléfono para notificarte',
  '我同意 ': 'Acepto ',
  请通知我: 'Enviado',
  去其他定位看看: 'Buscar otras ubicaciones',
  去热门商圈看看: 'Buscar distritos populares de negocios',
  提交: 'Enviar',
  使用条款和隐私政策: 'Términos de uso y política de privacidad',
  保持联系: 'Te mantendremos informado',
  查看可服务的城市: 'Ver las ciudades disponibles',
  查看可服务的国家: 'Ver los países disponibles',
  顾客: 'Comedor',
  骑手: 'Repartidor',
  请稍等: 'Por favor, espera…',
  '在您注册并同意我们的法律条款及隐私政策前，我们不会存储您的电话号码，也不会将其作为您的联系方式。':
    'Tu número no se almacenará ni usará como método principal para contactarte hasta que te registres y aceptes nuestros Términos y Condiciones y Política de privacidad.',
  '您可以使用这个新密码在所有%1$s服务（包括%2$s乘客、%3$s车主和%4$s外卖）中登录您的%5$s账号。':
    'Puedes usar esta nueva contraseña para todas las cuentas de %1$s (incluidos Pasajero de %2$s, Conductor de %3$s y %4$s Food) para iniciar sesión en tu cuenta %5$s.',
  '您正在更改当前的%1$s密码（%2$s乘客、%3$s车主和%4$s外卖均会受影响）。确定要更改吗？':
    'Estás cambiando tu contraseña actual de %1$s (incluidos Pasajero de %2$s, Conductor de %3$s y %4$s Food). ¿Confirmas este cambio?',
  无法添加银行卡: 'No se pudo agregar la tarjeta',
  搜索本店的菜品: 'Buscar productos de la tienda',
  '这个商店没有相关的商品。': '"No se encontraron resultados. "',
  '换个关键词试试！': 'Intenta con un término de búsqueda diferente.',
  店铺内搜索: 'Búsqueda en el menú',
  信用卡: 'Tarjeta bancaria',
  现金: 'Efectivo',
  配送费: 'Tarifa de entrega',
  优惠金额: 'Descuentos',
  '请补充楼层/公寓门牌号': 'Agregar piso y departamento',
  '补充楼层/公寓门牌，更快收到餐点':
    'Agregar el piso y número de departamento garantizará una entrega más rápida',
  '楼层/门牌号': 'Piso / Número de la calle',
  门牌号: 'Número de la calle',
  '公司/公寓名称': 'Nombre del empresa / departamento',
  建筑名称: 'Nombre del edificio',
  选择送餐地址: 'Selecciona una dirección',
  已过期: 'Expirado',
  备注: 'Comentarios',
  '正在支付…': 'Realizando el pago...',
  银行卡类型: 'Tipo de tarjeta',
  '添加信用卡/借记卡': 'Agregar una tarjeta de crédito/débito',
  支付成功: 'Se realizó el pago correctamente',
  支付方式: 'Método de pago',
  请选择支付方式: 'Selecciona un método de pago',
  选择: 'Selecciona',
  支付方式选择: 'Selecciona el método de pago',
  充值: 'Recargar',
  服务费: 'Tarifa de servicio',
  小额订单费: 'Tarifa de pedido pequeño',
  商家活动: 'Promoción de la tienda',
  菜品总价: 'Precio total de los productos',
  乘客的感谢红包: "Rider\\'s Tip",
  总计: 'Total',
  网络异常: 'Error de red',
  请检查网络连接: 'Comprueba tu conexión a Internet',
  暂不更新: 'Ahora no',
  否: 'No',
  '您想在骑手到达时收到推送通知吗？':
    '¿Te gustaría recibir una notificación cuando llegue el repartidor?',
  是: 'Si',
  '您有一个相同的订单正在处理中，请避免重复下单。':
    'Ya tienes un pedido idéntico en curso, evita realizar pedidos repetidos por favor.',
  '确定要取消订单吗？': '¿Estás seguro de que deseas cancelar la orden?',
  更换其他支付方式: 'Cambiar método de pago',
  已收到订单: 'Orden recibida',
  下单时间: 'Horario de la orden',
  正在寻找骑手: 'Buscando un repartidor',
  此订单由商家自行配送: 'La tienda entregará esta orden',
  查看订单详情: 'Detalles de la orden',
  券: 'Cupones',
  请输入正确邮箱: 'Ingrese una dirección de correo electrónico válido',
  '您收到了订单了吗？': '¿Recibiste tu orden?',
  评分: 'Calificar',
  商家评价: 'Calificación de la tienda',
  '退出页面后，当前评价会全部丢失，确认退出吗？':
    '¿Estás seguro? Si sales ahora, tu calificación no se guardará.',
  骑手评价: 'Calificación del repartidor',
  匿名提交: 'Enviar de forma anónima',
  购买商品: 'Comprar producto',
  取消订单: 'Cancelar pedido',
  联系骑手: 'Contactar al repartidor',
  联系客服: 'Contáctanos',
  联系商家: 'Comunícate con la tienda',
  '有问题吗？': '¿Tienes un problema?',
  未收到正确的订单信息: 'No se recibió la información correcta de la orden',
  未评价: 'Sin calificar',
  帮助商家了解您的想法: 'Envía tus comentarios a la tienda',
  '您对商家满意吗？': '¿Qué te pareció la tienda?',
  '您对骑手满意吗？': '¿Cómo fue tu experiencia con el repartidor?',
  订单编号: 'Nº de orden',
  '付款服务器忙，请稍后重试。':
    'El servidor de pago está ocupado. Inténtalo de nuevo en un momento.',
  '%1$s Food 优惠券': 'Cupones de %1$sFood',
  订单金额: 'Total de la orden',
  无: 'Ninguno',
  发送提醒: 'Enviar aviso',
  重新下单: 'Realizar una nueva orden',
  开发票: 'Enviar recibo',
  '点击“发送”，将收据发送至以下邮箱地址：':
    'Toca “Enviar” para enviar el recibo a la siguiente dirección:',
  输入你的邮箱: 'Ingresar dirección de correo electrónico',
  '收据已发送至：': 'Recibo enviado a:',
  '订单结束后，收据将自动发送至以下邮箱：':
    'Luego de que se entregue mi orden, envíenme el recibo por correo electrónico a:',
  发送: 'Enviar',
  邀请: '¡Invita ahora',
  订单跟踪: 'Seguimiento del pedido',
  商家券: 'Cupones de Tienda',
  小费: 'Propina',
  查看评价: 'Ver calificaciones',
  查看当前订单: 'Ver el pedido existente',
  支付失败: 'Error en el pago',
  '您的信用卡余额不足。建议您重新下单，并使用其他支付方式。系统将自动为您生成一个新订单。':
    'El saldo de tu tarjeta de crédito es insuficiente. Te recomendamos que realices una orden nueva y uses un método de pago diferente. El sistema creará una orden nueva automáticamente.',
  '网络不稳定，请尝试重新支付': 'La red es inestable. Vuelve a intentar pagar',
  '支付 %s': 'Pagar%s',
  '请在 15 分钟内支付，超时订单将自动取消。':
    'Paga dentro de los próximos 15 minutos para evitar la cancelación.',
  剩余支付时间: 'Tiempo restante para el pago',
  支付: 'Pedir',
  '很抱歉支付失败，请您重新尝试': 'No se pudo realizar el pago. Inténtalo de nuevo',
  '网络或系统错误，请稍后重试。': 'Error de red o del sistema, inténtalo de nuevo más tarde.',
  推荐: 'Sugerido',
  待验证: 'Esperando la comprobación',
  支付处理中: 'Procesamiento de pagos',
  '请稍后重试，并耐心等待支付结果。如果信息长时间无法更新，请联系客服':
    'Inténtalo de nuevo y espera un momento para ver los resultados de pago. Si después de un largo período la información no se ha actualizado, comunícate con soporte.',
  权限申请: 'Solicitud de permiso',
  '请务必授予定位、存储空间和电话使用权限，以确保%1$s外卖正常运行':
    'Se necesitan permisos de Ubicación, Almacenamiento y Teléfono para que %1$s Food funcione con normalidad',
  '请务必授予定位、存储空间和电话使用权限，以确保%1$s外卖正常运行。请在“设置”中授予这些权限\\n\\n设置路径：“设置” -> “%2$s外卖” -> “权限”':
    'Se necesitan permisos de Ubicación, Almacenamiento y Teléfono para que %1$s Food funcione con normalidad. Habilítalos desde la configuración\\n\\nRuta de la configuración: Configuración -> %2$s Food -> Permisos',
  '为保护隐私，他人无法看到您的真实电话号码':
    'Por motivos de privacidad, tu número real de teléfono no será visible para otros usuarios',
  '首个\\n订单': 'PRIMER\\nPEDIDO',
  马上分享: 'Comparte ahora',
  '分享至好友，\\n人人可赢': 'Comparte\\ncon tus amigos,\\n¡todos pueden ganar',
  兑换优惠券: 'Canjear cupón',
  兑换失败: 'No se pudo canjear el cupón',
  兑换成功: 'Cupón canjeado',
  去看看: 'Volver a la página de inicio',
  '恭喜您获得一张%s优惠券，快去使用吧':
    '¡Felicidades! Tienes un cupón de %s. Úsalo para ordenar ahora',
  '恭喜您获得%s张优惠券，快去使用吧':
    '¡Felicidades! Tienes un cupón de %s. Úsalo para ordenar ahora',
  法律条款及隐私政策: 'Términos y Condiciones y Aviso de Privacidad',
  在下列时间之前完成: 'Completar',
  修剪照片: 'Recortar la foto',
  从相册选择: 'Seleccionar de tu álbum de fotos',
  拍照: 'Tomar una foto',
  更换头像: 'Cambiar foto de perfil',
  邮件: 'Correo electrónico',
  请输入您的姓氏: 'Ingresa tu(s) apellido(s)',
  注销账户: 'Eliminar cuenta',
  密码: 'Contraseña',
  联系人电话: 'Teléfono',
  请输入您的名: 'Ingresa tu nombre',
  第三方账号登录: 'Redes sociales',
  个人资料: 'Mi perfil',
  为我们评分: 'Califícanos',
  '欢迎在应用商店中留下好评，我们将不胜感激！':
    'Déjanos una calificación en la app store, ¡Realmente lo apreciaremos!',
  '请先安装 Google Play 或合适的浏览器，然后重试':
    'Instala Google Play o un navegador apropiado y vuelve a intentarlo',
  '喜欢我们的服务吗？': '¿Cómo estuvo nuestro servicio?',
  未验证: 'Sin verificar',
  邮箱地址: 'Dirección de correo electrónico',
  '输入姓名/公司名': 'Ingresar nombre de la persona/compañía',
  此信息不能为空: 'Falta información obligatoria',
  '个人姓名/公司名称': 'Nombre de la persona/compañía',
  保存: 'Save',
  收据发送成功: 'Recibo enviado',
  '已发送至：': 'Enviado a:',
  '验证后点击发送，下次订单结束将自动发送收据':
    'Toca “ENVIAR” luego de la verificación. Te enviaremos automáticamente por correo electrónico los próximos recibos cuando se complete cada orden.',
  '保存信息后，将会在订单结束后为您自动发送收据':
    'Para que te envíen los recibos por correo electrónico automáticamente, guarda tu información',
  去验证: 'Verificar',
  输入新的送餐地址: 'Ingresa una dirección de entrega nueva',
  '未找到任何结果。试试其他搜索字词':
    'No se encontraron resultados, intenta con otros términos de búsqueda',
  试试其他搜索字词: 'intenta con otros términos de búsqueda',
  未找到任何结果: 'No se encontraron resultados',
  清除搜索历史记录: 'Eliminar el historial de búsqueda',
  最近搜过: 'Búsquedas recientes',
  '抱歉，未找到任何结果': 'No se encontraron resultados',
  热门搜索: 'Búsquedas populares',
  购买过的店铺: 'Tiendas que compraste',
  '账号安全始终是%1$s的头等大事。我们需要对您进行身份验证，以确保您的账号安全。':
    '"La seguridad de tu cuenta es siempre la máxima prioridad de %1$s. Para garantizar la seguridad de tu cuenta, se necesita autenticación para verificar tu identidad "',
  '验证失败，请尝试其他验证方式':
    'Imposible verificar; prueba con un método de verificación diferente',
  '验证失败，暂时无法为您提供服务': 'Imposible verificar; no podemos darte servicio temporalmente',
  您可以使用其他方式验证身份: 'Puedes usar un método diferente para verificar tu identidad',
  暂时无法为您提供服务: 'Por el momento, no podemos darte servicio',
  '此账号已经过验证，请使用其他账号':
    'Esta cuenta ya está verificada; utiliza una cuenta diferente',
  您可以使用其他银行卡验证身份:
    'Puedes usar una tarjeta bancaria diferente para verificar tu identidad',
  验证失败: 'Imposible verificar',
  验证成功: 'Verificación exitosa',
  安全验证: 'Verificación de seguridad',
  通过CPF验证: 'Verificar con CPF',
  使用银行卡验证: 'Verificar con tarjeta de crédito',
  '使用 CURP 验证': 'Verificar con CURP',
  使用Facebook验证: 'Verificar con Facebook',
  '为确保安全，请先验证您的身份': 'Por tu seguridad, verifica primero tu identidad',
  快速认证: 'Verificar ahora',
  账号安全: 'Seguridad de la cuenta',
  '订单编号：%s': 'Nº de orden: %s',
  未连接服务: 'Servicio no conectado',
  设置: 'Configuración',
  '关于%1$s外卖': 'Acerca de %1$s Food',
  确认您的邮箱: 'Confirma tu correo electrónico',
  数据生成中: 'Se están preparando tus datos',
  '您可下载的数据包括个人信息、订单明细、绑卡信息，我们将为您提供最近3个月内的数据，若要查看全部的数据您可联系客服获取。收到您的申请后，我们将准备个人数据Excel表文件，并在24小时内发送至您的邮箱，为了保证数据准确性，您可在72小时后再次下载数据。':
    'Los datos descargables pueden incluir tu información personal, los detalles del pedido y la información de la tarjeta vinculada. La información abarcará los últimos tres meses. Si deseas acceder a todos tus datos, ponte en contacto con Soporte DiDi. Una vez recibida tu solicitud, prepararemos un archivo Excel con tus datos personales y te lo enviaremos por correo electrónico dentro de las siguientes 24 horas. Para asegurar que la información sea correcta, puedes descargar otra copia de tus datos luego de esas 72 horas.',
  数据已生成: 'Tus datos ya están listos para descargar',
  '您可下载的数据包括个人信息、订单明细、绑卡信息，我们将为您提供最近3个月内的数据，若要查看全部的数据您可联系客服获取。':
    'Los datos descargables pueden incluir tu información personal, los detalles del pedido y la información de la tarjeta vinculada. La información abarcará los últimos tres meses. Si deseas acceder a todos tus datos, por favor ponte en contacto con Soporte DiDi.',
  下载个人数据: 'Descargar datos personales',
  个性化推荐设置: 'Recomendaciones personalizadas',
  退出登录: 'Cerrar sesión',
  隐私: 'Privacidad',
  折扣与资讯: 'Comunicaciones y ofertas especiales',
  个人数据: 'Información personal',
  系统权限设置: 'Permisos del sistema',
  语言: 'Idiomas',
  允许访问相机权限: 'Permitir acceso a la cámara',
  '关闭相机权限，将无法进行扫码、直接拍照上传照片，确认关闭？':
    'Si deshabilitas el acceso a la cámara, no podrás escanear los códigos QR o tomar y subir fotos. ¿Estás seguro?',
  '可进行扫码、直接拍照上传照片等': 'Escanear códigos QR, tomar y subir fotos',
  '为了向您提供更好的用户服务体验，在特定场景可能需要向您申请以下手机权限。':
    'Para disfrutar de la mejor experiencia de usuario, habilita los siguientes permisos.',
  允许访问位置信息权限: 'Permitir el acceso a la ubicación',
  '关闭定位权限，将无法根据您的定位获得可服务商家，确认关闭？':
    'Si deshabilitas el acceso a la ubicación, no podrás ver las tiendas disponibles cercanas. ¿Estás seguro?',
  根据您的定位信息提供可服务的商家: 'Ver tiendas disponibles cercanas a tu ubicación',
  允许麦克风权限: 'Permitir acceso al micrófono',
  '关闭麦克风权限，将无法给商家或骑手发送语音消息，确认关闭？':
    'Si deshabilitas el acceso al micrófono, no podrás enviar mensajes de voz a repartidores o tiendas a través de la mensajería instantánea. ¿Estás seguro?',
  您可通过IM功能和商家或骑手进行语音沟通:
    'Enviar mensajes de voz a tiendas y repartidores a través de mensajería instantánea',
  允许通知权限: 'Permitir notificaciones',
  '关闭通知权限，将无法获取订单动态信息及优惠信息等，确认关闭？':
    'Si deshabilitas las notificaciones, no podrás obtener información en tiempo real sobre los pedidos y las ofertas especiales. ¿Deseas continuar?',
  可为您发送订单动态信息及优惠信息等:
    'Recibir alertas en tiempo real de los pedidos y de ofertas especiales',
  允许访问相册权限: 'Permitir acceso a fotos',
  '关闭相册权限，将无法进行图片或文件的保存和读取，确认关闭？':
    'Si deshabilitas el acceso a las fotos, no podrás guardar ni acceder a las fotos y a los archivos. ¿Estás seguro?',
  可帮您实现图片和文件的保存和读取等: 'Guardar y acceder a fotos y archivos',
  '关闭后，无法根据您的兴趣爱好、消费习惯为您推荐服务。确认关闭？':
    'Desactivar esta opción nos impedirá recomendarte servicios basados en tus intereses, preferencias y hábitos de compra. ¿Desactivar de todas formas?',
  我的收藏: 'Favoritos',
  帮助: 'Ayuda',
  邀请好友: 'Invitar amigos',
  订单: 'Pedidos',
  钱包: 'Monedero',
  '开始使用%1$s Food服务': 'Empezar a usar %1$s Food',
  跳过: 'Saltar',
  '随时随地\\n享受\\n美好食物': 'Tus\\nrestaurantes\\nfavoritos\\ndirecto\\na tu puerta',
  购物车: 'Carrito',
  重试: 'Intentar de nuevo',
  请稍后重试: 'Inténtalo más tarde',
  '此订单由商家自行配送。点击“支付”，代表您已知晓您的姓名、地址和联系方式将会发送给商家用于配送。':
    'La tienda entregará este pedido. Al hacer clic en el botón de pago, permites que enviemos tu nombre, tu dirección y tu número de contacto a la tienda para que pueda realizar la entrega.',
  查看购物车: 'Ver carrito',
  修改: 'Modificar',
  '再次点击即可退出%1$s外卖': 'Haz clic de nuevo para salir de %1$s Food',
  '加载失败，请点击重试': 'Imposible cargar; haz clic en para volver a intentar',
  松开以刷新: 'Suelta para actualizar',
  无法清除缓存: 'Imposible borrar la memoria caché',
  已清除缓存: 'Se borró la memoria caché correctamente',
  '确定要清除缓存吗？': '¿Deseas borrar el caché?',
  提示: 'Notificación',
  '确认退出吗？': '¿Estás seguro de que deseas cerrar sesión?',
  账号与安全: 'Cuenta y seguridad',
  清除缓存: 'Borrar caché',
  评价我们的服务: 'Califica nuestro servicio',
  法律条款: 'Legal',
  用户指南: 'Manual de usuario',
  注销: 'Eliminar',
  兑换码: 'Código promocional',
  立即领券: 'Canjear ahora',
  登陆领券: 'Iniciar sesión para canjear',
  有效期至: 'Vigente hasta el',
  好: 'Aceptar',
  确定: 'Confirmar',
  商品缺货时: 'Si un plato está agotado',
  '您所选择的商品缺货时，您可选择以下方式让骑手处理':
    '¿Qué debe hacer el socio repartidor si un plato está agotado?',
  缺货退款明细: 'Detalles del reembolso',
  退款详情: 'Detalles del reembolso',
  退款方式: 'Método de reembolso',
  PayPay: 'PayPay',
  再等等: 'Más tarde',
  '绑定 PayPay 账户': 'Agregar cuenta de PayPay',
  '您未安装相应的客户端或客户端版本过低，请安装后重试':
    'La app correspondiente no está actualizada o no está instalada. Instala la última versión e inténtalo de nuevo.',
  '抱歉，IM 功能目前不可用，请稍后再试！':
    'Lo sentimos, la función de MI no está disponible. Intenta de nuevo más tarde.',
  '抱歉，分享功能目前不可用，请稍后再试！':
    'Lo sentimos la función de compartir no está disponible. Intenta de nuevo más tarde.',
  购买: 'Realizar compra',
  '系统繁忙，请稍后重试': 'El sistema está ocupado. Inténtalo de nuevo más tarde',
  '请确认并勾选%1$s来使用%2$s。接下来我们将和您确认是否开启以下权限。':
    'Para poder usar %2$s, confirma y acepta nuestros %1$s. También necesitamos confirmar que los permisos siguientes estén activados en tu dispositivo.',
  继续: 'Continuar',
  '为了提供更好的服务，我们将通过短信、电子邮件、Push通知的方式，向您发送优惠活动和相关消息。您可以在登陆后通过设置去关闭此权限。':
    'Con este permiso podremos enviarte noticias y promociones por mensaje de texto, correo electrónico y notificaciones automáticas. Sin embargo, puedes desactivar este permiso en Configuración luego de registrarte.',
  隐私权限确认: 'Permisos de privacidad',
  确认发送数据: 'Enviar',
  数据已生成并加密: 'Se generó y encriptó tu información.',
  '如需更改邮箱，请到个人信息页修改':
    'De ser necesario, puedes actualizar tu correo electrónico en “Mi perfil”.',
  '将发送加密数据至您绑定的邮箱，请确认':
    'Confirma que tu correo electrónico sea correcto para que puedas recibir información de forma encriptada.',
  '将发送加密数据至您绑定的邮箱，您当前的邮箱尚未验证，请先验证':
    'Verifica que tu correo electrónico sea correcto para que podamos enviarte información de forma encriptada.',
  '文件解压所需密钥已通过短信形式发送到您的手机号%s。您可下载的数据包括个人信息、订单明细、绑卡信息，我们将为您提供最近3个月内的数据，若要查看全部的数据您可联系客服获取。':
    'La clave para descomprimir el archivo se ha enviado por mensaje de texto al número %s. Los datos para descargar pueden incluir información personal, detalles de pedidos e información de tarjetas. Los datos son de los últimos tres meses. Si quieres acceder y consultar toda tu información, por favor contacta a Soporte DiDi.',
  定位权限: 'Servicios de ubicación',
  通知权限: 'Notificaciones',
  '根据99Food用户条款，未成年用户不能使用99Food。为了您的安全，请验证您的CPF和生日信息':
    'De acuerdo con los Términos y Condiciones de 99Food, las personas menores de 18 años no pueden usar la aplicación. Por tu seguridad, ingresa tu número de CPF y tu fecha de nacimiento.',
  生日: 'Fecha de nacimiento',
  CPF验证: 'Verificación del CPF',
  '为了提供更好的服务，我们将针对您的兴趣、喜好和购买习惯为您提供推荐服务。您可以在登陆后通过设置中的隐私选项去关闭此权限。':
    'Para mejorar tu experiencia, te enviaremos recomendaciones personalizadas basadas en tus intereses, preferencias y hábitos de compra. Puedes deshabilitar este permiso en la configuración de privacidad luego de ingresar a tu cuenta.',
  添加新地址: 'Agregar dir. nueva',
  '确认修改地址吗？': '¿Cambiar dirección?',
  修改地址: 'Cambiar dirección',
  修改地址失败: 'No se pudo cambiar la dirección',
  '您只有一次修改地址的机会，确认修改地址后，您的配送地址将变更为新地址，您的预计送达时间会根据新地址发生变化。':
    'La dirección se puede cambiar una sola vez y puede causar que cambie el tiempo estimado de entrega.',
  '请确认您的新地址，您只有一次修改的机会。': 'Confirma la dirección (sólo un cambio permitido).',
  给配送员的备注: 'Notas para el socio repartidor',
  请选择标签或填写评语: 'Selecciona una etiqueta o deja un comentario',
  请评价: 'Califica tu pedido',
  知道了: 'Aceptar',
  '您的订单支付不成功，请联系发卡行获取具体信息':
    'No se pudo procesar el pago para este pedido. Contacta a la entidad emisora de tu tarjeta para obtener más información.',
  '已获得%d个赠品': 'Recibiste %d productos gratis',
  已获得1个赠品: 'Recibiste 1 productos gratis',
  "\n有<font color=\\'#FF7A45\\'>%1$d</font>家<fontcolor=\\'#FF7A45\\'>%2$s</font>超出配送范围":
    "<font color=\\'#FF7A45\\'>%1$d</font> de <font color=\\'#FF7A45\\'>%2$s</font> situadas fuera de entrega",
  "\n有<font color=\\'#FF7A45\\'>1</font>家<font color=\\'#FF7A45\\'>%2$s</font>超出配送范围":
    "<font color=\\'#FF7A45\\'>1</font> de <font color=\\'#FF7A45\\'>%2$s</font> situada fuera de entrega",
  '查看全部(%d)': 'Ver todos (%d)',
  '查看全部(1)': 'Ver todos (1)',
  '查看全部商家(%d)': 'Ver todas las tiendas (%d)',
  '查看全部商家(1)': 'Ver todas las tiendas (1)',
  '选择%d个': 'Seleccionar %d',
  选择1个: 'Seleccionar 1',
  '同一个商品最多加购%d个': 'Se pueden pedir hasta %d unidades de este artículo',
  同一个商品最多加购1个: 'Sólo se puede pedir 1 unidad más de este artículo',
  '展开全部已售罄菜品 (%d)': 'Mostrar todos los productos agotados (%d)',
  '展开全部已售罄菜品 (1)': 'Mostrar todos los productos agotados (1)',
  '%d分钟': '%d minuto(s)',
  '1分钟': '1 minuto',
  '限购菜品只能购买%d个，超出数量按原价购买':
    'En productos con descuento limitado a %d artículos, los pedidos adicionales se cobran al precio original',
  '限购菜品只能购买一个，超出数量按原价购买':
    'En artículos con descuento limitado a 1 artículo, los pedidos adicionales se cobran al precio original',
  '加购%d': 'Agregar %d',
  加购: 'Agregar 1',
  '最多选择%d个': 'Límite %d',
  最多选择1个: 'Límite 1',
  '收起全部(%d)': 'Contraer todo (%d)',
  '收起全部(1)': 'Contraer todo (1)',
  '再加1个可免费获得%d个': 'Agregar 1 más para recibir %d gratis',
  再加1个可免费获得1个: 'Agregar 1 más para recibir 1 gratis',
}
