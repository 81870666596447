// safari无痕浏览器 使用fp2, 其他的使用fp1
import { fp1, fp2 } from './fp.js'
import { isBrowser } from '../../utils/browser.js'
import { getLocalStorage, setLocalStorage } from '@/utils/localStorage.js'

const PUB_FID = 'pubFid'
const SAFARI = 'Safari'

// 是否是safari浏览器
export const isSafari = isBrowser(SAFARI)


// 获取服务层传入的hash
const getHashInfo = () => {
  const metaTag = document.querySelector('meta[name="hash"]')
  return metaTag.getAttribute('info')
}

const getFp = async () => {

  const hash_info = getHashInfo()

  // 如果是safari，使用设备指纹2
  if (isSafari) {
    return `fp2_${fp2()},${hash_info}`
  }

  // 否则使用设备指纹1
  const res = await fp1()
  return `fp1_${res},${hash_info}`
}


export const getPubFid = async function() {

  const pubFid = getLocalStorage(PUB_FID)
  // 如何有值，且值内不包含'{{hash_info}}'
  if (pubFid && pubFid.indexOf('{{hash_info}}') === -1) {
    return pubFid
  }
  // 避免获取值失败导致埋点上报错误
  let res = ''
  try {
    res = await getFp()
    setLocalStorage(PUB_FID, res)
  } catch{}
  return res

}

export const getFpVal = async (val) => {
  const res = await  getPubFid()
  const arr = res.split(',')
 if (val === 'ip_fp') {
    return arr[1]
  } else if (val === 'ua_fp') {
    return arr[2]
  } else if(val === 'uuid_fp') {
    return arr[3]
  }
}

