import queryString from 'query-string'
import UrlHandler from './urlHandler'

class UrlHashHandler {
  constructor(url) {
    this.urlHandler = new UrlHandler(url)
    this.hashKey = '_callbackType'
  }

  getUrl() {
    return this.urlHandler.getUrl()
  }

  getHashObject() {
    return queryString.parse(this.urlHandler.getHash())
  }

  getHash() {
    return this.urlHandler.getHash()
  }

  set(hashValue) {
    if (this.exist()) {
      return this.urlHandler.getUrl()
    }
    const hashObject = this.getHashObject()
    hashObject[this.hashKey] = hashValue
    this.urlHandler.set({ hash: queryString.stringify(hashObject) })
    return this.urlHandler.getUrl()
  }

  get() {
    const hashObject = this.getHashObject()
    return hashObject[this.hashKey]
  }

  clear() {
    if (!this.exist()) {
      return this.urlHandler.getUrl()
    }
    const hashObject = this.getHashObject()
    delete hashObject[this.hashKey]
    this.urlHandler.set({ hash: queryString.stringify(hashObject) })
    return this.urlHandler.getUrl()
  }

  exist() {
    const hashObject = this.getHashObject()
    return hashObject[this.hashKey] !== undefined
  }
}

export default UrlHashHandler
