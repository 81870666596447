const countryId = {
  JP: 392,
  MX: 484,
  CO: 170,
  CR: 188,
}
const countryCallingCode = {
  JP: '+81',
  MX: '+52',
  CO: '+57',
  CR: '+506',
  BR: '+55',
  PE: '+51',
}
const cityId = {
  JP: 0,
  MX: 0,
  CO: 0,
  CR: 0,
  PE: 0,
}
const canonicalCountryCode = {
  JP: 'JP',
  MX: 'MX',
  CO: 'CO',
  PE: 'PE',
  CR: 'CR',
}
const appIdPC = {
  JP: 120838,
  MX: 120838,
  CR: 200039,
}
const lawUrlH5 = {
  JP: 'https://img0.didiglobal.com/static/dpubimg/16aebd1620a29cccdf3914691d87c1bb/index.html?lang=ja-JP&location_country=JP',
  MX: 'https://privacycenter.didiglobal.com/MX/privacy-notice/6c74a6722b783a1ad67860f868b96553/Mexico',
  CO: 'https://privacycenter.didiglobal.com/CO/privacy-notice/23a14eee63f21e4612a0757e4079639e/Colombia',
  CR: 'https://img0.didiglobal.com/static/dpubimg/16aebd1620a29cccdf3914691d87c1bb/index.html?lang=es-419&location_country=CR',
}
const lawUrFooter = {
  MX: 'https://privacycenter.didiglobal.com/MX/privacy-notice/6c74a6722b783a1ad67860f868b96553/Mexico',
  CO: 'https://privacycenter.didiglobal.com/CO/privacy-notice/23a14eee63f21e4612a0757e4079639e/Colombia',
}
export {
  countryId,
  appIdPC,
  lawUrlH5,
  countryCallingCode,
  cityId,
  canonicalCountryCode,
  lawUrFooter,
}
