import { is_mobile } from '@/utils/os';
import { GET_HELP_URL } from '@/const/personalCenter';
export default [
  {
    path: '/user/rewards',
    meta: {
      title: '我的奖励',
    },
    component: () =>
      import(
        /* webpackChunkName: "user" */
        '@/views/User/rewards/index'
      ),
  },
  {
    path: '/user/help',
    meta: {
      title: '帮助中心',
      hideHeader: true,
    },
    beforeEnter: (to, from, next) => {
      const { query = {} } = to;
      const url = GET_HELP_URL(query);
      if (is_mobile()) {
        window.location.href = url;
      } else {
        window.open(url);
      }
    },
    component: () =>
      import(
        /* webpackChunkName: "user" */
        '@/views/User/Help/index'
      ),
  },
  {
    path: '/user/profile',
    meta: {
      title: '个人简介',
    },
    component: () =>
      import(
        /* webpackChunkName: "user" */
        '@/views/User/Profile/index'
      ),
  },
  {
    path: '/user/coupon',
    meta: {
      title: '我的优惠券',
    },
    component: () =>
      import(
        /* webpackChunkName: "user" */
        '@/views/User/Coupon/index'
      ),
  },
  {
    path: '/user/coupon/unavailable',
    meta: {
      title: '我的优惠券',
    },
    component: () =>
      import(
        /* webpackChunkName: "user" */
        '@/views/User/Coupon/unavailable'
      ),
  },
];
