/*
 * @Author: amywulan
 * @Date: 2021-07-Mo 11:41:54
 * @Last Modified by:  amywulan
 * @Last Modified time: 2021-07-Mo 11:41:54
 * @description: 存放管理所有页面路由
 */
import { requireSubRoutes } from '@/utils/requireContext'
// 深度搜索routes下的js文件
const autoInjectRoutes = requireSubRoutes(require.context('./modules/', true, /\.js$/))

export default [
  {
    path: '/',
    name: 'store',
    meta: {
      title: '商家页',
      showFooter: true,
      signOutNoBackFeed: true,
      loginWithoutVerification: true,
      notCheckPl: true,
      unScrollTop: true, // 禁止规格页打开时页面滚动到顶部
    },
    component: () => import(/* webpackChunkName: "store" */ '@/views/Store/index'),
  },
  {
    path: '/400',
    name: 'error400',
    component: () => import(/* webpackChunkName: "errorPage" */ '@/views/errorPage/index'),
    meta: {
      showFooter: true,
      notCheckPl: true,
      loginWithoutVerification: true,
    },
  },
  // autoInjectRoutes
  ...autoInjectRoutes,
  // ...others
  {
    path: '*',
    component: () => import(/* webpackChunkName: "store" */ '@/views/Store/index'),
    meta: {
      title: '商家页',
      showFooter: true,
      signOutNoBackFeed: true,
      loginWithoutVerification: true,
      notCheckPl: true,
      unScrollTop: true, // 禁止规格页打开时页面滚动到顶部
    },
  },
]
