/*
 * @Author: amywulan
 * @Date: 2021-07-Fr 11:44:27
 * @Last Modified by: amywulan
 * @Last Modified time: 2021-07-Fr 11:44:27
 * @description 获取url的参数
 * urlParam(key)
 * urlParam(key, 'https://www.didi.com?test=1')
 */
export default (name, url) => {
  const results = new RegExp('[\\?&]' + name + '=([^&#]*)').exec(url || window.location.href)
  if (!results) {
    return ''
  }
  return results[1] || ''
}
