/**
 * 提单页详情：http://wiki.intra.xiaojukeji.com/pages/viewpage.action?pageId=451224206
 * [done] 提单页详情：bill/info cartId、shopInfo.shopId、sections.components.data.itemsInfo.shopId、sections.components.data.itemsInfo.items.itemId
 *
 * 购物车详情：http://wiki.intra.xiaojukeji.com/pages/viewpage.action?pageId=364062468
 * [done] 购物车详情：cart/info cart/setItemsAmount cart/setItem: itemId
 *
 * 提单页列表：http://wiki.intra.xiaojukeji.com/pages/viewpage.action?pageId=451224489
 * 购物车列表：bill/list: cartId、shopId、itemId
 */
import { isArray } from './types';

class Precision {
  _transSections(sections) {
    if (!isArray(sections)) {
      return sections;
    }
    sections.forEach(section => {
      section.components.forEach(component => {
        if (component?.data?.itemsInfo) {
          component.data.itemsInfo.shopId = component.data.itemsInfo.shopIdStr;
          component.data.itemsInfo.items.forEach(item => {
            item.itemId = item.itemIdStr;
          });
        }
      });
    });
    return sections;
  }

  _transShopInfo(shopInfo) {
    shopInfo.shopId = shopInfo.shopIdStr;
    return shopInfo;
  }

  _transItems(items) {
    if (!isArray(items)) {
      return items;
    }
    items.forEach(item => {
      item.itemId = item.itemIdStr;
    });
    return items;
  }

  transBillInfo(data) {
    data.cartId = data.cartIdStr;
    data.shopInfo = this._transShopInfo(data.shopInfo);
    data.sections = this._transSections(data.sections);
    return data;
  }

  transCartInfo(data) {
    data.items = this._transItems(data.items);
    return data;
  }

  transBillList(data) {
    if (!isArray(data.bills)) {
      return data;
    }
    data.bills.forEach(bill => {
      bill.cartId = bill.cartIdStr;
      bill.shopInfo = this._transShopInfo(bill.shopInfo);
      bill.sections = this._transSections(bill.sections);
    });
    return data;
  }
}

export default new Precision();
