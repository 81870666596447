// MX 墨西哥城，CR 圣何塞
const data = {
  JP: {
    cityId: 81400100,
    address: '日本、福岡県',
    addressAll: '福冈市, 日本、福岡県',
    addressAllDisplay: '日本、福岡県',
    city: '福岡県',
    coordinateType: 'wgs84',
    countryCode: 'JP',
    countryId: 81,
    countyGroupId: 814001000001,
    countyId: 81400114,
    displayName: '福冈市',
    lat: 33.5901838,
    lng: 130.4016888,
    poiId: 'ChIJKYSE6aHtQTURg4c5NplyCvY',
    poiSrcTag: 'manual_sug',
    poiType: '',
    searchId: '0a9e23de61c2c08e176d3b6a12828e02',
    srcTag: 'geocode',
  },
  MX: {
    poiId: '',
    addressAllDisplay: '',
    city: '',
    cityId: 0,
    countryCode: 'MX',
    countryId: 0,
    lat: 0,
    lng: 0,
    displayName: '',
    address: '',
    addressAll: '',
    coordinateType: '',
    poiSrcTag: '',
    srcTag: '',
    searchId: '',
    distStr: '',
    poiType: '',
    countyId: 0,
    countyGroupId: 0,
  },
  CR: {
    poiId: '',
    addressAllDisplay: '',
    city: '',
    cityId: 0,
    countryCode: 'CR',
    countryId: 0,
    lat: 0,
    lng: 0,
    displayName: '',
    address: '',
    addressAll: '',
    coordinateType: '',
    poiSrcTag: '',
    srcTag: '',
    searchId: '',
    distStr: '',
    poiType: '',
    countyId: 0,
    countyGroupId: 0,
  },
  CO: {
    poiId: '',
    addressAllDisplay: '',
    city: '',
    cityId: 0,
    countryCode: 'CO',
    countryId: 0,
    lat: 0,
    lng: 0,
    displayName: '',
    address: '',
    addressAll: '',
    coordinateType: '',
    poiSrcTag: '',
    srcTag: '',
    searchId: '',
    distStr: '',
    poiType: '',
    countyId: 0,
    countyGroupId: 0,
  },
  PE: {
    poiId: '',
    addressAllDisplay: '',
    city: '',
    cityId: 0,
    countryCode: 'PE',
    countryId: 0,
    lat: 0,
    lng: 0,
    displayName: '',
    address: '',
    addressAll: '',
    coordinateType: '',
    poiSrcTag: '',
    srcTag: '',
    searchId: '',
    distStr: '',
    poiType: '',
    countyId: 0,
    countyGroupId: 0,
  },
  CL: {
    poiId: '',
    addressAllDisplay: '',
    city: '',
    cityId: 0,
    countryCode: 'CL',
    countryId: 0,
    lat: 0,
    lng: 0,
    displayName: '',
    address: '',
    addressAll: '',
    coordinateType: '',
    poiSrcTag: '',
    srcTag: '',
    searchId: '',
    distStr: '',
    poiType: '',
    countyId: 0,
    countyGroupId: 0,
  },
  DO: {
    poiId: '',
    addressAllDisplay: '',
    city: '',
    cityId: 0,
    countryCode: 'DO',
    countryId: 0,
    lat: 0,
    lng: 0,
    displayName: '',
    address: '',
    addressAll: '',
    coordinateType: '',
    poiSrcTag: '',
    srcTag: '',
    searchId: '',
    distStr: '',
    poiType: '',
    countyId: 0,
    countyGroupId: 0,
  },
}

class DefaultPoi {
  constructor(option) {
    const { data, defaultRegion = 'MX' } = option
    this.data = data
    this.defaultRegion = defaultRegion
  }

  getPoi(region) {
    return this.data[region] || this.data[this.defaultRegion]
  }
}

const defaultPoi = new DefaultPoi({
  data,
  defaultRegion: 'MX',
})

export default defaultPoi
