import '@/assets/scss/elFit.scss'
import '@/assets/styles/iconfont.css'
import '@/assets/styles/reset.scss'
import '@/assets/styles/color-theme.scss'
import '@/lib/wsg/wsgchallenge.js' // 挑战应答
import '@/lib/wsg/wsgsig.js' // dd05接入
import '@/r-fusion-pc/com' // 自定义/插件式 组件
import '@/r-fusion-pc/r-fusion-pc' // 组件库
import 'babel-polyfill'
import 'es6-promise/auto'
import './directives/index' // 指令
import config from '@/config/index'
import i18n from '@/i18n/locale'
import router from '@/router'
import store from '@/store/index'
import LodashFn from '@/utils/lodashFn'
import screenSizeType from '@/utils/screenSizeType'
import { swRegister } from '@/utils/swRegister'
import Vue from 'vue'

import { initWsgSDK } from '@/lib/wsg/wsgenv'
initWsgSDK()

// 加载所有VUE Plugins
require('@/plugins/index')
require('@/utils/fingerprint')

Vue.use(screenSizeType, {
  store,
})
Vue.use(LodashFn)

Vue.config.productionTip = false
Vue.config.devtools = config.env.isDev

// Registering Our Service Worker
swRegister()
export { Vue, i18n, router, store }
