import { post } from '@/lib/request/index'
import { cart, bill } from '../apiConstants'

// 新增/更新 购物车菜品
export function addOrUpdateItem(param) {
  return post(cart.CART_SETITEM, { param })
}

// 菜品详情接口
export function cartInfo(param) {
  return post(cart.CART_INFO, { param })
}

// 菜品详情接口
export function setItemsAmount({ cartId, content, revision }) {
  return post(cart.CART_SETAMOUNT, {
    param: {
      cartId,
      content: JSON.stringify(content),
      revision,
    },
  })
}

// 全局购物车
export function golbalBillList(param) {
  return post(bill.GLOBAL_CART_BILL_LIST, { param })
}

// 清空购物车
export function golbalCartClear(param) {
  return post(cart.GLOBAL_CART_CLEAR, { param })
}

// 恢复购物车、提单页
export function recoveryCart({ orderId }) {
  return post(bill.BILL_INFO, { param: { orderId } })
}
