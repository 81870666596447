<template>
  <div class="chang-phone">
    <span>{{ text }}</span>
    <span @click="downloadApp" class="finger-style down-style">
      {{ $t('下载滴滴外卖App') }} </span
    ><i class="down-style icon-outline_entrance"></i>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getDevice } from '@/utils/os';

import config from '@/config/default';
import { downloadPopupShow } from './downloadPopup.js';
import i18n from '@/i18n/locale';

export default {
  name: 'DownloadApp',
  props: {
    text: {
      type: String,
      default: i18n.t('FoodC_supplement_Modify_Mobile_ZJkT'),
    },
  },
  computed: {
    ...mapState({
      isH5: ({ common }) => common.isH5,
    }),
  },
  methods: {
    downloadPopupShow,
    handleAppStoreClick() {
      this.openUrl(config.appMap.ios.url);
    },
    handleGooglePlay() {
      this.openUrl(config.appMap.android.url);
    },
    openUrl(downLoadUrl) {
      window.open(downLoadUrl);
    },
    downloadApp() {
      if (this.isH5) {
        if (getDevice() === 1) {
          this.handleAppStoreClick();
        } else {
          this.handleGooglePlay();
        }
      } else {
        this.downloadPopupShow();
      }
    },
  },
};
</script>

<style lang="scss" scoped src="./index.scss"></style>
