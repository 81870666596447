import // shop
'@/api/apiConstants'

// const { SHOP_INDEX } = shop;

/**
 * @desc api => 埋点id 绑定关系 [通过eventId获取对应接口里的rec_id]
 */
export const API_EVENT_CONFIG = {
  // 商家页
  // [SHOP_INDEX] : [
  //   'sailing_c_x_shop_common_sw', // 展示
  // ]
}
