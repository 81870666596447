export function isObject(obj) {
  let toStr = Object.prototype.toString;
  return toStr.call(obj) === '[object Object]';
}

export function isArray(obj) {
  let toStr = Object.prototype.toString;
  return toStr.call(obj) === '[object Array]';
}

export function isEmptyObject(obj) {
  return isObject(obj) && Object.keys(obj).length === 0;
}

export function isNumber(obj) {
  let toStr = Object.prototype.toString;
  return toStr.call(obj) === '[object Number]';
}


export function isStorage(obj) {
  let toStr = Object.prototype.toString;
  return toStr.call(obj) === '[object Storage]';
}