import api from '@/api'
import { getLocalStorage, setLocalStorage } from '@/utils/localStorage'
import { Decrypt } from '@/utils/crypto'
import { userCurrentOrientation } from '@/utils/userCurrentOrientation.js'
import { isEmpty } from 'lodash-es'
const ADDRESS_TYPE = {
  PREV_ORDER: 1,
  CURRENT_LOCATE: 2,
  EMPTY: 3,
  CACHE: 4,
}

const state = {
  isH5: false, // 是否是手机端
  isPayCallback: false,

  openedCityList: [], // 开国国家列表
  token: '',
  uid: '',
  locale: '',
  country: 'MX',
  config: {},
  // 登录
  isLogin: false,

  sidebar: {
    banner: null,
    selfInfo: {},
    sideList: [
      {
        items: [],
      },
      {
        items: [],
      },
    ],
  },
  // 侧边栏icon映射，header&sidebar共用
  sideMap: {
    orders: {
      icon: 'icon-outlined_order',
    },
    payment: {
      icon: 'icon-outlined_payment',
    },
    messages: {
      icon: 'icon-outlined_message',
    },
    help: {
      icon: 'icon-outlined_help',
    },
    settings: {
      icon: 'icon-outlined_setting',
    },
    promotions: {
      icon: 'icon-outlined_special',
    },
    favorite: {
      icon: 'icon-outlined_collection',
    },
    invite: {
      icon: 'icon-outline_invitefriends',
    },
  },
  // 配送的时候用户姓名和电话号码
  userInfo: {
    firstName: '',
    lastName: '',
    phone: '',
    callingCode: '',
  },
  clientHeight: '', // 页面可视区域高度

  currentAddressInfo: {},
  latLngStatue: false, // 经纬度获取成功失败

  getAddressName: '', // 店铺页头部地址名称
  // 弹窗是否应该展示
  isShowDialogStatus: {
    isLogin: true, // 是否登录
    noAddress: true, // 是否无地址
    overDelivery: true, // 是否超出配送范围
    shopClosing: true, // 是否关店
    isCloseTimeToast: true, // 临近打烊toast
  },
  // 当前地址使用的是那个来源

  address_type: ADDRESS_TYPE.EMPTY,
  // 点击结算按钮登陆后跳转到提单页，状态暂存
  isCheckBillLogin: false,
  // 在点击结算以后，如果没有登陆，不可以二次点击出弹窗
  isLoginPopShowBefore: false, // 是否点击了结算
}

const mutations = {
  setLoginPopShowBefore(state, data) {
    state.isLoginPopShowBefore = data
  },
  setCheckBillLogin(state, data) {
    state.isCheckBillLogin = data
  },
  setAddressType(state, data) {
    state.address_type = data
  },
  setDialogStatus(state, data) {
    // state.isShowDialogStatus = {
    //   ...state.isShowDialogStatus,
    //   ...data,
    // }
  },
  setClientHeightState(state, data) {
    state.clientHeight = data
  },
  setIsH5State(state, data) {
    state.isH5 = data
  },
  serIsPayCallback(state, data) {
    state.isPayCallback = data
  },
  setLocale(state, data) {
    state.locale = data
  },
  setCountry(state, data) {
    state.country = data
  },
  setSidebar(state, data) {
    state.sidebar = data
    const phone = Decrypt(state.sidebar?.selfInfo?.phone)

    const a = 'JLKOtH+yjSEQNHuHotsS6g=='
    console.log('🚀 ~ setSidebar ~ yjSEQNHuHotsS6g:', Decrypt(a))

    state.sidebar.selfInfo.phone = phone
  },
  setIsLogin(state, data) {
    state.isLogin = data
  },
  updateOpenedCityList(state, data) {
    state.openedCityList = data
  },
  setConfig(state, data) {
    state.config = data
  },
  async getUserInfoMutations(state, param) {
    let { firstName, lastName, phone, callingCode } = getLocalStorage('userInfo') || {}
    if (!phone) {
      // 取地址接口返回的数据
      firstName = param?.firstName
      lastName = param?.lastName
      phone = param?.phone
      callingCode = param?.callingCode
      if (!phone) {
        const res = await api.sidebar()
        firstName = res?.selfInfo?.firstName || ''
        lastName = res?.selfInfo?.lastName || ''
        phone = Decrypt(res?.selfInfo?.phone) || ''
        callingCode = res?.selfInfo?.callingCode || ''
      }
    }
    state.userInfo.firstName = firstName
    state.userInfo.lastName = lastName
    state.userInfo.phone = phone
    state.userInfo.callingCode = callingCode
  },
  setUserInfoMutations(state, param) {
    const { firstName = '', lastName = '', phone = '', callingCode = '' } = param
    state.userInfo.firstName = firstName
    state.userInfo.lastName = lastName
    state.userInfo.phone = phone
    state.userInfo.callingCode = callingCode
    setLocalStorage('userInfo', param)
  },
  setUserPosition(state, param) {
    state.currentAddressInfo = param
  },
  setLatLngStatue(state, param) {
    state.latLngStatue = param
  },
  setCurrentAddressDisplayName(state, param) {
    state.getAddressName = param
  },
  setCatchAddress(state, param) {
    setLocalStorage('lastPoiAid', param?.aid || '')
    setLocalStorage('lastPoi', {
      ...(param.poi || {}),
      aid: param?.aid || '',
    })
    setLocalStorage('allAddressInfos', param || {})
  },
}
const actions = {
  setClientHeight({ commit }, param) {
    commit('setClientHeightState', param)
  },
  setIsH5({ commit }, param) {
    commit('setIsH5State', param)
  },
  async getSidebar({ commit }, param) {
    let res = {}
    try {
      res = await api.sidebar(param)
      commit('setSidebar', res)
    } catch {
      return false
    }
    return res
  },
  getUserInfo({ commit }, param) {
    commit('getUserInfoMutations', param)
  },
  setUserInfo({ commit }, param) {
    commit('setUserInfoMutations', param)
  },
  /**
   * @description: 获取token
   * @param {type}
   * @return
   */
  async getToken({ commit, state }, params) {
    let res = {}
    try {
      res = await api.getToken(params)
      setLocalStorage('ticket', res?.token)
      setLocalStorage('uid', res?.uid)
      state.token = res?.token
      state.uid = res?.uid
    } catch (e) {
      return false
    }
    return res
  },
  /**
   * @description: 获取已开城城市列表
   * @param {type}
   * @return
   */
  async getCityList({ commit }, params) {
    let res = {}
    try {
      res = await api.getCityList({})
      commit('updateOpenedCityList', res?.cities)
    } catch (e) {
      return false
    }
    return res
  },
  /**
   * @description: capi解析country
   * @param {type}
   * @return
   */
  async getCountry({ commit }, params) {
    let res = {}
    try {
      res = await api.getCountry({})
      commit('setConfig', res)
    } catch (e) {
      return false
    }
    return res
  },
  // 获取接口请求的地址位置信息
  async getRequestAddressInfo({ commit }, params) {
    const pageInit = params?.pageInit
    // 获取当前缓存地址
    const lastPoi = getLocalStorage('lastPoi') || {}
    if (lastPoi?.displayName) {
      commit('setCurrentAddressDisplayName', lastPoi?.displayName)
      if (!pageInit) {
        commit('setAddressType', ADDRESS_TYPE.CACHE)
      }
      return
    }
    // 获取当前定位poi
    let getLocationResult = {}
    try {
      getLocationResult = await userCurrentOrientation.getLocation()
    } catch (err) {
      getLocationResult = err
    }
    if (getLocationResult?.code === 0) {
      // 直接调用服务端接口获取对应的poi定位信息
      try {
        const res = await api.getAddressByLocation(getLocationResult?.location)
        if (res?.address?.poi?.displayName) {
          commit('setCurrentAddressDisplayName', res?.address?.poi?.displayName)
          if (!pageInit) {
            commit('setAddressType', ADDRESS_TYPE.CURRENT_LOCATE)
          }
          commit('setCatchAddress', res?.address)
          return
        }
      } catch {}
    }
    // 判断是否登录，登录后去取上一次下单的地址
    const isLogin = getLocalStorage('isLogin')
    if (isLogin) {
      let res = {}
      try {
        res = await api.getUserLastOrderAddress()
        // 如果有上次下单的地址，缓存起来
      } catch (err) {}
      if (!isEmpty(res?.poi)) {
        commit('setCurrentAddressDisplayName', res?.poi?.displayName)
        if (!pageInit) {
          commit('setAddressType', ADDRESS_TYPE.PREV_ORDER)
        }
        commit('setCatchAddress', res)
      }
    }
  },
  async getUserPosition({ commit }, params) {
    // 获取用户当前位置的lng lat
    let getLocationResult = {}
    try {
      getLocationResult = await userCurrentOrientation.getLocation()
    } catch (err) {
      getLocationResult = err
    }
    // 获取成功

    if (getLocationResult?.code === 0) {
      // 直接调用服务端接口获取对应的poi定位信息
      commit('setLatLngStatue', true)
      try {
        const res = await api.getAddressByLocation(getLocationResult?.location)
        commit('setUserPosition', res?.address || {})
      } catch {
        commit('setUserPosition', {})
      }
    } else {
      // 获取失败
      commit('setUserPosition', {})
      commit('setLatLngStatue', false)
    }
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
