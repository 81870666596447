import { trackEvent } from '@/lib/omega'
import { setSessionStorage, getSessionStorage, delSessionStorage } from '@/utils/sessionStorage'

/** @desc 添加唤起登录埋点标识 */
export const LOGIN_SOURCE_MAP = {
  checkout: 1,
  sidebar: 2,
}

/** @desc 添加唤起登录埋点标识 */
export const ADD_LOGIN_TAG_FUN = (pull_type = '') => {
  try {
    setSessionStorage('loginSource', pull_type)
  } catch (err) {}
}

/** @desc 唤起登录 */
export const sailing_c_x_sf_login_sw = async () => {
  const loginSource = getSessionStorage('loginSource') || '' // 来源

  trackEvent({
    eventId: 'sailing_c_x_sf_login_sw',
    eventLabel: '唤起登录页',
    attrs: {
      pull_type: loginSource,
    },
  })
}

/** @desc SF登录结果曝光 */
export const sailing_c_x_sf_login_result_sw = async () => {
  const afterLoginTag = getSessionStorage('afterLogin') || ''
  const loginSource = getSessionStorage('loginSource') || '' // 来源

  // 由于H5登录后会刷新页面，因此在登录逻辑上报埋点会导致埋点丢失概率大。所以基于标识进行上报
  if (afterLoginTag) {
    delSessionStorage('afterLogin') // 删除标识，毕竟页面刷新多次上报

    trackEvent({
      eventId: 'sailing_c_x_sf_login_result_sw',
      eventLabel: 'SF登录结果曝光',
      attrs: {
        login_result: 1, // 登录成功
        pull_type: loginSource,
      },
    })
  }
}
