import env from './env'
import sim from '@/utils/sim'
import android from '@/assets/images/download/download_app_android.png'
import ios from '@/assets/images/download/download_app_ios.png'
// import { isH5 } from '@/utils/tools'
import getCountryConfig from '@/config/countryConfig/index.js'
import { getCountry } from '@/utils/getLocalStorageCountry'
import { getLanguageLong } from '@/utils/locale'
const getLanguage = getLanguageLong() === 'es-419' ? 'es-MX' : getLanguageLong()

// https://rlab-sim060-soda-c-api.rlab.sim.intra.xiaojukeji.com
// c.didi-food.com 为router层域名，不是capi域名，所以仿真环境也要使用router层域名
const cApiBase = sim.isSim() ? sim.getSimCApi() : 'https://c.didi-food.com'

const actApiBase = sim.isSim() ? sim.getSimActApi() : 'https://c.didi-food.com'
const fulfillmentApiBase = sim.isSim()
  ? sim.getSimFulfillmentApi()
  : 'https://fulfillment.didi-food.com'
const cashierApiBase = sim.isSim() ? sim.getSimCashierApi() : 'https://cashier.didiglobal.com'
const payApiBase = sim.isSim() ? sim.getSimPayApi() : 'https://pay.didiglobal.com'

const iosDownloadUrl = 'https://didi-food.onelink.me/ssCr/11b12b9b'
const androidDownloadUrl = 'https://didi-food.onelink.me/ssCr/4e13c018'
const androidDownloadPcUrl =
  'https://play.google.com/store/apps/details?id=com.xiaojukeji.didi.global.customer'
const appList = [
  {
    type: 'ios',
    logo: ios,
    name: 'iPhone',
    url: iosDownloadUrl,
  },
  {
    type: 'android',
    logo: android,
    name: 'Android',
    url: androidDownloadUrl,
    webUrl: androidDownloadPcUrl,
  },
]

const appMap = {}
appList.forEach(item => {
  appMap[item.type] = item
})

export default {
  env,
  omega: {
    uploadHost: 'omgup.didiglobal.com',
    appKey: 'omega167c2bacd7', // 国际外卖storefont
    // 以路由区分页面
    browserRouterEnable: true,
    // 去掉Omega的自动定位，防止自动给用户弹窗获取定位权限
    autoPosition: false,
    jsErrorFilters: [
      {
        message:
          /^(Script error\.)|(null is not an object \(evaluating 'this.marker.makerInfoWindow'\))|(Cannot read properties of null \(reading 'makerInfoWindow'\))|(null is not an object \(evaluating 'this.state.map.createMarker'\))|(Cannot read properties of null \(reading 'createMarker'\))|(null is not an object \(evaluating 'this.markerInfoWindow.close'\))$/,
      },
    ],
  },

  cApiBase,
  actApiBase,
  cashierApiBase,
  payApiBase,
  fulfillmentApiBase,
  passport: {
    h5LoginUrl: '//static.didiglobal.com/common-biz/global-login/2.3.14/login.global.min.js',
    passportUrl: 'https://page.didiglobal.com/public-biz/pc-login/3.0.8/index.html',
    setCookieUrl: `${cApiBase}/login/callback`,
    appId: 200073,
    source: 70001, // 根据国家区分
    role: 1,
    lang: getLanguage,
    law_url: getCountryConfig.login.lawUrlH5(getCountry),
    country_id: getCountryConfig.login.countryId(getCountry),
    country_calling_code: getCountryConfig.login.countryCallingCode(getCountry),
    city_id: 0,
    canonical_country_code: getCountryConfig.login.canonicalCountryCode(getCountry),
    // 根据国家区分
    // eslint-disable-next-line no-void
    theme: env.is99 ? 'yellow' : void 0,
  },
  constants: {
    NOT_LOGIN: 990003,
    NETWORK_ERROR: -1,
    PAY_QUERY_LOOP_CONFIG: {
      TIMES: 100,
      TIMEOUT: 30 * 1000,
    },
  },
  appMap,
  appList,
}
