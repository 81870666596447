// http://wiki.intra.xiaojukeji.com/pages/viewpage.action?pageId=451224467

import { post } from '@/lib/request/index'
import { bill } from '../apiConstants'

// 提单页信息
export function apiBillInfo(param) {
  return post(bill.BILL_INFO, { param })
}

// 提单页信息 EFO
export function apiFastBuy(param) {
  return post(bill.FAST_BUY, { param })
}

// 更新提单页信息
export function apiBillUpdate(param) {
  return post(bill.BILL_UPDATE, { param })
}

// 提单页动态信息
export function apiBillAlert(param) {
  return post(bill.UPDATE_BILL_ALERT, { param })
}
