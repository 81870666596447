function getExplorerInfo() {
  let explorer = window.navigator.userAgent
  explorer = explorer.toLowerCase()

  if (explorer.indexOf('msie') >= 0) {
    return 'IE'
  }
  // firefox
  else if (explorer.indexOf('firefox') >= 0) {
    return 'Firefox'
  }
  // Chrome
  else if (explorer.indexOf('chrome') >= 0) {
    return 'Chrome'
  }
  // Opera
  else if (explorer.indexOf('opera') >= 0) {
    return 'Opera'
  }
  // Safari
  else if (explorer.indexOf('safari') >= 0) {
    return 'Safari'
  }
  if (explorer.indexOf('edge') >= 0) {
    return 'edge'
  }
  // 遨游浏览器
  if (explorer.indexOf('maxthon') >= 0) {
    return '傲游浏览器'
  }
  // QQ浏览器
  if (explorer.indexOf('qqbrowser') >= 0) {
    return 'QQ浏览器'
  }
  // 搜狗浏览器
  if (explorer.indexOf('se 2.x') >= 0) {
    return '搜狗浏览器'
  }
  return ''
}
export const isBrowser = browserName => {
  return browserName === getExplorerInfo()
}
