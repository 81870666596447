const SUCCESS = 0
const FAIL = -1

class UserCurrentOrientation {
  getLocation() {
    return new Promise((resolve, reject) => {
      function geoShowPosition(position) {
        if (position) {
          // 使用中国经纬度获取不到对应的poi，先mock成墨西哥的
          // const location = { lat: 19.417612, lng: -99.1486205 }
          const location = { lat: position?.coords?.latitude, lng: position?.coords?.longitude }
          resolve({ location, code: SUCCESS })
        } else {
          // eslint-disable-next-line prefer-promise-reject-errors
          reject({
            msg: '获取定位失败',
            code: FAIL,
          })
        }
      }

      function geoShowError(error) {
        console.log(`getPosError:${error.code},${navigator.geolocation},${error.message}`)
        // eslint-disable-next-line prefer-promise-reject-errors
        reject({
          msg: '获取定位失败',
          code: FAIL,
        })
      }
      const options = {
        timeout: 5 * 1000, // 超时时间最大是5s
      }

      navigator.geolocation.getCurrentPosition(geoShowPosition, geoShowError, options)
    })
  }
}

export const userCurrentOrientation = new UserCurrentOrientation()
