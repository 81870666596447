<template>
  <div v-if="data" class="sel-cate-flavor-wrapper">
    <CateHeader
      :title="data.itemName"
      :trans-show="transSHow"
      :trans-open="transOpen"
      :pos-sticky="!firstLevel"
    >
      <Breadcrumb v-show="!firstLevel" :list="stack" attr-name="itemName" @nav-change="navClick" />
    </CateHeader>
    <div ref="scrollBox" class="scroll-box" :class="{ 'no-full': !firstLevel }">
      <CateHeadImg v-if="firstLevel && isH5" :head-img="data.headImg" :title="getSlodTitle(data)" />
      <div
        v-show="firstLevel"
        class="cate-flavor-header padding-center"
        :class="{ 'no-head-img': !hasHeadImg }"
      >
        <h1 ref="cateTitle" class="cate-flavor-title" v-text="data.itemName"></h1>
        <p v-if="data.shortDesc" class="cate-desc" v-text="data.shortDesc"></p>
        <WeakTips v-if="+data.cHasWine === 1" class="cate-msg" :content="data.soldDesc" />
        <div v-if="acttips && acttips.length" class="cate-tips-box">
          <CateTips :disabled="disabled" :tips="acttips" />
        </div>
        <div v-if="acttags && acttags.length" class="cate-tips-box new-cate-tips">
          <ActTag :data="acttags" />
        </div>
        <div v-else-if="data.tips && data.tips.length" class="cate-tips-box new-cate-tips">
          <p class="cate-tips-title">{{ data.tips[0].content }}</p>
        </div>
        <p v-if="data.specialPrice !== -1" class="item-price">
          <span class="price">{{ data.specialPriceDisplay }}</span>
          <s v-if="data.specialPrice !== data.price" class="crossed-price">
            {{ data.priceDisplay }}
          </s>
        </p>
        <p v-else class="item-price">
          {{ data.priceDisplay }}
        </p>
        <!-- <div v-if="acttags && acttags.length" class="cate-tips-box">
          <LimitTxt v-for="(tag, tagindex) in acttags" :key="tagindex" :content="tag.content" />
        </div> -->
        <!-- <div v-else-if="data.tips && data.tips.length" class="cate-tips-box">
          <CateTips :disabled="disabled" :tips="data.tips" :moretext="moreTipsText" />
        </div> -->
      </div>
      <div class="cate-flavor-content" :class="{ 'cate-flavor-next-content': !firstLevel }">
        <CollapseCom
          :content-list="data.contentList"
          :disabled="disabled"
          :is-leaf="isLeaf"
          :is-selected="isSelected"
          :get-group-tip="getGroupTip"
          :get-check-type="getCheckType"
          :get-item-counter="getItemCounter"
          :get-require-tip="getRequireTip"
          :format-display-name="strategy.formatDisplayName"
          :sum-display-price="strategy.sumDisplayPrice"
          :get-disable-status="getDisableStatus"
          :get-counter-disable-status="getCounterDisableStatus"
          @item-click="itemClick"
          @counter-add="counterAdd"
          @counter-remove="counterRemove"
          @disabled-item-click="disabledItemClick"
        />
        <div v-if="isH5" class="bottom-add-wrapper">
          <p v-if="actMsg" class="err-msg-h5" v-text="actMsg"></p>
          <CateAddStepper
            v-model="comCopies"
            :style="{ margin: '0 auto' }"
            :min="minCopies"
            :max="maxCopies"
            :disabled="disabled"
            @input="changeCopies"
          />
        </div>
      </div>
    </div>
    <div class="cate-flavor-footer">
      <CateAdd
        v-if="firstLevel"
        v-model="comCopies"
        :text="btnName"
        :disabled="addDisabledStatus"
        :now-price="nowPrice"
        :origin-price="originPrice"
        :cate-currency="data.currency"
        @add="addShopCart(data)"
      >
        <CateAddStepper
          v-if="!isH5"
          v-model="comCopies"
          :min="minCopies"
          :max="maxCopies"
          :disabled="disabled"
          :style="{ float: 'left' }"
          @input="changeCopies"
        />
      </CateAdd>
      <CateNextLvFooter
        v-else
        :disabled="disabled"
        :next-ready="isItemReady(data)"
        @cancel="cancel(data)"
        @confirm="nextConfirm(data)"
      />
    </div>
    <!-- 加购时提示？ -->
    <el-alert
      v-if="!isH5 && actMsg"
      class="err-msg"
      type="error"
      :title="actMsg"
      :closable="false"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import strategy from './strategy'
import CONSTANTS from './constants'
import { getSlodTitle } from '@/components/DishFood/methods'
import { CATE_SOURCE } from '@/const/dishfood'
import WeakTips from '@/components/DishFood/weakTips.vue'
import CateAdd from '@/components/DishFood/cateAdd.vue'
import CateTips from '@/components/DishFood/cateTips.vue'
import LimitTxt from '@/components/CateResult/limittxt'
import CollapseCom from '@/components/CollapseCom'
import CateHeader from '@/components/DishFood/cateHeader.vue'
import Breadcrumb from '@/components/DishFood/breadcrumb.vue'
import CateNextLvFooter from '@/components/DishFood/cateNextLvFooter.vue'
import CateAddStepper from '@/components/DishFood/cateAddStepper.vue'
import CateHeadImg from '@/components/DishFood/cateHeadImg.vue'
// import RichText from '@/components/RichText/index.vue'
import ActTag from '@/components/ActTag'

export default {
  name: 'TreeCateCard',
  components: {
    WeakTips,
    CateAdd,
    CateTips,
    LimitTxt,
    CollapseCom,
    CateHeader,
    Breadcrumb,
    CateNextLvFooter,
    CateAddStepper,
    CateHeadImg,
    ActTag,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    data: Object,
    // eslint-disable-next-line vue/require-default-prop
    stack: Array,
    // eslint-disable-next-line vue/require-default-prop
    source: String,
    disabled: Boolean,
    hasHeadImg: Boolean,
    copies: {
      type: Number,
      default: 1,
    },
    // eslint-disable-next-line vue/require-default-prop
    moreTipsText: String,
    // eslint-disable-next-line vue/require-default-prop
    actMsg: String,
    // eslint-disable-next-line vue/require-default-prop
    nowPrice: String,
    // eslint-disable-next-line vue/require-default-prop
    originPrice: String,
    // eslint-disable-next-line vue/require-default-prop
    cateItemNum: Number,
    // eslint-disable-next-line vue/require-default-prop
    acttips: Array,
    // eslint-disable-next-line vue/require-default-prop
    acttags: Array,
  },
  data() {
    return {
      CONSTANTS,
      strategy,
      transSHow: false,
      transOpen: true,
      firstLevel: true,
      comCopies: 1,
      minCopies: 1,
      maxCopies: Infinity,
      btnName: '',
    }
  },
  computed: {
    ...mapState({
      isH5: ({ common }) => common.isH5,
    }),
    addDisabledStatus() {
      if (this.firstLevel) {
        return this.disabled || (!this.isItemReady(this.data) && this.data.contentList.length)
      } else {
        return false
      }
    },
  },
  watch: {
    copies(val) {
      this.comCopies = val
    },
    stack(val) {
      this.firstLevel = val.length <= 1
      this.transOpen = val.length <= 1
      const dom = this?.$refs?.scrollBox
      this.firstLevel && (this.transSHow = false)
      dom && (dom.scrollTop = 0)
    },
  },
  mounted() {
    this.initData()
  },
  destroyed() {
    this.unbindScroll()
  },
  methods: {
    changeCopies(n) {
      this.$emit('change-copies', n)
    },
    scrollToView(getIndexFn = this.getRequireNoSelItem) {
      // 获取必选未选item
      const list = this?.data?.contentList || []
      const index = getIndexFn(list)
      if (index < 0) return
      // 控制 n的 高亮效果
      if (index !== -1 && !list[index]._requiredWarning) {
        this.$set(list[index], '_requiredWarning', true)
        setTimeout(() => {
          list[index]._requiredWarning = false
        }, 1100)
      }
      // 如果有必选 未选中的选项 滚动定位效果
      const aHeader = document.querySelectorAll('.el-collapse-item__header')
      aHeader[index].scrollIntoView({
        block: 'center',
        inline: 'nearest',
        behavior: 'smooth',
      })
    },
    getRequireNoSelItem(list) {
      const n = Array.prototype.findIndex.call(list, (item, index) => {
        const status = this.getRequireTip(item)
        const requiredPending = !!(status && status !== 'completed')
        const limtOrSelPending = !strategy.matchGroup(item)
        return requiredPending || limtOrSelPending
      })
      return n
    },
    addShopCart(data) {
      // disabled 状态 如果因为必选项没有选择 点击时滚动到必选项位置 并红色提示required
      if (!this.disabled && !this.isItemReady(data)) {
        this.scrollToView()
      }
      if (this.addDisabledStatus) return
      // '加入购物车' 最多15个限制  cateItemNum
      if (this.comCopies + this.cateItemNum > 15) {
        this.$utils.infoToast(this.$t('FoodC_perception_Sorry_you_FKSy'))
        return
      }
      this.ok(data)
    },
    nextConfirm(data) {
      if (!this.disabled && !this.isItemReady(data)) {
        this.scrollToView()
        return
      }
      this.ok(data)
    },
    disabledItemClick(index) {
      this.scrollToView(() => index)
    },
    getSlodTitle,
    initData() {
      this.btnName = CATE_SOURCE[this.source]?.btnName
      this.minCopies = CATE_SOURCE[this.source]?.minCopies
      this.maxCopies = CATE_SOURCE[this.source]?.maxCopies
      this.stack.length <= 1 && this.bindScroll()
      this.comCopies = this.copies
      // hasHeadImg
      // this.transOpen = this.hasHeadImg;
    },
    navClick(item) {
      this.$emit('nav-click', item)
    },
    bindScroll() {
      this?.$refs?.scrollBox?.addEventListener('scroll', this.onScroll)
    },
    unbindScroll() {
      this?.$refs?.scrollBox?.removeEventListener('scroll', this.onScroll)
    },
    onScroll(e) {
      if (!this.transOpen) return
      const dom = this?.$refs?.scrollBox
      const domTitle = this?.$refs?.cateTitle
      // let scrollTop = this.isH5 ? 580 : 20
      this.transSHow = dom?.scrollTop > domTitle?.offsetTop - 32
    },
    // 逻辑
    itemClick(data) {
      if (this.isLeaf(data)) {
        this.$emit('trigger-temp', data)
      }

      if (!this.isLeaf(data)) {
        if (this.isSelected(data)) {
          this.$emit('trigger', data)
        } else {
          this.$emit('go-down', data)
        }
      }
    },
    cancel(data) {
      this.$emit('cancel', data)
      this.$emit('go-up', data)
    },
    ok(data) {
      this.$emit('ok', data)
      this.$emit('go-up', data)
    },
    getRequireTip(group) {
      return this.isMust(group) ? (this.isCompleted(group) ? 'completed' : 'required') : ''
    },
    isMust(group) {
      // 1必选；2非必选
      return strategy.isMust(group)
    },
    isCounter(group) {
      return strategy.isCounter(group)
    },
    isRadio(group) {
      return strategy.isRadio(group)
    },
    isCheckbox(group) {
      return strategy.isCheckbox(group)
    },
    getCheckType(group) {
      if (this.isCounter(group)) {
        return CONSTANTS.SELECT_TYPE.COUNTER
      } else if (this.isRadio(group)) {
        return CONSTANTS.SELECT_TYPE.RADIO
      } else {
        return CONSTANTS.SELECT_TYPE.CHECKBOX
      }
    },
    isLeaf(data) {
      return strategy.isLeaf(data)
    },
    isSelected(data) {
      return strategy.isSelected(data)
    },
    getItemCounter(data) {
      return strategy.getItemCounter(data)
    },
    isItemReady(data) {
      return !this.isLeaf(data) && strategy.match(data)
    },
    getDisableStatus(group, node) {
      return strategy.shouldDisableRest(group) && !strategy.isSelected(node)
    },
    getCounterDisableStatus(group) {
      return strategy.shouldDisableRest(group)
    },
    getGroupTip(group) {
      const { maxItemNum } = group
      const tip = strategy.isSelectType(group)
        ? CONSTANTS.LIMIT_TYPE.SELECT(maxItemNum)
        : CONSTANTS.LIMIT_TYPE.LIMIT(maxItemNum)
      return tip
    },
    counterAdd(item) {
      this.$emit('counter-add', item)
    },
    counterRemove(item) {
      this.$emit('counter-remove', item)
    },
    isCompleted(group) {
      return strategy.matchGroup(group)
    },
  },
}
</script>

<style lang="scss">
.sel-cate-flavor-wrapper .err-msg {
  .el-alert__title {
    font-family: 'Aspira Medium';
    font-weight: 500;
    display: inline-block;
  }
}
</style>
<style lang="scss" scoped src="./treecatecard.scss"></style>
