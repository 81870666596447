import Vue from 'vue'
import { supportWebp } from '@/utils/tools'

const isWebp = supportWebp()

const imgConfig = (src, config) => {
  if (src.search(/\?/) !== -1) {
    return `${src}&${config}`
  }

  return `${src}?${config}`
}

Vue.directive('webp', {
  bind(el) {
    if (isWebp) {
      if (el.src.includes('x-s3-process')) {
        el.src = el.src + `,image/format,webp`
      } else {
        el.src = imgConfig(el.src, `x-s3-process=image/format,webp`)
      }
    }
  },
})
