/*
 * @Author: amywulan
 * @Date: 2021-07-Fr 11:44:27
 * @Last Modified by: amywulan
 * @Last Modified time: 2021-07-Fr 11:44:27
 * @description require.context 工具类
 */

/**
 * @descripttion: 自动化导入api
 * @param {*} ctx
 * @return {*}
 */
export function requireContextApi(ctx) {
  let API = {};
  ctx.keys().forEach(key => {
    API = Object.assign(API, ctx(key));
  });
  return API;
}

/**
 * @descripttion: 自动化导入路由
 * @param {*} item
 * @return {*}
 */
export function requireSubRoutes(item) {
  const routes = [];
  item.keys().forEach(key => {
    Object.keys(item(key)).forEach(name => {
      routes.push(...item(key)[name]);
    });
  });
  return routes;
}

/**
 * @descripttion: 自动化导入store
 * @param {*} ctx
 * @return {*}
 */
// export function requireContextStore(ctx) {
//   let store = {}
//   ctx.keys().forEach(key => {
//     store = Object.assign(store, { home: ctx(key).default })
//   })
//   return store
// }
