module.exports = {
  "小费": "チップ",
  "FoodC_education__2_jwSo": "(2) 玄関先に置く（備考欄に記入）",
  "FoodC_web_Application_Progress_UYtG": "払い戻し申請状況",
  "FoodC_001_The_WEB_Iizt": "Web版では現在PayPayはご利用いただけません。PayPayでお支払いの場合は{{download}}をダウンロードしてください",
  "FoodC_page_Upon_discovery_baTq": "規約違反や不正行為が発覚された場合、割引は適用されず、アカウントのブロックや法的責任を追及される場合があります。",
  "FoodC_homepage_Enter_delivery_IZAu": "お届け先住所を入力",
  "FoodC_dish_Are_you_TaUN": "このページを離れますか？",
  "FoodC_Card2_When_the_sFOa": "受付開始時間",
  "FoodC_education__1_vwgm": "(1) 玄関先で受け取る",
  "soda-c-i18n---早间：%s-%s": "早朝：%s-%s",
  "soda-c-i18n---延迟送达%s分钟以上": "配達遅延%s分以上",
  "FoodC_stores_All_stores_clRv": "すべての店舗",
  "FoodC_education_4_Customers_laxR": "4. 配達員が到着してから10分以内にご注文をお受け取り下さい。",
  "FoodC_dish_XX_YY_opCV": "終了まで",
  "FoodC_perception_Tips_hZWk": "チップ",
  "FoodC_education__1_qvVC": "(1) お客様が時間内に受け取らなかった",
  "FoodC_method_Wait_keAM": "もう少し待つ",
  "soda-c-i18n---请告诉我们，帮助我们一起改善": "お客様よりいただいたフィードバックは、今後のサービス向上のために役立ててまいります。",
  "soda-c-i18n---个人中心的“优惠券”": "DiDi Foodアカウントの「クーポン」。",
  "FoodC_web_Feedback_on_ookM": "現在のところ、Web版では関連する問題のフィードバックに対応していません。携帯電話のアプリで操作してください。ご不便をおかけしまして大変申し訳ございません。ご不明点がございましたらカスタマーセンターにお問い合わせください。",
  "FoodC_supplement_Return_wVpv": "戻る",
  "soda-c-i18n---1、超时赔付服务是%s为您免费提供的服务，当前订单（不含已取消订单）未在预计送达时间内送达，%s将赔付给您一定金额的优惠券，订单完成后可以在中查看。": "1、遅延補填は%sがお客様に提供しているサービスです。ご注文の商品（キャンセルした注文は除く）が予定配達時刻以内に配達されなかった場合、%sよりお詫びとしてクーポンを差し上げます。注文完了後×にてご確認できます。",
  "FoodC_homepage_Search_for_CiMZ": "お店を探す",
  "优惠券": "クーポン",
  "FoodD_suspensionpage_In_the_zGEU": "以上",
  "FoodB_reason_Contact_Courier_Fqog": "配達員へ連絡",
  "FoodC_education_Meal_picking_ziun": "受取ルール",
  "soda-c-i18n---订单实际送达时间晚于预计送达时间%s分钟以上、%s分钟以下，": "実際の配達時間が予定配達時刻を%sから%s分遅れた場合、",
  "FoodC_education_1_Customers_Gpbb": "1. ご注文確定前に受取方法を選択できます。配達員は、お客様が選択した受取方法に従ってお届けします。",
  "FoodC_perception_Tip_exceeds_VSFf": "チップが上限額を超えています",
  "FoodC_sales_Condition_of_lnwg": "商品受取時の封の状態（選択してください）",
  "soda-c-i18n---送您1张%s折扣券；": "%sの割引クーポンを1枚差し上げます。",
  "productname_didi_food": "DiDi Food",
  "FoodC_revision_Contact_customer_XbFk": "カスタマーセンターへ連絡",
  "分钟": "分",
  "soda-c-i18n---现金": "現金",
  "sailing.d-api.common.页面获取失败，请稍后重试": "ページを取得できませんでした。後ほどお試しください",
  "FoodC_education__1_mJTG": "(1) 店舗がすでに注文受付を確定した場合、「注文キャンセル」機能でキャンセルについて店舗に相談できます。すでに注文引き渡し済みなどの理由で店舗がキャンセルに同意しなかった場合、無料で注文をキャンセルすることはできません。それでも注文をキャンセル、または商品の受取を拒否した場合は発生した費用はすべてお支払いいただきます。",
  "FoodC_education_Principles_of_RKEk": "受取時の問題対応の原則",
  "soda-c-i18n---当前订单（不含已取消订单）未在预计送达时间内送达，%s将赔付给您一定金额的优惠券，订单完成后可以在": "注文商品（キャンセルした注文は除く）が予定配達時刻以内に配達されなかった場合、%sより一定額のクーポンを差し上げます。注文完了後に次の箇所で確認できます：",
  "soda-c-i18n---将赔付给您1张%s优惠券": "遅延補填として%sの配達料無料クーポンを1枚差し上げます",
  "FoodC_dish_Purchase_NrCl": "注文",
  "FoodC_page_Invite_friends_oGuj": "招待されたお友達が下記3点を満たした場合、 あなたは{{ productname_didi_food}}[クーポン]を獲得できます。 ・お友達が{{ productname_didi_food}}に新規ユーザー登録をする ・初回注文前にあなたが送ったクーポンコードを入力 ・キャンペーン期間内、かつ紹介された日より7日以内に注文を完了する（クーポン利用有無は関係ありません）",
  "FoodC_popup_After_closing_ujoW": "ポップアップを閉じると、選択したオプションは保存されません。",
  "FoodC_remark_Enjoy_your_OvmD": "お食事をお楽しみください",
  "提交申请": "Submit Request",
  "支付失败": "決済失敗",
  "soda-c-i18n---超时赔付规则说明": "遅延補填規則の説明",
  "FoodC_education_5_Cash_zBuY": "5. 現金支払いの場合は、十分な現金をご用意ください。",
  "FoodC_referral_Copy_link_kCti": "リンクをコピー",
  "FoodC_sidebar_Scan_QR_ONzJ": "携帯電話のカメラでQRコードをスキャンしてください",
  "FoodC_sidebar_Use_the_huoZ": "アプリを使って、グルメを楽しもう",
  "取消": "Cancel",
  "FoodC_education_3_If_wduh": "3. 商品の問題、天候の問題など人為的なもの以外の原因で受取の問題が発生した場合は、プラットフォームが責任を負います。",
  "FoodC_web_Customer_Service_EBqf": "カスタマーセンター",
  "FoodC_sidebar_Update_successfully_cuyI": "更新完了",
  "FoodC_education__1_IIfh": "(1) 店舗の注文受付前に、注文詳細ページで「注文キャンセル」機能からキャンセルした場合",
  "FoodC_details_Refund_Details_sGIk": "払戻詳細",
  "FoodC_page_This_special_fYuo": "本キャンペーン適用対象はDiDi Foodサービス開始済みエリアとなります。",
  "FoodC_page_For_any_IWNZ": "本キャンペーンについてのお問い合わせは、{{contact_email}}カスタマーセンターまでお願いします。",
  "支付": "支払う",
  "FoodC_reasons_Enter_a_Pnzg": "具体的な理由を入力してください",
  "支付方式": "Payment Method",
  "FoodC_education_Basic_requirements_hIbv": "ご注文受取の基本要件",
  "sailing.c-api-intl.你最多可以下单{0}的货到付款单": "現金・その他決済の注文は最大{{0}}までです",
  "FoodC_homepage_Now_place_Kmkm": "今すぐ注文してお家でグルメを楽しもう",
  "FoodC_education_In_order_SRIb": "{{brand_name}}をご利用いただくお客様に、より良いご利用体験を提供するため、キャンセルに関するルールを設定させていただいております。",
  "FoodC_education__4_QYXm": "(4) 現金支払い注文で、お客様が支払いを拒否した",
  "FoodC_education_In_the_MKeB": "以下のような注文キャンセルの場合は、お客様の責任になりません。",
  "FoodC_education__3_ltNM": "（3） 配達員の手配ができないなどプラットフォーム側の原因、または外部の原因により注文がキャンセルされた場合",
  "FoodC_supplement_Modify_Mobile_ZJkT": "携帯電話番号の変更/パスワードの変更/メールアドレスの登録/アカウントの削除を行うには",
  "soda-c-i18n---送您1张%s优惠券；": "%sのクーポンを1枚差し上げます。",
  "FoodD_problem_I_see_NhGG": "了解しました",
  "FoodC_sales_If_the_TsPm": "パッケージにテープや他の密封方法（ホチキスなど）がされていた場合、その止め具合を上記の選択肢から選択してください。この情報は店舗と配達員への事実確認にのみ使用されます。申立の結果には影響しません。",
  "FoodOpC_backend_Copy_HxOc": "コピー",
  "FoodC_perception_Sorry_you_FKSy": "大変申し訳ありませんが、追加できる商品は15点までです",
  "FoodC_sales_Supplementary_information_ihyk": "追加情報",
  "FoodC_education__2_uVUK": "(2) 店舗の営業時間外、商品の在庫切れ、事故などの原因で店舗または配達員によって注文がキャンセルされた場合。ご注文代金はすべて払い戻しとなります。",
  "FoodC_001_This_operation_Dwog": "この操作を行うとカートは削除されます。継続してよろしいですか？",
  "FoodC_all_No_reward_ocGJ": "ボーナスはありません",
  "FoodC_education_In_order_AZCw": "{{brand_name}}をご利用いただくお客様に、より良いご利用体験を提供するため、受取に関するルールを設定させていただいております。ご注文の受取について問題が発生した場合は、以下のルールに従って対応いたします。",
  "FoodC_page_Your_invited_sPpR": "招待されたお友達は{{\t\nproductname_didi_food}}の新規ユーザーであることが必要です。携帯電話番号、デバイス、クレジットカード番号などの決済情報のいずれかが重複している場合、同ーユーザーと見なされます。",
  "FoodC_web_Only_1_ZddK": "1点限り",
  "FoodC_page_Rewards_are_dWeY": "ボーナスは【被紹介者】の所在都市に応じて発行されます。",
  "FoodC_education__2_KZTZ": "(2) 配達員がお客様に連絡したが、連絡がつかなかった",
  "FoodC_education_In_the_nIgD": "以下のような注文キャンセルの場合は、お客様の責任となります。",
  "FoodC_zhaopin_Recruitment_OZrZ": "採用情報",
  "FoodC_education_Platform_Delivery_MJPQ": "DiDi Food配達",
  "FoodC_dish_New_guest_bkGC": "初回注文限定",
  "提交": "提出",
  "soda-c-i18n---将赔付给您%s张免配送费券": "遅延補填として%s枚の配達料無料クーポンを差し上げます",
  "FoodC_all_You_haven_lSmt": "まだお友達を紹介していません。今すぐ紹介しましょう",
  "FoodC_dish_Look_again_fJrp": "いいえ",
  "FoodC_remark_Thank_you_ENoY": "{{brand_name}}をご利用いただきありがとうございました",
  "soda-c-i18n---提交申请": "注文をキャンセル",
  "soda-c-i18n---联系商家": "店舗へ連絡",
  "FoodC_dish_Leaving_LwWJ": "はい",
  "FoodC_education__2_KHhl": "(2) プラットフォームの規定時間内に受取地点に到着しなかった、お届け先住所の入力が間違っていた、受取番号が間違っていた、配達員の電話に応答しなかった、正当な理由なく商品の受け取りを拒否した場合は、ご注文代金をお支払いいただきます。",
  "FoodC_education_Cancellation_Policy_fxrl": "キャンセルルール",
  "FoodC_nonaffiliate_Courier_is_iunG": "配達員がお店に向かっています",
  "FoodC_dish_Current_activity_Iyrv": "こちらのキャンペーンはあと{{arrived_time}}で終了します。終了後、初回注文特典スペシャルディッシュはご注文いただけなくなります。",
  "FoodC_dish_You_will_AXAT": "このページを離れると初回注文特典スペシャルディッシュはご注文いただけなくなります。",
  "必选": "必須",
  "FoodC_education_If_you_RTIV": "ご注文代金のお支払い完了後にキャンセルされる場合、以下のルールに従って対応いたします。",
  "FoodC_education_2_Receiving_gFZl": "2. お届け先住所、連絡先情報を正確に入力してください。",
  "soda-c-i18n---晚间：%s-%s": "夜間：%s-%s",
  "FoodC_page_The_threshold_yZFq": "クーポンの使用条件および有効期限はクーポンの注意事項をご確認ください。",
  "FoodC_education__3_TDsa": "(3) お客様が商品の受取を拒否した",
  "FoodC_education_2_If_SoSk": "2. 配達員がプラットフォーム規定に従ってご注文のお届けを完了せず、それにより受取の問題が発生した場合は、まず配達員の責任として対応します。",
  "FoodC_education__unk_aOCD": "{{delivery_type}}プラットフォーム規則",
  "soda-c-i18n---将赔付给您1张%s折扣券": "遅延補填として%sの割引クーポンを1枚差し上げます",
  "FoodC_education_1_If_iWDm": "1. お客様が上記のルールに沿ってご注文を受け取らなかったことによって、問題が発生した場合、まずお客様側の責任として対応します。具体的には、次のような場合があります（ただしこれらの例に限りません）。",
  "soda-c-i18n---送您%s张免配送费券；": "%s枚の配達料無料クーポンを差し上げます。",
  "确认": "確認",
  "FoodB_supplement_Network_connection_RVcl": "ネットワークに接続されていません。ネットワークの設定を確認してください",
  "FoodC_page_Rewards_will_NhMd": "クーポンは招待されたお友達が条件達成後、48時間以内にアカウントに付与いたします。{{\t\nproductname_didi_food}} → 「お友達紹介クーポン 」→ 「クーポン一覧」にて確認することができます。",
  "请选择支付方式": "決済方法をお選びください",
  "soda-c-i18n---订单实际送达时间晚于预计送达时间%s分钟以上，": "実際の配達時間が予定配達時刻より%s分以上遅れた場合、",
  "soda-c-i18n---取消订单": "注文をキャンセルする",
  "FoodC_education_3_Customers_Zoec": "3. 配達員から連絡が取りやすいよう、常に電話がつながる状態にしておいてください。"
}