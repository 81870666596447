import Vue from 'vue'
import '@didi/r-fusion-pc/lib/theme-chalk/index.css'
import { langs } from '@/config/countryConfig/list/langs'

import { Theme, Loading } from '@didi/r-fusion-pc'
import { getLocale } from '@/utils/locale'
import locale from '@didi/r-fusion-pc/lib/locale'

import omega from './omega'
// import passport from './passport';
import passportMonitor from './passportMonitor'
import utils from './utils'
import sprintf from './sprintf'

Vue.use(Theme).use(Loading)

Theme.use('didi') // didi主题

// 设置语言
locale.use(langs[getLocale()])

Vue.use(omega)
// Vue.use(passport);
Vue.use(passportMonitor)
Vue.use(utils)
Vue.use(sprintf)

// 图片懒加载
require('./lazyload')
