import zhCNExternal from './use/zh-CN'
import enUSExternal from './use/en-US'
import jaJPExternal from './use/ja-JP'
import ptBRExternal from './use/pt-BR'
import esMXExternal from './use/es-MX'
import es419External from './use/es-419'

export default {
  zhCNExternal,
  enUSExternal,
  jaJPExternal,
  ptBRExternal,
  esMXExternal,
  es419External,
}
