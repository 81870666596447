import { eventMap } from './config'

/** @desc 获取event key */
const getEventKey = (eventId) => {
  return eventMap[eventId] || ''
}

/** @desc 获取埋点id => eventId + eventKey */
export const getLogInfoId = (data = {}) => {
  const { eventId, attrs = {}, tag = '' } = data

  if (!eventId) {
    console.warn('[tools]:缺少eventId~')
    return ''
  }

  return eventId + (attrs[getEventKey(eventId)] || '') + tag
}

/** @desc 存储埋点信息 */
export const LOG_DATA_MAP = {
  // getLogInfoId(data) : data
}

/** @desc 记录已展现埋点key */
export const LOG_SHOW_MAP = {
// getLogInfoId(data) : true
}
