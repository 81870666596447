import { post } from '@/lib/request/index'
import { afterSales } from '../apiConstants'

// // 取消预先判责
export function cancelDuty(param) {
  return post(afterSales.CANCEL_DUTY, {
    param,
  })
}

// 取消订单
export function cancelOrder(param) {
  return post(afterSales.CANCEL_ORDER, {
    param,
  })
}

// 获取取消订单原因
export function getCancelReason(param) {
  return post(afterSales.GET_CANCEL_REASON, {
    param,
  })
}

// 取消订单
export function applyCancel(param) {
  return post(afterSales.APPLY_CANCEL, {
    param,
  })
}

// 提交取消订单原因列表
export function submitCancelReason(param) {
  return post(afterSales.CANCEL_REASON, {
    param,
  })
}

// 获取取消订单进度
export function getCancelFlow(param) {
  return post(afterSales.GET_CANCEL_FLOW, {
    param,
  })
}

// 获取退款详情页数据
export const getCancelOrderRefundDetail = function (param) {
  return post(afterSales.GET_CANCEL_ORDER_REFUND_DETAIL, {
    param,
  })
}
