<template>
  <span
    class="c-rideo-btn"
    :class="{'is-checked': isChecked, 'disabled': disabled}"
  ></span>
</template>

<script>
export default {
  name: 'RideoBtn',
  props: {
    isChecked: Boolean,
    disabled: Boolean,
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {}
}
</script>

<style lang="scss" scoped src="./index.scss"></style>