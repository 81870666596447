<template>
  <div class="dish-food-wrappper">
    <el-dialog
      :visible.sync="dialogVisible"
      :width="width"
      :show-close="false"
      custom-class="sf-center-el-dialog"
      @close="closePopup"
    >
      <Cate
        v-r-loading="comLoading"
        :source="initSource(source)"
        :info="cloneDeep(info)"
        :disabled="cateDisabled"
        :has-head-img="hasHeadImg"
        :cate-item-num="cateItemNum"
        @close="closeCheck"
        @add-shopping-cart="addShoppingCart"
      />
    </el-dialog>
    <el-drawer
      :visible.sync="drawerVisible"
      :with-header="false"
      custom-class="mobile-popup-wrapper"
      direction="btt"
      size="calc(100% - 40px)"
      :wrapper-closable="false"
      :close-on-press-escape="false"
    >
      <Cate
        v-r-loading="comLoading"
        :source="initSource(source)"
        :info="cloneDeep(info)"
        :is-h5="isH5"
        :disabled="cateDisabled"
        :has-head-img="hasHeadImg"
        :cate-item-num="cateItemNum"
        @close="closeCheck"
        @add-shopping-cart="addShoppingCart"
      />
    </el-drawer>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { cloneDeep } from 'lodash-es'
import { stopScroll, openScroll } from '@/utils/baseUtil'
import { CATE_SOURCE, CATE_DISABLED_STATUS_LIST, CATE_ACTTYPE_TO_SOURCE } from '@/const/dishfood'
import ConfirmMsg from '@/components/ConfirmMsg/index.js'
import Cate from './cate.vue'

export default {
  name: 'DishFood',
  components: {
    Cate,
  },
  props: {
    disabled: {
      type: Boolean,
      required: false,
    },
    info: {
      type: Object,
      required: true,
    },
    // 标记来源 提单页: bill、购物车: cart、店铺详情：store 必传
    source: {
      type: String,
      default: CATE_SOURCE.STORE.name,
    },
    // 购物车现有菜品数量
    cateItemNum: {
      type: Number,
      default: 0,
    },
  },

  data() {
    return {
      show: false,
      comLoading: false,
      dialogVisible: false,
      drawerVisible: false,
      width: '470px',
      hasHeadImg: false,
      cateDisabled: false,
      cloneDeep,
      showConfirmMsg: ConfirmMsg,
    }
  },
  computed: {
    ...mapState({
      isH5: ({ common }) => common.isH5,
    }),
  },
  watch: {
    show(nVal) {
      this.init(nVal)
    },
  },
  mounted() {
    this.show = true
    if (this.isH5 && this.show) {
      stopScroll()
    }
  },
  destroyed() {
    openScroll()
  },
  methods: {
    init(val) {
      if (val) {
        // 售罄/预告 需要展示兜底图片  1: '销售中',2: '已售罄',3: '待售',
        const cateDisabled = CATE_DISABLED_STATUS_LIST.includes(+this.info?.soldStatus)
        this.cateDisabled = this.disabled || cateDisabled
        this.hasHeadImg = !!(this.info?.headImg || cateDisabled)
        this.width = this.hasHeadImg ? '940px' : '470px'
      }
      this.isH5 && (this.drawerVisible = val)
      !this.isH5 && (this.dialogVisible = val)
    },
    initSource(source) {
      // 防止外部没有传入 正确的source
      const typeToSource = CATE_ACTTYPE_TO_SOURCE[this.info?.activityType]
      return typeToSource || source
    },
    // 钩子函数 供外部获取 加购状态
    addClickHook() {
      //
    },
    closeHook() {
      //
    },
    // 外部操作 控制内部状态
    onShowLoading() {
      this.comLoading = true
    },
    onCloseLoading() {
      this.comLoading = false
    },
    onClose() {
      this.closePopup()
    },
    addShoppingCart(data) {
      // 加购
      this.addClickHook(data)
      this.$emit('add-shopping-cart', data)
    },
    closeCheck(hasChange) {
      // 判断是否多层级 ，是否有需要保存的信息 有则进行二次弹窗
      // this?.info?.node?.totalLevel
      if (hasChange) {
        const instance = this.showConfirmMsg({
          value: true,
          title: this.$t('FoodC_popup_After_closing_ujoW'),
        })
          .confirmHook(() => {
            instance.onClose()
            this.closePopup()
          })
          .cancelHook(() => {
            instance.onClose()
          })
      } else {
        this.closePopup()
      }
    },
    closePopup() {
      this.show = false
      this.comLoading = false
      openScroll()
      this.closeHook()
      setTimeout(() => {
        this.$destroy()
        this.$el?.parentNode?.removeChild(this.$el)
      }, 200)
    },
  },
}
</script>
<style lang="scss">
.dish-food-wrappper {
  .mobile-popup-wrapper {
    border-radius: rem(24) rem(24) 0 0;
    transform: rotate(0deg);
  }
  .el-dialog {
    border-radius: 8px;
  }
  .el-dialog__header {
    display: none;
  }
  .el-dialog__body {
    padding: 0;
    border-radius: 8px;
    background: #f5f5f7;
  }
  .mobile-popup-wrapper .el-drawer__body {
    height: 100%;
  }
}
</style>
<style lang="scss" scoped src="./index.scss"></style>
