<template>
  <div
    class="tree-box"
    :class="{
      'max-height': maxHeight,
      'has-head-img': hasHeadImg,
      'pfadeInRight-box': pfadeInRight,
    }"
  >
    <TreeCateCard
      v-if="data"
      :data="data"
      :acttips="acttips"
      :acttags="acttags"
      :stack="stack"
      :source="source"
      :disabled="disabled"
      :has-head-img="hasHeadImg"
      :copies="copies"
      :more-tips-text="moreTipsText"
      :act-msg="actMsg"
      :now-price="nowPrice"
      :origin-price="originPrice"
      :cate-item-num="cateItemNum"
      @change-copies="changeCopies"
      @nav-click="nav"
      @trigger-temp="triggerItemTemp"
      @trigger="triggerItemPermanent"
      @cancel="cancel"
      @ok="ok"
      @go-down="goDown"
      @go-up="goUp"
      @counter-add="counterAdd"
      @counter-remove="counterRemove"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import ta from './tree-algorithm'
import strategy from './strategy'
import CONSTANTS from './constants'
import { CATE_ACT_TYPE_CONFIG, CATE_SOURCE } from '@/const/dishfood'

import TreeCateCard from './treeCateCard.vue'

import {
  sailing_c_x_sf_item_add_or_drop_ck,
  sailing_c_x_sf_specselection_choose_ck,
} from '@/utils/track/shop'

export default {
  name: 'TreeCate',
  components: {
    TreeCateCard,
  },
  props: {
    // eslint-disable-next-line vue/require-default-prop
    info: Object,
    // eslint-disable-next-line vue/require-default-prop
    source: String,
    disabled: Boolean,
    hasHeadImg: Boolean,
    // eslint-disable-next-line vue/require-default-prop
    cateItemNum: Number,
  },
  data() {
    return {
      copies: 1,
      treeData: null,
      data: null,
      stack: [],
      maxHeight: false,
      hasChange: false,
      moreTipsText: '',
      actMsg: '',
      nowPrice: '',
      originPrice: '',
      pfadeInRight: false,
      animationST: null,
      acttips: [], // 用来 EFO新人专享 的标签展示等 和tips互斥
      acttags: [], // 老客/特价菜 等富文本标签
    }
  },
  mounted() {
    this.initData(this.info)
    this.updatePrice(this.copies)
  },
  methods: {
    initData(val) {
      this.copies = val.amount || 1
      this.treeData = val
      this.data = val
      this.stack = [val]
      this.maxHeight = val.node.totalLevel > 1
      this.moreTipsText = +this.data.activityType === 4 ? this.$t('多买多赠') : ''
    },
    updatePrice(val, computedPrice) {
      // 活动提醒 菜品活动价格计算
      if (!val && val !== 0) return
      const nextPrice = this.setCatePrice() // 规格价钱
      const type = this.info.activityType
      const activityInfo = this.info.activityInfo
      const actTag = this.info?.actTag || []
      let nowPrice = (this.info.price + nextPrice) * val
      const limitLabel = this.info?.limitLabel || {}
      const openSource = CATE_SOURCE.CART.name === this.source
      if (!type || !activityInfo) {
        this.nowPrice = nowPrice
        return nowPrice
      }

      if (+type === 4 && !computedPrice) {
        let msg = ''
        // 买赠
        const gift = activityInfo.buyGift
        const giveNum = gift.getNum - gift.buyNum
        const multiple = val / gift.buyNum
        if (multiple >= 1) {
          msg = this.$sprintf(this.$t('已获得%d个赠品'), Math.floor(multiple * giveNum))
        }
        this.actMsg = msg
        this.acttags = openSource ? strategy.mergeTag(actTag, limitLabel) : actTag
      } else if (+type === 2) {
        // 特惠菜 影响优惠价格在底部btn上
        const gift = activityInfo.special
        const specialNum = gift.maxOrderSale > val ? val : gift.maxOrderSale
        nowPrice =
          specialNum * gift.specialPrice + (val - specialNum) * gift.price + nextPrice * val
        this.originPrice = val * (gift.price + nextPrice)

        // this.acttags = []
        // const richTextSpace = '&em#{"type":2,"width":4}&em#'
        // actTag.length &&
        //   this.acttags.push({
        //     content: actTag[0]?.icon || '' + richTextSpace + actTag[0]?.content,
        //   })
        this.acttags = openSource ? strategy.mergeTag(actTag, limitLabel) : actTag
      } else if (+type === 100) {
        // EFO
        const gift = activityInfo.platSpecial
        nowPrice = val * gift.specialPrice + nextPrice * val
        this.originPrice = val * (gift.price + nextPrice)

        this.acttips = []
        this.acttips.push({
          icon: 'icon-filled_newcomer',
          content: gift.desc,
        })
        this.actMsg = this.$t('FoodC_web_Only_1_ZddK')
      } else if (+type === CATE_ACT_TYPE_CONFIG.OUO.type) {
        // 老客特惠菜
        // let gift = activityInfo[CATE_ACT_TYPE_CONFIG?.OUO?.dataName] || {}
      }
      this.nowPrice = nowPrice
      return nowPrice
    },
    async changeTopSel() {
      // 监听 选项 、数量变化
      if (+this.data?.node.level > 1) return
      await Promise.resolve()
      this.updatePrice(this.copies)
    },
    setCatePrice() {
      const list = strategy.getSelectedSubItems(this.info)
      const price = list.reduce((prev, next) => {
        return prev + next[CONSTANTS.DISPLAY_TAG.PRICE] * (next[CONSTANTS.DISPLAY_TAG.NUMBER] || 1)
      }, 0)
      return price
    },
    changeCopies(n) {
      sailing_c_x_sf_item_add_or_drop_ck({
        item_cnt: n,
        item_id: this.info?.itemId,
        item_add_or_drop: n > this.copies ? 1 : -1,
      })

      this.copies = n
      this.changeTopSel()
    },
    // 单选、复选框处理逻辑
    triggerItemTemp(item) {
      // console.log('🚀 ~ triggerItemTemp ~ item:', item)
      this.hasChange = true
      const group = strategy.getItemGroupByTree(this.treeData, item)
      if (strategy.isRadio(group)) {
        const currentItem = strategy.getSelectedItemInRadioGroup(group)
        if (currentItem && currentItem.itemId === item.itemId) {
          sailing_c_x_sf_specselection_choose_ck({
            item_id: item?.itemId,
            content_select_type: 1,
            choose_type: 2,
            content_id: item?.contentId,
          })

          this.changeTopSel()
          return this._setItemTemp(currentItem, 0)
        } else {
          sailing_c_x_sf_specselection_choose_ck({
            item_id: item?.itemId,
            content_select_type: 1,
            choose_type: 1,
            content_id: item?.contentId,
          })

          group.subItemList.forEach(_ => {
            this.setItemTemp(_, 0)
          })
          this.changeTopSel()
          return this._setItemTemp(item, 1)
        }
      }

      if (strategy.isCheckbox(group)) {
        sailing_c_x_sf_specselection_choose_ck({
          item_id: item?.itemId,
          content_select_type: 2,
          choose_type: strategy.isSelected(item) ? 2 : 1,
          content_id: item?.contentId,
        })

        this.changeTopSel()
        return this._setItemTemp(
          item,
          this._getRadioOrCheckboxSelectedValue(!strategy.isSelected(item)),
        )
      }

      if (strategy.isCounter(group)) {
        // 在独立事项中处理
        // counterAdd
        // counterRemove
      }
    },
    _getRadioOrCheckboxSelectedValue(bool) {
      return bool ? 1 : 0
    },
    // 计数器处理逻辑
    counterAdd(item) {
      sailing_c_x_sf_specselection_choose_ck({
        item_id: item?.itemId,
        content_select_type: 3,
        choose_type: 1,
        content_id: item?.contentId,
      })

      this.hasChange = true
      this.setItemCounterTemp(item, CONSTANTS.OPERATION_TYPE.ADD)
      this.changeTopSel()
    },
    counterRemove(item) {
      sailing_c_x_sf_specselection_choose_ck({
        item_id: item?.itemId,
        content_select_type: 3,
        choose_type: 2,
        content_id: item?.contentId,
      })
      this.hasChange = true
      this.setItemCounterTemp(item, CONSTANTS.OPERATION_TYPE.REMOVE)
      this.changeTopSel()
    },
    triggerItemPermanent(item) {
      this.hasChange = true
      this._setItem(item, this._getRadioOrCheckboxSelectedValue(!strategy.isSelected(item)))
    },
    goDown(item) {
      this.animationClick('pfadeInRight', 200)
      this.data = item

      // 导航，根据交互形式限制
      this.stack.push(item)
    },
    goUp(item) {
      if (+item?.node.level > 1) {
        this.animationClick('pfadeInRight', 200)
      }
      const parent = ta.getParentNode(this.treeData, item)
      if (parent) {
        this.data = parent
        // 导航，根据交互形式限制
        this.stack.pop()
        this.changeTopSel()
      } else {
        if (strategy.isSelected(item)) {
          const output = this.getNodeList(this.treeData)
          // cartId mduId
          console.log('=========== 确定 规格面板 ===========', output)
          this.addShoppingCart(output)
        } else {
          console.log('=========== 取消 规格面板 ===========', item)
        }
      }
    },
    addShoppingCart(data) {
      this.$emit('add-shopping-cart', data)
    },
    _clone(data) {
      return JSON.parse(JSON.stringify(data))
    },
    getNodeList(tree) {
      const node = this._clone(tree.node)
      node.amount = this.copies
      let arr = [node]
      strategy.getSelectedLeafByPreOrder(tree, item => {
        const node = this._clone(item.node)
        node.amount = item.amount
        arr.push(node)
      })
      // 去掉重复的根节点
      arr = this._removeDuplicateRootNodes(arr, this.copies)
      return arr
    },
    _removeDuplicateRootNodes(arr, rootAmount) {
      let list = arr.filter(item => {
        if (item.level === 1) {
          return item.amount === rootAmount
        } else {
          return true
        }
      })
      list = this._unique(list, _ => _.nodeId)
      return list
    },
    _unique(arr, keyGen) {
      const tmp = {}
      return arr.filter(item => {
        const k = keyGen(item)
        if (tmp[k] === undefined) {
          tmp[k] = true
          return true
        } else {
          return false
        }
      })
    },
    cancel(item) {
      this._each(item, (subItem, type) => {
        if (type === CONSTANTS.SELECT_TYPE.COUNTER) {
          this._setItemCounterTemp(subItem, CONSTANTS.UNMODIFIED_VALUE)
        } else {
          this.setItemTemp(subItem, CONSTANTS.UNMODIFIED_VALUE)
        }
      })
    },
    ok(item) {
      this._each(item, (subItem, type) => {
        if (type === CONSTANTS.SELECT_TYPE.COUNTER) {
          this._setItemCounter(subItem, strategy.getItemCounter(subItem))
          this._setItemCounterTemp(subItem, CONSTANTS.UNMODIFIED_VALUE)
        } else {
          // 生效节点状态
          this.setItem(subItem, this._getRadioOrCheckboxSelectedValue(strategy.isSelected(subItem)))
          // 清除节点临时状态
          this.setItemTemp(subItem, CONSTANTS.UNMODIFIED_VALUE)
        }
      })
      // 设置当前节点
      this.setItem(item, 1)
    },
    setItemTemp(item, val) {
      this._setItemTemp(item, val)
    },
    setItem(item, val) {
      this._setItem(item, val)
      this._setSelectedSubItemsForDisplay(item)
    },
    _setSelectedSubItemsForDisplay(item) {
      if (!strategy.isLeaf(item)) {
        const subItemsForDisplay = strategy.getSelectedSubItems(item)
        Vue.set(item, CONSTANTS.DISPLAY_TAG.ARRAY_KEY, subItemsForDisplay)
      }
    },
    _setItem(item, val) {
      Vue.set(item, CONSTANTS.SELECTED_TAG.RADIO_AND_CHECKBOX.PERMANENT, val)
      item[CONSTANTS.SELECTED_TAG.RADIO_AND_CHECKBOX.PERMANENT] = val
    },
    _setItemTemp(item, val) {
      Vue.set(item, CONSTANTS.SELECTED_TAG.RADIO_AND_CHECKBOX.TEMPORARY, val)
      item[CONSTANTS.SELECTED_TAG.RADIO_AND_CHECKBOX.TEMPORARY] = val
    },
    setItemCounterTemp(item, type) {
      let val = strategy.getItemCounter(item)
      if (type === CONSTANTS.OPERATION_TYPE.ADD) {
        return this._setItemCounterTemp(item, ++val)
      } else {
        return this._setItemCounterTemp(item, --val)
      }
    },
    _setItemCounter(item, val) {
      Vue.set(item, CONSTANTS.SELECTED_TAG.COUNTER.PERMANENT, val)
      item[CONSTANTS.SELECTED_TAG.COUNTER.PERMANENT] = val
    },
    _setItemCounterTemp(item, val) {
      Vue.set(item, CONSTANTS.SELECTED_TAG.COUNTER.TEMPORARY, val)
      item[CONSTANTS.SELECTED_TAG.COUNTER.TEMPORARY] = val
    },
    _each(item, cb) {
      item.contentList.forEach(group => {
        let type
        if (strategy.isRadio(group)) {
          type = CONSTANTS.SELECT_TYPE.RADIO
        }
        if (strategy.isCheckbox(group)) {
          type = CONSTANTS.SELECT_TYPE.CHECKBOX
        }
        if (strategy.isCounter(group)) {
          type = CONSTANTS.SELECT_TYPE.COUNTER
        }
        group.subItemList.forEach(item => {
          cb(item, type)
        })
      })
    },
    backToRoot() {
      this.data = this.info
      this.stack = [this.info]
    },
    updateParentItem(item) {
      const parent = ta.getParentNode(this.info, item)
      const hasChildren = parent && parent.contentList && parent.contentList.length > 0
      if (hasChildren) {
        const selected = strategy.match(parent)
        this.setItem(parent, this._getRadioOrCheckboxSelectedValue(selected))
        this.updateParentItem(parent)
      }
    },
    nav(item) {
      this.animationClick('pfadeInRight', 200)
      // 切换视图
      this.data = item

      // 且导航
      let index = -1
      this.stack.forEach((_, i) => {
        if (_.itemId === item.itemId) {
          index = i
        }
      })
      this.stack = this.stack.slice(0, index + 1)
    },
    animationClick(okName, time) {
      if (this.animationST) return
      this[okName] = true
      this.animationST = setTimeout(() => {
        this[okName] = false
        this.animationST = null
      }, time)
    },
  },
}
</script>

<style scoped lang="scss">
.tree-box {
  border-radius: 8px;
  background: #fff;
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .tree-box {
    min-height: 390px;
    max-height: 656px;
  }
  .max-height {
    height: 656px;
  }
  .has-head-img {
    min-height: 470px;
  }
}
@media screen and (max-width: 768px) {
  .tree-box {
    border-radius: rem(18) rem(18) 0 0;
    height: 100%;
  }
}
// 转场动画
.pfadeInRight-box {
  animation: pfadeInRight 200ms both;
}
@keyframes pfadeInRight {
  from {
    opacity: 0.3;
    transform: translateX(100%);
  }
}
</style>
