import Vue from 'vue'

Vue.directive('inputFocus', {
  // 当被绑定的元素插入到 DOM 中时
  inserted: el => {
    if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
      el.addEventListener('touchend', event => {
        if (event.target instanceof HTMLInputElement) {
          event.target.focus()
        }
      })
    }
  },
})
