import Vue from 'vue'
import loadJs from '@/components/Loading/index.js'
/**
 * v-r-loading 指令
 * 属性 fe-loading-size 标记loading尺寸
 * 属性 fe-loading-custom-class class名
 * 属性 fe-hide-container 隐藏容器
 * 属性 fe-loading-background 背景控制 background属性值
 * 修饰符 fullscreen 标识全局loading (无则默认插入当前元素)
 * 修饰符 nonefloat 标识 loading 不需要 遮罩和脱离文档流
 */
function InstanceCom(el, binding) {
  const { value, modifiers } = binding
  // modifiers 修饰符对象  fullscreen
  if (!value) return

  const size = el.getAttribute('fe-loading-size')
  const hideContainer = !!el.getAttribute('fe-hide-container')
  const customClass = el.getAttribute('fe-loading-custom-class')
  const background = el.getAttribute('fe-loading-background')

  const nonefloat = modifiers?.nonefloat
  const fullscreen = modifiers?.fullscreen
  const parentNode = fullscreen ? document.body : el

  const props = {}
  size && (props.size = size)
  customClass && (props.customClass = customClass)
  hideContainer && (props.hideContainer = hideContainer)
  nonefloat && (props.noneFloat = nonefloat)
  fullscreen && (props.fullScreen = fullscreen)
  background && (props.background = background)

  el.instance = loadJs({
    el: parentNode,
    props,
  })
}
function InstanceClose(el) {
  const obj = el.instance
  if (!obj) return
  obj && obj.close()
  el.instance = null
}
Vue.directive('r-loading', {
  bind() {},
  inserted(el, binding) {
    const { value } = binding
    value && InstanceCom(el, binding)
  },
  update(el, binding) {
    const { value, oldValue } = binding
    if (value !== oldValue) {
      value && InstanceCom(el, binding)
    }
    !value && InstanceClose(el, binding)
  },
  unbind(el, binding) {
    InstanceClose(el, binding)
  },
})
