// const ERROR_TICKET_ERROR       = 50000;   //校验失败
// const ERROR_TICKET_EMPTY       = 50001;   //参数错误
// const ERROR_NEED_LOGIN         = 50002;   //未登录
// const ERROR_LOGIN_OTHER_DEVICE = 50003;   //在其他地方登陆
// const ERROR_OLD_DEVICE         = 50004;   //客户端版本太老
// const ERROR_USER_BANNED        = 50005;   //用户封禁
import i18n from '@/i18n/locale';
import { act } from '@/api/apiConstants';

const { NA_POP_DIALOG } = act;

export const loginStatus = [50000, 50001, 50002, 50003, 50004];
export const ignoreLoginPathList = [NA_POP_DIALOG];

// 根据不同的状态码，生成不同的提示信息 ========todo翻译
export function showStatus(status) {
  let message = '';
  switch (status) {
    case 400:
      message = i18n.t('网络异常，请稍后再试');
      break;
    case 401:
      message = i18n.t('网络异常，请稍后再试');
      break;
    case 403:
      message = i18n.t('网络异常，请稍后再试');
      break;
    case 404:
      message = i18n.t('网络异常，请稍后再试');
      break;
    case 408:
      message = i18n.t('网络异常，请稍后再试');
      break;
    case 500:
      message = i18n.t('网络异常，请稍后再试');
      break;
    case 501:
      message = i18n.t('网络异常，请稍后再试');
      break;
    case 502:
      message = i18n.t('网络异常，请稍后再试');
      break;
    case 503:
      message = i18n.t('网络异常，请稍后再试');
      break;
    case 504:
      message = i18n.t('网络异常，请稍后再试');
      break;
    case 505:
      message = i18n.t('网络异常，请稍后再试');
      break;
    default:
      message = i18n.t('网络异常，请稍后再试');
  }
  return message;
}
