import { debounce } from 'lodash-es'
// import Vconsole from 'vconsole';
import env from '@/config/env'
const screenSizeType = {} // 屏幕尺寸的类型  pc 或者 H5

let vm // 存储Vue实例

screenSizeType.install = function (Vue, options) {
  // Vue.prototype.$useOptionChain = get;
  vm = Vue
  const { store } = options

  let initTag = true // 初始标识
  let initState // 初始状态
  ;(function (d, w) {
    const e = d.documentElement
    const a = 'orientationchange' in window ? 'orientationchange' : 'resize'
    const b = debounce(function () {
      const f = e.clientWidth
      const clientHeight = e.clientHeight || 0
      if (!f) {
        return
      }

      let status = false

      if (f > 768) {
        // PC
        status = false

        if (!initTag && initState === 'H5') {
          env?.isPro && window.location.reload()
        } else {
          initTag = false
          initState = 'PC'
        }
      } else {
        // H5
        status = true

        if (!initTag && initState === 'PC') {
          env?.isPro && window.location.reload()
        } else {
          initTag = false
          initState = 'H5'
        }

        if (!env?.isPro) {
          // let vConsole = new Vconsole();
          // Vue.use(vConsole);
        }
      }

      store.dispatch('common/setIsH5', status)
      store.dispatch('common/setClientHeight', clientHeight)
      vm.prototype.isH5 = status
    }, 300)
    w.addEventListener(a, b, false)
    d.addEventListener('DOMContentLoaded', b, false)
  })(document, window)
}

export default screenSizeType
