import { initWsgenv } from '@didi/wsgsdk'
import { omega } from '../omega.js'
import wsgConfig from './wsgConfig.js'
class WsgChallenge {
  constructor(options) {
    const { appId, env, uid, domainList } = options
    this.appId = appId
    this.env = env
    this.uid = uid
    this.domainList = domainList

    this.initWsgSDK()
  }

  initWsgSDK() {
    try {
      initWsgenv({
        appId: this.appId,
        env: this.env,
        uid: this.uid,
        domainList: this.domainList,
        omega,
      })
    } catch {}
  }
}

let wsgChallenge = new WsgChallenge(wsgConfig.online)

export default wsgChallenge
