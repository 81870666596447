/**
 *  https://wiki.intra.xiaojukeji.com/pages/viewpage.action?pageId=451224206
 */
import i18n from '@/i18n/locale'
import { requestCommonParams } from '@/lib/request/requestCommonParams'
import { md5Encrypt } from '@/utils/crypto'
import { getLocalStorage } from '@/utils/localStorage'

// 提单页INFO
export const BILL_INFO_TYPE = {
  REMINDER_INFO: 1, // 顶部提醒组件（小绿条） reminderInfo
  ADDRESS: 2, // 地址组件 address
  DELIVERY_METHOD: 3, // 交货方式 deliveryMethods
  PAY_CHANNEL: 4, // 支付方式 payChannel
  ITEMS_INFO: 5, // 商品展示组件 itemsInfo
  PRICE_INFO: 6, // 费用项组件 priceInfo （<– 目前最通用的，没有priceInfo就直接展示content的内容即可）
  TIP_FEE: 7, // 小费 priceInfo & tipFeeInfo
  REMARK: 8, // 备注
  SHOP_COUPON: 9, // 商家券 couponInfo & priceInfo
  PLAT_COUPON: 10, // 平台券 couponInfo & priceInfo
  FOLLOWING_INFO: 11, // 券包展示组件 followingInfo
  ALL_PRICE_INFO: 12, // 总价 priceInfo
  ABNORMAL_ITEM_OPT: 13, // 缺货处理选项 abnormalItemInfo
  DISHE_SUBTOTAL: 14, // 购物车小计 princeInfo
  STORE_COUPON: 15, // 商家满减券 rcouponInfo
  CETA_TIME: 18, // 预计送达时间
  PRICE_TITLE: 24, //  费用标题
}

// 提单页 update
export const BILL_UPDATE_TYPE = {
  DELIVERY: 1,
  PAYMENT: 2,
  EDIT_DISHES: 3,
  DEL_DISHES: 4,
  MODIFY_TIP: 5, // 修改小费
  MODIFY_COMMENTS: 22, // 修改备注从6调整为22
  MODIFY_BUSINESS_COUPON: 7,
  MODIFY_PLATFORM_COUPON: 8,
  SELECT_COUPON_BOX: 9,
  DEFECT_INFO: 10,
  DEFAULT_TIP_AND_ONLY: 25, // 开启/关闭小费默认开关
}

// 提单页 组件ID
export const BILL_COMP_IDS = {
  DISH_ORGINAL_ID: 517, // 菜品原价
  DISH_PRICE_PROMO_ID: 516, // 菜品折扣后的价格
  DELIVERY_ID: 402, // 配送费-原价
  DELIVERY_PROMO_ID: 512, // 配送费-折扣价
  SERVICE_ID: 404, // 服务费
  SAVE_MONEY_INFO_ID: 501, // Spend X Save Y 组件
  PAY_DEDUCTION_ID: 506, // 支付立减组件id
}

// 提单页 preOrderId 生成规则：md5(uid+datatype+microtime)
export const getPreOrderId = () => {
  try {
    const { dataType } = requestCommonParams()
    const uid = getLocalStorage('uid') || ''
    const microtime = new Date().getTime()

    return md5Encrypt(uid + dataType + microtime)
  } catch (err) {}
}

/**
 *   🔽 SF:
 *    http://wiki.intra.xiaojukeji.com/pages/viewpage.action?pageId=1151986325
 *    http://wiki.intra.xiaojukeji.com/pages/viewpage.action?pageId=725328068
 */

// 建筑类型
export const BUILDING_TYPE = {
  Apartment: 1, // 公寓
  ResidentialBuilding: 2, // 居民楼
  Hotel: 3, // 酒店
  OfficeBuilding: 4, // 写字楼
  Other: 5, // 其他
}

// 见面方式
export const MeetingOptions = {
  MeetAtEntrance: 1, // 门口见面
  // DropAtEntrance: 2, 放在门口（废弃）
  MeetOutsideBuilding: 3, // 楼外见面
  MeetInLobby: 4, // 大厅见面
}

/** @desc 见面方式提示文案 */
export const MeetingMsgMap = {
  [MeetingOptions.MeetAtEntrance]: {
    msg: i18n.t(
      'FoodC_edit_DOXu_EYnd' /* 例如：请敲门，不要按门铃。还可上传您所在位置附近的参照物图，方便骑手准确送达。 */,
    ),
    icon: 'icon-outline_personalcenter',
  },
  [MeetingOptions.MeetOutsideBuilding]: {
    msg: i18n.t(
      'FoodC_edit_ldOR_SyHv' /* 例如：我穿着花衬衫，在街边等候。还可上传您所在位置附近的参照物图，方便骑手准确送达。 */,
    ),
    icon: 'icon-outline_meetatdoor',
  },
  [MeetingOptions.MeetInLobby]: {
    msg: i18n.t(
      'FoodC_edit_fLUx_wHAP' /* 例如：我穿着花衬衫。还可上传您所在位置附近的参照物图，方便骑手准确送达。 */,
    ),
    icon: 'icon-outlined_meet_outsiide',
  },
}

/** @desc 交餐方式列表信息 */
export const DeliveryMethodsListInfoMap = {
  [BUILDING_TYPE.Apartment]: [
    {
      type: MeetingOptions.MeetAtEntrance,
      content: i18n.t('FoodC_edit_kzdk_yzVc' /* 请在房间门口见面 */),
    },
    {
      type: MeetingOptions.MeetOutsideBuilding,
      content: i18n.t('FoodC_edit_NwrE_OHgM' /* 请在公寓楼外见面 */),
    },
    {
      type: MeetingOptions.MeetInLobby,
      content: i18n.t('FoodC_edit_hDGv_arOT' /* 请在楼下大厅见面 */),
    },
  ],
  [BUILDING_TYPE.ResidentialBuilding]: [
    {
      type: MeetingOptions.MeetAtEntrance,
      content: i18n.t('FoodC_edit_cKhP_xcTQ' /* 请在我家门口见面 */),
    },
    {
      type: MeetingOptions.MeetOutsideBuilding,
      content: i18n.t('FoodC_edit_HEFc_Dppo' /* 请在我家楼外见面 */),
    },
  ],
  [BUILDING_TYPE.Hotel]: [
    {
      type: MeetingOptions.MeetAtEntrance,
      content: i18n.t('FoodC_edit_kzdk_yzVc' /* 请在房间门口见面 */),
    },
    {
      type: MeetingOptions.MeetOutsideBuilding,
      content: i18n.t('FoodC_edit_bZFb_ghVG' /* 请在酒店楼外见面 */),
    },
    {
      type: MeetingOptions.MeetInLobby,
      content: i18n.t('FoodC_edit_SkdG_WQKu' /* 请在酒店大堂见面 */),
    },
  ],
  [BUILDING_TYPE.OfficeBuilding]: [
    {
      type: MeetingOptions.MeetAtEntrance,
      content: i18n.t('FoodC_edit_kzdk_yzVc' /* 请在房间门口见面 */),
    },
    {
      type: MeetingOptions.MeetOutsideBuilding,
      content: i18n.t('FoodC_edit_dECN_fkIy' /* 请在写字楼外见面 */),
    },
    {
      type: MeetingOptions.MeetInLobby,
      content: i18n.t('FoodC_edit_hDGv_arOT' /* 请在楼下大厅见面 */),
    },
  ],
  [BUILDING_TYPE.Other]: [
    {
      type: MeetingOptions.MeetAtEntrance,
      content: i18n.t('FoodC_edit_NDmM_lEJY' /* 请在门口见面 */),
    },
    {
      type: MeetingOptions.MeetOutsideBuilding,
      content: i18n.t('FoodC_edit_JdIz_uKvw' /* 在外面见面 */),
    },
    {
      type: MeetingOptions.MeetInLobby,
      content: i18n.t('FoodC_edit_fOqC_upyr' /* 在大厅见面 */),
    },
  ],
}

/** @desc 建筑类型列表 */
export const BUILDING_TYPE_LIST = [
  {
    icon: 'Apartment',
    type: BUILDING_TYPE.Apartment,
    content: i18n.t('FoodC_edit_wBJW_yfSo'),
  },
  {
    icon: 'House',
    type: BUILDING_TYPE.ResidentialBuilding,
    content: i18n.t('FoodC_edit_Hxmm_vrkU'),
  },
  {
    icon: 'Hotel',
    type: BUILDING_TYPE.Hotel,
    content: i18n.t('FoodC_edit_OVRs_CzUt'),
  },
  {
    icon: 'Office',
    type: BUILDING_TYPE.OfficeBuilding,
    content: i18n.t('FoodC_edit_oOAR_FExH'),
  },
  {
    icon: 'Other',
    type: BUILDING_TYPE.Other,
    content: i18n.t('FoodC_edit_stZr_IBeA'),
  },
]

export const ADDRESS_OVERMATCH = 203 // 超出配送区域弹窗

// 提单后点击修改，修改的内容
export const AFTERCHECK_EDIT_TYPE = {
  EDIT_ADDRESS: 1, // 修改地址
  EDIT_DELIVERY: 2, // 修改见面方式
  EDIT_PAY: 3, // 修改支付方式
  EDIT_COM_ITEM: 4, // 修改菜品
  EDIT_TIPS: 5, // 修改小费
  EDIT_OTHER: -1, // 其他
}
