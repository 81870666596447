<template>
  <span :style="style">{{ data.text }}</span>
</template>

<script>
import { hexARGBGetRGBA } from '@/utils/index'

export default {
  name: 'TextPart',
  props: {
    data: Object,
    isArgb: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    style() {
      const { color, size, stroke, weight } = this.data
      const obj = {
        color: this.isArgb && color ? hexARGBGetRGBA(color) : color,
        fontSize: size + 'px',
        textDecoration: stroke === 1 ? 'line-through' : 'none',
      }
      if (weight === 2) {
        obj.fontFamily = 'DiDiSans-Pro-Medium'
      } else if (weight === 3) {
        obj.fontFamily = 'DiDiSans-Pro-Bold'
      } else {
        obj.fontFamily = 'DiDiSans-Pro-Regular'
        obj.fontWeight = this.getWeight(weight)
      }
      return obj
    },
  },
  methods: {
    getWeight(weight) {
      switch (weight) {
        case 0:
          return 'normal'
        case 1:
          return 'normal'
        case 2:
          return '500' // MEDIUM
        case 3:
          return 'bold'
        case 4:
          return 'lighter'
        case 5:
          return 'normal' // DEMI（暂时只Flutter支持）
        default:
          return 'normal'
      }
    },
  },
}
</script>
