import App from './App.vue'
import { Vue, i18n, router, store } from './main-common'
import { openInit } from './init'
import currency from '@/utils/currency'

initialize([
  currency.init(), // 50ms 延时
]).then(() => {
  init()
})

function init() {
  openInit().then(() => {
    new Vue({
      i18n,
      router,
      store,
      render: h => h(App),
    }).$mount('#app')
  })
}

function initialize(promiseArr) {
  return Promise.all(promiseArr)
}
