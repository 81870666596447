export const routerRedirectCatch = Router => {
  // https://blog.csdn.net/mm0715/article/details/112768494
  const originalPush = Router.prototype.push
  const originalReplace = Router.prototype.replace

  Router.prototype.push = function (location, onResolve, onReject) {
    location.query && delete location.query.navigationType
    if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
    return originalPush.call(this, location).catch(err => err)
  }

  Router.prototype.replace = function (location, onResolve, onReject) {
    // navigationType 是为了解决 导航守卫无法区分 push / replace 方法触发的问题
    if (onResolve || onReject) return originalReplace.call(this, location, onResolve, onReject)
    return originalReplace.call(this, location).catch(err => err)
  }
}
