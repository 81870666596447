import { META } from '@/config/countryConfig/list/page-title'
import { getLocalStorage } from '@/utils/localStorage'
const SLASH = '/'
const FEED_NAME = 'feed'
const STORE_NAME = 'store'
const ORDER_NAME = 'order'
const BRAND_PAGE_NAME = 'brandPage'
// 该重定向影响了 replace 效果，因为无法判断是否是 replace 触发导航守卫
export const routerRedirect = (to, from, next) => {
  if (to?.path.endsWith(SLASH)) {
    next()
    return
  }
  next({
    path: to?.path + SLASH,
    query: to?.query,
    replace: to.name === from.name,
  })
}

export function urlParamsSymbolChange(to, from, next) {
  if (to.name === STORE_NAME) {
    // url 去除空格 加号 反斜杠 等符号 转换为_  目前只有store页需要
    const toParams = paramsReplace(to.params)
    if (!toParams) {
      next()
    } else {
      const pathStr = getPathFromParams(to?.matched[0]?.path, toParams)
      // path: "/store/:storeId?/:shopName?/:itemId?/:itemName?"
      next({
        path: pathStr,
        params: toParams,
        query: to.query,
        replace: to.name === from.name,
      })
    }
  } else if (to.name === ORDER_NAME) {
    // 如果是来自brand页面，回到order页面的时候需要带上对应的brandTicket
    if (getLocalStorage('isTNEntranceFrom') === BRAND_PAGE_NAME) {
      if (to.query.brandTicket) {
        next()
      } else {
        // 构造一个新的路由对象，包含要添加的 query 参数
        const newRoute = {
          path: to.path,
          query: { ...to.query, brandTicket: getLocalStorage('brandTicket') },
        }
        // 使用 next() 函数进行导航，并传入新的路由对象
        next(newRoute)
      }
    }
    next()
  } else {
    next()
  }
}

function getPathFromParams(pathRule = '', params) {
  const split = '/'
  const arr = pathRule.replace(/\?/gi, '').split(split)
  return arr.reduce((pre, next) => {
    let str = next
    if (str.indexOf(':') === 0) {
      str = params[str.substring(1)]
    }
    const splitStr = str ? split : ''
    return `${pre}${splitStr}${str}`
  }, '')
}
function paramsReplace(params = {}) {
  const keys = Object.keys(params)
  let change = false
  const rParams = {}
  keys.forEach(item => {
    const paramsItem = params[item]
    const str = rStr(paramsItem)
    if (paramsItem && paramsItem !== str) {
      change = true
    }
    rParams[item] = str
  })
  return change ? rParams : null
}
function rStr(str = '') {
  // 替换 params 特殊字符
  return str.replace(/\+|\s|\/|\.|\?|`|%|#|&|=/gm, '-') // 和seo逻辑相关联
}

// 修改document.title
export function setTitle(to, from, next) {
  // TODO 多国上线时需要适配
  try {
    if (to.name !== from.name) {
      const obj = META[to.name] ? META[to.name] : META[FEED_NAME]
      obj.title && (document.title = obj.title)
    }
  } catch (e) {}
  next()
}
