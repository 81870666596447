/**
 * 支付异常处理逻辑：http://wiki.intra.xiaojukeji.com/pages/viewpage.action?pageId=182813871
 * 1. 支付异常
 * 2. 支付异常响应逻辑
 *
 * 支付流程：http://wiki.intra.xiaojukeji.com/pages/viewpage.action?pageId=472807223
 *
 * 状态码异常：http://wiki.intra.xiaojukeji.com/pages/viewpage.action?pageId=1084183792
 * 提单异常处理逻辑：http://wiki.intra.xiaojukeji.com/pages/viewpage.action?pageId=472807223
 * 1. 提单异常
 * 2. 提单异常响应逻辑
 */

import { APP_INFO } from './base'
import config from '@/config'

function collectionToMap(collection, trans) {
  const map = {}
  Object.keys(collection).forEach(key => {
    const list = collection[key]
    list.forEach(value => {
      map[value] = trans(key)
    })
  })
  return map
}

function transToNumber(value) {
  return Number(value)
}

/**
 * @desc 提单异常分支分类
 * 共拆分9类异常分支
 * 填充按钮确认/取消
 * 默认异常确认更新页面
 */
export const SUBMIT_ORDER_ERROR_RESPONSE_TYPE = {
  /** riskControl 弹窗并更新 【确认】**/
  PUP_RISK_UPDATE: 1,
  /** riskControl 只弹窗，不更新 【确认】**/
  PUP_RISK_NO_UPDATE: 2,
  /** 弹窗，”确认“后刷新提单页 【确认】刷新页面**/
  PUP_REFRESH: 3,
  /** 只弹窗【确认】仅确认**/
  PUP: 4,
  /** 弹窗 & 退出提单页 【确认、取消】确认返回上一页**/
  PUP_CLOSE: 5,
  /** 登录异常	跳转登陆 【确认】去登录**/
  LOGIN_ERR: 6,
  /** etaConfirm 强制下单 【确认】etaConfirm=1 **/
  ETA_CONFIRM: 7,
  /** 未成年二次确认 【确认】adultConfirmStatus=1 **/
  UNDER_AGE: 8,
  /** 重复下单提示 【确认】duplicateOrderConfirm=1 **/
  ORDER_REPEAT: 9,
  /** 重复下单提示 【确认】noCapacityConfirm=1 **/
  NO_CAPACITY: 10,
}

/**
 * 提单异常
 */
export const SUBMIT_ORDER_ERROR_RESPONSE_COLLECTION = {
  /** riskControl 弹窗并更新 【确认】**/
  [SUBMIT_ORDER_ERROR_RESPONSE_TYPE.PUP_RISK_UPDATE]: [
    100003, // 券系统返回无真身错误码
    100004, // 不能下单
    100007, // 需要验证
    100008, // 部分渠道不可用
  ],
  /** riskControl 只弹窗，不更新 【确认】**/
  [SUBMIT_ORDER_ERROR_RESPONSE_TYPE.PUP_RISK_NO_UPDATE]: [
    41051, // 当前优惠券无法使用
    41053, // 暂时无法享创建订单
    41054, // 当前无法使用现金支付
    41055, // 当前无法使用信用卡支付
    41056, // Adyen绑卡
    41057, // Facebook授权
    41058, // CURP验真
    41059, // CPF验真
    41060, // 需要真身验证
    41063, // 银行卡随机金额验证
    41064, // 线上单限额 - 钱包
    41066, // 线上单限额 - paypay
    41067, // EFO被风控
    41069, // 老客被风控
  ],
  /** 弹窗，”确认“后刷新提单页 【确认】**/
  [SUBMIT_ORDER_ERROR_RESPONSE_TYPE.PUP_REFRESH]: [
    40115, // 在不可配送区域,不可创建订单
    41000, // 创建订单失败
    41010, // 商品为空
    41013, // 下单部分商品不存在
    41020, // 订单价格更新, 请重新下单
    41021, // 商品价格为0, 不可下单
    41025, // 有未支付的现金单
    41032, // 店铺未营业
    41034, // 订单信息发送变化。如配送费、eta等
    41036, // 不在开通城市
    41038, // 支付方式参数校验错误
    41039, // 支付方式参数校验错误
    41043, // 地址参数错误
    41068, // 活动状态发生改变，已为您刷新
    41072, // 下单的时候超限了
    41073, // 下单的时候子菜超限了
    44040, // 酒类订单特殊错误码
    44100, // 查询提单页信息异常
    48201, // 随机金额校验
  ],
  /** 只弹窗【确认】**/
  [SUBMIT_ORDER_ERROR_RESPONSE_TYPE.PUP]: [
    41033, // 店铺超出配送范围
    41040, // 超出{0}个之外的菜品，需要另外下单
    41045, // 请选用线上支付方式购买券包
    44004, // 当前订单金额上限为{0}。
    47101, // 下单后被配送方式拦截(门口见 与 酒类菜品线下支付 互斥)
    41090, // 下单用户太多, 请您稍等一会儿下单
    41091, // 抱歉，暂无持有POS机的骑手，请更换支付方式下单。
    41092, // 抱歉，暂无支持配送现金单的骑手，请更换支付方式下单。
    41094, // 您当前的支付金额低于该银行最小可支付金额，请更换其他支付方式
    42424, // 校验姓名的黑名单
  ],
  /** 弹窗 & 退出提单页 【确认、取消】**/
  [SUBMIT_ORDER_ERROR_RESPONSE_TYPE.PUP_CLOSE]: [
    41049, // 当前菜品的活动发生变化，请返回刷新活动
  ],
  /** 登录异常	跳转登陆 【确认】**/
  [SUBMIT_ORDER_ERROR_RESPONSE_TYPE.LOGIN_ERR]: [50000, 50001, 50002, 50003, 50004, 50005],
  /** etaConfirm  确认后下单【确认】**/
  [SUBMIT_ORDER_ERROR_RESPONSE_TYPE.ETA_CONFIRM]: [
    41035, // 配送时间比较长
    41042, // 购物车当前展示ETA与请求后最新ETA差值 Δ（Δ≥ 20分钟）
  ],
  /** 未成年二次确认 【确认】**/
  [SUBMIT_ORDER_ERROR_RESPONSE_TYPE.UNDER_AGE]: [
    41046, // 未成年二次确认框
    41047,
  ],
  /** 重复下单提示 【确认】**/
  [SUBMIT_ORDER_ERROR_RESPONSE_TYPE.ORDER_REPEAT]: [
    46900, // 重复下单拦截
  ],
  /** 运力不足  确认后下单【确认】**/
  [SUBMIT_ORDER_ERROR_RESPONSE_TYPE.NO_CAPACITY]: [
    41093, // 这家店附近骑手少，下单后可能需要长时间等待骑手接单，是否仍然下单?
  ],
}

export const SUBMIT_ORDER_RESPONSE_ERROR_MAP = collectionToMap(
  SUBMIT_ORDER_ERROR_RESPONSE_COLLECTION,
  transToNumber,
)

/**
 * 支付异常响应逻辑
 */
export const PAY_ERROR_RESPONSE_TYPE = {
  // 内部错误，重试
  RETRY: 1,
  // 外部错误，弹窗提示后刷新提单页
  PUP_REFRESH: 2,
}

/**
 * 提单异常
 */
export const PAY_ERROR_RESPONSE_COLLECTION = {
  [PAY_ERROR_RESPONSE_TYPE.RETRY]: [-1, 82019],
  [PAY_ERROR_RESPONSE_TYPE.PUP_REFRESH]: [
    900000, 900001, 900002, 900003, 900004, 900005, 900006, 900007, 900008, 900008, 900009, 900010,
    900011, 900012, 900013, 900014,
  ],
}

export const PAY_RESPONSE_ERROR_MAP = collectionToMap(PAY_ERROR_RESPONSE_COLLECTION, transToNumber)

export const PAY_SDK_CONFIG = {
  WEB_CHANNEL: '1300',
  DATA_TYPE: 130,
  APP_VERSION: APP_INFO.APP_VERSION,
}

// http://pay.intra.xiaojukeji.com/#techwiki/view/170
// 1 未支付；2 免密支付中；3 已支付；4 免密支付失败；5 线下支付；6 风控关单；7 已关单
export const PAY_STATUS = {
  NOT_PAY: 1,
  IN_PAY: 2,
  PAID: 3,
}

export const PAY_REQUEST_STATUS = {
  SUCCESS: 0,
  PARAMS_MISS: '81002',
}

export const PAY_CHANNEL_TYPE = {
  // Paypay 免密支付
  CHANNEL_PAYPAY: 182,
  // 99pay
  CHANNEL_WALLET: 190,
  // 现金支付
  CHANNEL_CASH: 153,
  // 在线支付
  CHANNEL_CREDIT_CARD: 150,
  // POS 机支付
  CHANNEL_POS: 154,
}

export const PAYMENT_QUERY_CALLBACK = {
  // 网络请求
  REQUEST_LEVEL: {
    isNetworkError(res) {
      // 网络异常
      return res.errno === config.constants.NETWORK_ERROR
    },
    isParamsMissing(res) {
      // 缺少 transId
      return res.errno === PAY_REQUEST_STATUS.PARAMS_MISS
    },
  },
  // 支付业务
  BUSINESS_LEVEL: {
    // 请求成功，request直接返回 res.data
    isParamsError(data) {
      // transId 取值异常
      // eslint-disable-next-line no-void
      return data.pay_status === PAY_STATUS.NOT_PAY && data.pay_status_detail === void 0
    },
    isSuccess(data) {
      return data.pay_status === PAY_STATUS.PAID
    },
    isTimeout(data) {
      return data.pay_status === PAY_STATUS.IN_PAY
    },
    isFail(data) {
      return !this.isSuccess(data) && !this.isTimeout(data)
    },
  },
}
