/**
 * @desc 自动曝光埋点指令
 * @example <div v-observer-log="data"></div>
 * @param {Object} data 埋点参数
 *  data = {
 *     eventId: string // omega 上报的事件名，唯一标志
 *     eventLabel?: string // omega 上报事件的标签，用来描述该事件行为
 *     attrs? : object  // omega 业务中需要上报的数据
 *     tag? : string  // 事件标识: 相同数据不同场景的上报 (通常用于筛选,不同的筛选条件相同的数据,算多次曝光)
 *  }
 */
import Vue from 'vue'
import 'intersection-observer'
import { logStrategy } from './logStrategy'
import { getLogInfoId, LOG_DATA_MAP } from './tools'

/** @desc 曝光事件监听 */
export const observer = new IntersectionObserver(
  entries => {
    entries.forEach(entry => {
      if (entry.isIntersecting) {
        logStrategy(entry.target)
      }
    })
  },
  {
    root: null, // 默认浏览器视窗
    threshold: 0.05, // 元素出现既上报
  },
)

Vue.directive('observer-log', {
  bind(ele, binding) {
    const { value = '' } = binding
    if (value) {
      const id = getLogInfoId(value)

      ele.setAttribute('data-id', id)
      LOG_DATA_MAP[id] = value
    } else {
      console.warn('[directive] observer-log 未获取到埋点参数~')
    }
  },
  inserted(ele) {
    observer.observe(ele)
  },
  unbind(ele) {
    observer.unobserve(ele)
  },
})
