import { initWsgenv } from '@didi/wsgsdk'
import queryString from 'query-string'
import wsgConfig from './wsgConfig.js'
import env from '@/config/env.js'

export const initWsgSDK = function () {
  const { appId, env, uid } = wsgConfig.online
  try {
   const wsgInstance = initWsgenv({
      appId,
      env,
      uid,
      wsgenvType: 'common', // 初始化成功前也有wsgenv返回
    })
    window.WsgInstance= wsgInstance
    return wsgInstance
  } catch {}
}

class WsgEnv {
  constructor() {
    this.wsgenv = ''
  }

  getWsgEnv({ url, bfield = {} }) {
    return new Promise((resolve, reject) => {
      try {
        window?.WsgInstance?.wsgParams({ url, bfield }, wsgenv => {
          this.wsgenv = wsgenv
          resolve(wsgenv)
        })
      } catch {
        reject()
      }
    })
  }

  async wsgEnvToBody(config) {
    if(env.isDev || location?.host?.startsWith('sim')) {
      // 开发环境或者sim环境不上传
      return
    }
    await this.getWsgEnv({ url: config.url })
    if (!this.wsgenv) return config
    const params = this.getDataObject(config.data)
    params.wsgenv = this.wsgenv
    config.data = this.setDataString(params)
    return config
  }

  getDataObject(data) {
    return queryString.parse(data)
  }

  setDataString(data) {
    return queryString.stringify(data)
  }

  async wsgEnvToServer(config) {
    await this.wsgEnvToBody(config)
    return config
  }
}

let wsgEnv = new WsgEnv()

export default wsgEnv
