/*
 * @Author: amywulan
 * @Date: 2021-07-Mo 11:41:54
 * @Last Modified by:  amywulan
 * @Last Modified time: 2021-07-Mo 11:41:54
 * @description: api入口文件
 */

import { requireContextApi } from '@/utils/requireContext'
const ctx = require.context('./modules', true, /\.js$/)
export default requireContextApi(ctx)
