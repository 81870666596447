<template>
  <el-dialog
    custom-class="next-confirm-msg-popup-wrapper sf-center-el-dialog"
    :visible="value"
    width="300px"
    :show-close="false"
    :append-to-body="true"
  >
    <h1 class="close-tip-title">{{ title }}</h1>
    <div class="bottom-btn">
      <p v-if="cancelShow" class="cancel" @click="closeCancel" v-text="cancelTxt || $t('取消')"></p>
      <p
        v-if="confirmShow"
        class="confirm"
        @click="closeConfirm"
        v-text="confirmTxt || $t('确认')"
      ></p>
      <i v-if="confirmShow && cancelShow" class="border"></i>
    </div>
  </el-dialog>
</template>
<script>
export default {
  name: 'ConfirmMsg',
  props: {
    value: Boolean,
    title: String,
    confirmShow: {
      type: Boolean,
      default: true,
    },
    cancelShow: {
      type: Boolean,
      default: true,
    },
    confirmTxt: {
      type: String,
      default: '',
    },
    cancelTxt: {
      type: String,
      default: '',
    },
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {
    closeConfirm() {
      this.confirmHook && this.confirmHook()
      this.$emit('confirm')
    },
    closeCancel() {
      this.cancelHook && this.cancelHook()
      this.$emit('cancel')
    },
    onClose() {
      this.$destroy()
      this.$el?.parentNode?.removeChild(this.$el)
    },
  },
}
</script>

<style lang="scss" src="./index.scss"></style>
