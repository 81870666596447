/**
 * @Author: renhaojie
 * @Date: 2021-08-03 12:00:00
 */
import api from '@/api'
import { PAY_CHANNEL_TYPE } from '@/const/paymentProcess'
// import { COMMON_INFO } from '@/const/base'
// import { requestCommonParams } from '@/lib/request/requestCommonParams'
// import { getTicket } from '@/utils/user'
// import config from '@/config/index'
// const { lang, appVersion, lat, lng, cityId } = requestCommonParams()

const state = {
  // 卡列表
  extraInfoList: [],
  // 选中卡
  selectedMent: {},
  // 确定选中卡
  selectedCard: {},
  // 现金支付
  cashList: {},
  // pos支付
  posList: {},
  // pos支付
  payPayList: {},

  cardLoading: false,
}
const mutations = {
  setPayment(state, data) {
    const extraInfo =
      (Array.isArray(data?.payments) &&
        data?.payments.find(item => {
          // payment_mode 为150时为信用卡支付方法，其内部包含用户的所有卡的列表，字段为extraInfoList
          return +item.channelID === PAY_CHANNEL_TYPE.CHANNEL_CREDIT_CARD
        })) ||
      {}
    // 将channelID添加到每个卡信息中。
    Array.isArray(extraInfo?.extraInfoList) &&
      // eslint-disable-next-line array-callback-return
      extraInfo?.extraInfoList.map(elem => {
        elem.channelID = extraInfo.channelID
      })
    state.extraInfoList = extraInfo.extraInfoList
    state.cashList =
      (Array.isArray(data?.payments) &&
        data?.payments.find(item => {
          return +item.channelID === PAY_CHANNEL_TYPE.CHANNEL_CASH
        })) ||
      {}
    state.posList =
      (Array.isArray(data?.payments) &&
        data?.payments.find(item => {
          return +item.channelID === PAY_CHANNEL_TYPE.CHANNEL_POS
        })) ||
      {}
    state.payPayList =
      (Array.isArray(data?.payments) &&
        data?.payments.find(item => {
          return +item.channelID === PAY_CHANNEL_TYPE.CHANNEL_PAYPAY
        })) ||
      {}
  },

  setSelectedMent(state, data) {
    state.selectedMent = data
  },
  setSelectedCard(state, data) {
    state.selectedCard = data
  },
  setToken(state, data) {
    state.token = data?.token
  },
  setCardLoading(state, data) {
    state.cardLoading = data
  },
  delPayDataMutations(state) {
    state.extraInfoList = []
    state.cashList = {}
    state.posList = {}
    state.payPayList = {}
  },
}
const actions = {
  /**
   * @descripttion: 获取卡列表
   * @param {type}
   * @return
   */
  async getPayInfo({ commit, state }, params) {
    let res = {}
    try {
      res = await api.getPayInfo(params)
      commit('setPayment', res)
    } catch (e) {
      return false
    }
    return res
  },
  delPayData({ commit }) {
    commit('delPayDataMutations')
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
