import env from '@/config/env'
// import { isH5 } from '@/utils/tools'
import getCountryConfig from '@/config/countryConfig/index'
import { getCountry } from '@/utils/getLocalStorageCountry'
import { getLanguageLong } from '@/utils/locale'
const cApiBase = 'https://pre-c.didi-food.com'
const actApiBase = 'https://pre-act-api.didi-food.com'
const fulfillmentApiBase = 'https://pre-fulfillment.didi-food.com'
const cashierApiBase = 'https://cashier-pre.didiglobal.com'
const payApiBase = 'https://pay-pre.didiglobal.com'
const getLanguage = getLanguageLong() === 'es-419' ? 'es-MX' : getLanguageLong()

const config = {
  cApiBase,
  actApiBase,
  cashierApiBase,
  payApiBase,
  fulfillmentApiBase,

  passport: {
    h5LoginUrl: '//static.didiglobal.com/common-biz/global-login/2.0.9/login.global.min.js',
    passportUrl: 'https://page.didiglobal.com/public-biz/pc-login/3.0.8/index.html',
    setCookieUrl: `${cApiBase}/login/callback`,
    appId: 200073,
    source: 70001, // 根据国家区分
    role: 1,
    lang: getLanguage,
    law_url: getCountryConfig.login.lawUrlH5(getCountry),
    country_id: getCountryConfig.login.countryId(getCountry),
    country_calling_code: getCountryConfig.login.countryCallingCode(getCountry),
    city_id: 0,
    canonical_country_code: getCountryConfig.login.canonicalCountryCode(getCountry),
    // 根据国家区分
    // eslint-disable-next-line no-void
    theme: env.is99 ? 'yellow' : void 0,
  },
}

export default config
