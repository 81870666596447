/*
 * @Author: amywulan
 * @Date: 2021-07-Mo 11:41:54
 * @Last Modified by:  amywulan
 * @Last Modified time: 2021-07-Mo 11:41:54
 * @description: api常量按照功能汇总====根据后端划分
 * wiki接口地址:http://wiki.intra.xiaojukeji.com/pages/viewpage.action?pageId=603590921
 */
import config from '@/config/index'

import mockConfigApi from './mockConfig'
const cApi = config.cApiBase
const actApi = config.actApiBase
const cashierApi = config.cashierApiBase
const fulfillmentApi = config.fulfillmentApiBase

// 首页
const feed = {
  FEED_INDEX_V2: cApi + '/feed/indexV2',
  FEED_TOPIC_INDEX_V2: cApi + '/feed/topicIndexV2',
  FEED_RECOMMEND: cApi + '/feed/recommend', // 热词推荐
  FEED_SEARCH: cApi + '/feed/search', // 首页搜索接口
  FEED_SEARCH_SUGGESTION: cApi + '/feed/searchSuggestion',
  // 首页 (已开城列表接口)
  // HOME_SERVICE_CITY_LIST: cApi + '/feed/serviceCityList',
  HOME_SERVICE_CITY_LIST: cApi + '/feed/unopened',
}
// 地址
const address = {
  ADDRESS_CREATE: cApi + '/address/create',
  ADDRESS_UPDATE: cApi + '/address/update',
  ADDRESS_DELETE: cApi + '/address/delete',
  ADDRESS_TEXTSEARCH: cApi + '/address/textSearch',
  ADDRESS_BACKTOFRONT: cApi + '/address/backToFront',
  ADDRESS_LIST: cApi + '/address/listPage',
  GET_ADDRESS_BY_LOCATION: cApi + '/address/getCurrentPoi',
  // （反解poi接口）
  ADDRESS_REVERSE_POI: cApi + '/address/reversePoi',
  UPDATE_DELIVER_METHOD: cApi + '/address/updateDeliveryMethod',
  GET_USER_LAST_ORDER_ADDRESS: cApi + '/address/getUserLastOrderAddress',
}
// 提单
const bill = {
  BILL_INFO: cApi + '/bill/info',
  FAST_BUY: cApi + '/bill/fastBuy',
  BILL_UPDATE: cApi + '/bill/update',
  UPDATE_BILL_ALERT: cApi + '/bill/alert',
  GLOBAL_CART_BILL_LIST: cApi + '/bill/list',
}
// 订单
const order = {
  ORDER_PAY: cApi + '/order/getPayStatus',
  ORDER_DETAIL_BATCH: cApi + '/order/detailBatch',
  // 订单页面详情
  ORDER_LAYOUT_BATCH: cApi + '/order/pageBatch',
  // 未登录订单详情页订单接口
  PAGE_BATCH_WITHOUT_LOGIN: fulfillmentApi + '/order/pageBatchWithoutLogin',
  // 欠款单支付
  DEBT_REPAY: cApi + '/debt/repay',
  // 欠款单支付失败，回执
  DEBT_REPAY_FAIL: cApi + '/debt/repayFail',
  ORDER_REMARK_TAGS: cApi + '/order/remarkTags',
  ORDER_CREATE: cApi + '/order/create',
  ORDER_CREATE_V2: cApi + '/order/createV2',
  ORDER_CANCEL: cApi + '/order/cancelV2',
  ORDER_CONFIRM_MEAL: cApi + '/order/confirmMeal',
  ORDER_REMINDER_MEAL: cApi + '/order/sendReminder',
  ORDER_SENDRECEIPT: cApi + '/order/sendReceiptV2',
  // ORDER_LIST: cApi + '/order/orderList',
  ORDER_LIST_V2: cApi + '/order/orderListV2',
  ORDER_REFUND: cApi + '/order/abnormalItemsInfo',
  // 评价相关
  ORDER_EVALUATION_TAG_LIST: cApi + '/evaluation/tagList',
  ORDER_EVALUATION_EVALUATE: cApi + '/evaluation/evaluate',
  ORDER_EVALUATION_GET_EVALUATE_INFO: cApi + '/evaluation/evaluationInfo',
}

// 购物车
const cart = {
  CART_INFO: cApi + '/cart/info',
  // 全局购物车
  GLOBAL_CART_CLEAR: cApi + '/cart/clear',
  // 购物车加购:批量添加/减少菜品数量
  CART_SETAMOUNT: cApi + '/cart/setItemsAmount',
  // 购物车加购:新增/更新菜品
  CART_SETITEM: cApi + '/cart/setItem',
}

// 券
const coupon = {
  FETCH_COUPONlIST: cApi + '/coupon/fetchCouponList',
  CART_REDEEM: actApi + '/act-api/api/code/cartRedeem',
  PRODUCT_LIST: cApi + '/memberBenefit/productList',
  GET_WALLET_COUPONS: actApi + '/act-api/api/sidebar/validCouponsV2',
  SIDEBAR_EXCHANGE_COUPONS: actApi + '/act-api/api/code/sidebarRedeem',
}
// 品牌
const brand = {
  GET_BRANDINFO: cApi + '/storefront/brandInfo',
  GET_BRAND_SHOPLIST: cApi + '/storefront/brandShopList',
}
// 商家
const shop = {
  SHOP_INDEX: cApi + '/storefront/shopIndex',
  ITEM_DETAIL: cApi + '/item/detail',
}
// 支付
const pay = {
  PAY_GETPAYINFO: cApi + '/pay/getPayInfo',
  GET_PAY_STATUS: cashierApi + '/gulfstream/pay/v1/didipay/getPayStatus',
  GET_SUCCESS_PAY_INFO: cApi + '/pay/getSuccessPayInfo',
}

// 营销
const act = {
  // 天降弹窗曝光上报接口
  POP_DIALOG_EXPOSURE: actApi + '/growth/report/bannerExposure',
  // 客户端弹窗
  NA_POP_DIALOG: actApi + '/act-api/growth/common/popDialog',
  // NA_POP_DIALOG : cApi + "/growth/common/popDialog",
  FEED_EFO_MORE: cApi + '/feed/efoMore',
}

// 用户设置
const user = {
  // 语言切换时，把语言给服务器
  SET_APP_LANG: cApi + '/user/setAppLang',
  // 设置隐私开关
  GET_DISCOUNT_NEWS_SWITCH: cApi + '/setting/discountNewsInfo',
  SET_DISCOUNT_NEWS_SWITCH: cApi + '/setting/setDiscountNews',
  SET_PERSONALIZED_SWITCH: cApi + '/user/updatePersonalizedSettings',
  GET_PERSONALIZED_SWITCH: cApi + '/user/getPersonalizedSettings',
  GET_DATA_SEND_INFO: cApi + '/user/getDataSendingInfo',
  SEND_DATA: cApi + '/user/sendData',
  UPDATE_USER_INFO: cApi + '/user/updateUserInfo',
  ORDER_GET_RECEIPT_RECEIVER_INFO: cApi + '/user/getReceiptReceiverInfo',
  ORDER_SAVE_RECEIPT_RECEIVER_INFO: cApi + '/user/updateReceiptUp',
}
// 公共接口
const common = {
  COMMON_CONFIG: cApi + '/common/config',
  // 天降红包
  POP_DIALOG: cApi + '/common/popDialog',
  IDENTITY: cApi + '/common/identity',
  // 侧边栏
  SIDE_BAR: cApi + '/common/sidebar',
  // 获取token
  GET_TOKEN: cApi + '/common/getToken',
  // 通知确认结果
  COMMON_NOTIFYCONFIRMATION: cApi + '/common/notifyConfirmation',
  // 号码保护
  PROTECT_NUM: cApi + '/virtualtel/preCall',
  // 上传图片
  UPLOAD_BASE64_IMAGE: cApi + '/common/uploadbase64',
}

// 售后
const afterSales = {
  CANCEL_DUTY: cApi + '/order/cancelDutyV2',
  CANCEL_ORDER: cApi + '/order/cancelV2',
  GET_CANCEL_REASON: cApi + '/order/getCancelReason',
  APPLY_CANCEL: cApi + '/cancel/apply',
  CANCEL_REASON: cApi + '/order/cancelReason',
  GET_CANCEL_FLOW: cApi + '/cancel/applyFlow',
  GET_CANCEL_ORDER_REFUND_DETAIL: cApi + '/order/refundDetail',
}

// 申诉（少错餐）
const complaint = {
  GET_MISS_ITEM_INFO: cApi + '/complaint/missItemInfo', // 获取少错餐投诉详情
  GET_APPEAL_PROGRESS: cApi + '/complaint/process', // 获取少错餐投诉进度
  MISS_ITEM_APPEAL: cApi + '/complaint/missItemsAppeal', // 少错餐申诉
  GET_REFUND_REE: cApi + '/complaint/getRefundFee', // 实时获取退款金额
  POST_APPEAL_DATA: cApi + '/complaint/missItem', // 提交申请
}

export {
  feed,
  address,
  bill,
  order,
  cart,
  shop,
  pay,
  act,
  user,
  common,
  coupon,
  afterSales,
  complaint,
  brand,
}

const apiArrs = [
  feed,
  address,
  bill,
  order,
  cart,
  shop,
  pay,
  act,
  user,
  common,
  coupon,
  afterSales,
  complaint,
  brand,
]
apiArrs.map(item => {
  return Object.assign(item, mockConfigApi)
})
