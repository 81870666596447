import { getLocale } from './locale'
import { init, numberToCurrency } from '@didi/elvish-lib-javascript'
// 同步加载config，后续升级config，需要升级SDK
import config from '@didi/elvish-lib-javascript/config'
import { map, currencyMap } from '@/config/countryConfig/list/currency'

/**
 * init 异步初始化
 * numberToCurrency 数字货币化
 */
class Currency {
  constructor(props) {
    this.config = props
  }

  init() {
    const { locale, country, config } = this.config
    return init(locale, country, config)
  }

  numberToCurrency(number) {
    return numberToCurrency(number)
  }

  numberToCurrencyDynamic(number, currency) {
    const { locale, country } = currencyMap[currency] || currencyMap.JPY
    init(locale, country, config)
      .then(() => {})
      .catch(() => {})
    let str = numberToCurrency(number)
    // 由于npm包转换币种MXN的货币单位出错，暂时在这里纠正
    str = str.replace('MXN$', 'MX$')
    return str
  }
}

const { locale, country } = map[getLocale()]
const currency = new Currency({ locale, country, config })
currency.init()
export default currency
