export default {
  '你最多可以下单MX$900.00的货到付款单': '現金・その他決済の注文は最大MX$900.00までです',
  联系我们: 'お問い合わせ',
  '简单操作，快速下单，支付便捷，支持多种方式支付，足不出户享受美食。':
    'アプリから、数タップで注文完了。決済もスマホで完結するので、慌てて現金を用意する必要はありません。注文はカンタンに、手軽に。誰にとっても使いやすいアプリを目指しています。',
  '海量餐厅，多样菜品。你可以在熟悉的餐厅订餐，也能在新餐厅发现新美食，探索更多美味。':
    'さまざまなジャンルの飲食店から「今、食べたい料理」をいつでも、どこでも注文。もちろん、おいしいと評判のお店ばかりです。',
  姓名: '氏名',
  成为合作餐厅: '加盟店になるには',
  公司合作: 'パートナー向け情報',
  '成为滴滴外卖合作餐厅，轻松获得更多订单，更多顾客，让生意赚更多。':
    'DiDi Foodの加盟店になれば、カンタンにオンライン店舗をオープンできます。今までの方法に加えて、新しい収益が見込めます。',
  一键享美食: '“おいしい”をすぐにお手元へ',
  '加入滴滴配送团队，大量订单，多种补贴奖励，自由选择上线时间，电动车、自行车都能配送，高收入高回报。':
    '好きな時間に、自転車やバイクで料理を配達するだけ。勤務時間は自由に決められるので、働き方はあなた次第。サポート体制も万全です。',
  加入滴滴配送: '配達パートナーになるには',
  特定商取引法: '特定商取引法に基づく表示',
  下载滴滴外卖App: 'DiDi Foodアプリをダウンロードして',
  电话: '电话',
  快捷订餐: 'マホでカンタン注文',
  关注我们: '詳細はこちら',
  快速送达: 'スピーディーかつ安全な配達',
  关于滴滴外卖: 'DiDi Foodについて',
  公司信息: '会社概要',
  成为滴滴骑手: '配達パートナーになるには',
  成为滴滴商户: 'DiDi Foodに加盟するには',
  查看详情: '詳細を確認',
  '专业的配送团队和配送设备，确保每一份美食以最快的速度完好送达。':
    'DiDi Foodの配達パートナーは、スピードだけでなく「美味しい料理を届けること」を何より大事にしています。レストランで出された料理を、きれいな状態のまま、あなたの元へお届けします。',
  查看更多: '詳細を見る',
  更多美味: 'バラエティ豊かなフードメニュー',
  最新消息: '新着情報',
  '3. 您下单时通过现金支付，如退款申请通过，退款金额将以优惠券的形式退还到您的钱包。':
    '3. 現金・その他決済の場合は払い戻し金額分のクーポンをアカウントに付与いたします。現金での払い戻いはできません。',
  '您的订单还未完成，您可以联系骑手确认当前的具体配送位置。':
    'ご注文はまだ完了していません。配達員に連絡し現在置を確認してください。',
  您所在的地区还未开通服务: 'お客様の地域では現在ご利用いただけません',
  取消订单进度: 'キャンセル申請状況',
  '4、以下您方原因导致订单超时送达或无法送达的，将不会获得赔付：':
    '4、以下のお客様都合によって発生した配達遅延や配達不能に関しては補填が適用されません。',
  跳过: 'スキップ',
  '个人中心的“优惠券”': 'DiDi Foodアカウントの「クーポン」',
  商家正在备餐: '調理中です',
  再等等: 'もう少し待つ',
  申请信息: '申請情報',
  骑手正在配送中: '配達中です',
  描述: '詳細',
  '5、每位用户每天最多赔付6张优惠券；': '5、本補填は一日につき最大6枚まで適用いたします。',
  无效券: '過去のクーポン',
  不使用优惠券: 'クーポンを使用しない',
  订单: '注文',
  请上传商品和小票照片: '商品の写真をアップロードしてください',
  订单取消成功: '注文はキャンセルされました',
  进度详情: '申請状況の詳細',
  使用规则: '注意事項',
  反馈超时: '申請期限が過ぎました',
  '(1) 您要求更换收获地址、您提交的地址错误、联系不上您或无人收货、拒收等导致订单超时送达或无法送达的；':
    '(1) お届先変更、お届先誤入力、連絡不通、受取人不在、受取拒否などの原因で配達遅延/配達不可となった場合',
  暂无优惠券: 'クーポンはありません',
  联系骑手: '配達員へ連絡',
  关闭: '閉じる',
  提交: '送信',
  订单尚未完成: 'ご注文は完了していません',
  请选择有问题的商品: '問題のある商品を選択',
  提交申请: '注文をキャンセル',
  取消: 'キャンセル',
  请选择取消原因以便商家更快帮您处理:
    'キャンセル理由を選択すると、店舗がより早く状況確認の上、処理することができます',
  钱包: 'お支払い方法',
  退款金额: '払い戻し金額',
  '6、每位用户每天最多赔付6张优惠券；': '6、本補填は一日につき最大6枚まで適用いたします。',
  取消订单: '注文キャンセル',
  输入兑换码: 'クーポンコードの入力',
  展开: '開く',
  '请您上传所收到的全部商品照片、问题商品细节照片、商品小票照片，这将协助我们更快地处理您的问题。':
    '受取った全商品の画像、問題のある商品の画像、レシートの画像をアップロードしてください。迅速に対応させていただきます。',
  更多评价: 'ここに記入してください',
  详细说明: '詳細説明',
  '4、部分时间段下单暂不参加赔付；': '4、一部時間帯においては本補填は適用されません。',
  提交反馈: 'フィードバックを送信',
  帮助中心: 'ヘルプセンター',
  '7、请您对骑手的送达时间进行监督，如遇骑手提前点击送达而实际未能按照约定时间送达的，请您拨打%s客服电话%s进行投诉。':
    '7、配達完了時間をご確認いただき、実際は予定時間内に配達が完了していないにも関わらず、予定時間内に配達完了となっていた場合、%sのカスタマーセンター%sまでご連絡ください。',
  '6、请您对骑手的送达时间进行监督，如遇骑手提前点击送达而实际未能按照约定时间送达的，请您拨打%s客服电话%s进行投诉。':
    '6、配達完了時間をご確認いただき、実際は予定時間内に配達が完了していないにも関わらず、予定時間内に配達完了となっていた場合、%sのカスタマーセンター%sまでご連絡ください。',
  '对处理结果不满意？': '申し立てをしたい場合?',
  '2、超时赔付服务由平台为用户提供，用户没有支付任何费用，若订单取消则服务取消。':
    '2、遅延補填はDiDi Foodが提供している無料のサービスです。お客様の注文がキャンセルされた場合、遅延補填もキャンセルされます。',
  '请告诉我们，帮助我们一起改善':
    'お客様よりいただいたフィードバックは、今後のサービス向上のために役立ててまいります。',
  申请取消原因: 'キャンセル理由',
  查看评价: '評価を見る',
  收起: '閉じる',
  '5、以下您方原因导致订单超时送达或无法送达的，将不会获得赔付：':
    '5、以下のお客様のご都合による配達遅延や配達不能に関しては補填が適用されません。',
  优惠券: 'クーポン',
  退款渠道: '払い戻し方法',
  '(2) 因您的配送地址所在地不允许骑手入内，导致超时送达或无法送达的；':
    '(2) お届先住所に配達員が立ち入り不可のため、配達遅延/配達不可となった場合',
  配送费: '配達料金（税込）',
  联系客服: 'お問い合わせ',
  联系客服进行申诉: 'カスタマーセンターにご連絡ください',
  确认: '確定',
  超时赔付规则说明: '遅延補填規則の説明',
  '已发起取消，等待商家确认': 'キャンセル申請をしました。店舗にて確認中',
  '3、恶劣天气（雨、雪等）暂不参加赔付；':
    '3、悪天候時（雨や雪など）においては本補填は適用されません。',
  兑换优惠: 'クーポン引換',
  '您为什么取消？': 'キャンセル理由はなんですか',
  '(3) 其他因您的原因导致骑手超时送达或无法送达的。':
    '(3) その他お客様の都合によって、配達遅延/配達不可となった場合',
  '（必填）请填写您的申诉原因': '申立て理由を入力してください（必須）',
  '2、部分时间段下单暂不参加赔付；': '2、一部時間帯においては本補填は適用されません。',
  骑手没有配送我的订单: '注文は完了しているが、商品を受け取っていない',
  '3、您因超时赔付服务获得的优惠券，将发放至您的%s账户，请在':
    '3、本補填が発生した場合、お客様の%sアカウントにクーポンを支給いたします。',
  骑手已到达: '配達員が到着しました',
  等待商家接单: 'お店の注文受付待ちです',
  '1、超时赔付服务是%s为您免费提供的服务，延迟送达时间及赔付优惠券金额如下：':
    '1、遅延補填は%sがお客様に提供しているサービスです。詳細は以下の通りです。',
  '您的订单完成已经超过24小时，无法自助申请，如您有问题可联系客服，给您带来不便深表歉意。':
    '注文完了してから24時間を過ぎた場合、払い戻しの申請はできません。ご不明点がございましたらカスタマーセンターまでご連絡ください。',
  商家拒绝取消申请: '店舗がキャンセル申請を承認しませんでした',
  联系商家: '店舗へ連絡',
  '1. 部分商品退款时，该金额为您申请的商品分摊优惠后总金额，配送费不退还。 2. 全部退款时，您的付款金额将全部退还。如您下单时使用过优惠券，优惠券将退还到您的钱包。':
    '1. 注文の一部が払い戻し対象の場合、対象商品に対して割引適用後の実際に支払った金額を払い戻しいたします。配達料金は払い戻し対象外になります。 2. 注文の全てが払い戻し対象の場合、お支払いした金額は全額払い戻しとなります。ご利用したクーポンはアカウントに返還いたします。 ',
  中查看: 'にて確認できます。',
  正在寻找骑手: '配達員を手配しています',
  '网络异常，请稍后再试': 'ネットワークエラー。しばらくしてからもう一度お試しください',
  '优惠券使用门槛及有效期详见券面提示，未在券有效期限内使用完毕的，不可继续使用':
    'クーポンの使用条件および有効期限はクーポンの注意事項をご確認ください。期限切れのクーポンはご利用できません',
  将优惠分享到: 'クーポンコードをシェア',
  登录: 'ログインする',
  '单笔订单金额满足优惠券使用门槛金额时方可使用，单笔订单只能使用一张优惠券，使用优惠后，如订单金额超出部分需用户自行支付':
    '一回の注文金額がクーポンの使用条件金額を満たした場合に限りご使用出来ます。一回の注文でご使用できるクーポンは一枚までとなります。なお注文金額がクーポン額より高い場合は差額をお支払い頂く必要があります',
  规则详情: '規制の詳細',
  '您邀请的好友需通过%s下单，通过其他渠道下单不发放邀请奖励':
    '招待されたお友達は%sアプリまたはDiDiタクシーアプリのフードタブから注文する必要があります',
  待入账: '獲得予定',
  活动时间: 'キャンペーン期間',
  '添加信用卡/借记卡': 'クレジットカード/デビットカードを追加',
  券包: 'クーポンパック',
  兑换成功: '引き換えに成功しました',
  支付方式: '支払方法',
  '支付超时！': '決済がタイムアウトになりました',
  '奖励会在邀请成功后的48小时内发放入您的账号，您可在%s-钱包-券中查看':
    'クーポンは招待したお友達が条件達成後、48時間以内にアカウントにお贈りいたします。 %sアプリ → お友達紹介クーポン → クーポン一覧にて確認することができます',
  复制成功: 'コピーしました',
  免配送费: '配達料金割引',
  支付: '注文を確定する',
  '一经发现任何违规、套现行为，将追回相关金额或者封停账号，且依法追究其法律责任':
    '規約違反や不正行為が発見された場合、割引は適用されず、アカウントの閉鎖や法的責任を追及される場合がございます',
  重试: '再試行',
  '本优惠活动仅限%s[在线支付]的订单享受，不可提现转赠':
    '本クーポンは%sの[オンライン決済］注文にのみ適用されます。現金への交換および他人への譲渡はできません',
  通过SNS邀请: 'SNSで招待',
  分钟: '分',
  请选择支付方式: '支払方法を選択してください',
  我的奖励: 'ボーナス一覧',
  待入账奖励: '獲得予定',
  卡类型: 'カードタイプ',
  '您邀请的好友必须是%s新用户，每人仅限享受1次新用户首单优惠，同一手机号、同一设备、同一支付账号视为同一用户':
    '招待されたお友達は%sの新規ユーザーであることが必要です。携帯電話番号、デバイス、クレジットカード番号などの決済情報のいずれかが重複している場合、同ーユーザーと見なされます',
  收餐人手机号与领取券时输入的手机号和账号注册手机号必须为同一号码:
    '商品受取人の携帯電話番号、クーポン受け取り時に入力した携帯電話番号、アカウントの登録携帯電話番号が同一の番号である必要があります',
  已过期: '失効',
  '本优惠活动仅限[%s]开通%s区域': '本キャンペーン適用対象は%sの%sサービスエリアとなります',
  确定: '確定',
  已获得奖励: '獲得済み',
  条款和条件: '利用規約',
  '关于本活动的任何问题，请联系%s客服':
    '本キャンペーンについては、 %sカスタマーセンターにお問い合わせください。メールアドレス：',
  '邀请好友成为%s新用户，好友领取您送出的红包，并在活动时间截止前完成订单且无退款，无论是否使用领取的红包下单，您均可获得%s[优惠券]奖励':
    'あなたが招待したお友達が下記3点を満たし場合、 あなたは%s［クーポン］を獲得できます。%s新規ユーザー登録 あなたが送ったクーポン受け取る キャンペーン締め切り前に注文完了（クーポン利用有無は関係ありません）',
  '随时随地\\n享受\\n美好食物': '食べたいもの\\nいつだって',
  下单: '注文します',
  个人资料: 'アカウント情報',
  休息中: '受付時間外',
  券: 'クーポン',
  商家券: '店舗クーポン',
  修改取餐人: '受取人を選択',
  已购菜品: '選択済みの商品',
  公司: '勤務先',
  名字: '名',
  呼叫: '発信',
  已售罄: '完売',
  地址: '住所',
  收货地址: '受取住所',
  姓氏: '姓',
  帮助: 'お問い合わせ',
  家: '自宅',
  将使用以下号码加密呼出: '発信電話番号が下記と異なる場合：↓の番号を編集して発信してください',
  小费: 'チップ',
  必选: '必須',
  总计: '合計金額（税込）',
  使用条款及隐私政策: '利用規約とプライバシーポリシー',
  '网络或系统错误，请稍后重试。':
    'ネットワークエラーもしくはシステムエラーです。しばらくしてからもう一度お試しください',
  我的订单: '注文一覧',
  '抱歉，未找到任何结果': '申し訳ありません。検索結果はありません',
  提示: 'ヒント',
  支付失败: '支払い失敗',
  '您对商家满意吗？': 'お店にはご満足いただけましたか？',
  '您对骑手满意吗？': '配達員にはご満足いただけましたか？',
  商家评价: 'お店への評価',
  骑手评价: '配達員への評価',
  未评价: '未評価',
  '退出页面后，当前评价会全部丢失，确认退出吗？':
    'このページを離れると、入力したデータは保存されません。本当にページを離れますか？',
  '未找到匹配地址，请尝试搜索。': '該当する住所がありません。再度検索してください！',
  暂无订单: '注文がありません',
  更多: 'もっと見る',
  更新: 'バージョン更新',
  最近搜过: '検索履歴',
  寻找餐厅和美食: 'お店や料理を検索',
  查看全部: 'すべて見る',
  查看购物车: 'カートを見る',
  '为保护隐私，他人无法看到您的真实电话号码':
    'ダミー電話番号を適用しました。相手にはあなたの実際の電話番号は表示されません。',
  注销: '削除',
  添加: 'カートに入れる',
  输入备注信息: 'お店／配達員へのリクエスト',
  热门搜索: '検索上位',
  '确定要清除缓存吗？': 'アプリ内のキャッシュが削除されます。',
  '确认退出吗？': '本当にログアウトしますか？',
  编辑: '編集',
  网络异常: 'ネットワーク接続エラー',
  电话号码: '携帯電話番号',
  商家自配送: '店舗配達',
  查看订单详情: '注文詳細',
  订单跟踪: '注文ステータス',
  设为常用地点: 'よく使う住所に設定する',
  '公司/公寓名称': 'マンション名・ビル名・表札',
  '楼层/门牌号': '例：101号室／赤い屋根の一軒家です',
  请输入名字: '名を入力してください',
  请输入姓氏: '姓を入力してください',
  请输入电话号码: '電話番号を入力してください',
  选择送餐地址: 'お届先住所を選択してください',
  超出配送范围: '配達エリア外',
  输入地址: '住所を入力する',
  退出登录: 'ログアウト',
  支付方式选择: '支払い方法を選択',
  邀请好友: 'お友達紹介クーポン',
  我的收藏: 'お気に入り',
  配送方式: '受取方法',
  '开始营业%s': '受付開始\\n%s',
  营业中: '注文受付中',
  活动: 'イベント',
  营业时间: '注文受付時間',
  商家联系方式: '店舗電話番号',
  法规: '特定商取引法',
  暂停配送: '配達員が不足中',
  重置: 'クリア',
  查看当前订单: '既存注文を確認します',
  '您有一个相同的订单正在处理中，请避免重复下单。':
    '同じ注文が既に進行中ですので、重複注文しないように注意してください。',
  进行中的订单: '"進行中の注文 "',
  试试减少筛选条件: '違うフィルターで試してください',
  请检查网络连接: '"ネットワーク状態を確認してください "',
  '确定删除地址吗？': '住所情報を削除しますか？',
  不能删除这个地址: '住所情報は削除できません',
  请重试: '再試行してください',
  多买多赠: '買えば買うほどお得',
  礼物: '無料',
  '您收到了订单了吗？': '商品を受け取りましたか？',
  每份: '1点',
  '操作太快啦，请稍后尝试': '操作が早すぎます。しばらくしてからお試しください',
  可定制: 'カスタマイズ可',
  请添加地址: 'お届け先住所を入力してください',
  补充详细地址信息: '住所の詳細情報を追加',
  '添加大楼名称以及门牌号，可以帮助配送员更快找你的送餐位置':
    '建物名や部屋番号などを追加すると、よりスムーズにお届けすることができます。',
  去添加: '追加する',
  忽略: 'スキップ',
  '抱歉，您最多可加购15个菜品': '大変申し訳ありませんが、追加できる商品は15点までです',
  购物车: 'カート',
  去结算: '決済へ進む',
  '给骑手小费%s': '配達員へのチップ%s',
  给配送员的备注: '配達員へのリクエスト',
  '最多选择%d个': '最大%d個まで選択',
  '选择%d个': '%d点選択する',
  '已获得%d个赠品': '無料で%d個追加されます',
}
