// 品牌文案
const brandTextObj = {
  brand_txt_1: {
    'zh-CN': '滴滴',
    'en-US': 'DiDi',
    'es-MX': 'DiDi',
    'pt-BR': 'DiDi',
    'ja-JP': 'DiDi',
    'es-419': 'DiDi',
  },
  brand_txt_2: {
    'zh-CN': 'DiDi Food',
    'en-US': 'DiDi Food',
    'es-MX': 'DiDi Food',
    'pt-BR': 'DiDi Food',
    'ja-JP': 'DiDi Food',
    'es-419': 'DiDi Food',
  },
  brand_txt_3: {
    'zh-CN': '滴滴外卖',
    'en-US': 'DiDi Food',
    'es-MX': 'DiDi Food',
    'pt-BR': 'DiDi Food',
    'ja-JP': 'DiDi Food',
    'es-419': 'DiDi Food',
  },
  brand_txt_4: {
    'zh-CN': '滴滴App',
    'en-US': 'DiDi App',
    'es-MX': 'DiDi App',
    'pt-BR': 'DiDi App',
    'ja-JP': 'DiDi App',
    'es-419': 'DiDi App',
  },
}
export const brandText = {
  JP: brandTextObj,
  MX: brandTextObj,
  CR: brandTextObj,
  BR: {
    brand_txt_1: {
      'zh-CN': '99',
      'en-US': '99',
      'es-MX': '99',
      'pt-BR': '99',
      'ja-JP': '99',
      'es-419': '99',
    },
    brand_txt_2: {
      'zh-CN': '99 外卖',
      'en-US': '99 Food',
      'es-MX': '99 Food',
      'pt-BR': '99 Food',
      'ja-JP': '99 Food',
      'es-419': '99 Food',
    },
    brand_txt_3: {
      'zh-CN': '99 外卖',
      'en-US': '99 Food',
      'es-MX': '99 Food',
      'pt-BR': '99 Food',
      'ja-JP': '99 Food',
      'es-419': '99 Food',
    },
    brand_txt_4: {
      'zh-CN': '99 App',
      'en-US': '99 App',
      'es-MX': '99 App',
      'pt-BR': '99 App',
      'ja-JP': '99 App',
      'es-419': '99 App',
    },
  },
}

export default brandText
