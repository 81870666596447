/*
 * @Author: amywulan
 * @Date: 2021-07-Mo 11:41:54
 * @Last Modified by:  amywulan
 * @Last Modified time: 2021-07-Mo 11:41:54
 * @description: 路由入口文件
 */
import Vue from 'vue'
import Router from 'vue-router'
import maps from './map'
import { urlParamsSymbolChange, setTitle } from '@/utils/routerUrlTransform'
import { routerRedirectCatch } from '@/utils/routerRedirectCatch'
import { getRouterBasePath } from '@/utils/urlPathUtil.js'

const routerBasePath = getRouterBasePath()
console.log('🚀 ~ routerBasePath:', routerBasePath)

Vue.use(Router)
// 解决 router A重定向到B，B直接重定向到C时报错的问题或者降级vueRouter版本到3.0.7
routerRedirectCatch(Router)
const router = new Router({
  routes: maps,
  mode: 'history',
  base: routerBasePath,
  scrollBehavior(to, from, savedPosition) {
    if (to?.name === from?.name && to?.meta?.unScrollTop) {
      return false
    } else {
      return { x: 0, y: 0 }
    }
  },
})

router.beforeEach((to, from, next) => {
  // 去除空格 加号 反斜杠 等符号 转换为_  目前只有store页需要
  urlParamsSymbolChange(to, from, next)
})
router.beforeEach((to, from, next) => {
  // 修改 document.title
  setTitle(to, from, next)
})

export default router
