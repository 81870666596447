<template>
  <div>
    <template v-if="isH5">
      <el-drawer
        :visible="selectAddressVisible"
        direction="btt"
        :show-close="false"
        size="95%"
        @closed="closeDialog"
        custom-class="qr-code_popup-h5"
      >
        <div slot="title" class="h5-dialog_content">
          <div class="h5-close" @click="close">
            <i class="el-icon-close"></i>
          </div>
          <div class="h5-title">
            <img class="icon-earth" src="../../assets/svgs/logo-dark.svg" />
          </div>
        </div>
        <template>
          <Download />
        </template>
      </el-drawer>
    </template>
    <template v-else>
      <el-floating
        width="470px"
        :visible="selectAddressVisible"
        :close-on-click-modal="false"
        @closed="closeDialog"
        @close="close"
        custom-class="qr-code_popup el-floating_d-reset"
        icon-class="icon-outline_close"
      >
        <div slot="title">
          <img class="icon-earth" src="../../assets/svgs/logo-dark.svg" />
        </div>
        <Download />
      </el-floating>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Download from './download.vue';
export default {
  name: 'DownloadPopup',
  computed: {
    ...mapState({
      isH5: ({ common }) => common.isH5,
    }),
  },
  data() {
    return {
      selectAddressVisible: true,
    };
  },
  components: {
    Download,
  },
  created() { // 创建vm实例后执行
    // 浏览器控制按钮前进后退触发函数
    window.addEventListener('popstate', this.popstate, false);
  },
  methods: {
    popstate() {
      this.close()
    },
    closeDialog() {
      this.newCloseDialog();
      window.removeEventListener('popstate', this.popstate, false);
    },
    close() {
      this.selectAddressVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-earth {
  width: 118px;
  height: 24px;
}
</style>

<style lang="scss">
// floating重置

.qr-code_popup {
  background: #f5f5f7;
  height: 656px;
  border-radius: 8px !important;
  margin-top: 0 !important;
  transform: translateY(-50%);
  top: 50%;

  .el-floating__header {
    height: 72px !important;
    margin-bottom: 20px;
    >div {
      height: 100%;
      display: flex;
      align-items: center;
    }
    .el-floating__headerbtn {
      left: 27px;
      top: 27px;
      z-index: 10;
      .el-floating__close {
        font-size: 24px;
      }
    }
    .el-floating__title {
      align-self: flex-start;
      position: relative;
      top: 32px;
      font-family: 'Aspira Medium';
      font-size: 24px;
      color: #000000;
      font-weight: 500;
    }
  }
  .el-floating__body_height {
    height: 520px;
  }
}

@media screen and (max-width: 768px) {
  .qr-code_popup-h5 {
    border-radius: rem(24) rem(24) 0 0px;
    .el-drawer__body {
      overflow: auto;
    }
    .el-drawer__header {
      padding: 0;
      margin-bottom: 0;
    }
    .h5-dialog_content {
      height: rem(112);
      position: relative;
      margin-bottom: rem(55);
    }
    .h5-close {
      font-size: rem(48);
      position: absolute;
      left: rem(40);
      top: rem(32);
    }
    .h5-title {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
      width: 100%;
    }
    .icon-earth {
      width: rem(184);
      height: rem(36);
    }
  }
}
</style>
