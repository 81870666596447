import { post } from '@/lib/request/index'
import { address } from '../apiConstants'

// 查询地址
export function addressTextsearch(param) {
  return post(address.ADDRESS_TEXTSEARCH, { param })
}

// 创建地址接口
export function addressCreate(param) {
  return post(address.ADDRESS_CREATE, { param })
}

// 更新地址接口
export function addressUpdate(param) {
  return post(address.ADDRESS_UPDATE, { param })
}

// 删除地址接口
export function addressDelete(param) {
  return post(address.ADDRESS_DELETE, { param })
}

// 获取地址列表信息
export function addressList(param) {
  return post(address.ADDRESS_LIST, { param })
}

// 根据定位获取地址信息
export function getAddressByLocation(param) {
  return post(address.GET_ADDRESS_BY_LOCATION, { param })
}

// 获取用户上一次下单的地址
export function getUserLastOrderAddress(param) {
  return post(address.GET_USER_LAST_ORDER_ADDRESS, { param })
}
