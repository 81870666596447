/**
 * 背景：仿真环境与正式环境的沟通流程一致
 * 核心点：仿真环境与正式环境的唯一区别在于，仿真环境中，接口使用仿真域名
 * 解法：根据前端域名，判断是否为仿真环境，决定后端接口是否使用仿真接口
 *
 * 前端服务仿真域名示例：http://sim060.www.rlab.sim.intra.xiaojukeji.com/ja-JP/food/address/
 * 后端接口仿真域名示例c-api：https://sim060.rlab.sim.intra.xiaojukeji.com/c-api
 * 后端接口仿真域名示例act-api：https://sim060.rlab.sim.intra.xiaojukeji.com/act-api
 */
class Sim {
  constructor() {
    this.defaultNum = '310'
    if (GLOBAL_SIM_NUM) {
      this.defaultNum = GLOBAL_SIM_NUM
    }
  }

  isSim() {
    return location.host.startsWith('sim')
  }

  isCustomerDomain1() {
    return location.host.includes('test01')
  }

  isCustomerDomain2() {
    return location.host.includes('test02')
  }

  getLocationOrigin() {
    return location.host
  }

  getBaseUrl() {
    if (process.env.BUILD_ENV === 'development') {
      return `sim${this.defaultNum}.rlab.sim.intra.xiaojukeji.com`
    }
    if (this.isCustomerDomain1()) {
      return 'sim233.rlab.sim.intra.xiaojukeji.com'
    }
    if (this.isCustomerDomain2()) {
      return 'sim258.rlab.sim.intra.xiaojukeji.com'
    }
    return this.getLocationOrigin().replace('.www', '')
  }

  getSimCApi() {
    return `//${this.getBaseUrl()}/c-api`
  }

  getSimActApi() {
    return `//${this.getBaseUrl()}/act-api`
  }

  getSimCashierApi() {
    return '//dsim310-api.intra.xiaojukeji.com'
    // return '//dsim454-api.intra.xiaojukeji.com'
  }

  getSimPayApi() {
    return '//dsim310-api.intra.xiaojukeji.com'
    // return '//dsim454-api.intra.xiaojukeji.com'
  }

  getSimFulfillmentApi() {
    return `//${this.getBaseUrl()}/fulfillment-api`
  }
}

export default new Sim()
