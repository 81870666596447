import { isStorage } from './types'

const ls = window?.localStorage
const ss = window?.sessionStorage
const ds = {
  setItem() {},
  getItem() {},
  removeItem() {},
  clear() {},
}

function getLS() {
  if (isStorage(ls)) return ls

  if (isStorage(ss)) return ss

  return ds
}

const store = getLS()

// 存数据
export const setLocalStorage = (key, value) => {
  if (!key) return
  value = JSON.stringify(value)
  store.setItem(key, value)
}

// 取数据
export const getLocalStorage = key => {
  if (!key) return
  if (store.getItem(key) === '') return ''
  try {
    return JSON.parse(store.getItem(key))
  } catch {
    return store.getItem(key)
  }
}

// 删数据
export const delLocalStorage = key => {
  if (!key) return
  store.removeItem(key)
}

// 全部清空
export const clearLocalStorage = () => {
  store.clear()
}
