export default [
  // 首页
  {
    path: '/store/:shopName?/:storeId?',
    name: 'store',
    meta: {
      title: '商家页',
      showFooter: true,
      signOutNoBackFeed: true,
      loginWithoutVerification: true,
      notCheckPl: true,
      unScrollTop: true, // 禁止规格页打开时页面滚动到顶部
    },
    component: () =>
      import(
        /* webpackChunkName: "store" */
        '@/views/Store/index'
      ),
  },
]
