<template>
  <div class="footer">
    <div class="bottom content-auto">
      <div class="select">
        <!-- 展示语言 -->
        <div class="country-wrapper">
          <img class="icon icon-img" :src="require('@/assets/svgs/select-country-icon-dark.svg')" />
          <el-select
            v-model="currentLanguage"
            placeholder=""
            popper-class="country-wrapper-sel-class"
            @change="handleSwitchLang"
          >
            <el-option
              v-for="item in languageOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="copy-right">
        <div class="copy-right-left">
          <p class="product-name">©{{ year }} DiDi</p>
          <p class="product-name">
            {{
              $t('FoodC_20240607_STdK_ZTMu', { brand_name: 'DiDi ' } /* 本平台技术服务由DiDi提供 */)
            }}
          </p>
        </div>
        <div class="copy-right-center">
          <p>
            <a target="_blank" class="link" :href="getLawUrl">
              {{ $t('使用条款及隐私政策') }}
            </a>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getLocale } from '@/utils/locale'
import { getLanguageOptionsForCountry } from '@/config/countryConfig/list/footer'
import getCountryConfig from '@/config/countryConfig/index.js'
import { updateLocaleUrl } from '@/utils/urlPathUtil'

export default {
  name: 'MyFooter',
  data() {
    return {
      year: new Date().getFullYear(),
      currentLanguage: getLocale(),
      locale: getLocale(),
    }
  },
  computed: {
    ...mapState({
      country: ({ common }) => common.country,
    }),
    languageOptions() {
      return getLanguageOptionsForCountry(this.country)
    },
    getLawUrl() {
      return getCountryConfig.login.lawUrFooter(this.country)
    },
  },
  methods: {
    handleSwitchLang(value) {
      const url = updateLocaleUrl(value, this.$route.fullPath)
      console.log('🚀 ~ handleSwitchLang ~ value:', `${url}`)
      window.location.href = url
    },
  },
}
</script>

<style lang="scss" scoped src="./index.scss"></style>
<style lang="scss">
.footer {
  .el-input {
    margin-left: 25px;
    width: 155px;
    height: 28px;
    line-height: 28px;
  }
  .el-input__suffix {
    height: 28px;
    right: 10px;
    .el-input__suffix-inner {
      height: 28px;
      i {
        display: inline-block;
        color: #000;
      }
    }
    .el-input__icon {
      line-height: 28px;
    }
  }
  .el-input__inner {
    width: 150px;
    height: 28px;
    font-size: 12px;
    line-height: 28px;
    border: 0;
    background: none;
    border-radius: 0;
    color: #000;
  }
}
.country-wrapper-sel-class {
  margin-bottom: 8px !important;
  border: none !important;
  .popper__arrow {
    display: none !important;
  }
  .el-select-dropdown__item {
    color: #89898c;
    font-family: DiDiSans-Pro-Regular;
  }
  .el-select-dropdown__item.selected {
    color: #ff5f14 !important;
  }
  .el-select-dropdown__item.hover,
  .el-select-dropdown__item:hover {
    background-color: #f5f5f7;
  }
}
@media screen and (max-width: 768px) {
  .footer {
    .el-input {
      margin-left: rem(50);
      width: rem(250);
      height: rem(48);
      line-height: rem(48);
    }
    .el-input__suffix {
      height: rem(48);
      right: rem(20);
      .el-input__suffix-inner {
        height: rem(48);
        i {
          color: #000;
        }
      }
      .el-input__icon {
        line-height: rem(48);
      }
    }
    .el-input__inner {
      width: 150px;
      height: rem(48);
      font-size: rem(24);
      line-height: rem(48);
      border: 0;
      background: none;
      border-radius: 0;
      color: #000;
    }
  }
}
</style>
