import Vue from 'vue'
import i18n from '@/i18n/locale';
import CodePopup from './codePopup.vue'

let ConfirmConstructor = Vue.extend(CodePopup)
let theConfirm = function(options){
  return new Promise((res,rej)=>{       
    let confirmDom=new ConfirmConstructor({
      el:document.createElement('div'),
      store:this.$store,
      propsData: options,
      i18n
    })
    //可以通过$el属性来访问创建的组件实例
    document.body.appendChild(confirmDom.$el)
    confirmDom.showCode=true;
    //此时进行创建组件的逻辑处理

    confirmDom.confirmClick2 = (resData) => {
      confirmDom.$el.remove();
      confirmDom.showCode=false;
      res(resData)   //正确时返回的操作
    }
    confirmDom.closeDrawer2=()=>{
      confirmDom.$el.remove();
      confirmDom.showCode=false;
      rej()   //失败时返回的操作
    }    
  })
}

//将逻辑函数进行导出和暴露
export default theConfirm
