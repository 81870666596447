/**
 * TODO
 * author: lizhengnacl
 * 后续新开国家，需要在这里补充映射关系
 */
const map = {
  'zh-CN': {
    locale: 'zh_CN',
    country: 'CN',
  },
  'en-US': {
    locale: 'en_US',
    country: 'US',
  },
  'pt-BR': {
    locale: 'pt_BR',
    country: 'BR',
  },
  'ja-JP': {
    locale: 'ja_JP',
    country: 'JP',
  },
  'es-MX': {
    locale: 'es_MX',
    country: 'MX',
  },
  'es-CR': {
    locale: 'es_CR',
    country: 'CR',
  },
  'es-419': {
    locale: 'es_MX',
    country: 'MX',
  },
  'es-CO': {
    locale: 'es_CO',
    country: 'CO',
  },
  'es-PE': {
    locale: 'es_PE',
    country: 'PE',
  },
  'es-CL': {
    locale: 'es_CL',
    country: 'CL',
  },
  'es-DO': {
    locale: 'es_DO',
    country: 'DO',
  },
}

/**
 * TODO
 * author: lizhengnacl
 * 后续新开国家，需要在这里补充映射关系 wiki:http://sailing.intra.didiglobal.com/#/ServiceDetail/1_001?data=%5B%22CO%22%5D
 * shop/index 接口可以获取 USD、JPY 等信息
 */
const currencyMap = {
  USD: {
    locale: 'en_US',
    country: 'US',
  },
  JPY: {
    locale: 'ja_JP',
    country: 'JP',
  },
  MXN: {
    locale: 'es_MX',
    country: 'MX',
  },
  COP: {
    locale: 'es_CO',
    country: 'CO',
  },
  CRC: {
    locale: 'es_CR',
    country: 'CR',
  },
  DOP: {
    locale: 'es_DO',
    country: 'DO',
  },
  PEN: {
    locale: 'es_PE',
    country: 'PE',
  },
  CLP: {
    locale: 'es_CL',
    country: 'CL',
  },
}
export { map, currencyMap }
