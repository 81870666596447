import { post } from '@/lib/request/index'
import { complaint } from '../apiConstants'

// 获取少餐投诉详情
export function getMissItemInfo(param) {
  return post(complaint.GET_MISS_ITEM_INFO, {
    param,
  })
}

// 获取售后申诉进度
export function getAppealProgress(param) {
  return post(complaint.GET_APPEAL_PROGRESS, {
    param,
  })
}
//  少错餐申诉
export function appealToService(param) {
  return post(complaint.MISS_ITEM_APPEAL, {
    param,
  })
}

// 实时获取退款金额
export function getRefundFee(param) {
  return post(complaint.GET_REFUND_REE, {
    param,
  })
}

// 提交少错餐申诉数据
export function postFeedbackData(param) {
  return post(complaint.POST_APPEAL_DATA, {
    param,
  })
}
