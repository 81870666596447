<template>
  <div class="msg-wrapper" v-if="content">
    <p class="msg-desc">
      <i class="icon-filled_alcohol" /> <span>{{ content }}</span>
    </p>
  </div>
</template>

<script>
export default {
  name: 'WeakTips',
  props: {
    content: String,
  },
  data() {
    return {};
  },
  mounted() {},
  methods: {},
};
</script>

<style lang="scss" scoped >
.msg-desc {
  padding: 12px 14px;
  line-height: 16px;
  font-size: 12px;
  font-family: DiDiSans-Pro-Regular;
  font-weight: 200;
  color: #ff4060;
  background: rgba($color: #ff4060, $alpha: 0.08);
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  i {
    vertical-align: middle;
    margin-right: 4px;
    font-size: 14px;
  }
  span {
    flex: 1;
  }
}
@media screen and (max-width: 768px) {
  .msg-desc {
    padding: rem(24) rem(32);
    line-height: rem(32);
    font-size: rem(24);
    border-radius: rem(20);
  }
}
</style>