const ss = 'sessionStorage' in window && window?.sessionStorage ? window?.sessionStorage : '';

// 存数据
export const setSessionStorage = (key, value) => {
  if (!key) return;
  value = JSON.stringify(value);
  ss.setItem(key, value);
};

// 取数据
export const getSessionStorage = key => {
  if (!key) return;
  if (ss.getItem(key) == '') return '';
  try {
    return JSON.parse(ss.getItem(key));
  } catch {
    return ss.getItem(key);
  }
};

// 删数据
export const delSessionStorage = key => {
  if (!key) return;
  ss.removeItem(key);
};

// 全部清空
export const clearSessionStorage = () => {
  ss.clear();
};
