/**
 * @Author: amywulan
 * @Date: 2021-08-02 12:00:00
 */
import api from '@/api'
const state = {
  // 购物车券列表
  couponList: [],
  couponLoading: false,
  sidebarCouponLoading: false,
  availableCouponList: [],
  unavailableCouponList: [],
}
const mutations = {
  setCouponList(state, data) {
    state.couponList = data
  },
  setCouponLoading(state, data) {
    state.couponLoading = data
  },
  setSidebarCouponLoading(state, data) {
    state.sidebarCouponLoading = data
  },
  setAvailableCouponList(state, data) {
    state.availableCouponList = data
  },
  setUnavailableCouponList(state, data) {
    state.unavailableCouponList = data
  },
}
const actions = {
  /**
   * @description: 获取购物车券列表
   * @param {type}
   * @return
   */
  async fetchCouponList({ commit, state, dispatch }, param) {
    let res = {}
    try {
      commit('setCouponLoading', true)
      res = await api.fetchCouponList(param)
      const couponList = res?.couponList.map(couponItem => {
        return {
          ...couponItem,
          couponAmount: couponItem.originalPrice,
          couponAmountSplit: couponItem.originalPriceSplit, // C-API用券接口，使用originalPrice作为券展示价格
        }
      })
      commit('setCouponList', couponList)
      dispatch('filterCouponList')
    } catch (e) {
      return false
    }
    commit('setCouponLoading', false)
    return res
  },
  /**
   * @description: 过滤券列表
   * @param {type}
   * @return
   */
  async filterCouponList({ commit, state }, param) {
    const availableCouponList = []
    const unavailableCouponList = []
    let isFirst = true
    let couponItemTip = ''
    state.couponList.forEach((couponItem, index) => {
      if (couponItem.couponItemId) {
        if (isFirst) {
          this.couponSelectIndex = index
          isFirst = false
        }
        couponItemTip = { couponItemIndex: index }
      }
      if (couponItem.availableStatus === 2) {
        unavailableCouponList.push({
          ...couponItem,
          ...couponItemTip, // 给券包代金券增加唯一标识
          couponAmount: couponItem.originalPrice,
          couponAmountSplit: couponItem.originalPriceSplit, // C-API用券接口，使用originalPrice作为券展示价格
        })
      } else {
        availableCouponList.push({
          ...couponItem,
          ...couponItemTip, // 给券包代金券增加唯一标识
          couponAmount: couponItem.originalPrice,
          couponAmountSplit: couponItem.originalPriceSplit, // C-API用券接口，使用originalPrice作为券展示价格
        })
      }
    })
    // 更新可用券列表
    commit('setAvailableCouponList', availableCouponList)
    // 更新不可用券列表
    commit('setUnavailableCouponList', unavailableCouponList)
  },
  /**
   * @description: 兑换码
   * @param {type}
   * @return
   */
  async cartRedeem({ commit, state }, param) {
    let res = {}
    res = await api.cartRedeem(param)
    return res
  },

  /**
   * @description: 侧边栏券
   * @param {type}
   * @return
   */
  async getWalletCoupons({ commit, state }, param) {
    let res = {}
    try {
      commit('setSidebarCouponLoading', true)
      res = await api.getWalletCoupons(param)
    } catch (e) {
      return false
    }
    commit('setSidebarCouponLoading', false)
    return res
  },

  /**
   * @description: 侧边栏兑换
   * @param {type}
   * @return
   */
  async sidebarExchange({ commit, state }, param) {
    let res = {}
    res = await api.sidebarExchange(param)
    return res
  },
  /**
   * @description: 获取券包列表
   * @param {type}
   * @return
   */
  async getProductList({ commit, state }, param) {
    let res = {}
    try {
      res = await api.productList(param)
    } catch (e) {
      return false
    }
    return res
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
