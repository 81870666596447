import { get } from 'lodash-es'
/**
 * @desc sleep延时方法
 * @param {Number} timestamp 延时时长
 * @return {Promise<void>} await sleep(1000)
 */
export const sleep = timestamp =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve()
    }, timestamp)
  })

/** @desc 获取url参数 */
export const getQueryString = (name, url) => {
  const reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  let r
  try {
    if (url) {
      r = new URL(url).search.substr(1).match(reg)
    } else {
      r = window.location.search.substr(1).match(reg)
    }
    if (r != null) return unescape(r[2])
    return null
  } catch (error) {
    return null
  }
}

export function getIsNewTab() {
  const len = get(window, 'history.length', false)
  return !(len > 1)
}

export const isH5 = () => {
  return document?.body?.clientWidth <= 768
}

/** @desc js秒转时分秒 */
export function secondToDate(result) {
  const h =
    Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600)
  const m =
    Math.floor((result / 60) % 60) < 10
      ? '0' + Math.floor((result / 60) % 60)
      : Math.floor((result / 60) % 60)
  const s = Math.floor(result % 60) < 10 ? '0' + Math.floor(result % 60) : Math.floor(result % 60)
  return (result = h + ':' + m + ':' + s)
}

/** @desc webp格式判断 */
export function supportWebp() {
  let support = true
  const d = document

  try {
    const el = d.createElement('object')
    el.type = 'image/webp'
    el.style.visibility = 'hidden'
    el.innerHTML = '!'
    d.body.appendChild(el)
    support = !el.offsetWidth
    d.body.removeChild(el)
  } catch (err) {
    support = false
  }

  return support
}

// str 获取host
export function GET_URL_HOST(string) {
  try {
    if (!string) return ''

    const url_info = new URL(string)
    const host = url_info?.host
    return host
  } catch (error) {
    return ''
  }
}

/**
 * 客户端增加了rf前缀 后续沟通如何统一 暂时先替换前缀
 */
export const iconByRf = text => {
  if (!text) {
    return ''
  }
  return text.replace(/^rf_icon(_|-)/, 'icon-')
}

export const isJSON = str => {
  if (typeof str === 'string') {
    try {
      const obj = JSON.parse(str)
      if (typeof obj === 'object' && obj) {
        return true
      } else {
        return false
      }
    } catch (e) {
      return false
    }
  }
  return false
}
