import FingerprintJS from '@fingerprintjs/fingerprintjs'

class Fingerprint {
  constructor() {
    this.fingerprint = null
    this.interval = 100
    this._init().catch()
  }

  async _init() {
    const fpPromise = FingerprintJS.load()
    const fp = await fpPromise
    const result = await fp.get()
    this.fingerprint = result.visitorId
    return this.fingerprint
  }

  async init() {
    const s = Date.now()
    return new Promise(resolve => {
      if (this.fingerprint !== null) {
        resolve(this.fingerprint)
      } else {
        const t = setInterval(() => {
          if (this.fingerprint !== null) {
            resolve(this.fingerprint)
            console.log(
              '=========== async init fingerprint cost ===========',
              `${Date.now() - s} ms`,
            )
            clearInterval(t)
          }
        }, this.interval)
      }
    })
  }

  isEmpty() {
    return this.fingerprint === null
  }

  get() {
    return this.fingerprint
  }

  clear() {
    this.fingerprint = null
  }
}

const fp = new Fingerprint()

export default fp
