class TreeAlgorithm {
  getParentNode(tree, node) {
    let res = null
    this.preOrder(tree, parent => {
      const isParent = this.isParentNode(parent, node)
      if (isParent) {
        res = parent
      }
    })
    return res
  }

  preOrder(tree, cb) {
    const parent = tree
    const hasChildren = parent && parent.contentList && parent.contentList.length > 0
    if (hasChildren) {
      cb(parent)
      parent.contentList.forEach(group => {
        group.subItemList.forEach(_ => {
          this.preOrder(_, cb)
        })
      })
    }
  }

  // 后续遍历，找到叶子节点
  postOrderLeaf(tree, cb) {
    const parent = tree
    const hasChildren = parent && parent.contentList && parent.contentList.length > 0
    if (hasChildren) {
      parent.contentList.forEach(group => {
        group.subItemList.forEach(_ => {
          this.postOrderLeaf(_, cb)
        })
      })
    } else {
      cb(parent)
    }
  }

  isParentNode(parent, child) {
    let isParent = false
    parent.contentList.forEach(group => {
      group.subItemList.forEach(_ => {
        if (_.itemId === child.itemId) {
          isParent = true
        }
      })
    })
    return isParent
  }

  isLeaf(node) {
    return !(node.contentList && node.contentList.length > 0)
  }
}

const ta = new TreeAlgorithm()
export default ta
