/**
 * 商家页埋点
 */
import { trackEvent } from '@/lib/omega'

/** @desc 商家页实曝光 */
export const sailing_c_x_sf_shop_common_sw = res => {
  trackEvent({
    eventId: 'sailing_c_x_sf_shop_common_sw',
    eventLabel: '商家页实曝光',
    attrs: {
      ...res,
    },
  })
}

/** @desc 商家页内单规格菜品加购 */
export const sailing_c_x_sf_shop_item2cart_ck = ({ shop_id, item_id }) => {
  trackEvent({
    eventId: 'sailing_c_x_sf_shop_item2cart_ck',
    eventLabel: '商家页内单规格菜品加购',
    attrs: {
      shop_id,
      item_id,
    },
  })
}

/** @desc 商家页内多规格菜品加购 */
export const sailing_c_x_sf_shop_specselection_item2cart_ck = ({ shop_id, item_id }) => {
  trackEvent({
    eventId: 'sailing_c_x_sf_shop_specselection_item2cart_ck',
    eventLabel: '商家页内多规格菜品加购',
    attrs: {
      shop_id,
      item_id,
    },
  })
}

/** @desc SF商户页菜品点击	*/
export const sailing_c_x_sf_item_ck = (res = {}) => {
  trackEvent({
    eventId: 'sailing_c_x_sf_item_ck',
    eventLabel: 'SF商户页菜品点击',
    attrs: {
      ...res,
    },
  })
}

/** @desc SF商户页目录点击	*/
export const sailing_c_x_sf_tab_ck = (res = {}) => {
  trackEvent({
    eventId: 'sailing_c_x_sf_tab_ck',
    eventLabel: 'SF商户页目录点击',
    attrs: {
      ...res,
    },
  })
}

/** @desc SF规格页选择规格点击 */
export const sailing_c_x_sf_specselection_choose_ck = (res = {}) => {
  try {
    trackEvent({
      eventId: 'sailing_c_x_sf_specselection_choose_ck',
      eventLabel: 'SF规格页选择规格点击',
      attrs: {
        ...res,
      },
    })
  } catch (err) {}
}

/** @desc SF商户页菜品个数加减按钮-点击 */
export const sailing_c_x_sf_item_add_or_drop_ck = (res = {}) => {
  try {
    trackEvent({
      eventId: 'sailing_c_x_sf_item_add_or_drop_ck',
      eventLabel: 'SF商户页菜品个数加减按钮点击',
      attrs: {
        ...res,
      },
    })
  } catch (err) {}
}

/** @desc SF登录引导半浮层曝光 */
export const sailing_c_x_sf_loginguide_popup_sw = (res = {}) => {
  try {
    trackEvent({
      eventId: 'sailing_c_x_sf_loginguide_popup_sw',
      eventLabel: 'SF登录引导半浮层曝光',
      attrs: {
        ...res,
      },
    })
  } catch (err) {}
}

/** @desc SF登录引导半浮层点击 */
export const sailing_c_x_sf_loginpopup_ck = (res = {}) => {
  try {
    trackEvent({
      eventId: 'sailing_c_x_sf_loginpopup_ck',
      eventLabel: 'SF登录引导半浮层点击',
      attrs: {
        ...res,
      },
    })
  } catch (err) {}
}

/** @desc SF店铺页联系方式点击 */
export const sailing_c_x_sf_shop_contact_ck = (res = {}) => {
  try {
    trackEvent({
      eventId: 'sailing_c_x_sf_shop_contact_ck',
      eventLabel: 'SF店铺页联系方式点击',
      attrs: {
        ...res,
      },
    })
  } catch (err) {}
}

/** @desc SF店铺页联系方式半浮层点击 */
export const sailing_c_x_sf_shop_contact_popup_ck = (res = {}) => {
  try {
    trackEvent({
      eventId: 'sailing_c_x_sf_shop_contact_popup_ck',
      eventLabel: 'SF店铺页联系方式半浮层点击',
      attrs: {
        ...res,
      },
    })
  } catch (err) {}
}

/** @desc SF店铺页右上角登录入口点击 */
export const sailing_c_x_sf_shop_login_ck = (res = {}) => {
  try {
    trackEvent({
      eventId: 'sailing_c_x_sf_shop_login_ck',
      eventLabel: 'SF店铺页右上角登录入口点击',
      attrs: {
        ...res,
      },
    })
  } catch (err) {}
}

/** @desc SF店铺页更多店铺信息入口点击 */
export const sailing_c_x_sf_shop_info_ck = (res = {}) => {
  try {
    trackEvent({
      eventId: 'sailing_c_x_sf_shop_info_ck',
      eventLabel: 'SF店铺页更多店铺信息入口点击',
      attrs: {
        ...res,
      },
    })
  } catch (err) {}
}

/** @desc SF商户页订单状态卡片曝光 */
export const sailing_c_x_sf_shop_ordercard_sw = (res = {}) => {
  try {
    trackEvent({
      eventId: 'sailing_c_x_sf_shop_ordercard_sw',
      eventLabel: 'SF商户页订单状态卡片曝光',
      attrs: {
        ...res,
      },
    })
  } catch (err) {}
}

/** @desc SF商户页订单状态卡片(单个订单)-点击 */
export const sailing_c_x_sf_shop_ordercard_single_ck = (res = {}) => {
  try {
    trackEvent({
      eventId: 'sailing_c_x_sf_shop_ordercard_single_ck',
      eventLabel: 'SF商户页订单状态卡片(单个订单)-点击',
      attrs: {
        ...res,
      },
    })
  } catch (err) {}
}

/** @desc SF商户页订单状态卡片(多个订单)-点击 */
export const sailing_c_x_sf_shop_ordercard_multi_ck = (res = {}) => {
  try {
    trackEvent({
      eventId: 'sailing_c_x_sf_shop_ordercard_multi_ck',
      eventLabel: 'SF商户页订单状态卡片(多个订单)-点击',
      attrs: {
        ...res,
      },
    })
  } catch (err) {}
}

/** @desc SF店铺页-切换店铺按钮点击 */
export const sailing_c_x_sf_shop_switch_ck = (res = {}) => {
  try {
    trackEvent({
      eventId: 'sailing_c_x_sf_shop_switch_ck',
      eventLabel: 'SF店铺页-切换店铺按钮点击',
      attrs: {
        ...res,
      },
    })
  } catch (err) {}
}
