<template>
  <div class="card-popup">
    <DrawerPopup
      size="90%"
      :show="showCard"
      :append-to-body="false"
      :title="$t('支付方式选择')"
      :bottom-name="$t('确认')"
      @close="closeDrawer"
      @bottom-click="confirmClick"
    >
      <div class="cash-content">
        <el-radio
          v-model="selectedMent"
          :label="true"
          :tip="$t('你最多可以下单MX$900.00的货到付款单')"
          position="right"
          icon="https://pbpay.didistatic.com/static/pay/ic_Cash_3x.png"
          >{{ $t('soda-c-i18n---现金') }}</el-radio
        >
      </div>
      <p class="tip-app">{{ $t('FoodC_payment_eCqh_ogxh') }}</p>
    </DrawerPopup>
  </div>
</template>

<script>
import DrawerPopup from '@/components/DrawerPopup'
export default {
  name: 'CardPopup',
  components: {
    DrawerPopup,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    paymentParams: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      showCard: false,
      cardObj: {},
      selectedMent: true,
      cardLoading: false,
    }
  },
  computed: {},
  watch: {
    value(val) {
      this.showCard = val
    },
  },
  async mounted() {},
  methods: {
    closeDrawer() {
      this.showCard = false
    },
    confirmClick() {
      this.showCard = false
      this.confirmHook()
    },
  },
}
</script>

<style lang="scss" scoped>
.tip-app {
  font-size: rem(32);
  font-family: 'Aspira Light';
  padding: rem(24);
  line-height: rem(40);
  color: #666;
}
.info-btn {
  padding: 20px 24px 20px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
  .el-button {
    min-width: 140px;
  }
}
.card {
  height: 504px;
  overflow: auto;
  padding-top: 10px;
}
@media screen and (max-width: 768px) {
  .card {
    height: calc(92vh - 200px);
    padding-bottom: 200px;
  }
}
</style>
<style lang="scss">
.card-popup-floating {
  background: #f5f5f7;
}
.card-popup .el-drawer {
  background: #f5f5f7;
}
.cash-content {
  width: 422px;
  background: #fff;
  margin: 20px auto;
  border-radius: 8px;
  padding: 20px 0 20px 20px;
  .el-radio__position {
    width: 382px;
    margin: 0 auto;
  }
  .el-radio__label {
    flex: 1;
    font-size: 16px;
    font-family: 'Aspira Medium';
    font-weight: 500;
    padding-left: 8px;
  }
  .el-radio__tip {
    font-family: 'Aspira Light';
    font-weight: 200;
    font-size: 14px;
    line-height: 15px;
    color: #999;
  }
}
.cash-disabled {
  cursor: not-allowed;
  .el-radio {
    cursor: not-allowed;
    color: #666;
  }
}
@media screen and (max-width: 768px) {
  .cash-content {
    width: rem(702);
    margin: rem(20) auto;
    border-radius: rem(24);
    padding: rem(40) 0 rem(40) rem(40);
    .el-radio {
      margin-left: rem(-20);
    }
    .el-radio__position {
      width: rem(650);
    }
    .el-radio__icon {
      width: rem(48);
    }
  }
}
</style>
