<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div class="drawer-popup-wrapper">
    <el-drawer
      :visible.sync="show"
      :with-header="false"
      custom-class="mobile-popup-wrapper"
      :direction="direction"
      :size="size"
      :before-close="close"
      :wrapperClosable="wrapperClosable"
      :close-on-press-escape="false"
      :append-to-body="appendToBody"
    >
      <div v-if="titleShow" class="drawer-popup-title-box">
        <p class="title" v-text="title"></p>
        <div class="close-box" @click="close">
          <i class="icon-v3_outline_close"></i>
        </div>
      </div>
      <slot />
      <div v-if="bottomShow" class="bottom-box">
        <BottomBtn :text="bottomName" :disabled="disabled" @add="bottomClick" />
      </div>
    </el-drawer>
  </div>
</template>

<script>
import BottomBtn from '@/components/DishFood/cateAdd.vue'
export default {
  name: 'DrawerPopup',
  components: {
    BottomBtn,
  },
  props: {
    appendToBody: Boolean,
    show: Boolean,
    // eslint-disable-next-line vue/require-default-prop
    title: String,
    disabled: {
      type: Boolean,
      default: false,
    },
    wrapperClosable: {
      type: Boolean,
      default: false,
    },
    titleShow: {
      type: Boolean,
      default: true,
    },
    // eslint-disable-next-line vue/require-default-prop
    bottomName: String,
    bottomShow: {
      type: Boolean,
      default: true,
    },
    size: {
      type: String,
      default: '30%',
    },
    direction: {
      type: String,
      default: 'btt',
    },
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {
    close() {
      this.$emit('close')
    },
    bottomClick() {
      this.$emit('bottom-click')
    },
  },
}
</script>
<style lang="scss">
.drawer-popup-wrapper {
  .mobile-popup-wrapper {
    border-radius: rem(64) rem(64) 0 0;
  }
  .mobile-popup-wrapper .el-drawer__body {
    height: 100%;
  }
}
</style>
<style lang="scss" scoped src="./index.scss"></style>
