import Vue from 'vue';
import i18n from '@/i18n/locale';

import DownloadPopupComponents from './downloadPopup.vue';

const downloadPopup = Vue.extend(DownloadPopupComponents);

let downloadPopupInstance = null;


const hide = () => {
  downloadPopupInstance.$el.remove();
  downloadPopupInstance = null;
};

export function downloadPopupShow () {
  if (downloadPopupInstance) return;
  downloadPopupInstance = new downloadPopup({
    el: document.createElement('div'),
    store: this.$store,
    i18n
  });

  downloadPopupInstance.newCloseDialog = () => hide();

  document.body.appendChild(downloadPopupInstance.$el);
};
