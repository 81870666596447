import { trackEvent } from '@/lib/omega'
import { GET_URL_HOST } from '@/utils/tools'
import { inAppSdk } from '@/utils/inapp-sdk/index'
import { isSafari, getFpVal } from '@/lib/wsg/getPubFid.js'
import { fp1, fp2 } from '@/lib/wsg/fp.js'
/** @desc 处理URL */
function truncationUrl(url) {
  try {
    const request_easy_path_arr = url && url.split('/')
    let request_easy_path = ''

    if (Array.isArray(request_easy_path_arr) && request_easy_path_arr.length > 2) {
      request_easy_path = request_easy_path_arr.slice(-2).join('/')
    }
    return request_easy_path
  } catch (error) {
    return ''
  }
}

/**
 * @DESC API监控
 */
export const apiMonitorLog = (response = {}) => {
  try {
    const { data = {}, config = {} } = response
    const { metadata = {}, url } = config
    const apiDuration = +(metadata?.endTime - metadata?.startTime)
    const { errno = '', traceId = -1 } = data
    const request_easy_path = truncationUrl(url)

    trackEvent({
      eventId: 'sailing_tech_api_monitor_bt',
      eventLabel: 'FE_API监控',
      attrs: {
        request_path: url,
        request_easy_path,
        request_errno: errno,
        trace_id: traceId,
        time: apiDuration,
        page_path: window.location.href,
      },
    })
  } catch (err) {
    console.error(err)
  }
}

/**
 * @DESC 页面监控
 */
export const techPageMonitorLog = async () => {
  try {
    const { appName } = inAppSdk()
    trackEvent({
      eventId: 'sailing_tech_page_monitor_bt',
      eventLabel: 'FE_页面监控',
      attrs: {
        type: 'init',
        referrer: document.referrer,
        referrer_host: GET_URL_HOST(document.referrer),
        page_path: window.location.href,
        app_name: appName,
        fp1: await fp1(),
        fp2: fp2(),
        ip_fp: await getFpVal('ip_fp'),
        ua_fp: await getFpVal('ua_fp'),
        uuid_fp: await getFpVal('uuid_fp'),
        is_safari: isSafari ? '1' : '0',
      },
    })
  } catch (err) {
    console.warn(err)
  }
}
