/**
 *  @desc 个人中心
 *  公参：http://wiki.intra.xiaojukeji.com/pages/viewpage.action?pageId=623319679
 *  pm: jiangmeng rd: wanggongcun
 */

import Qs from 'qs'
import { requestCommonParams } from '@/lib/request/requestCommonParams'
import store from '@/store/modules/common'
import { getLocalStorage } from '@/utils/localStorage'
import { is_mobile } from '@/utils/os'

/** @desc 获取帮助中心URL */
export const GET_HELP_URL = (data = {}) => {
  const baseUrl = 'https://help.didiglobal.com/passenger-index-new.html'
  const commonParams = requestCommonParams()

  const { lat, lng, lang, locale, appVersion, cityId, countyId, countyGroupId } = commonParams

  const baseParams = {
    token: getLocalStorage('ticket'),
    lat,
    lng,
    lang,
    locale,
    appversion: appVersion,
    cityId,
    countyId,
    countyGroupId,
    countryCode: store?.state?.country || 'JP',
    utc_offset: new Date().getTimezoneOffset(),
    soda_type: 'c',
    app_platform: 3,
    source: 'soda_global_c_home',
    // 传入数据
    ...data,
  }

  return `${baseUrl}?${Qs.stringify(baseParams)}`
}

/** @desc 跳转帮助中心 */
export const jumpHelpCenter = (fromData = {}) => {
  const url = GET_HELP_URL(fromData)

  if (is_mobile()) {
    window.location.href = url
  } else {
    window.open(url)
  }
}
