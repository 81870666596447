export default {
  '你最多可以下单MX$900.00的货到付款单': 'Pay on Delivery is available for orders up to MX$900.00',
  联系我们: 'Contact us',
  '简单操作，快速下单，支付便捷，支持多种方式支付，足不出户享受美食。':
    'Simple to use interface making ordering easy and quick. DiDi Food supports multiple payment methods for convenient purchases. Have your next meal delivered straight to your door.',
  '海量餐厅，多样菜品。你可以在熟悉的餐厅订餐，也能在新餐厅发现新美食，探索更多美味。':
    'DiDi Food offers a wide variety of restaurants and food options. Order your next meal from familiar favorites or discover new dishes. The city is your menu.',
  姓名: 'Name',
  成为合作餐厅: 'Open a store on DiDi Food',
  公司合作: 'Partner with Us',
  '成为滴滴外卖合作餐厅，轻松获得更多订单，更多顾客，让生意赚更多。':
    'Partner with DiDi Food to easily attract more customers and take your business to the next level.',
  一键享美食: 'The food you crave is just one click away.',
  '加入滴滴配送团队，大量订单，多种补贴奖励，自由选择上线时间，电动车、自行车都能配送，高收入高回报。':
    'Join DiDi Food’s Delivery Team and deliver orders with your scooter or bike. Freely choose your own hours and earn a variety of rewards and benefits. High earnings and returns!',
  加入滴滴配送: 'Join DiDi Food’s Delivery Team',
  特定商取引法: 'Notification',
  下载滴滴外卖App: 'Download the DiDi Food App.',
  电话: 'Phone',
  快捷订餐: 'Hassle-Free Ordering',
  关注我们: 'Follow DiDi Food',
  快速送达: 'Express Delivery',
  关于滴滴外卖: 'About DiDi Food',
  公司信息: 'Company Information',
  成为滴滴骑手: 'Become a DiDi Food Courier',
  成为滴滴商户: 'Become a DiDi Store',
  查看详情: 'View details',
  '专业的配送团队和配送设备，确保每一份美食以最快的速度完好送达。':
    'Professional delivery teams and equipment ensure that every order is delivered quickly and in tip-top shape.',
  查看更多: 'View',
  更多美味: 'Extensive Selection',
  最新消息: 'Press',
  '3. 您下单时通过现金支付，如退款申请通过，退款金额将以优惠券的形式退还到您的钱包。':
    '3. If you paid for your order in cash and are eligible for a refund, your refund will be issued to you in the form of a coupon added to your Wallet.',
  '您的订单还未完成，您可以联系骑手确认当前的具体配送位置。':
    'You have an open order that hasn’t been delivered yet. If you need to check the current location of your order, please contact the courier.',
  您所在的地区还未开通服务: 'Service not yet available in your area',
  取消订单进度: 'Cancellation Status',
  '4、以下您方原因导致订单超时送达或无法送达的，将不会获得赔付：':
    '4. Orders that are delayed or cannot be delivered are not eligible for compensation under the following circumstances:',
  跳过: 'Skip',
  '个人中心的“优惠券”': 'Account > Coupons.',
  商家正在备餐: 'Store Is Preparing Order',
  再等等: 'Not Now',
  申请信息: 'Request Details',
  骑手正在配送中: 'Courier Is on the Way',
  描述: 'Description',
  '5、每位用户每天最多赔付6张优惠券；':
    '5. Compensation is limited to 6 coupons per customer per day;',
  无效券: 'Invalid Coupons',
  不使用优惠券: 'Don’t Use Coupon',
  订单: 'Orders',
  请上传商品和小票照片: 'Please upload supporting photos',
  订单取消成功: 'Order cancelled!',
  进度详情: 'Request Status',
  使用规则: 'Terms of Use',
  反馈超时: 'Feedback Window Expired',
  '(1) 您要求更换收获地址、您提交的地址错误、联系不上您或无人收货、拒收等导致订单超时送达或无法送达的；':
    '(1) You request a delivery address change, the address you provide is incorrect, we are unable to reach you, no one is present to accept delivery, or you refuse delivery, and any of the above results in a delay or failure to deliver;',
  暂无优惠券: 'No coupons available',
  联系骑手: 'Contact Courier',
  关闭: 'Closed',
  提交: 'SUBMIT',
  订单尚未完成: 'You Have an Open Order',
  请选择有问题的商品: 'Select an item to report',
  提交申请: 'Request Cancellation',
  取消: 'Cancel',
  请选择取消原因以便商家更快帮您处理:
    'To help the store process your request faster, please select your reason for cancelling',
  钱包: 'Wallet',
  退款金额: 'Refund Amount',
  '6、每位用户每天最多赔付6张优惠券；':
    '6. Compensation is limited to 6 coupons per customer per day;',
  取消订单: 'Cancel Order',
  输入兑换码: 'Enter promotion code',
  展开: 'Deploy',
  '请您上传所收到的全部商品照片、问题商品细节照片、商品小票照片，这将协助我们更快地处理您的问题。':
    'To help us process your request faster, please upload photos of the following: all item(s) you received; closeup of the item(s) having a problem; and receipt for the item(s).',
  更多评价: 'More ratings',
  详细说明: 'Details',
  '4、部分时间段下单暂不参加赔付；':
    '4.Orders placed during certain hours are not eligible for compensation;',
  提交反馈: 'Submit Feedback',
  帮助中心: 'Help',
  '7、请您对骑手的送达时间进行监督，如遇骑手提前点击送达而实际未能按照约定时间送达的，请您拨打%s客服电话%s进行投诉。':
    '7. Please note the delivery time reported by your courier. If a courier claims to have delivered an order before you actually receive it, please call %s Customer Service at %s to file a complaint.',
  '6、请您对骑手的送达时间进行监督，如遇骑手提前点击送达而实际未能按照约定时间送达的，请您拨打%s客服电话%s进行投诉。':
    '6. Please note the delivery time reported by your courier. If a courier claims to have delivered an order before you actually receive it, please call %s Customer Service at %s to file a complaint.',
  '对处理结果不满意？': 'Not satisfied?',
  '2、超时赔付服务由平台为用户提供，用户没有支付任何费用，若订单取消则服务取消。':
    '2.Coupons are provided to you at no extra charge. However, if you cancel an order, you won’t be able eligible to receive any coupons.',
  '请告诉我们，帮助我们一起改善': 'Let us know and help us improve',
  申请取消原因: 'Reason for Cancellation',
  查看评价: 'View ratings',
  收起: 'Collapse',
  '5、以下您方原因导致订单超时送达或无法送达的，将不会获得赔付：':
    '5.Orders that are delayed or cannot be delivered are not eligible for compensation under the following circumstances:',
  优惠券: 'Coupons',
  退款渠道: 'Refund Method',
  '(2) 因您的配送地址所在地不允许骑手入内，导致超时送达或无法送达的；':
    '(2) The address you provide doesn’t permit couriers to enter, and this results in a delay or failure to deliver;',
  配送费: 'Delivery Fee',
  联系客服: 'Contact Customer Service',
  联系客服进行申诉: 'Contact Customer Service and submit an appeal',
  确认: 'Confirm',
  超时赔付规则说明: 'Compensation for Late Delivery',
  '已发起取消，等待商家确认': 'Cancellation request submitted. Waiting for store to confirm',
  '3、恶劣天气（雨、雪等）暂不参加赔付；':
    '3. Delays caused by severe weather (rain, snow, etc.) are not eligible for compensation;',
  兑换优惠: 'Redeem Coupon',
  '您为什么取消？': 'Why did you cancel?',
  '(3) 其他因您的原因导致骑手超时送达或无法送达的。':
    '(3) Any other factors attributable to you directly cause a delay or prevent delivery by the courier.',
  '（必填）请填写您的申诉原因': '(Required) Specify your reason to submit appeal',
  '2、部分时间段下单暂不参加赔付；':
    '2. Orders placed during certain hours are not eligible for compensation;',
  骑手没有配送我的订单: 'Courier didn’t deliver my order',
  '3、您因超时赔付服务获得的优惠券，将发放至您的%s账户，请在':
    '3.Coupons for late deliveries will be added to your %s account after your order is completed. You can view your coupons by going to ',
  骑手已到达: 'Courier Has Arrived',
  等待商家接单: 'Waiting for Store to Accept',
  '1、超时赔付服务是%s为您免费提供的服务，延迟送达时间及赔付优惠券金额如下：':
    '1.Compensating you for a late delivery is our way of making things right. Depending on how long the delay is, we’ll issue one or more free coupons to you:',
  '您的订单完成已经超过24小时，无法自助申请，如您有问题可联系客服，给您带来不便深表歉意。':
    'Since your order was completed more than 24 hours ago, you can no longer make a direct refund request. If you have any questions, please contact Customer Service. We apologize for any inconvenience.',
  商家拒绝取消申请: 'Cancellation request declined by store',
  联系商家: 'Contact Store',
  '1. 部分商品退款时，该金额为您申请的商品分摊优惠后总金额，配送费不退还。 2. 全部退款时，您的付款金额将全部退还。如您下单时使用过优惠券，优惠券将退还到您的钱包。':
    '1. For partial refunds, the refund amount is the total amount for items in your order that you requested a refund for, minus any prorated coupon discounts. Delivery Fee is nonrefundable. 2. For full refund, your entire payment will be refunded in full. If you used any coupons on your order, they will be returned to your Wallet.',
  中查看: ' ',
  正在寻找骑手: 'Finding a courier',
  '网络异常，请稍后再试': 'Network error, try again later',
  '优惠券使用门槛及有效期详见券面提示，未在券有效期限内使用完毕的，不可继续使用':
    'Coupon usage and validity period details are displayed on the coupon. Coupons cannot be used after the period of validity ',
  将优惠分享到: 'Share Promotion to',
  登录: 'Sign In',
  '单笔订单金额满足优惠券使用门槛金额时方可使用，单笔订单只能使用一张优惠券，使用优惠后，如订单金额超出部分需用户自行支付':
    "A coupon can be used when an order meets the coupon's usage threshold amount. Only one coupon can be used per order. Any amount in excess of the coupon's value must be paid by the customer",
  规则详情: 'Detailed Rules',
  '您邀请的好友需通过%s下单，通过其他渠道下单不发放邀请奖励':
    'The friend you invite must make an order via the %s. Orders made through other channels will not fulfill the requirement to receive an invite reward',
  待入账: 'Pending',
  活动时间: 'Promotion Time',
  '添加信用卡/借记卡': 'Add Credit/Debit Card',
  券包: 'Coupon Package',
  兑换成功: 'Coupon redeemed',
  支付方式: 'Payment Method',
  '支付超时！': 'Paid Timeout',
  '奖励会在邀请成功后的48小时内发放入您的账号，您可在%s-钱包-券中查看':
    'Rewards will be sent to your account within 48 hours after a successful invite. To view them, open your %s > Wallet > Coupons',
  复制成功: 'Copied',
  免配送费: 'Free Delivery',
  支付: 'Pay',
  '一经发现任何违规、套现行为，将追回相关金额或者封停账号，且依法追究其法律责任':
    'Any rules violation or attempt to cash in a coupon may result in a deduction of the amount in question or account suspension. Those in violation may also be held legally accountable',
  重试: 'Try Again',
  '本优惠活动仅限%s[在线支付]的订单享受，不可提现转赠':
    'This promotion is only available for %s [online payment] orders. It cannot be transferred or redeemed for cash',
  通过SNS邀请: 'Share via Social Media',
  分钟: 'Min',
  请选择支付方式: 'Select a payment method',
  我的奖励: 'My Rewards',
  待入账奖励: 'Pending Rewards',
  卡类型: 'Card Type',
  '您邀请的好友必须是%s新用户，每人仅限享受1次新用户首单优惠，同一手机号、同一设备、同一支付账号视为同一用户':
    'The friend you invite must be a new %s customer. Each person can only get a “New Customer First Order Discount” one time. The same phone number, same device, and same payment account are all considered to be the same customer',
  收餐人手机号与领取券时输入的手机号和账号注册手机号必须为同一号码:
    'The phone number of the order recipient, the number entered upon claiming the coupon, and the number used to register the account must be the same ',
  已过期: 'Expired',
  '本优惠活动仅限[%s]开通%s区域':
    'This promotion is only available in areas in [%s] where %s is available',
  确定: 'Confirm',
  已获得奖励: 'Earned Rewards',
  条款和条件: 'Terms & Conditions',
  '关于本活动的任何问题，请联系%s客服':
    'For any questions regarding this promotion, contact %s customer service at ',
  '邀请好友成为%s新用户，好友领取您送出的红包，并在活动时间截止前完成订单且无退款，无论是否使用领取的红包下单，您均可获得%s[优惠券]奖励':
    'Invite a friend to become a new %s customer so they can claim a coupon sent from you. If the friend makes an order before the promotion deadline and does not refund the order, no matter if they use your coupon to place the order, you may receive %s [Coupons] Rewards',
  '随时随地\\n享受\\n美好食物': 'Enjoy\\ndelicious food\\nanytime\\nanywhere',
  '未找到匹配地址，请尝试搜索。': 'No matching addresses found, try search function!',
  网络异常: 'Network Error',
  请检查网络连接: 'Please check your network connection',
  试试减少筛选条件: 'Try using fewer filters',
  下单: 'Place this order',
  更新: 'Update',
  退出登录: 'Sign Out',
  提示: 'Tip',
  '确定要清除缓存吗？': 'Clear cache?',
  '确认退出吗？': 'Are you sure you want to sign out?',
  注销: 'Delete',
  收货地址: 'Delivery Address',
  更多: 'More',
  查看全部: 'View All',
  寻找餐厅和美食: 'Find restaurants and food',
  小费: 'Tip',
  券: 'Coupons',
  商家券: 'Store Coupons',
  地址: 'Address',
  查看订单详情: 'Order Details',
  支付失败: 'Payment Failed',
  '您有一个相同的订单正在处理中，请避免重复下单。':
    'You already have an identical order in progress, please avoid placing duplicate orders.',
  查看当前订单: 'View the existing order',
  '您对商家满意吗？': 'How did this store do?',
  '您对骑手满意吗？': 'Were you satisfied with your courier?',
  商家评价: 'Store Rating',
  骑手评价: 'Courier Rating',
  未评价: 'Not rated',
  '退出页面后，当前评价会全部丢失，确认退出吗？':
    'Are you sure? If you exit now, your rating won’t be saved.',
  '您收到了订单了吗？': 'Did you receive your order?',
  请重试: 'Please try again',
  '确定删除地址吗？': 'Delete address?',
  不能删除这个地址: 'Unable to Delete',
  编辑: 'Edit',
  输入地址: 'Enter an address',
  家: 'Home',
  公司: 'Office',
  设为常用地点: 'Set as a Favorite Location',
  名字: 'First Name',
  姓氏: 'Last Name(s)',
  电话号码: 'Phone No.',
  请输入名字: 'Enter first name',
  请输入姓氏: 'Enter last name(s)',
  请输入电话号码: 'Enter a phone number',
  已售罄: 'SOLD OUT',
  添加: 'Add',
  必选: 'Required',
  休息中: 'CLOSED',
  '开始营业%s': 'Opens at\\n%s',
  超出配送范围: 'Outside delivery range',
  营业中: 'Open',
  活动: 'Promotions',
  营业时间: 'Business Hours',
  商家联系方式: 'Contact Info',
  法规: 'Notification',
  暂停配送: 'Temporarily Unavailable',
  配送方式: 'Delivery Option',
  商家自配送: 'Store Delivery',
  输入备注信息: 'Add Remarks',
  我的订单: 'My Orders',
  暂无订单: 'No orders yet',
  查看购物车: 'View Cart',
  修改取餐人: 'Change recipient',
  已购菜品: 'Items Selected',
  总计: 'Total',
  支付方式选择: 'Select Payment Method',
  '公司/公寓名称': 'Company / Apartment Name',
  '楼层/门牌号': 'Floor / Apartment No.',
  选择送餐地址: 'Select delivery address',
  热门搜索: 'Popular searches',
  最近搜过: 'Recently searched',
  '抱歉，未找到任何结果': 'Sorry, no results found',
  个人资料: 'My profile',
  帮助: 'Help',
  邀请好友: 'Invite Friends',
  我的收藏: 'Favorites',
  使用条款及隐私政策: 'Terms of Use and Privacy Policy',
  '网络或系统错误，请稍后重试。': 'Network or system error, please try again later.',
  '为保护隐私，他人无法看到您的真实电话号码':
    'To protect your privacy, your real phone number will not be visible to others',
  将使用以下号码加密呼出: 'This private number will be used to make your call',
  呼叫: 'Call',
  进行中的订单: 'Ongoing order',
  订单跟踪: 'Order Tracking',
  重置: 'Reset',
  多买多赠: 'Buy More, Get More',
  礼物: 'Freebie',
  每份: 'Each',
  '操作太快啦，请稍后尝试': 'Too many attempts. Please try again later',
  可定制: 'Customize',
  请添加地址: 'Add your address',
  补充详细地址信息: 'Add Address Details',
  '添加大楼名称以及门牌号，可以帮助配送员更快找你的送餐位置':
    'To help the courier find you faster, please specify your building and apartment number.',
  去添加: 'Add Now',
  忽略: 'Skip',
  购物车: 'Cart',
  去结算: 'Check Out',
  '抱歉，您最多可加购15个菜品': 'Sorry, you can only add up to 15 items to your cart',
  '给骑手小费%s': 'Tip the Courier %s',
  给配送员的备注: 'Notes for the Courier',
  '最多选择%d个': 'Limit %d',
  '选择%d个': 'Select %d',
  '已获得%d个赠品': 'Got %d free items',
  undefined: '(%d)',
}
