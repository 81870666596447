/**
 * 覆盖翻译平台自动同步的数据，一般用于紧急文案缺失的问题，避免delay
 * 正常情况一定要按标准流程，让产品翻译平台提翻译需求
 *
 * 注意：这只是紧急方案，翻译平台批量新增翻译时，会漏掉这部分
 * 这里的 UI Key 是人为构造的，与翻译平台自动生成的保持一致便可
 * 为便于检索，可以统一以 _AAAA 后缀结尾
 */
export default {
  'zh-CN': {
    FoodB_I_Loading_AAAA: '加载中...',
    已选择: '已选择',
    FoodC_Website_DiDi_FoodDiDi_Search_AAAA: 'KEYWORD cerca de mi | Ordena aquí para domicilio',
  },
  'en-US': {
    FoodB_I_Loading_AAAA: 'Loading...',
    您的个人信息: 'Your Personal Information',
    已选择: 'Selected',
    FoodC_Website_DiDi_FoodDiDi_Search_AAAA: 'KEYWORD cerca de mi | Ordena aquí para domicilio',
  },
  'ja-JP': {
    FoodB_I_Loading_AAAA: '読み込み中...',
    您的个人信息: 'アカウント情報',
    电话: '携帯電話番号',
    全部商家: '店舗一覧',
    到底了: '以上です',
    更新: '確定',
    请输入内容: 'クーポンコードの入力',
    提示: '注意',
    // 明确特殊处理场景 @恩姬
    // '取消': 'いいえ',
    // '确认': 'はい',
    订单: '注文一覧',
    收货地址: 'お届け先',
    暂停营业: '受付停止中',
    给骑手小费: '配達員へのチップ',
    下载滴滴外卖App: 'DiDi Foodアプリをダウンロード',
    关注我们: 'フォローお願いします',
    已选择: '選択済み',
    选择送餐地址: 'お届先を選択してください',
  },
  'es-MX': {
    FoodB_I_Loading_AAAA: 'Cargando...',
    FoodC_Website_DiDi_FoodDiDi_Search_AAAA: 'KEYWORD cerca de mi | Ordena aquí para domicilio',
  },
  'es-CO': {
    FoodB_I_Loading_AAAA: 'Cargando...',
    FoodC_Website_DiDi_FoodDiDi_Search_AAAA: 'KEYWORD cerca de mi | Ordena aquí para domicilio',
  },
  'pt-BR': {
    FoodB_I_Loading_AAAA: 'Carregando...',
  },
  'es-419': {
    FoodB_I_Loading_AAAA: 'Cargando...',
    FoodC_Website_DiDi_FoodDiDi_Search_AAAA: 'KEYWORD cerca de mi | Ordena aquí para domicilio',
  },
}
