import api from '@/api'
import { getMetaInfo } from '@/lib/request/index'
// import i18n from '@/i18n/locale'

const state = {
  errno: 0,
  listErrno: 0,
  loading: false,
  page: 0,
  orderList: [],
  hasMore: false,
  unfinished: false,
  ordersInfos: [],
  // 卡片相关的信息
  layoutCards: {},
  // 地图相关的信息
  orderData: {},

  // 评价信息
  evaTagList: {},
  // 查看评价信息
  evaInfo: {},
  serverApiTime: 0,
  // 欠款单loading
  debtRepayLoading: false,
  paySuccessInfo: {},
}

const mutations = {
  setPaySuccessInfo(state, data) {
    state.paySuccessInfo = data || {}
  },
  setOrderList(state, data) {
    state.orderList.push(...data.orderList)
    state.hasMore = !!data.haveNext
    state.unfinished = data.unfinished
    if (state.hasMore) {
      state.page++
    }
  },
  refreshOrderList() {
    state.page = 0
    state.orderList.length = 0
  },
  /** @desc 获取订单页信息 */
  setPageBatchInfo(state, data) {
    // 订单id传一个
    const orderInfos = data?.orders[0]
    state.orderData = orderInfos?.orderData
    state.serverApiTime = getMetaInfo(data)?.time
    const layoutCards = {}
    // eslint-disable-next-line array-callback-return
    orderInfos?.layouts.map(layout => {
      // eslint-disable-next-line array-callback-return
      layout?.cards.map(card => {
        const cardKey = card.template.slice(4)
        layoutCards[cardKey] = card
      })
    })
    state.layoutCards = { ...layoutCards }
    state.ordersInfos = data?.orders
  },
  /** @desc 标签相关 */
  setEvaTagList(state, data) {
    state.evaTagList = data
  },
  setEvaInfo(state, data) {
    state.evaInfo = data
  },
  setOrderLoading(state, data) {
    state.debtRepayLoading = data
  },
}

const actions = {
  /** @desc 获取支付成功信息 */
  async getPaySuccessInfo({ commit, state }, param) {
    try {
      const res = await api.getPaySuccessInfo(param)
      commit('setPaySuccessInfo', res)
    } catch {}
  },

  /** @desc 获取标签列表 */
  async getEvaTagList({ commit, state }, param) {
    try {
      const res = await api.apiOrderEvaTagList(param)
      commit('setEvaTagList', res)
    } catch {}
  },
  /** @desc 用户订单评价提交 */
  async postEvaInfo({ commit, state }, param) {
    try {
      await api.apiOrderPostEva(param)
    } catch {}
  },
  /** @desc 查看订单评价信息 */
  async getEvaInfo({ commit, state }, param) {
    try {
      const res = await api.apiOrderGetEvaInfo(param)
      commit('setEvaInfo', res)
    } catch {}
  },
  /** @desc 获取订单列表 */
  async getOrderList({ commit, state }, param) {
    try {
      if (param.pageNum === 0) {
        commit('refreshOrderList')
      }
      const res = await api.apiOrderList(param)
      commit('setOrderList', res)
      state.listErrno = 0
    } catch (err) {
      state.listErrno = err.errno
    }
  },
  /** @desc 未登录获取订单页信息 */
  async getPageBatchInfoNoLogin({ commit, state }, param) {
    state.loading = true
    try {
      const res = await api.pageBatchWithoutLogin(param)
      commit('setPageBatchInfo', res)
      state.errno = 0
      state.loading = false
      return res
    } catch (err) {
      state.errno = err.errno
      state.loading = false
      return err?.data
    }
  },
  /** @desc 获取订单页信息 */
  async getPageBatchInfo({ commit, state }, param) {
    state.loading = true
    try {
      const res = await api.orderPageBatch(param)
      commit('setPageBatchInfo', res)
      state.errno = 0
      state.loading = false
      return res
    } catch (err) {
      state.errno = err.errno
      state.loading = false
      return err?.data
    }
  },
  /**
   * http://wiki.intra.xiaojukeji.com/pages/viewpage.action?pageId=115453837
   * 订单状态
   */
  async getOrderStatus({ commit, state }, { orderId }) {
    try {
      const data = await api.getOrderStatus({ orderId })
      const status = data?.orders?.[0]?.orderData.status
      return {
        isInPay: status < 100,
        isPaid: status >= 100 && status < 900,
        isCancel: status >= 900,
      }
    } catch (err) {
      return {
        isInPay: false,
        isPaid: false,
        isCancel: false,
      }
    }
  },
  async getConfirmMeal({ commit, state }, param) {
    state.loading = true
    try {
      await api.confirmMeal(param)
      state.errno = 0
    } catch (err) {
      state.errno = err.errno
    }
    state.loading = false
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
