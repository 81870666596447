/**
 * base3.xiaojukeji.com/docs/passport/开发指南/PC-passport接入.html
 * base3.xiaojukeji.com/docs/passport/概览/术语.html
 */

import config from '@/config/index'
import emitter from './emitter'
// import i18n from '@/i18n/locale'
class PassportH5 {
  constructor({
    appId,
    role,
    lang,
    city_id,
    law_url,
    country_calling_code,
    country_id,
    canonical_country_code,
  }) {
    this.config = {
      appid: appId,
      role,
      city_id,
      law_url,
      lang,
      country_calling_code,
      country_id,
      // ticket: getLocalStorage('ticket'),
      canonical_country_code,
      // 自定义按钮及法律icon样式
      styleObj: {
        btn: {
          disableColor: '#999999',
          activeColor: '#fff',
          bgColor: '#ff7a45',
        },
        law: {
          color: '#ff7a45',
          iconBlue: true,
          marginRight: '12px',
        },
      },
      // isHalfScreen: true, // 登陆页面是否半屏展示
      // 登陆半浮层的标题和副标题
      // phonePage: {
      //   title: i18n.t('FoodC_202408_QAer_OYaB'),
      //   subTitle: i18n.t('FoodC_202408_tlOV_uOmJ'),
      // },
    }
  }

  login(config) {
    const sdk = window.login
    this.config = Object.assign(this.config, config)
    sdk.setConfig(this.config)
    sdk.login(
      res => {
        emitter.emit('h5-login', res)
      },
      err => {
        emitter.emit('h5-login-err', err)
        console.log('=========== err ===========', err)
      },
    )
  }

  logout(config) {
    const sdk = window.login
    this.config = Object.assign(this.config, config)
    sdk.setConfig(this.config)
    sdk.logout(
      () => {
        emitter.emit('h5-logout')
      },
      err => {
        emitter.emit('h5-logout-err', err)
        console.log('=========== err ===========', err)
      },
    )
  }
}

export default new PassportH5(config.passport)
