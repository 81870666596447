const ua = navigator.userAgent
export const is_mobile = () => {
  let is_mobi = true
  is_mobi =
    ua
      .toLowerCase()
      .match(
        /(ipod|ipad|iphone|android|coolpad|mmp|smartphone|midp|wap|xoom|symbian|j2me|blackberry|wince)/i,
      ) != null
  return is_mobi
}

export const getNetworkType = () => {
  return navigator?.connection?.effectiveType
}

// 操作系统类型
// 0：default
// 1：ios
// 2：android
// 9 ：windows
// 10 ：macOs
export const getDevice = () => {
  const agent = navigator.userAgent.toLowerCase()
  const result = {
    device: (function () {
      if (/windows/.test(agent)) {
        return 9
      } else if (/iphone|ipod|ipad/.test(agent) && /mobile/.test(agent)) {
        return 1
      } else if (/android/.test(agent) && /mobile/.test(agent)) {
        return 2
      } else if (/mac/.test(agent)) {
        return 10
      } else {
        return 0
      }
    })(),
  }
  return result?.device
}
