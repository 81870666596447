import common from './common'
import address from './address'
import store from './store'
import coupon from './coupon'
import bill from './bill'
import cart from './cart'
import pay from './pay'
import afterSales from './afterSales'
import order from './order'
import user from './user'

export default {
  common,
  address,
  store,
  coupon,
  bill,
  cart,
  pay,
  afterSales,
  order,
  user,
}
