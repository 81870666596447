/*
 * @Author: amywulan
 * @Date: 2022-03-Mo 11:41:54
 * @Last Modified by:  amywulan
 * @Last Modified time: 2022-03-Mo 11:41:54
 * @description: 开国初始化
 */
import { getLocalStorage, setLocalStorage } from '@/utils/localStorage'
import { CountryMap, getLocale, getRegion } from '@/utils/locale'
import store from './store/index.js'

/**
 * 异步获取country方案
 * 第一步：获取 url 中的 国家code 和countryMap做匹配
 * 第二步：没有匹配成功，请求 c-api common/config 接口反解 country，countryMap做匹配
 * 第三步：capi没有获取到，使用getLocalStorage('countryCode')
 * 若以上步骤均失败，默认取墨西哥 country: MX，
 * country获取优先通过store获取，无法使用store则通过getLocalStorageCountry.js
 */

export async function openInit() {
  // 进入时先获取地址相关的信息
  await store.dispatch('common/getRequestAddressInfo')
  await store.dispatch('common/getCountry')
  await store.dispatch('common/getUserPosition')

  return new Promise(resolve => {
    // 实验信息存入store
    // 获取locale并存入store
    store.commit('common/setLocale', getLocale())
    if (CountryMap.includes(getRegion())) {
      // 获取国家并存入store
      store.commit('common/setCountry', getRegion())
      setLocalStorage('countryCode', getRegion())
    } else {
      const country = getLocalStorage('countryCode') || 'MX'
      store.commit('common/setCountry', country)
      setLocalStorage('countryCode', country)
    }
    resolve()
  })
}
