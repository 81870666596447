/**
 * @Author: amywulan
 * @Date: 2021-07-07 12:00:00
 */
import api from '@/api'
import precision from '@/utils/precision'
import Vue from 'vue'
import { isStrategyShow } from '@/utils/baseUtil'
import { adapterCateInfo } from '@/utils/shopUtil'
import { setLocalStorage } from '@/utils/localStorage'
import { storeShopId, shopInfoPoi } from '@/utils/storeRouterChange'

function transRevision(revision) {
  if (revision === undefined) {
    return revision
  }
  return Math.floor(Number(revision) / 100)
}

const state = {
  // 店铺聚合
  cateInfo: {},
  shopInfo: {},
  shopHeader: {},
  popDialogData: {},
  alertInfo: null,
  attentionInfo: null,
  recId: '',
  showCateItemDetail: null,

  // SF
  vuexShopId: '', // 从shopindex获取的店铺id
  shopPoi: {}, // 店铺的地址信息
}
const mutations = {
  setShopData(state, data) {
    state.vuexShopId = data?.shopInfo?.shopId || ''
    state.shopPoi = data?.shopPoi || {}

    state.cateInfo = adapterCateInfo(data?.cateInfo)
    state.shopInfo = data?.shopInfo
    setLocalStorage(storeShopId, data?.shopInfo?.shopId)
    setLocalStorage(shopInfoPoi, {
      lat: data?.shopInfo?.lat,
      lng: data?.shopInfo?.lng,
      cityId: data?.shopInfo?.cityId,
    })
    state.cartRevision = transRevision(data?.shopInfo?.cartRevision)
    state.alertInfo = data?.alertInfo
    state.popDialogData = data?.popDialogData
    state.attentionInfo = data?.attentionInfo
    state.recId = data?.recId
  },
  setShopHeader(state, data) {
    const { compList = [] } = data
    const com = compList.find(el => {
      const type = el?.componentV2?.type
      return +type === 5
    })
    state.shopHeader = com?.data || {}
  },
  setItemDetail(state, data) {
    state.showCateItemDetail = data
  },
  setCartInfo(state, data) {
    state.cartInfo = data
  },
  updateCartRevision(state, data) {
    state.cartRevision = data.cartRevision
  },
}
const actions = {
  /**
   * @description: 获取店铺首页接口
   * @param {type}
   * @return
   */
  async shopIndex({ commit, state }, params) {
    let res = {}
    try {
      res = await api.shopIndex(params)

      const { shopHeader = {} } = res
      commit('setShopData', res)
      commit('setShopHeader', shopHeader)
    } catch (e) {
      return false
    }
    return res
  },
  /**
   * @description: 获取菜品详情
   * @param {type}
   * @return
   */
  async itemDetail({ commit, state }, params) {
    let res = {}
    try {
      res = await api.itemDetail(params)
      commit('setItemDetail', res)
    } catch (e) {
      return false
    }
    return res
  },

  /**
   * @description: 商家菜品列表加购菜品
   * @param commit
   * @param state
   * @param rootState
   * @param nodeList
   */
  async addItem({ commit, state, rootState }, { nodeList, mduId, wineConfirm = 0 }) {
    const {
      shopInfo: { shopId },
      cartRevision,
    } = state
    const {
      cart: { revision },
    } = rootState
    const cartRevisionExist = typeof revision === 'number'
    let requestRevision

    if (cartRevisionExist) {
      requestRevision = revision + 1
      commit('cart/setRevision', { revision: requestRevision }, { root: true })
    } else {
      requestRevision = cartRevision + 1
      commit('updateCartRevision', { cartRevision: requestRevision })
    }

    let res = {}
    try {
      commit('cart/setLoading', { loading: true }, { root: true })
      const params = { shopId, nodeList, mduId, wineConfirm }
      if (requestRevision) {
        params.revision = requestRevision
      }
      res = await api.addItem(params)
      res = precision.transCartInfo(res)
      // 特价菜提示
      if (res?.toast?.content) {
        const show = isStrategyShow('special_cate_limit', res?.toast?.strategy)
        show && Vue.prototype.$message.warning(res?.toast?.content)
      }
      commit('cart/setLoading', { loading: false }, { root: true })
      commit('cart/setCartInfo', res, { root: true })
    } catch (e) {
      commit('cart/setLoading', { loading: false }, { root: true })
      // TODO 捕获异常后，这里的commit为何没有生效？
      // 多端操作冲突问题
      // 更新商家页
      // commit('shopIndex', { shopId });
      // 更新购物车
      // commit('cart/cartInfo', { shopId }, { root: true });
      return e
    }
    return res
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
