import Vue from 'vue'
import i18n from '@/i18n/locale';
import Loading from './index.vue'

const LoadingConstructor = Vue.extend(Loading)
let defaultEl = document.body
const defaultProps = {
  fullScreen: true
}

export default function Confirm({ el = defaultEl, props = defaultProps} = {}) {
  setPrevNode(el, props)
  let instance = new LoadingConstructor({
    propsData: props,
    i18n
  }).$mount()
  el.appendChild(instance.$el)
  const methods = {
    close() {
      let parentNode = instance?.$el?.parentNode
      parentNode?.removeChild(instance.$el)
      removeClass(parentNode, 'r-loading-parent-overflow')
      removeClass(parentNode, 'r-loading-parent-relative')
      return methods
    }
  }
  return methods
}
function setPrevNode(parentNode, props) {
  let parentNodeStyle = window.getComputedStyle(parentNode)
  let position = parentNodeStyle.position
  if (!props.fullScreen && (!position || position === 'static')){
    parentNode.className += ' r-loading-parent-relative'
  }
  if (props.fullScreen) {
    parentNode.className += ' r-loading-parent-overflow'
  }
}
function removeClass(el, name) {
  if (!el) return
  let className = el.className
  if (className) {
    el.className = className.replace(name, '')
  }
}