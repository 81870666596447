/*
 * @Author: amywulan
 * @Date: 2021-07-Fr 11: 44: 27
 * @Last Modified by: amywulan
 * @Last Modified time: 2021-07-Fr 11: 44: 27
 * @description 项目公参 http://wiki.intra.xiaojukeji.com/pages/viewpage.action?pageId=628231793
 */
import config from '@/config'
import dPoi from '@/config/countryConfig/list/default-poi'
import env from '@/config/env'
import fingerprint from '@/utils/fingerprint'
import { getCountry } from '@/utils/getLocalStorageCountry'
import { getLocalStorage } from '@/utils/localStorage'
import { getLanguageLong, getLocale } from '@/utils/locale'
import { getDevice, getNetworkType } from '@/utils/os'
import qs from '@/utils/urlParam'
import SparkMD5 from 'spark-md5'
import packageJSON from '../../../package.json'
function generateRequestId(deviceId) {
  let timestamp = Math.floor(Date.now() / 1000)
  return SparkMD5.hash(timestamp + '' + deviceId).toUpperCase()
}
// StoreFont 方向不用默认地址
let defaultPoi = dPoi.getPoi(getCountry)

export function requestCommonParams() {
  const lastPoi = getLocalStorage('lastPoi') || {}

  return {
    /** @desc 公参不许加token适配pc **/
    /** @desc 地址信息 **/
    countryCode: lastPoi?.countryCode || defaultPoi.countryCode, // SF加入
    countyId: lastPoi?.countyId || defaultPoi.countyId,
    countyGroupId: lastPoi?.countyGroupId || defaultPoi.countyGroupId,
    cityId: lastPoi?.cityId || defaultPoi.cityId,
    lat: lastPoi?.lat || defaultPoi.lat,
    lng: lastPoi?.lng || defaultPoi.lng,
    poiId: lastPoi?.poiId || defaultPoi.poiId,

    /** @desc 地址未返回的参数 **/
    poiCityId: lastPoi?.poiCityId || lastPoi?.cityId || defaultPoi?.cityId,
    poiCountyId: lastPoi?.poiCountyId || lastPoi?.countyId || defaultPoi.countyId,
    poiCountyGroupId:
      lastPoi?.poiCountyGroupId || lastPoi?.countyGroupId || defaultPoi.countyGroupId,
    poiName: lastPoi?.poiName || lastPoi?.displayName || defaultPoi.displayName,
    poiLat: lastPoi?.poiLat || lastPoi?.lat || defaultPoi.lat,
    poiLng: lastPoi?.poiLng || lastPoi?.lng || defaultPoi.lng,

    /** @desc 固定参数 **/
    appVersion: packageJSON.cApiVersion,
    versionCode: packageJSON.cApiVersion,
    clientType: 6,
    brand: env.is99 ? '3' : '2',
    timestamp: new Date().getTime(),
    dataType: 21, // 新增
    locale: getLocale(),
    lang: getLanguageLong(),
    businessType: 4,
    sailingBizLine: 5,
    terminalType: '5',
    oid: config.omega.appKey,
    mapType: 'gmap',
    locationType: '-1',

    /** @desc 设备信息 **/
    osType: getDevice(),
    // https://developer.mozilla.org/zh-CN/docs/Web/API/Network_Information_API
    // 有兼容性，大部分获取不到
    networkType: getNetworkType(),
    wifiName: 'wifi', // todo
    osVersion: navigator.userAgent, // todo

    /** @desc 风控 **/
    suuid: fingerprint.get(), // todo
    imei: fingerprint.get(), // todo
    deviceId: fingerprint.get(), // todo
    deviceToken: fingerprint.get(), // todo
    requestId: generateRequestId(fingerprint.get()),

    /** @desc url上参数 **/
    enterChannel: qs('enterChannel'),
    extActivityId: qs('extActivityId'),

    /** @desc 场景 **/
    scene: 1,
  }
}
