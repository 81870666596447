import { post } from '@/lib/request/index'
import { common, feed } from '../apiConstants'
// 获取侧边栏信息，包括个人信息、侧边栏入口、侧边栏banner
export function sidebar(param) {
  return post(common.SIDE_BAR, {
    param,
  })
}
// 获取号码保护的虚拟电话
export function getVirtualtelCall(param) {
  return post(common.PROTECT_NUM, {
    param,
  })
}

// 上传图片
export function uploadBase64Image(param) {
  return post(common.UPLOAD_BASE64_IMAGE, {
    param,
  })
}

// 获取token
export function getToken(param) {
  return post(common.GET_TOKEN, { param })
}
// 获取Country
export function getCountry(param) {
  return post(common.COMMON_CONFIG, { param })
}
// 获取开城列表
export function getCityList(param) {
  return post(feed.HOME_SERVICE_CITY_LIST, { param })
}
