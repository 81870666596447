class Emitter {
  constructor() {
    this.state = {}
  }

  _exist(list, fn) {
    return list.indexOf(fn) !== -1
  }

  _foo() {}

  on(eventName, listener = this._foo) {
    if (this.state[eventName] === undefined) {
      this.state[eventName] = []
    }
    if (this._exist(this.state[eventName], listener)) {
      return
    }
    this.state[eventName].push(listener)
  }

  emit(eventName, ...args) {
    if (this.state[eventName] === undefined) {
      return
    }
    this.state[eventName].forEach(listener => {
      listener(...args)
    })
  }

  off(eventName, listener = this._foo) {
    if (this.state[eventName] === undefined) {
      return
    }
    if (!this._exist(this.state[eventName], listener)) {
      return
    }
    const index = this.state[eventName].indexOf(listener)
    this.state[eventName].splice(index, 1)
  }
}

export default new Emitter()
