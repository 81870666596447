import {
  // requestHelper,
  requestDataHelper,
  // requestLoadingHelper,
  // requestDataLoadingHelper,
} from './helper';
import { isBusinessError, getMetaInfo } from './utils'

import { get as baseGet, post as basePost } from './request';
// postFormData

function get(url, { param, config = {} }) {
  return requestDataHelper(baseGet, url, {
    param: param,
    config,
  })
}

function post(url, { param, config = {} }) {
  return requestDataHelper(basePost, url, param, config);
}

function download(url, { param }, name) {
  const queryString = Object.keys(param)
    .map(key => key + '=' + param[key])
    .join('&');
  let a = document.createElement('a');
  a.href = url + '?' + queryString;
  a.download = name || 'download_file';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
}

export {
  // requestHelper,
  // requestDataHelper,
  // requestLoadingHelper,
  // requestDataLoadingHelper,
  isBusinessError,
  getMetaInfo,
  get,
  post,
  download,
  baseGet,
  basePost,
  // postFormData,
};
