// http://wiki.intra.xiaojukeji.com/pages/viewpage.action?pageId=218126000

import { post } from '@/lib/request/index'
import { act } from '../apiConstants'

// 获取客户端弹窗
export function apiGetCommonPopup(param) {
  return post(act.NA_POP_DIALOG, { param })
}

// 获取efo活动页
export function apiGetEFOMore(param) {
  return post(act.FEED_EFO_MORE, { param })
}
