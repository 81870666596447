import store from '@/store/modules/common'
import fingerprint from '@/utils/fingerprint'
import { getLocalStorage } from '@/utils/localStorage'
import axios from '@didi/dajax/dist/dajax'
import qs from 'qs'
import './interceptors'
import { requestCommonParams } from './requestCommonParams'
import { requestShopParams, needUseRequestShopParams } from './requestShopParams'

export function getCommonConfig(config) {
  return {
    timeout: 30000,
    headers: getLocalStorage('ticket')
      ? {
          Accept: 'application/json',
          ticket: getLocalStorage('ticket'),
        }
      : {
          Accept: 'application/json',
        },
    withCredentials: true,
    ...config,
  }
}

export function addCommonParams(data = {}, api = '') {
  let params = { ...requestCommonParams() }
  // 实验参数填充到公参内
  const testConfig = store?.state?.config?.testConfig || {}
  // 如果需要商家poi信息的接口，则填充商家poi信息（注意先后顺序，会覆盖默认公参）
  if (needUseRequestShopParams(api)) {
    params = { ...params, ...requestShopParams() }
  }
  return Object.assign({}, params, { testConfig: JSON.stringify(testConfig) }, data)
}

export async function get(url = '', { param = {}, config = {} }) {
  await fingerprint.init()
  const commParams = addCommonParams({}, url)
  const params = Object.assign({}, commParams, param)
  const data = Object.assign({}, getCommonConfig(config), {
    params,
  })
  return axios.get(url, data)
}

export async function post(url = '', data = {}, config = {}) {
  await fingerprint.init()
  const params = addCommonParams(data, url)
  return axios.post(url, qs.stringify(params), Object.assign({}, getCommonConfig(config)))
}
