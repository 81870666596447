/***
 * @deac 项目不允许直接使用'@didi/i18n-util'包
 * 1、 对MX，B侧生成链接的时候不带locale。C侧将兜底语言设置为 es-MX，i18n包内为zh-CN，需要特殊处理
 * 2、对CO、CR、PE，我们后续在这些国家放量，B侧生成链接的时候带上locale参数 like 'es-CO'。C侧从链接里面直接读这个参数
 * 3、使用的方法需要关注兜底逻辑处理
 * ***/

import I18nUtil from '@didi/i18n-util'
import { getLocalStorage } from '@/utils/localStorage'

const isMX = getLocalStorage('countryCode') === 'MX'
// eslint-disable-next-line no-unused-vars
const isCheckLocale = () => {
  const { checkLocale } = I18nUtil
  const path = location.pathname.split('/')?.[1]
  return checkLocale(path)
}

export const checkLocale = () => {
  const { checkLocale } = I18nUtil
  const path = location.pathname.split('/')?.[1]
  return checkLocale(path)
}
export const getLocale = () => {
  const { getLocale } = I18nUtil
  // return getLocale()
  return isCheckLocale() || !isMX ? getLocale() : 'es-MX'
}

export const getLanguageLong = () => {
  const { getLanguageLong } = I18nUtil
  return isCheckLocale() || !isMX ? getLanguageLong() : 'es-MX'
}

export const getLanguage = () => {
  const { getLanguage } = I18nUtil
  return isCheckLocale() || !isMX
    ? getLanguage()
    : {
        short: 'es',
        long: 'es-MX',
      }
}
export const getRegion = () => {
  const { getRegion } = I18nUtil
  return getRegion()
}

export const CountryMap = I18nUtil?.CountryMap
