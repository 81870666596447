import { post } from '@/lib/request/index'
import { pay } from '../apiConstants'
import retry from '../../utils/retry'

// 获取卡列表
export function getPayInfo(param) {
  return post(pay.PAY_GETPAYINFO, { param })
}

// 获取支付状态
export function getPayStatus({ transId }) {
  return post(pay.GET_PAY_STATUS, { param: { out_trade_id: transId } })
}

// 订单支付成功页面
export function getPaySuccessInfo(param) {
  return post(pay.GET_SUCCESS_PAY_INFO, { param })
}
// 轮询支付状态
export function pollingPayStatusV2({ transId }, { times, timeout }, isSuccess) {
  const _pollingPayStatus = retry(getPayStatus, { times, timeout }, isSuccess)
  return _pollingPayStatus({ transId })
}
