function delay(period) {
  return new Promise(resolve => {
    let t = setTimeout(() => {
      resolve();
      clearTimeout(t);
    }, period);
  });
}

async function request() {
  await delay(1000);
  // throw new Error('err');
  return { errno: 0, errmsg: 'SUCCESS' };
}

const TIME_OUT_CODE = 408;

export default function retry(request, config = {}, isSuccess) {
  const { times = 1, timeout = 10 * 1000 } = config;
  let count = 0;
  let startTime = Date.now();

  function isOverTime(startTime, timeout) {
    return Date.now() - startTime > timeout;
  }

  // async function _retry(params) {
  //   if (isOverTime(startTime, timeout)) {
  //     return { errno: TIME_OUT_CODE };
  //   }
  //
  //   // try {
  //   //   let res = await request(params);
  //   //   if (isSuccess(res)) {
  //   //     return res;
  //   //   }
  //   //   if (isOverTime(startTime, timeout)) {
  //   //     return { errno: TIME_OUT_CODE };
  //   //   }
  //   //
  //   //   count++;
  //   //   if (count <= times) {
  //   //     return _retry(params);
  //   //   }
  //   //   return res;
  //   // } catch (err) {
  //   //   throw err;
  //   // }
  //
  //   let res = await request(params);
  //   if (isSuccess(res)) {
  //     return res;
  //   }
  //   if (isOverTime(startTime, timeout)) {
  //     return { errno: TIME_OUT_CODE };
  //   }
  //
  //   count++;
  //   if (count <= times) {
  //     return _retry(params);
  //   }
  //   return res;
  // }

  function _retry(params) {
    return new Promise((resolve, reject) => {
      if (isOverTime(startTime, timeout)) {
        return { errno: TIME_OUT_CODE };
      }

      // await 的控制粒度比较粗
      request(params)
        .then(res => {
          // 请求正常，业务层判断
          if (isSuccess(res)) {
            return resolve(res);
          }
          if (isOverTime(startTime, timeout)) {
            return resolve({ errno: TIME_OUT_CODE });
          }
          count++;
          if (count < times) {
            return resolve(_retry(params));
          }
          return resolve(res);
        })
        .catch(err => {
          // 请求异常，直接上抛
          reject(err);
        });
    });
  }

  return _retry;
}

// function isSuccess(res) {
//   return false;
// }
//
// const retryRequest = retry(request, { times: 4, timeout: 3 * 1000 },
//   isSuccess);
//
// (async () => {
//   let s = Date.now();
//   let res = {};
//   try {
//     res = await retryRequest();
//   } catch (err) {
//   }
// })();
