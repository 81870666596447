<template>
  <span class="c-checkbox-btn" :class="{ 'is-checked': isChecked, disabled: disabled }"></span>
</template>

<script>
export default {
  name: 'CheckBoxBtn',
  props: {
    isChecked: Boolean,
    disabled: Boolean,
  },
  data() {
    return {}
  },
  mounted() {},
  methods: {},
}
</script>

<style lang="scss" scoped src="./index.scss"></style>
