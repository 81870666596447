import { post } from '@/lib/request/index'
import { cart, shop } from '../apiConstants'

// 首页聚合接口
export function shopIndex(param) {
  return post(shop.SHOP_INDEX, { param }).catch(err => {
    return err
  })
}

// 菜品详情接口
export function itemDetail(param) {
  return post(shop.ITEM_DETAIL, { param })
}

// 菜品详情接口
export function addItem({ shopId, nodeList, revision, mduId = '' }) {
  return post(cart.CART_SETITEM, {
    param: {
      shopId,
      nodeList: JSON.stringify(nodeList),
      revision,
      mduId,
    },
  })
}
