<template>
  <div
    ref="rloading"
    class="top-loading-wrapper"
    :class="{ 'none-float': noneFloat, 'full-screen': fullScreen }"
    :style="{ background: background }"
  >
    <transition name="fadeIn60">
      <div
        v-show="show"
        class="loading-content"
        :class="[customClass, { containter: !hideContainer }]"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 36 25"
          :width="size"
          :height="size"
          preserveAspectRatio="xMidYMid meet"
          style="transform: translate3d(0px, 0px, 0px)"
        >
          <defs>
            <clipPath id="__lottie_element_2">
              <rect width="36" height="25" x="0" y="0"></rect>
            </clipPath>
            <clipPath id="__lottie_element_4">
              <path d="M0,0 L750,0 L750,1624 L0,1624z"></path>
            </clipPath>
          </defs>
          <g clip-path="url(#__lottie_element_2)">
            <g
              clip-path="url(#__lottie_element_4)"
              transform="matrix(0.75,0,0,0.75,-266.25,-603.5)"
              opacity="1"
              style="display: block"
            >
              <g opacity="1" class="clip-g1" style="display: block">
                <g opacity="1" transform="matrix(1,0,0,1,-23.25,-308.92498779296875)">
                  <path
                    fill="#FF8040"
                    fill-opacity="1"
                    d=" M8.5,-24.46099853515625 C8.5,-24.46099853515625 8.5,24.46099853515625 8.5,24.46099853515625 C8.5,29.152149200439453 4.691150188446045,32.96099853515625 0,32.96099853515625 C0,32.96099853515625 0,32.96099853515625 0,32.96099853515625 C-4.691150188446045,32.96099853515625 -8.5,29.152149200439453 -8.5,24.46099853515625 C-8.5,24.46099853515625 -8.5,-24.46099853515625 -8.5,-24.46099853515625 C-8.5,-29.152149200439453 -4.691150188446045,-32.96099853515625 0,-32.96099853515625 C0,-32.96099853515625 0,-32.96099853515625 0,-32.96099853515625 C4.691150188446045,-32.96099853515625 8.5,-29.152149200439453 8.5,-24.46099853515625z"
                  ></path>
                  <path
                    stroke-linecap="butt"
                    stroke-linejoin="miter"
                    fill-opacity="0"
                    stroke-miterlimit="4"
                    stroke="rgb(0,0,0)"
                    stroke-opacity="1"
                    stroke-width="0"
                    d=" M8.5,-24.46099853515625 C8.5,-24.46099853515625 8.5,24.46099853515625 8.5,24.46099853515625 C8.5,29.152149200439453 4.691150188446045,32.96099853515625 0,32.96099853515625 C0,32.96099853515625 0,32.96099853515625 0,32.96099853515625 C-4.691150188446045,32.96099853515625 -8.5,29.152149200439453 -8.5,24.46099853515625 C-8.5,24.46099853515625 -8.5,-24.46099853515625 -8.5,-24.46099853515625 C-8.5,-29.152149200439453 -4.691150188446045,-32.96099853515625 0,-32.96099853515625 C0,-32.96099853515625 0,-32.96099853515625 0,-32.96099853515625 C4.691150188446045,-32.96099853515625 8.5,-29.152149200439453 8.5,-24.46099853515625z"
                  ></path>
                </g>
              </g>
              <g opacity="1" class="clip-g3" style="display: block">
                <g opacity="1" transform="matrix(1,0,0,1,-23.457000732421875,-309.03900146484375)">
                  <path
                    fill="#31B77E"
                    fill-opacity="1"
                    d=" M8.5,-24.46099853515625 C8.5,-24.46099853515625 8.5,24.46099853515625 8.5,24.46099853515625 C8.5,29.152149200439453 4.691150188446045,32.96099853515625 0,32.96099853515625 C0,32.96099853515625 0,32.96099853515625 0,32.96099853515625 C-4.691150188446045,32.96099853515625 -8.5,29.152149200439453 -8.5,24.46099853515625 C-8.5,24.46099853515625 -8.5,-24.46099853515625 -8.5,-24.46099853515625 C-8.5,-29.152149200439453 -4.691150188446045,-32.96099853515625 0,-32.96099853515625 C0,-32.96099853515625 0,-32.96099853515625 0,-32.96099853515625 C4.691150188446045,-32.96099853515625 8.5,-29.152149200439453 8.5,-24.46099853515625z"
                  ></path>
                  <path
                    stroke-linecap="butt"
                    stroke-linejoin="miter"
                    fill-opacity="0"
                    stroke-miterlimit="4"
                    stroke="rgb(0,0,0)"
                    stroke-opacity="1"
                    stroke-width="0"
                    d=" M8.5,-24.46099853515625 C8.5,-24.46099853515625 8.5,24.46099853515625 8.5,24.46099853515625 C8.5,29.152149200439453 4.691150188446045,32.96099853515625 0,32.96099853515625 C0,32.96099853515625 0,32.96099853515625 0,32.96099853515625 C-4.691150188446045,32.96099853515625 -8.5,29.152149200439453 -8.5,24.46099853515625 C-8.5,24.46099853515625 -8.5,-24.46099853515625 -8.5,-24.46099853515625 C-8.5,-29.152149200439453 -4.691150188446045,-32.96099853515625 0,-32.96099853515625 C0,-32.96099853515625 0,-32.96099853515625 0,-32.96099853515625 C4.691150188446045,-32.96099853515625 8.5,-29.152149200439453 8.5,-24.46099853515625z"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </transition>
  </div>
</template>

<script>
/**
 * size 尺寸
 * hideContainer 隐藏容器
 * customClass class名
 * fullscreen 标识全局loading
 * nonefloat 标识 loading 不需要 遮罩和脱离文档流
 * background 背景控制
 *
 * 使用方式 1
 * let load = this.$rLoading()
  setTimeout(() => {
    load.close()
  }, 3000);
 * 使用方式 2
 指令 v-r-loading="true"
 */
export default {
  name: 'Loading',
  props: {
    size: {
      type: Number,
      default: 24,
    },
    hideContainer: {
      type: Boolean,
      default: false,
    },
    customClass: {
      type: String,
      default: '',
    },
    fullScreen: Boolean,
    noneFloat: Boolean,
    background: String,
  },
  data() {
    return {
      show: false,
    }
  },
  mounted() {
    this.show = true
  },
  methods: {},
}
</script>
<style lang="scss">
.r-loading-parent-relative {
  position: relative;
}
.r-loading-parent-overflow {
  overflow: hidden;
}
</style>
<style lang="scss" scoped src="./index.scss"></style>
