import Vue from 'vue'
import i18n from '@/i18n/locale'
import CardPopup from './cardPopup.vue'

const ConfirmConstructor = Vue.extend(CardPopup)
const theConfirm = function (options) {
  const parentNode = document.body || this.$el
  const instance = new ConfirmConstructor({
    el: document.createElement('div'),
    // store: this.$store,
    propsData: options,
    i18n,
  }).$mount()
  // 可以通过$el属性来访问创建的组件实例
  parentNode.appendChild(instance.$el)
  instance.showCard = true
  // 此时进行创建组件的逻辑处理
  const methods = {
    confirmHook: fn => {
      instance.confirmHook = fn
      return methods
    },
    closeHook: fn => {
      instance.closeHook = fn
      return methods
    },
  }
  return methods
}

// 将逻辑函数进行导出和暴露
export default theConfirm
