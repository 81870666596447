import { CATE_STATUS_MAP, CATE_DISABLED_STATUS_LIST } from '@/const/dishfood'
/**
 * @desc 获取菜品disabled状态 title
 * @param {菜品信息} info
 * @returns
 */
export function getSlodTitle(info) {
  return CATE_DISABLED_STATUS_LIST.includes(info.soldStatus)
    ? +info.soldStatus === 3
      ? info.soldTimeDesc
      : CATE_STATUS_MAP[info.soldStatus] || ''
    : ''
}
