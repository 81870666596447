/**
 *  @desc 扩展公用方法
 */
import { cloneDeep } from 'lodash-es'
import { errorToast, warningToast, infoToast } from '@/plugins/tools/elementFun'

export default {
  install(Vue) {
    Object.defineProperty(Vue.prototype, '$utils', {
      writable: false,
      value: {
        cloneDeep(data) {
          return cloneDeep(data)
        },
        errorToast(message, duration) {
          return errorToast(message, duration)
        },
        warningToast(message, duration) {
          return warningToast(message, duration)
        },
        infoToast(message, duration) {
          return infoToast(message, duration)
        },
      },
    })
  },
}
