// http://wiki.intra.xiaojukeji.com/pages/viewpage.action?pageId=178093784

import { post } from '@/lib/request/index'
import { order } from '../apiConstants'
import api from '@/api'
import config from '@/config'
import { PAYMENT_QUERY_CALLBACK } from '@/const/paymentProcess'

// 获取标签列表
export function apiOrderEvaTagList(param) {
  return post(order.ORDER_EVALUATION_TAG_LIST, { param })
}

// 用户订单评价提交
export function apiOrderPostEva(param) {
  return post(order.ORDER_EVALUATION_EVALUATE, { param })
}

// 查看订单评价信息
export function apiOrderGetEvaInfo(param) {
  return post(order.ORDER_EVALUATION_GET_EVALUATE_INFO, { param })
}

// 创建订单V2
export function apiOrderCreate(param) {
  return post(order.ORDER_CREATE_V2, { param })
}

// 取消订单
export function cancelOrder({ orderId, paymentError }) {
  return post(order.ORDER_CANCEL, { param: { orderId, paymentError } })
}

// 订单列表
export function apiOrderList(param) {
  return post(order.ORDER_LIST_V2, { param })
}

// 订单详情页面
export function orderPageBatch(param) {
  return post(order.ORDER_LAYOUT_BATCH, { param })
}

// 未登录订单详情页订单接口
export function pageBatchWithoutLogin(param) {
  return post(order.PAGE_BATCH_WITHOUT_LOGIN, { param })
}

// 获取订单状态
export function getOrderStatus({ orderId }) {
  return post(order.ORDER_LAYOUT_BATCH, { param: { orderIds: orderId } })
}

// 订单详情  目前只用来获取 电话信息
export function orderDetailBatch(param) {
  return post(order.ORDER_DETAIL_BATCH, { param })
}

// 用户确认收餐。
export function confirmMeal(param) {
  return post(order.ORDER_CONFIRM_MEAL, { param })
}

// 欠款单支付
export function debtRepay({ orderId, debtId, payChannel }) {
  return post(order.DEBT_REPAY, { param: { orderId, debtId, payChannel } })
}

// 欠款单支付
export function debtRepayFail({ orderId, debtId }) {
  return post(order.DEBT_REPAY_FAIL, { param: { orderId, debtId } })
}

// http://wiki.intra.xiaojukeji.com/pages/viewpage.action?pageId=659142082
export async function getDebtRepay({ orderId, debtId, payChannel }) {
  try {
    const res = await api.debtRepay({ orderId, debtId, payChannel })
    const { transId } = res

    const queryResult = await api.pollingPayStatusV2(
      { transId },
      {
        times: config.constants.PAY_QUERY_LOOP_CONFIG.TIMES,
        timeout: config.constants.PAY_QUERY_LOOP_CONFIG.TIMEOUT,
      },
      PAYMENT_QUERY_CALLBACK.BUSINESS_LEVEL.isSuccess,
    )

    if (PAYMENT_QUERY_CALLBACK.BUSINESS_LEVEL.isFail(queryResult)) {
      await api.debtRepayFail({ orderId, debtId })
      return false
    }
    return true
  } catch (err) {
    return false
  }
}
