import en from '@didi/r-fusion-pc/lib/locale/lang/en'
import ja from '@didi/r-fusion-pc/lib/locale/lang/ja'
import zh from '@didi/r-fusion-pc/lib/locale/lang/zh-CN'
import mx from '@didi/r-fusion-pc/lib/locale/lang/es'

const langs = {
  'en-US': en,
  'zh-CN': zh,
  'ja-JP': ja,
  'es-MX': mx,
  'es-CO': mx,
  'es-CR': mx,
}

export { langs }
