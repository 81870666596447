/**
 * Service Worker
 * 使用workbox实现 由以下三个文件配合
 * 1、soda.config.js GenerateSW  实现 缓存策略和缓存文件配置
 * 2、src/utils/swRegister.js  实现 sw注册 & sw注销 功能
 */
import env from '@/config/env'
import { getLocale, checkLocale } from '@/utils/locale'
export function swRegister() {
  if (env?.isPro && 'serviceWorker' in navigator) {
    // if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
      const project = checkLocale() ? `/${getLocale()}` : ''
      navigator.serviceWorker
        .register(`${project}/sw-sf-c.js`, { scope: `${project}/` })
        .then(registration => {
          console.log('SW registered: ', registration)
        })
        .catch(registrationError => {
          console.log('SW registration failed: ', registrationError)
        })
    })
  }
}

export function swUnregister() {
  if ('serviceWorker' in navigator) {
    try {
      // 批量清除 CacheStorage 或者自定义具名清除
      caches.keys().then(keys => {
        keys.forEach(key => {
          caches.delete(key)
        })
      })
      // eslint-disable-next-line no-empty
    } catch (error) {}
    navigator.serviceWorker.ready.then(registration => {
      registration.unregister()
    })
  }
}
