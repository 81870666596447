import Vue from 'vue'

const imgConfig = (src, config) => {
  if (src.search(/\?/) !== -1) {
    return `${src}&${config}`
  }

  return `${src}?${config}`
}

/** @desc 图片尺寸 */
Vue.directive('img-size', {
  bind(el, binding) {
    const { value } = binding
    let proportion

    switch (value) {
      case 'higher':
        proportion = 85
        break
      case 'medium':
        proportion = 70
        break
      case 'small':
        proportion = 50
        break
      case 'mini':
        proportion = 30
        break
      default:
        proportion = 100
        break
    }

    if (el.src.includes('x-s3-process')) {
      el.src = el.src + `,image/resize,p_${proportion}`
    } else {
      el.src = imgConfig(el.src, `x-s3-process=image/resize,p_${proportion}`)
    }
  },
})
