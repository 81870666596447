import Vue from 'vue'
import i18n from '@/i18n/locale'
import ConfirmMsg from './index.vue'

const ConfirmConstructor = Vue.extend(ConfirmMsg)
export default function Confirm(options) {
  const parentNode = document.body || this.$el
  const instance = new ConfirmConstructor({
    propsData: options,
    i18n,
  }).$mount()
  parentNode.appendChild(instance.$el)
  const methods = {
    confirmHook: fn => {
      instance.confirmHook = fn
      return methods
    },
    cancelHook: fn => {
      instance.cancelHook = fn
      return methods
    },
    onClose: () => {
      // 关闭组件
      instance.onClose()
      return methods
    },
  }
  return methods
}
