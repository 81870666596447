<template>
  <span class="str">{{ data }}</span>
</template>

<script>
export default {
  name: 'NormalTextPart',
  props: {
    data: String,
  },
}
</script>

<style scoped lang="scss">
.str {
}
</style>
