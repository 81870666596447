import i18n from '@/i18n/locale'
import { sprintf } from 'sprintf-js';
export default {
  SELECT_TYPE: {
    COUNTER: 'counter',
    RADIO: 'radio',
    CHECKBOX: 'checkbox',
  },
  SELECTED_TAG: {
    RADIO_AND_CHECKBOX: {
      TEMPORARY: '_amount',
      PERMANENT: 'amount',
    },
    COUNTER: {
      TEMPORARY: '_amount',
      PERMANENT: 'amount',
    },
  },
  UNMODIFIED_VALUE: undefined,
  DISPLAY_TAG: {
    ARRAY_KEY: '_selectedSubItemsForDisplay',
    NAME: 'formatName',
    PRICE: 'accumulatedPrice',
    NUMBER: 'number',
  },
  OPERATION_TYPE: {
    CLICK: 'click',
    ADD: 'add',
    REMOVE: 'remove',
  },
  LIMIT_TYPE: {
    SELECT(n) {
      return sprintf(i18n.t('选择%d个'), n)
    },
    LIMIT(n) {
      return sprintf(i18n.t('最多选择%d个'), n)
    },
  },
};
