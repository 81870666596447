const sourceObj = {
  whatsApp: {
    regex: /WhatsApp/i,
    name: 'WhatsApp',
    type: 1,
  },
  facebook: {
    regex: /\bFB[\w_]+\//,
    name: 'Facebook',
    type: 2,
  },
  ins: {
    regex: /ins/i,
    name: 'INS',
    type: 3,
  },
  yelp: {
    regex: /Yelp\/([0-9.]+)/i,
    name: 'Yelp',
    type: 4,
  },
  twitter: {
    regex: /\bTwitter/i,
    name: 'Twitter',
    type: 5,
  },
  tiktok: {
    regex: /musical_ly|Bytedance/i,
    name: 'TikTok',
    type: 6,
  },
  chrome: {
    regex: /(?:Chrome|CriOS)(?!.*EdgA)/i,
    name: 'Chrome',
    type: 7,
  },
  google: {
    regex: /Google/i,
    name: 'Google',
    type: 8,
  },
  other: {
    regex: /(?:Safari|Edge|Firefox)\/([\d.]+)/i,
    name: 'Safari|Edge|Firefox',
    type: 9,
  },
  none: {
    regex: /\S+/g,
    name: 'none',
    type: 10,
  },
}

const getAppKey = ua => {
  return Object.keys(sourceObj)?.find(appName => sourceObj[appName]?.regex?.test(ua))
}
export const sourceType = () => {
  try {
    const userAgent =
      window?.navigator?.userAgent || window?.navigator?.vendor || window?.opera || ''
    const appKey = getAppKey(userAgent) || ''
    // console.log('----------', userAgent, appKey)
    const type = appKey ? sourceObj[appKey]?.type : 10
    return type
  } catch (err) {
    return 10
  }
}
