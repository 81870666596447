import i18n from '@/i18n/locale';
import config from '@/config/index';
import passportMonitor from '@/utils/passportMonitor';
import { loginStatus, ignoreLoginPathList } from './error-code';
import { API_RECID_MAP } from '@/config/trackLog/const';
import { delLocalStorage } from '@/utils/localStorage';
import { isObject } from '@/utils/types';

import { act } from '@/api/apiConstants';
const { FEED_EFO_MORE } = act;

/**
 * 通用错误处理中间件 处理errno!==0 的情况, 返回result.data
 * TODO 业务正常和业务异常的返回值不同，这种处理逻辑不太合理
 * 先提供工具函数区分result和result.data
 */
export function errnoDataMiddleware(request, response) {
  return new Promise((resolve, reject) => {
    response
      .then((result) => {
        if (Number(result.errno) !== 0) {
          const { params = [] } = request;
          const currentAPIPath = params[0] || '';
          const toLogin =
            loginStatus.includes(result.errno) &&
            !ignoreLoginPathList.includes(currentAPIPath);

          if (toLogin) {
            delLocalStorage('ticket');
            delLocalStorage('uid');
            passportMonitor.login();
            reject(result);
          } else {
            reject(result);
          }
        } else {
          // 将result.data同级数据挂载到result.data._meta上
          const data = result.data;
          if (isObject(data)) {
            data._meta = result;
            delete data._meta.data;
            resolve(data);
          } else {
            resolve(data);
          }
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
}


/**
 * 增加网络异常中间件
 */
export function networkErrorMiddleware(request, response) {
  return new Promise((resolve, reject) => {
    response
      .then((result) => {
        if (typeof result === 'undefined') {
          resolve({
            errno: config.constants.NETWORK_ERROR,
            errmsg: i18n.t('FoodB_supplement_Network_connection_RVcl'),
          });
        } else {
          resolve(result);
        }
      })
      .catch((error) => {
        console.warn(error);
        if (error.response) {
          resolve({
            errno: error.response.status,
            errmsg: ` ${error.response.status} ${error.response.statusText}`,
          });
        } else {
          resolve({
            errno: config.constants.NETWORK_ERROR,
            errmsg: i18n.t('FoodB_supplement_Network_connection_RVcl'),
          });
        }
      });
  });
}

/**
 * 增加获取rec_id中间件 (用于埋点上报)
 */
export function getRecIdMiddleware(request, response) {
  return new Promise((resolve, reject) => {
    response
      .then((result) => {
        const { params = [] } = request;
        const API = params[0] || '';
        const { recId = '' } = result || {};

        // 存储key：api val：redId
        if (API && recId) {
          API_RECID_MAP[API] = recId;
        } else if (API && API === FEED_EFO_MORE) {
          API_RECID_MAP[API] = result?.extra?.recId || '';
        }

        resolve(result);
      })
      .catch((error) => {
        console.warn(error);
        reject(error);
      });
  });
}
