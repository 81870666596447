import env from './env'
import getCountryConfig from '@/config/countryConfig/index'
import { getCountry } from '@/utils/getLocalStorageCountry'
// import { isH5 } from '@/utils/tools'
import { getLanguageLong } from '@/utils/locale'

const getLanguage = getLanguageLong() === 'es-419' ? 'es-MX' : getLanguageLong()

const cApiBase = 'https://c.didi-food.com'

const config = {
  cApiBase,
  // 待补充
  actApiBase: 'https://act-api..didi-food.com',

  passport: {
    h5LoginUrl: '//passport-test.didichuxing.com/static/trinity-login/2.0.7/login.test.js',
    passportUrl: 'https://page.didiglobal.com/public-biz/pc-login/3.0.8/index.html',
    setCookieUrl: `${cApiBase}/login/callback`, // todo 后端setCookie接口地址
    appId: 200073,
    source: 70001, // 根据国家区分
    role: 1,
    lang: getLanguage,
    law_url: getCountryConfig.login.lawUrlH5(getCountry),
    country_id: getCountryConfig.login.countryId(getCountry),
    country_calling_code: getCountryConfig.login.countryCallingCode(getCountry),
    city_id: 0,
    canonical_country_code: getCountryConfig.login.canonicalCountryCode(getCountry),
    // eslint-disable-next-line no-void
    theme: env.is99 ? 'yellow' : void 0,
  },
}

export default config
