import { Message, Toast } from '@didi/r-fusion-pc'
import { is_mobile } from '@/utils/os'
let isToastShowing = false
/**  @desc 错误toast => 统一UI规范 覆盖组件库Message样式 */
export const errorToast = (message, duration = 3000) => {
  Message({
    message,
    type: 'error',
    customClass: 'sailing_fe_error_toast',
    duration,
    offset: 40,
  })
}

/** @desc 警告toast  => 统一UI规范 覆盖组件库Message样式 */
export const warningToast = (message, duration = 3000) => {
  Message({
    message,
    type: 'warning',
    customClass: 'sailing_fe_message_style',
    duration,
    offset: 40,
  })
}

/** @desc 信息toast  => 统一UI规范 覆盖组件库Message样式 */
export const infoToast = (message, duration = 3000) => {
  if (is_mobile()) {
    if (isToastShowing) {
      return
    }
    // 设置标识为 true，表示现在有 toast 弹窗在显示
    isToastShowing = true
    Toast({
      message,
      center: true,
      verticalCenter: true,
      customClass: 'sailing_fe_h5_info_toast',
      type: 'txt',
      duration,
      onClose: () => {
        isToastShowing = false
      },
    })
  } else {
    Message({
      message,
      iconClass: 'fe_info_toast',
      customClass: 'sailing_fe_info_style',
      duration,
      offset: 40,
    })
  }
}
