export default {
  '你最多可以下单MX$900.00的货到付款单':
    'El monto máximo para pedidos con pago en la entrega puede ser hasta MX$900.00',
  联系我们: 'Contáctanos',
  '简单操作，快速下单，支付便捷，支持多种方式支付，足不出户享受美食。':
    '¿Quieres sushi, una hamburguesa o una pizza? Con DiDi Food pedir es fácil y rápido. ¿No tienes efectivo? No te preocupes, también puedes pagar con tarjeta de crédito. Nuestra prioridad es que recibas la comida directamente en tu puerta.',
  '海量餐厅，多样菜品。你可以在熟悉的餐厅订餐，也能在新餐厅发现新美食，探索更多美味。':
    'En DiDi Food podrás encontrar una gran variedad de restaurantes y tipos de comidas. Tu restaurante favorito está a un clic de distancia.',
  姓名: '姓名',
  成为合作餐厅: 'Abre una tienda en DiDi Food',
  公司合作: 'Asóciate con nosotros',
  '成为滴滴外卖合作餐厅，轻松获得更多订单，更多顾客，让生意赚更多。':
    'Asóciate con DiDi Food para atraer más clientes con facilidad y llevar tu negocio al siguiente nivel.',
  一键享美食: 'La comida que deseas está a unos clics de distancia.',
  '加入滴滴配送团队，大量订单，多种补贴奖励，自由选择上线时间，电动车、自行车都能配送，高收入高回报。':
    'Únete al equipo de entregas de DiDi Food y entrega pedidos con tu scooter, bici o motocicleta. Puedes elegir libremente las horas, ganar dinero y recompensas.',
  加入滴滴配送: 'Únete al equipo de entregas de DiDi Food',
  特定商取引法: '特定商取引法',
  下载滴滴外卖App: 'Descarga la app de DiDi Food.',
  电话: 'Enviar',
  快捷订餐: 'Pide sin complicaciones',
  关注我们: 'Sigue a DiDi Food',
  快速送达: 'Entrega express',
  关于滴滴外卖: 'Acerca de DiDi Food',
  公司信息: 'Información de la empresa',
  成为滴滴骑手: 'Hazte repartidor de DiDi Food',
  成为滴滴商户: 'Conviértete en una Tienda DiDi',
  查看详情: 'Conoce más',
  '专业的配送团队和配送设备，确保每一份美食以最快的速度完好送达。':
    'Los socios repartidores expertos de DiDi Food garantizan que los pedidos se entreguen rápidamente y en perfectas condiciones.',
  查看更多: 'Ver',
  更多美味: 'Pide de tus restaurantes favoritos',
  最新消息: '最新消息',
  '3. 您下单时通过现金支付，如退款申请通过，退款金额将以优惠券的形式退还到您的钱包。':
    '3. Si pagaste en efectivo tu pedido y eres elegible para un reembolso, tu reembolso se enviará en forma de cupón y se agregará a tu Billetera.',
  '您的订单还未完成，您可以联系骑手确认当前的具体配送位置。':
    'Tienes un pedido abierto que aún no ha sido entregado. Si necesitas conocer la ubicación actual de tu pedido, por favor ponte en contacto con el socio repartidor.',
  您所在的地区还未开通服务: 'Servicio aún no disponible en tu área',
  取消订单进度: 'Estado de la cancelación',
  '4、以下您方原因导致订单超时送达或无法送达的，将不会获得赔付：':
    '4. Los pedidos demorados o que no puedan entregarse no recibirán compensación en los siguientes casos:',
  跳过: 'Saltar',
  '个人中心的“优惠券”': 'Mi Perfil > Cupones',
  商家正在备餐: 'La tienda está preparando el pedido',
  再等等: 'Más tarde',
  申请信息: 'Detalles de la solicitud',
  骑手正在配送中: 'El repartidor está en camino',
  描述: 'Descripción',
  '5、每位用户每天最多赔付6张优惠券；':
    '5. La compensación se limita a 6 cupones por cliente por día;',
  无效券: 'Cupones vencidos',
  不使用优惠券: 'No usar cupón',
  订单: 'Pedidos',
  请上传商品和小票照片: 'Sube fotos del producto y el recibo del pedido',
  订单取消成功: 'Pedido cancelado',
  进度详情: 'Estado de la solicitud',
  使用规则: 'Términos de uso',
  反馈超时: 'Caducó tiempo de comentarios',
  '(1) 您要求更换收获地址、您提交的地址错误、联系不上您或无人收货、拒收等导致订单超时送达或无法送达的；':
    '(1) Si has solicitado un cambio de dirección de entrega, la dirección que has proporcionado es incorrecta, no podemos contactarte, no hay nadie para recibir la entrega, o si rechazas la entrega, y cualquiera de los casos anteriores resulta en una demora o imposibilidad de realizar la entrega;',
  暂无优惠券: 'No hay cupones disponibles',
  联系骑手: 'Contactar al repartidor',
  关闭: 'Cerrada',
  提交: 'Enviar',
  订单尚未完成: 'Tienes un pedido pendiente',
  请选择有问题的商品: 'Selecciona el producto',
  提交申请: 'Solicitar cancelación',
  取消: 'Cancelar',
  请选择取消原因以便商家更快帮您处理:
    'Para ayudar a la tienda a procesar tu solicitud más rápido, selecciona el motivo de la cancelación',
  钱包: 'Monedero',
  退款金额: 'Monto del reembolso',
  '6、每位用户每天最多赔付6张优惠券；':
    '6. La compensación se limita a 6 cupones por cliente por día;',
  取消订单: 'Cancelar pedido',
  输入兑换码: 'Ingrese un código promocional',
  展开: 'Ver más (and) Ver menos',
  '请您上传所收到的全部商品照片、问题商品细节照片、商品小票照片，这将协助我们更快地处理您的问题。':
    'Para ayudarnos a procesar tu solicitud más rápido, sube fotos de lo siguiente: todos los productos recibidos; una foto de cerca del producto con problemas; y recibo del producto.',
  更多评价: 'Más calificaciones',
  详细说明: 'Detalles',
  '4、部分时间段下单暂不参加赔付；':
    '4.Los pedidos realizados durante determinadas horas no recibirán compensación;',
  提交反馈: 'Enviar comentarios',
  帮助中心: 'Ayuda',
  '7、请您对骑手的送达时间进行监督，如遇骑手提前点击送达而实际未能按照约定时间送达的，请您拨打%s客服电话%s进行投诉。':
    '7. Anota el tiempo de entrega informado por tu socio repartidor. Si un socio repartidor reclama que entregó un pedido antes de que lo hayas recibido, llama a Soporte DiDi de %s al %s para presentar un reclamo.',
  '6、请您对骑手的送达时间进行监督，如遇骑手提前点击送达而实际未能按照约定时间送达的，请您拨打%s客服电话%s进行投诉。':
    '6. Anota el tiempo de entrega reportado por el socio repartidor. Si un socio repartidor dice que entregó un pedido antes de que lo hayas recibido, por favor llama a Soporte DiDi de %s al %s para presentar una queja.',
  '对处理结果不满意？': '¿Insatisfecho?',
  '2、超时赔付服务由平台为用户提供，用户没有支付任何费用，若订单取消则服务取消。':
    '2. Te brindamos los cupones sin costo alguno. Sin embargo, si cancelas un pedido, ya no podrás recibir más cupones.',
  '请告诉我们，帮助我们一起改善': 'Envíenos sus comentarios y ayúdenos a mejorar',
  申请取消原因: 'Motivo de cancelación',
  查看评价: 'Ver calificaciones',
  收起: 'Reducir',
  '5、以下您方原因导致订单超时送达或无法送达的，将不会获得赔付：':
    '5.Los pedidos demorados o que no puedan entregarse no recibirán compensación en los siguientes casos:',
  优惠券: 'Cupones',
  退款渠道: 'Método de reembolso',
  '(2) 因您的配送地址所在地不允许骑手入内，导致超时送达或无法送达的；':
    '(2) La dirección que has proporcionado no permite el ingreso de los repartidores y esto ocasiona una demora o la imposibilidad de realizar la entrega;',
  配送费: 'Tarifa de entrega',
  联系客服: 'Contactar a Servicio a Clientes',
  联系客服进行申诉: 'Contacta a Servicio al Cliente',
  确认: 'Confirmar',
  超时赔付规则说明: 'Compensación por entrega con demora',
  '已发起取消，等待商家确认':
    'Solicitud de cancelación enviada. Esperando confirmación de la tienda.',
  '3、恶劣天气（雨、雪等）暂不参加赔付；':
    '3. Las demoras ocasionadas por el mal clima (lluvia, nieve, etc.) no recibirán compensación;',
  兑换优惠: 'Canjear cupón',
  '您为什么取消？': '¿Por qué canceló?',
  '(3) 其他因您的原因导致骑手超时送达或无法送达的。':
    '(3) Cualquier otro factor atribuible directamente a ti que ocasione una demora o impida la entrega por el repartidor.',
  '（必填）请填写您的申诉原因': '(Obligatorio) Especifica el motivo para solicitar una revisión',
  '2、部分时间段下单暂不参加赔付；':
    '2. Los pedidos realizados durante determinadas horas no recibirán compensación;',
  骑手没有配送我的订单: 'El socio repartidor no entregó mi pedido',
  '3、您因超时赔付服务获得的优惠券，将发放至您的%s账户，请在':
    '3. Los cupones por entregas demoradas se agregarán a tu perfil de %s una vez que se complete el pedido. Puedes ver tus cupones en',
  骑手已到达: 'El repartidor llegó',
  等待商家接单: 'En espera que la tienda acepte',
  '1、超时赔付服务是%s为您免费提供的服务，延迟送达时间及赔付优惠券金额如下：':
    '1.Nuestra manera de remediar las cosas es compensarte por la entrega tardía. Según el tiempo de la demora, te daremos uno o más cupones gratis:',
  '您的订单完成已经超过24小时，无法自助申请，如您有问题可联系客服，给您带来不便深表歉意。':
    'Como tu pedido finalizó hace más de 24 horas, ya no puedes hacer una solicitud de reembolso directo. Si tienes alguna pregunta, ponte en contacto con Soporte DiDi. Nos disculpamos por cualquier inconveniente.',
  商家拒绝取消申请: 'La tienda rechazó tu solicitud de cancelación',
  联系商家: 'Contactar a la tienda',
  '1. 部分商品退款时，该金额为您申请的商品分摊优惠后总金额，配送费不退还。 2. 全部退款时，您的付款金额将全部退还。如您下单时使用过优惠券，优惠券将退还到您的钱包。':
    '1. Para los reembolsos parciales, el monto del reembolso corresponde al monto total de los productos de tu pedido para los que solicitaste un reembolso, menos cualquier descuento de cupón prorrateado. La tarifa de entrega no se reembolsa. 2. Para los reembolsos totales, se reembolsará el monto total del pago. Si usaste cupones en tu pedido, éstos regresarán a tu Billetera.',
  中查看: ' ',
  正在寻找骑手: 'Buscando un repartidor',
  '网络异常，请稍后再试': 'Error de red; intenta de nuevo más tarde',
  '优惠券使用门槛及有效期详见券面提示，未在券有效期限内使用完毕的，不可继续使用':
    'Los detalles del uso del cupón y el período de validez se muestran en el cupón. Los cupones no se pueden usar después del período de validez',
  将优惠分享到: 'Compartir promoción a',
  登录: 'Iniciar sesión',
  '单笔订单金额满足优惠券使用门槛金额时方可使用，单笔订单只能使用一张优惠券，使用优惠后，如订单金额超出部分需用户自行支付':
    'Se puede usar un cupón cuando un pedido alcanza el monto mínimo del cupón. Sólo se puede usar un cupón por pedido. El usuario debe pagar cualquier diferencia del valor del cupón.',
  规则详情: 'Reglas detalladas',
  '您邀请的好友需通过%s下单，通过其他渠道下单不发放邀请奖励':
    'El amigo que invites debe realizar un pedido mediante %s. Los pedidos realizados por otros canales no cumplirán el requisito para recibir una recompensa por referir.',
  待入账: 'Pendiente',
  活动时间: 'Tiempo de promoción',
  '添加信用卡/借记卡': 'Agregar una tarjeta de crédito/débito',
  券包: 'Cupón',
  兑换成功: 'Cupón canjeado',
  支付方式: 'Método de pago',
  '支付超时！': 'Tiempo de espera de la solicitud agotado',
  '奖励会在邀请成功后的48小时内发放入您的账号，您可在%s-钱包-券中查看':
    'Las recompensas se enviarán a tu cuenta dentro de las 48 horas siguientes a la invitación. Para visualizarlas, abre la aplicación %s > Monedero > Cupones',
  复制成功: 'Copiado',
  免配送费: 'Entrega gratuita',
  支付: 'Pedir',
  '一经发现任何违规、套现行为，将追回相关金额或者封停账号，且依法追究其法律责任':
    'Cualquier violación de las reglas o intento de cobro de un cupón puede resultar en la deducción de dicha cantidad o la suspensión de la cuenta. Aquellos que violen las reglas pueden también ser responsables legalmente',
  重试: 'Intentar de nuevo',
  '本优惠活动仅限%s[在线支付]的订单享受，不可提现转赠':
    'Esta promoción sólo está disponible para pedidos [pago en línea] de %s. No se puede transferir o canjear por efectivo',
  通过SNS邀请: 'Compartir en redes sociales',
  分钟: 'Min',
  请选择支付方式: 'Selecciona un método de pago',
  我的奖励: 'Mis recompensas',
  待入账奖励: 'Recompensas pendientes',
  卡类型: 'Tipo de tarjeta',
  '您邀请的好友必须是%s新用户，每人仅限享受1次新用户首单优惠，同一手机号、同一设备、同一支付账号视为同一用户':
    'El amigo que invites debe ser un nuevo usuario de %s. Cada persona sólo puede obtener un descuento por primer pedido de usuario nuevo una sola vez. El mismo número de teléfono, el mismo dispositivo y la misma cuenta de pago se consideran un mismo usuario.',
  收餐人手机号与领取券时输入的手机号和账号注册手机号必须为同一号码:
    'El número de teléfono del destinatario del pedido, el número ingresado al canjear el cupón y el número utilizado para registrar la cuenta deben ser el mismo',
  已过期: 'Expirado',
  '本优惠活动仅限[%s]开通%s区域':
    'Esta promoción sólo está disponible en las zonas en [%s] donde %s está disponible',
  确定: 'Confirmar',
  已获得奖励: 'Recompensas ganadas',
  条款和条件: 'Términos y Condiciones',
  '关于本活动的任何问题，请联系%s客服':
    'Para cualquier pregunta respecto a esta promoción, ponte en contacto con Servicio a Clientes de %s al ',
  '邀请好友成为%s新用户，好友领取您送出的红包，并在活动时间截止前完成订单且无退款，无论是否使用领取的红包下单，您均可获得%s[优惠券]奖励':
    'Invita a un amigo a ser un nuevo usuario de %s para que utilice un cupón enviado por ti. Si tu amigo realiza un pedido antes del plazo de la promoción y no devuelve el pedido, no importa si usa el cupón para realizar el pedido, podrás recibir %s [Cupones] recompensas',
  '随时随地\\n享受\\n美好食物': 'Tus\\nrestaurantes\\nfavoritos\\ndirecto\\na tu puerta',
  '未找到匹配地址，请尝试搜索。':
    'No se encontraron direcciones que coincidan, prueba la función de búsqueda',
  网络异常: 'Error de red',
  请检查网络连接: 'Comprueba tu conexión a Internet',
  试试减少筛选条件: 'Intenta usar menos filtros',
  下单: 'Hacer este pedido',
  更新: 'Actualizar',
  退出登录: 'Cerrar sesión',
  提示: 'Notificación',
  '确定要清除缓存吗？': '¿Deseas borrar el caché?',
  '确认退出吗？': '¿Estás seguro de que deseas cerrar sesión?',
  注销: 'Eliminar',
  收货地址: 'Dirección de entrega',
  更多: 'Más',
  查看全部: 'Ver todos',
  寻找餐厅和美食: 'Buscar restaurantes o comida',
  小费: 'Agradecimiento',
  券: 'Cupones',
  商家券: 'Cupones de Tienda',
  地址: 'Dirección',
  查看订单详情: 'Detalles del pedido',
  支付失败: 'Error en el pago',
  '您有一个相同的订单正在处理中，请避免重复下单。':
    'Ya tienes un pedido idéntico en curso, evita realizar pedidos repetidos por favor.',
  查看当前订单: 'Ver el pedido existente',
  '您对商家满意吗？': '¿Qué te pareció la tienda?',
  '您对骑手满意吗？': '¿Cómo fue tu experiencia con el repartidor?',
  商家评价: 'Calificación de la tienda',
  骑手评价: 'Calificación del repartidor',
  未评价: 'Sin calificar',
  '退出页面后，当前评价会全部丢失，确认退出吗？':
    '¿Estás seguro? Si sales ahora, tu calificación no se guardará.',
  输入地址: 'Ingresa una dirección',
  家: 'Casa',
  公司: 'Oficina',
  设为常用地点: 'Marcar como ubicación frecuente',
  名字: 'Nombre',
  姓氏: 'Apellido(s)',
  电话号码: 'Nº de teléfono',
  请输入名字: 'Ingresa nombre',
  请输入姓氏: 'Ingresa apellido(s)',
  请输入电话号码: 'Ingresa el número de teléfono',
  已售罄: 'Agotado',
  添加: 'Agregar',
  必选: 'Obligatorio',
  休息中: 'CERRADA',
  '开始营业%s': 'Abre\\n%s',
  超出配送范围: 'Fuera del rango de entrega',
  营业中: 'Abierto',
  活动: 'Promociones',
  营业时间: 'Horario de operación',
  商家联系方式: 'Información de contacto',
  法规: 'Notificación',
  暂停配送: 'No disponible ahora',
  配送方式: 'Opción de entrega',
  商家自配送: 'Entrega de tienda',
  输入备注信息: 'Agregar comentarios',
  我的订单: 'Mis pedidos',
  暂无订单: 'Todavía no hay órdenes',
  查看购物车: 'Ver carrito',
  修改取餐人: 'Cambiar destinatario',
  已购菜品: 'Productos seleccionados',
  总计: 'Total',
  支付方式选择: 'Selecciona el método de pago',
  '公司/公寓名称': 'Nombre del empresa / departamento',
  '楼层/门牌号': 'Piso / Número de la calle',
  选择送餐地址: 'Selecciona una dirección',
  热门搜索: 'Búsquedas populares',
  最近搜过: 'Búsquedas recientes',
  '抱歉，未找到任何结果': 'No se encontraron resultados',
  个人资料: 'Mi perfil',
  编辑: 'Editar',
  帮助: 'Ayuda',
  邀请好友: 'Invitar amigos',
  我的收藏: 'Favoritos',
  使用条款及隐私政策: 'Términos de uso y política de privacidad',
  '网络或系统错误，请稍后重试。': 'Error de red o del sistema, inténtalo de nuevo más tarde.',
  '为保护隐私，他人无法看到您的真实电话号码':
    'Por motivos de privacidad, tu número real de teléfono no será visible para otros usuarios',
  将使用以下号码加密呼出: 'Se utilizará este número privado para realizar la llamada',
  呼叫: 'Llamar',
  订单跟踪: 'Seguimiento del pedido',
  重置: 'Restablecer',
  进行中的订单: 'Pedidos en curso',
  '确定删除地址吗？': '¿Eliminar dirección?',
  不能删除这个地址: 'No se puede eliminar.',
  请重试: 'Intenta de nuevo',
  多买多赠: 'Compra más, recibe más',
  礼物: 'Regalo',
  '您收到了订单了吗？': '¿Recibiste tu pedido?',
  每份: 'c/u',
  '操作太快啦，请稍后尝试': 'Demasiados intentos. Intenta de nuevo más tarde.',
  可定制: 'Personalizar',
  请添加地址: 'Agrega tu dirección',
  补充详细地址信息: 'Agrega detalles de tu dirección',
  '添加大楼名称以及门牌号，可以帮助配送员更快找你的送餐位置':
    'Para ayudar a que el repartidor pueda encontrarte más rápido, especifica el número de edificio y apartamento.',
  去添加: 'Agregar ahora',
  忽略: 'Omitir',
  '抱歉，您最多可加购15个菜品': 'Lo sentimos, solo puedes agregar hasta 15 productos en tu carro',
  购物车: 'Carrito',
  去结算: 'Pagar',
  '给骑手小费%s': 'Darle un agradecimiento al socio repartidor de %s',
  给配送员的备注: 'Notas para el socio repartidor',
  '最多选择%d个': 'Límite %d',
  '选择%d个': 'Seleccionar %d',
  '已获得%d个赠品': 'Recibiste %d productos gratis',
}
