import { cloneDeep } from 'lodash-es'

const COMP_LIST_TYPE = {
  item: 101, // 菜卡
}

/**
 * @desc 适配新版shopindex返回值
 * @api http://wiki.intra.xiaojukeji.com/pages/viewpage.action?pageId=1163386095
 * */
export const adapterCateInfo = (cateInfo = []) => {
  try {
    const newData = cloneDeep(cateInfo)
    newData.forEach(item => {
      if (item.compList?.length > 0) {
        const newCompList = []
        item.compList.forEach(compItem => {
          if (compItem?.type === COMP_LIST_TYPE.item) {
            newCompList.push({
              ...compItem.data,
            })
          }
        })
        item.items = newCompList
      }
    })
    return newData
  } catch (err) {
    return []
  }
}
