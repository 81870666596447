/**
 * @Author: renhaojie
 * @Date: 2021-08-03 12:00:00
 */
import api from '@/api'
import { debounce } from 'lodash-es'
import precision from '@/utils/precision'
import Vue from 'vue'
import { isStrategyShow } from '@/utils/baseUtil'
function getItemById(arr, mduId) {
  let item = null
  arr.forEach(_ => {
    if (_.mduId === mduId) {
      item = _
    }
  })
  return item
}

function getItemsAmount(arr) {
  return arr.map(_ => {
    return {
      mduId: _.mduId,
      amount: _.amount,
    }
  })
}

function moduleIdByItemId(arr, itemId) {
  let moduleId = ''
  arr.forEach(item => {
    if (item.itemId === itemId) {
      moduleId = item.mduId
    }
  })
  return moduleId
}

function temp(cb) {
  cb()
}

function transRevision(revision) {
  if (revision === undefined) {
    return revision
  }
  return Math.floor(Number(revision) / 100)
}

const BACKUP_AMOUNT = '_backupAmount'
const DELAY = 1000
const debounceRequest = debounce(temp, DELAY)
let LATEST_REQUEST_COUNT = 0

const state = {
  cartInfo: {},
  cartId: '',
  revision: '',
  prices: {},
  items: [],
  golbalbills: [],

  // 购物车loading态
  loading: false,
  // 全局购物loading
  allLoading: false,
}
const mutations = {
  setCartInfo(state, data) {
    state.cartInfo = data
    // 后端保留 revision 后两位，便于拓展
    state.cartId = data?.cartId
    state.revision = transRevision(data?.revision)
    state.prices = data?.prices
    state.items = data?.items
  },
  setTempItem(state, data) {
    const { amount, mduId } = data
    const item = getItemById(state.items, mduId)
    if (item[BACKUP_AMOUNT] === undefined) {
      item[BACKUP_AMOUNT] = item.amount
    }
    item.amount = amount
  },
  setRevision(state, data) {
    state.revision = data.revision
  },
  setLoading(state, data) {
    state.loading = data.loading
  },
  setAllLoading(state, data) {
    state.allLoading = data
  },
  setBillList(state, data) {
    state.golbalbills = data.bills
  },
}
const actions = {
  /**
   * @description: 新增/更新 购物车菜品
   * @param {type}
   * @return
   * @param params
   */
  async addOrUpdateItem({ commit, state }, params) {
    let res = {}
    try {
      res = await api.addOrUpdateItem(params)
    } catch (e) {
      return false
    }
    return res
  },
  /**
   * @description: 获取mini购物车数据
   * @param {type}
   * @return
   * @param params
   */
  async cartInfo({ commit, state }, { shopId }) {
    let res = {}
    try {
      res = await api.cartInfo({ shopId })
      res = precision.transCartInfo(res)
      commit('setCartInfo', res)
    } catch (e) {
      return false
    }
    return res
  },
  /**
   * @description: 添加／减少数量
   * @param {type}
   * @return
   * @param params
   */
  async setItemsAmount({ commit, state }, params) {
    const { amount, mduId } = params
    commit('setLoading', { loading: true })
    commit('setTempItem', { amount, mduId })
    // 并发请求下，只保留匹配一致的结果
    ++LATEST_REQUEST_COUNT

    debounceRequest(async () => {
      const CURRENT_REQUEST_COUNT = LATEST_REQUEST_COUNT
      // 只要发出请求，就更新 revision
      let { cartId, revision } = state
      revision += 1
      commit('setRevision', { revision })
      const content = getItemsAmount(state.items)
      const params = {
        cartId,
        content,
        revision,
      }
      let res = {}
      try {
        res = await api.setItemsAmount(params)
        res = precision.transCartInfo(res) || {}
        if (CURRENT_REQUEST_COUNT === LATEST_REQUEST_COUNT) {
          commit('setCartInfo', res)
          commit('setLoading', { loading: false })
        }
        // 特价菜提示
        if (res?.toast?.content) {
          const show = isStrategyShow('special_cate_limit', res?.toast?.strategy)
          show && Vue.prototype.$message.warning(res?.toast?.content)
        }
      } catch (e) {
        if (CURRENT_REQUEST_COUNT === LATEST_REQUEST_COUNT) {
          // 更新异常提示
          // 恢复数据 直接调用cartInfo接口 shopId
          commit('setLoading', { loading: false })
        }
      }
    })
  },
  async reduceAmountToZero({ commit, state }, params) {
    // itemId与nodeId取值相等
    const { amount, nodeId } = params
    // 通过itemId获取mduId
    const mduId = moduleIdByItemId(state.items, nodeId)
    commit('setLoading', { loading: true })
    let { cartId, revision } = state
    revision += 1
    commit('setRevision', { revision })

    const content = [{ mduId, amount }]
    const requestParams = {
      cartId,
      content,
      revision,
    }
    let res = {}
    try {
      res = await api.setItemsAmount(requestParams)
      res = precision.transCartInfo(res)
      commit('setCartInfo', res)
      commit('setLoading', { loading: false })
    } catch (e) {
      commit('setLoading', { loading: false })
    }
  },
  /**
   * @description: 获取全局购物车数据
   * @param {type}
   * @return
   * @param params
   */
  async golbalBillList({ commit, state }, params) {
    let res = {}
    try {
      commit('setAllLoading', true)
      res = await api.golbalBillList(params)
      res = precision.transBillList(res)
      commit('setBillList', res)
      commit('setAllLoading', false)
    } catch (e) {
      return false
    }
    return res
  },

  /**
   * @description: 删除全局购物车数据
   * @param {type}
   * @return
   * @param params
   */
  async golbalCartClear({ commit, state }, params) {
    let res = {}
    try {
      commit('setAllLoading', true)
      res = await api.golbalCartClear(params)
      commit('setAllLoading', false)
    } catch (e) {
      return false
    }
    commit('setAllLoading', false)
    return res
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
