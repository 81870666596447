<template>
  <div class="left-box" v-if="title || headImg">
    <div class="center-box">
      <img
        v-if="headImg"
        v-lazy="headImg"
        data-width="750"
      />
      <div class="item-hide" v-if="title">
        <div class="text-box">
          <p v-text="title"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CateHeadImg',
  props: {
    title: String,
    headImg: String,
  },
}
</script>

<style lang="scss" scoped >
.left-box {
  flex: 1;
}
.left-box {
  position: relative;
  font-size: 0;
  text-align: center;
}
.center-box {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 368px;
  height: 368px;
}
.center-box img{
  width: 100%;
  height: 100%;
  border-radius: 8px;
  object-fit: cover;
  text-align: center;
}
.item-hide{
  position: absolute;
  top:0;
  left:0;
  padding: 0 10px;
  display: flex;
  width: 100%;
  height: 100%;
  // line-height: 18px;
  text-align: center;
  font-family: 'Aspira Medium';
  font-weight: 500;
  font-size: 16px;
  color: #fff;
  justify-content: center;
  align-items: center;
  background: rgba(0,0,0,0.50);
  border-radius: 8px;
}
.item-hide .text-box {
  padding: 8px;
  color: rgba(0,0,0,0.70);
  background: #fff;
  border-radius: 3px;
}
@media screen and (max-width: 768px){
  .center-box {
    position: relative;
    width: 100%;
    height: rem(562);
  }
  .center-box img {
    border-radius: rem(18) rem(18) 0 0;
  }
  .item-hide {
    line-height: rem(32);
    font-size: rem(28);
    border-radius: rem(18) rem(18) 0 0;
  }
}
</style>