import { API_EVENT_CONFIG } from './apiEventConf'

/** @desc 接口 => recId 映射关系  */
export const API_RECID_MAP = {
  // 'api' : recId(api接口中的recId)
}

/** @desc 埋点 => api 对象映射表 */
export const API_EVENT_MAP = {
  // 'sailing_c_xxx': FEED_INDEX_V2
}

const keys = Object.keys(API_EVENT_CONFIG)

keys.forEach(key => {
  API_EVENT_CONFIG[key].forEach(item => {
    API_EVENT_MAP[item] = key
  })
})
