export default {
  '你最多可以下单MX$900.00的货到付款单':
    'A opção “Pagar na entrega” está disponível para pedidos até MX$900.00',
  联系我们: 'Entre em contato conosco',
  '简单操作，快速下单，支付便捷，支持多种方式支付，足不出户享受美食。':
    'Com nossa interface simples, é fácil e rápido fazer um pedido. A 99Food aceita vários métodos de pagamento para a facilitar as compras. Receba sua próxima refeição diretamente em sua casa.',
  '海量餐厅，多样菜品。你可以在熟悉的餐厅订餐，也能在新餐厅发现新美食，探索更多美味。':
    'A 99Food oferece uma ampla variedade de estabelecimentos e muitas opções de pratos. Peça sua próxima refeição em um de seus restaurantes preferidos ou conheça novos. Todas as opções da cidade em seu cardápio.',
  姓名: '姓名',
  成为合作餐厅: 'Abrir uma loja na 99Food',
  公司合作: 'Seja nosso parceiro',
  '成为滴滴外卖合作餐厅，轻松获得更多订单，更多顾客，让生意赚更多。':
    'Seja um parceiro da DiDi Food para atrair mais clientes e ampliar seus negócios.',
  一键享美食: 'Seu prato predileto a apenas um toque de distância.',
  '加入滴滴配送团队，大量订单，多种补贴奖励，自由选择上线时间，电动车、自行车都能配送，高收入高回报。':
    'Faça parte da equipe de entrega da 99Food e entregue pedidos com seu patinete ou bicicleta. Você pode escolher seus próprios horários e ter várias recompensas e vantagens, além de ganhos e rendimentos altos.',
  加入滴滴配送: 'Faça parte da equipe de entrega da 99Food',
  特定商取引法: '特定商取引法',
  下载滴滴外卖App: 'Baixe o aplicativo DiDi Food.',
  电话: 'Telefone',
  快捷订餐: 'Pedidos sem complicação',
  关注我们: 'Siga a DiDi Food',
  快速送达: 'Entrega rápida',
  关于滴滴外卖: 'Sobre a 99Food',
  公司信息: 'Informações da empresa',
  成为滴滴骑手: 'Seja um entregador parceiro 99Food',
  成为滴滴商户: 'Seja uma DiDi Store',
  查看详情: 'Saiba mais',
  '专业的配送团队和配送设备，确保每一份美食以最快的速度完好送达。':
    'Equipamentos e equipes de entrega profissionais garantem que todos os pedidos sejam entregues rapidamente e cheguem sempre intactos.',
  查看更多: 'Ver',
  更多美味: 'Variedade de opções',
  最新消息: '最新消息',
  '3. 您下单时通过现金支付，如退款申请通过，退款金额将以优惠券的形式退还到您的钱包。':
    '3. Se você pagou seu pedido em dinheiro e está qualificado para receber o reembolso, ele será emitido para você na forma de um cupom adicionado à sua Carteira.',
  '您的订单还未完成，您可以联系骑手确认当前的具体配送位置。':
    'Você tem um pedido em aberto que ainda não foi entregue. Caso precise verificar a localização atual do pedido, entre em contato com o entregador parceiro.',
  您所在的地区还未开通服务: 'O serviço ainda não está disponível na sua região',
  取消订单进度: 'Status do cancelamento',
  '4、以下您方原因导致订单超时送达或无法送达的，将不会获得赔付：':
    '4. Os pedidos atrasados ou não entregues não darão direito a compensação nos seguintes casos:',
  跳过: 'Ignorar',
  '个人中心的“优惠券”': '“Conta > Cupons”.',
  商家正在备餐: 'A loja está preparando o pedido',
  再等等: 'Agora não',
  申请信息: 'Detalhes da solicitação',
  骑手正在配送中: 'Entregador parceiro a caminho',
  描述: 'Descrição',
  '5、每位用户每天最多赔付6张优惠券；':
    '5. A compensação é limitada a 6 cupons por cliente, por dia.',
  无效券: 'Cupons inválidos',
  不使用优惠券: 'Não usar o cupom',
  订单: 'Pedidos',
  请上传商品和小票照片: 'Envie fotos dos itens e o recibo do pedido',
  订单取消成功: 'Pedido cancelado.',
  进度详情: 'Status da solicitação',
  使用规则: 'Termos de uso',
  反馈超时: 'Espaço para solicitação expirado',
  '(1) 您要求更换收获地址、您提交的地址错误、联系不上您或无人收货、拒收等导致订单超时送达或无法送达的；':
    '(1) Se você solicitar uma mudança no endereço de entrega, se o endereço fornecido estiver incorreto, se não conseguirmos encontrar você, se não houver ninguém para receber o pedido ou se você recusar o recebimento, e qualquer uma dessas situações resultar em atraso ou impossibilidade de entrega.',
  暂无优惠券: 'Nenhum cupom',
  联系骑手: 'Entrar em contato com o entregador',
  关闭: 'Encerrado',
  提交: 'Enviar',
  订单尚未完成: 'Há um pedido em aberto',
  请选择有问题的商品: 'Qual item veio errado?',
  提交申请: 'Solicitar cancelamento',
  取消: 'Cancelar',
  请选择取消原因以便商家更快帮您处理:
    'Para que a loja processe sua solicitação mais rápido, selecione o motivo do cancelamento.',
  钱包: 'Carteira',
  退款金额: 'Valor do reembolso',
  '6、每位用户每天最多赔付6张优惠券；':
    '6. A compensação é limitada a 6 cupons por cliente, por dia.',
  取消订单: 'Cancelar pedido',
  输入兑换码: 'Insira o código promocional',
  展开: 'Ver mais',
  '请您上传所收到的全部商品照片、问题商品细节照片、商品小票照片，这将协助我们更快地处理您的问题。':
    'Para nos ajudar a processar sua solicitação mais rapidamente, envie fotos de: todos os itens que você recebeu; fotos com foco nos itens com problema; recibo dos itens.',
  更多评价: 'Mais avaliações',
  详细说明: 'Detalhes',
  '4、部分时间段下单暂不参加赔付；':
    '4. Pedidos realizados durante determinados horários não dão direito a compensação;',
  提交反馈: 'Enviar comentários',
  帮助中心: 'Ajuda',
  '7、请您对骑手的送达时间进行监督，如遇骑手提前点击送达而实际未能按照约定时间送达的，请您拨打%s客服电话%s进行投诉。':
    '7. Atente-se ao prazo indicado pelo entregador parceiro. Se um entregador parceiro alegar ter entregue um pedido antes de você recebê-lo, ligue para o Atendimento ao Cliente da %s (%s) para registrar uma reclamação.',
  '6、请您对骑手的送达时间进行监督，如遇骑手提前点击送达而实际未能按照约定时间送达的，请您拨打%s客服电话%s进行投诉。':
    '6. Atente-se ao prazo indicado pelo entregador parceiro. Se um entregador parceiro alegar ter entregue um pedido antes de você recebê-lo, ligue para o Atendimento ao Cliente da %s (%s) para registrar uma reclamação',
  '对处理结果不满意？': 'Insatisfeito?',
  '2、超时赔付服务由平台为用户提供，用户没有支付任何费用，若订单取消则服务取消。':
    '2. Os cupons são oferecidos sem custo adicional. Entretanto, se você cancelar um pedido, não terá direito a recebê-los.',
  '请告诉我们，帮助我们一起改善': 'Entre em contato e ajude-nos a melhorar',
  申请取消原因: 'Motivo do cancelamento',
  查看评价: 'Ver avaliações',
  收起: 'Ver menos',
  '5、以下您方原因导致订单超时送达或无法送达的，将不会获得赔付：':
    '5. Os pedidos atrasados ou não entregues não darão direito a compensação nos seguintes casos:',
  优惠券: 'Cupons',
  退款渠道: 'Forma de reembolso',
  '(2) 因您的配送地址所在地不允许骑手入内，导致超时送达或无法送达的；':
    '(2) Se o endereço que você fornecer não permitir a entrada de entregadores-parceiros e isso resultar em atraso ou impossibilidade de entrega.',
  配送费: 'Taxa de entrega',
  联系客服: 'Entre em contato conosco',
  联系客服进行申诉: 'Contate o Atendimento ao Cliente',
  确认: 'Aprovar',
  超时赔付规则说明: 'Compensação por atraso na entrega',
  '已发起取消，等待商家确认':
    'Solicitação de cancelamento enviada. Aguardando confirmação da loja.',
  '3、恶劣天气（雨、雪等）暂不参加赔付；':
    '3. Atrasos causados por mau tempo (chuva, etc.) não dão direito a compensação.',
  兑换优惠: 'Resgatar cupom',
  '您为什么取消？': 'Por que você cancelou?',
  '(3) 其他因您的原因导致骑手超时送达或无法送达的。':
    '(3) Se quaisquer outros fatores atribuíveis diretamente a você causarem um atraso ou impedirem que o entregador parceiro faça a entrega.',
  '（必填）请填写您的申诉原因': '(Obrigatório) Especifique o motivo da reclamação',
  '2、部分时间段下单暂不参加赔付；':
    '2. Pedidos realizados durante determinados horários não dão direito a compensação;',
  骑手没有配送我的订单: 'O entregador parceiro não entregou meu pedido',
  '3、您因超时赔付服务获得的优惠券，将发放至您的%s账户，请在':
    '3. Os cupons por entrega atrasada serão adicionados à sua conta da %s após a conclusão do pedido. Você pode vê-los acessando ',
  骑手已到达: 'O entregador parceiro chegou',
  等待商家接单: 'Aguardando confirmação do estabelecimento',
  '1、超时赔付服务是%s为您免费提供的服务，延迟送达时间及赔付优惠券金额如下：':
    '1. Compensar você por uma entrega atrasada é a nossa maneira de pedir desculpas. Dependendo do tempo de atraso, emitiremos um ou mais cupons gratuitos para você:',
  '您的订单完成已经超过24小时，无法自助申请，如您有问题可联系客服，给您带来不便深表歉意。':
    'Como seu pedido foi concluído há mais de 24 horas, você não pode mais fazer uma solicitação de reembolso direto. Se houver dúvidas, entre em contato com o Atendimento ao Cliente. Pedimos desculpas por qualquer inconveniente.',
  商家拒绝取消申请: 'Solicitação de cancelamento recusada pela loja',
  联系商家: 'Entrar em contato com o estabelecimento',
  '1. 部分商品退款时，该金额为您申请的商品分摊优惠后总金额，配送费不退还。 2. 全部退款时，您的付款金额将全部退还。如您下单时使用过优惠券，优惠券将退还到您的钱包。':
    '1. Para reembolsos parciais, o valor será igual ao total dos itens do seu pedido para os quais você solicitou reembolso, menos os descontos de cupom proporcionais. A taxa de entrega não é reembolsável. 2. Para reembolso total, seu pagamento será reembolsado integralmente. Se você usou cupons no seu pedido, eles serão devolvidos à sua Carteira. ',
  中查看: ' ',
  正在寻找骑手: 'Encontrando um entregador',
  '网络异常，请稍后再试': 'Erro de conexão, tente novamente mais tarde',
  '优惠券使用门槛及有效期详见券面提示，未在券有效期限内使用完毕的，不可继续使用':
    'O uso do cupom e os detalhes do período de validade são exibidos no próprio cupom. Não é possível usar cupons após o período de validade ',
  将优惠分享到: 'Compartilhar promoção com',
  登录: 'Entrar',
  '单笔订单金额满足优惠券使用门槛金额时方可使用，单笔订单只能使用一张优惠券，使用优惠后，如订单金额超出部分需用户自行支付':
    'É possível usar um cupom quando um pedido estiver dentro de seu valor limite. Só é possível usar um cupom por pedido. Qualquer quantia que ultrapasse o valor do cupom deverá ser paga pelo cliente',
  规则详情: 'Regras detalhadas',
  '您邀请的好友需通过%s下单，通过其他渠道下单不发放邀请奖励':
    'O amigo que você convidar precisa fazer um pedido pelo aplicativo %s. Pedidos feitos por outros canais não atenderão ao requisito para o recebimento de uma recompensa por convite',
  待入账: 'Pendente',
  活动时间: 'Hora da promoção',
  '添加信用卡/借记卡': 'Adicionar cartão de débito/crédito',
  券包: 'cupom',
  兑换成功: 'Cupom resgatado',
  支付方式: 'Forma de pagamento',
  '支付超时！': 'O tempo limite do pagamento expirou!',
  '奖励会在邀请成功后的48小时内发放入您的账号，您可在%s-钱包-券中查看':
    'As recompensas serão enviadas à sua conta em até 48 horas após um convite bem-sucedido. Para vê-las, abra seu aplicativo %s e acesse “Carteira > Cupons”',
  复制成功: 'Copiado',
  免配送费: 'Grátis',
  支付: 'Pagar',
  '一经发现任何违规、套现行为，将追回相关金额或者封停账号，且依法追究其法律责任':
    'Qualquer violação das regras ou tentativa de descontar um cupom pode resultar na dedução da quantia em questão ou na suspensão da conta. Os responsáveis pela violação também podem ser implicados juridicamente',
  重试: 'Tentar novamente',
  '本优惠活动仅限%s[在线支付]的订单享受，不可提现转赠':
    'Esta promoção só está disponível para pedidos da %s [pagamento online]. Não é possível transferi-la ou trocá-la por dinheiro',
  通过SNS邀请: 'Compartilhar nas redes sociais',
  分钟: 'Min',
  请选择支付方式: 'Selecione um método de pagamento',
  我的奖励: 'Minhas recompensas',
  待入账奖励: 'Recompensas pendentes',
  卡类型: 'Tipo de cartão',
  '您邀请的好友必须是%s新用户，每人仅限享受1次新用户首单优惠，同一手机号、同一设备、同一支付账号视为同一用户':
    'O amigo que você convidar precisa ser um cliente novo da %s. Cada pessoa só pode receber um “Desconto de primeiro pedido para cliente novo”. O mesmo número de telefone, o mesmo dispositivo e a mesma conta para pagamento são considerados como o mesmo cliente',
  收餐人手机号与领取券时输入的手机号和账号注册手机号必须为同一号码:
    'O número de telefone do destinatário do pedido, o número inserido após a reivindicação do cupom e o número usado para registrar a conta devem ser iguais',
  已过期: 'Expirado',
  '本优惠活动仅限[%s]开通%s区域':
    'Esta promoção só está disponível em áreas em [%s] onde a %s estiver disponível',
  确定: 'Confirmar',
  已获得奖励: 'Recompensas ganhas',
  条款和条件: 'termos e Condições',
  '关于本活动的任何问题，请联系%s客服':
    'Se você tiver dúvidas sobre esta promoção, entre em contato com o serviço de Atendimento ao Cliente da %s no número ',
  '邀请好友成为%s新用户，好友领取您送出的红包，并在活动时间截止前完成订单且无退款，无论是否使用领取的红包下单，您均可获得%s[优惠券]奖励':
    'Convide um amigo para que ele se torne um novo cliente %s e possa resgatar um cupom enviado por você. Se ele fizer um pedido antes do término da promoção e não pedir o reembolso (ele pode usar seu cupom ao fazer o pedido ou não), você pode receber %s [Cupons] Recompensas',
  '随时随地\\n享受\\n美好食物':
    'Aprecie pratos deliciosos\\na qualquer momento\\ne em qualquer lugar',
  公司: 'Escritório',
  家: 'Casa',
  设为常用地点: 'Definir como uma localização favorita',
  输入地址: 'Insira um endereço',
  输入备注信息: 'Adicionar observações',
  配送方式: 'Opção de entrega',
  商家自配送: 'Entrega da loja',
  休息中: 'FECHADO',
  '开始营业%s': 'Abre às \\n%s',
  营业中: 'Aberto',
  活动: 'Promoções',
  地址: 'Endereço',
  营业时间: 'Horário de funcionamento',
  商家联系方式: 'Informações de contato',
  法规: 'Notificação',
  暂停配送: 'Indispon. no momento.',
  呼叫: 'Ligar',
  将使用以下号码加密呼出: 'Este número anônimo será usado para fazer sua ligação',
  修改取餐人: 'Alterar',
  已购菜品: 'Itens Selecionados',
  名字: 'Nome',
  请输入名字: 'Insira o nome',
  姓氏: 'Sobrenome',
  请输入姓氏: 'Insira o sobrenome',
  电话号码: 'Nº de telefone',
  请输入电话号码: 'Insira um nº de telefone',
  使用条款及隐私政策: 'Termos de Uso e Política de Privacidade',
  下单: 'Fazer este pedido',
  收货地址: 'Endereço de entrega',
  '未找到匹配地址，请尝试搜索。':
    'Nenhum endereço correspondente encontrado. Use a função de pesquisa.',
  添加: 'Adicionar',
  更多: 'Mais',
  寻找餐厅和美食: 'Encontre restaurantes e pratos',
  查看全部: 'Ver tudo',
  必选: 'Obrigatório',
  已售罄: 'ESGOTADO',
  更新: 'Avalie nosso serviço',
  暂无订单: 'Ainda não há nenhum pedido',
  我的订单: 'Meus pedidos',
  超出配送范围: 'Fora da área de entrega',
  '公司/公寓名称': 'Número da casa/prédio',
  '楼层/门牌号': 'Complemento',
  选择送餐地址: 'Endereço de entrega',
  支付方式选择: 'Selecione a forma de pagamento',
  总计: 'Total',
  网络异常: 'Erro de rede',
  请检查网络连接: 'Verifique sua conexão de rede',
  试试减少筛选条件: 'Tente usar menos filtros',
  查看订单详情: 'Detalhes do pedido',
  券: 'Cupons',
  商家券: 'Coupons da Loja',
  订单跟踪: 'Rastreamento do pedido',
  小费: 'Gorjeta',
  支付失败: 'Pagamento recusado',
  '您有一个相同的订单正在处理中，请避免重复下单。':
    'Você já tem um pedido exatamente igual em andamento! Tem certeza que você quer duplicar o pedido?',
  查看当前订单: 'Exibir o pedido existente',
  '网络或系统错误，请稍后重试。': 'Erro de sistema ou rede. Tente novamente mais tarde.',
  '为保护隐私，他人无法看到您的真实电话号码':
    'Para proteger sua privacidade, seu número de telefone real não pode ser visto por outras pessoas',
  编辑: 'Editar',
  个人资料: 'Meu perfil',
  '您对商家满意吗？': 'Como este estabelecimento se saiu?',
  '您对骑手满意吗？': 'Você ficou satisfeito com o entregador parceiro?',
  商家评价: 'Avaliação do estabelecimento',
  骑手评价: 'Avaliação do entregador parceiro',
  未评价: 'Não avaliado',
  '退出页面后，当前评价会全部丢失，确认退出吗？':
    'Tem certeza? Se sair agora, sua avaliação não será salva.',
  最近搜过: 'Pesquisado recentemente',
  '抱歉，未找到任何结果': 'Nenhum resultado encontrado',
  热门搜索: 'Buscas frequentes',
  退出登录: 'Sair',
  帮助: 'Ajuda',
  邀请好友: 'Indique amigos',
  我的收藏: 'Favoritos',
  查看购物车: 'Ver carrinho',
  '确定要清除缓存吗？': 'Limpar cache?',
  提示: 'Notificação',
  '确认退出吗？': 'Tem certeza de que deseja sair?',
  注销: 'Excluir',
  重置: 'Redefinir',
  进行中的订单: 'Ordens em curso',
  '确定删除地址吗？': 'Deletar endereço?',
  不能删除这个地址: 'Impossível deletar',
  请重试: 'Tente de novo',
  多买多赠: 'Compre mais, ganhe mais',
  礼物: 'Brinde',
  '您收到了订单了吗？': 'Você recebeu seu pedido?',
  每份: 'cada',
  '操作太快啦，请稍后尝试': 'Muitas tentativas. Tente novamente mais tarde.',
  可定制: 'Personalizar',
  请添加地址: 'Adicione seu endereço',
  补充详细地址信息: 'Adicionar detalhes do endereço',
  '添加大楼名称以及门牌号，可以帮助配送员更快找你的送餐位置':
    'Para que o entregador parceiro encontre você mais rápido, especifique o número do seu prédio e apartamento.',
  去添加: 'Adicionar',
  忽略: 'Pular',
  '给骑手小费%s': 'Dar %s de gorjeta ao entregador parceiro',
  '抱歉，您最多可加购15个菜品': 'Você só pode adicionar até 15 itens ao seu carrinho',
  购物车: 'Carrinho',
  去结算: 'Ver sacola',
  给配送员的备注: 'Observações para o(a) entregador(a) parceiro(a)',
  '最多选择%d个': 'Limite: %d',
  '选择%d个': 'Selecionar %d',
  '已获得%d个赠品': 'Ganhou %d item(ns) gratuito(s)',
}
