<template>
  <div class="coupon-code">
    <el-floating
      :visible.sync="showCode"
      :width="'470px'"
      :height="'200px'"
      :top="'30vh'"
      v-if="!isH5"
      custom-class="code-popup-floating el-floating_d-reset"
      :title="i18n.t('兑换优惠')"
      @close="closeDrawer"
      :appendToBody="false"
    >
      <!-- <el-input
        :placeholder="i18n.t('输入兑换码')"
        v-model="codeValue"
        clearable
        class="code-value el-input_d-reset"
      >
      </el-input>
      <div class="info-btn">
        <el-button
          @click="confirmClick"
          :loading="loading"
          :disabled="disabled"
          >{{ i18n.t('确认') }}</el-button
        >
      </div> -->

      <DownloadApp
        class="down-load"
        :text="$t('FoodC_Website_At_present_XpFw')"
      />
      <div class="info-btn">
        <el-button @click="closeDrawer">{{ i18n.t('确认') }}</el-button>
      </div>
    </el-floating>
    <DrawerPopup
      v-if="isH5"
      size="230px"
      :show="showCode"
      :appendToBody="false"
      :title="i18n.t('兑换优惠')"
      @close="closeDrawer"
      :disabled="false"
      :bottomName="i18n.t('确认')"
      :bottomShow="true"
      @bottom-click="closeDrawer"
    >
      <!-- <el-input
        :placeholder="i18n.t('输入兑换码')"
        v-model="codeValue"
        class="code-value el-input_d-reset"
        :loading="loading"
      >
      </el-input> -->
      <DownloadApp
        class="down-load"
        :text="$t('FoodC_Website_At_present_XpFw')"
      />
    </DrawerPopup>
  </div>
</template>

<script>
import DrawerPopup from '@/components/DrawerPopup';
import DownloadApp from '@/components/DownloadApp';
import i18n from '@/i18n/locale';
import { mapState, mapActions, mapMutations } from 'vuex';

import passportMonitor from '@/utils/passportMonitor';
import { getLocalStorage } from '@/utils/localStorage';

const SHARE_TYPE = 'share';

export default {
  name: 'CodePopup',
  components: {
    DrawerPopup,
    DownloadApp
  },
  props: {
    value: {
      type: Boolean,
      default: true,
    },
    isCart: {
      type: Boolean,
      default: false,
    },
    // 类型
    type: {
      type: String,
      default: '',
    },
    // 分享Code
    shareCode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      showCode: true,
      disabled: true,
      codeValue: '',
      loading: false,
      i18n: i18n,
      isSuccess: false,
      messageInfo: '', // 提示信息
    };
  },
  computed: {
    ...mapState({
      isH5: ({ common }) => common.isH5,
    }),
  },
  watch: {
    value(val) {
      this.showCode = val;
    },
    showCode(val) {
      this.$emit('input', val);
    },
    codeValue(val) {
      this.disabled = !val;
    },
    shareCode: {
      handler(n) {
        this.codeValue = n;
      },
      immediate: true,
    },
  },
  methods: {
    ...mapActions('coupon', ['cartRedeem', 'sidebarExchange']),
    ...mapMutations('coupon', ['setCodeValue']),
    async submitCode() {
      // 登录校验
      if (!getLocalStorage('isLogin')) {
        this.closeDrawer();
        passportMonitor.login();
        return;
      }
      // 兑换逻辑
      this.loading = true;
      let data = {
        code: this.codeValue,
        enterTime: new Date().getTime(),
        ua: window.navigator.userAgent,
        channelNo: 2, // 1 代表嵌入端 2 代表独立端
      };

      try {
        let res;
        if (this.isCart) {
          await this.cartRedeem(data);
        } else {
          res = await this.sidebarExchange(data);
        }

        const messageInfo = (res && res?.bannerContent) || i18n.t('兑换成功');
        this.messageInfo = messageInfo;

        if (this.type !== SHARE_TYPE) {
          this.$message.success(messageInfo);
        }

        this.closeDrawer();
        this.isSuccess = true;
      } catch (error) {
        this.isSuccess = false;
        this.$utils.errorToast(error.errmsg);
      }
      this.loading = false;
    },
    closeDrawer() {
      this.showCode = false;
      this.loading = false;
      this.codeValue = '';
    },
    async confirmClick() {
      // 兑换券点击
      await this.submitCode();

      if (this.isSuccess) {
        this.confirmClick2({ message: this.messageInfo });
      }
    },
  },
};
</script>
<style>
.coupon-code .el-floating_d-reset .el-floating__body_height {
  height: 165px;
}
</style>
<style lang="scss" scoped>
.info-btn {
  padding: 12px 24px 20px 0;
  margin-top: 12px;
  display: flex;
  justify-content: flex-end;
  .el-button {
    min-width: 140px;
  }
}
.title {
  font-family: 'Aspira Light';
  font-size: 12px;
  margin-top: 8px;
  cursor: pointer;
}
</style>
<style lang="scss">
.code-popup-floating {
  height: 240px !important;
}
.code-value {
  margin: 10px 24px;
  width: 422px;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  .el-input__inner {
    border: none;
  }
}
.down-load {
  padding: 20px 40px;
  font-size: 32px;
}
@media screen and (max-width: 768px) {
  .code-value {
    margin: rem(20) rem(24);
    width: rem(702);
    border: 1px solid #e5e5e5;
    border-radius: 5px;
    .el-input__inner {
      border: none;
    }
  }
}
</style>