import {
  errnoDataMiddleware,
  // errnoMiddleware,
  // loadingMiddleware,
  networkErrorMiddleware,
  getRecIdMiddleware,
} from './middlewares';

export function applyPromiseMiddleware(middlewares = []) {
  return (method, ...params) => {
    return middlewares.reduce((response, middleware) => {
      return middleware(
        {
          method,
          params,
        },
        response
      );
    }, method.apply(null, params));
  };
}

// export const requestHelper = applyPromiseMiddleware([
//   networkErrorMiddleware,
//   errnoMiddleware,
// ]);
export const requestDataHelper = applyPromiseMiddleware([
  networkErrorMiddleware,
  errnoDataMiddleware,
  getRecIdMiddleware,
]);
// export const requestLoadingHelper = applyPromiseMiddleware([
//   networkErrorMiddleware,
//   loadingMiddleware,
//   errnoMiddleware,
// ]);
// export const requestDataLoadingHelper = applyPromiseMiddleware([
//   networkErrorMiddleware,
//   loadingMiddleware,
//   errnoDataMiddleware,
// ]);
