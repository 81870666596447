/**
 * 收敛stroefont 相关方法类
 * stroefont链接示例：`https://www.didi-food.com/${locale}/food/store/:shopName?/:storeId?/?cityId=&promotionChannel=SF`
 */

import router from '@/router'

export const storeShopSecret = 'storeShopSecret'
export const storeShopId = 'storeShopId'
export const shopInfoPoi = 'shopInfoPoi'

class LS {
  set(key, value) {
    return localStorage?.setItem(key, value)
  }

  get(key) {
    return localStorage?.getItem(key)
  }

  del(key) {
    return localStorage?.removeItem(key)
  }
}

const ls = new LS()
class StoreRouterChange {
  constructor() {
    this.storage = ls
  }

  watchRouterChange() {
    const storageStr = this.storage.get(storeShopSecret)
    /** 商家存在shopName/shopId存储url **/
    if (this.getShopSecret()) {
      this.setStorage(this.getShopSecret())

      /** 商家不存在shopName/shopId，从缓存内取 **/
    } else if (storageStr) {
      this.setQuery(storageStr)
    } else {
      /** 返回无效商家地址 **/
    }
  }

  setQuery(str, restQuery) {
    const currentHash = router?.app?._route?.hash || ''
    const shopSecret = this.storage.get(storeShopSecret)
    router
      .replace({
        path: `/store/${shopSecret}`,
        hash: currentHash,
      })
      .catch({})
  }

  setStorage(str) {
    this.storage.set(storeShopSecret, str)
  }

  getShopSecret() {
    const match = window.location?.href?.match(/\/store\/([^/]+)\/([^/]+)/)
    if (match?.[1] && match?.[2]) {
      return `${match?.[1]}/${match?.[2]?.slice(0, 6)}`
    }
    return null
  }

  getShopTicket() {
    const storageStr = this.storage.get(storeShopSecret)
    if (storageStr) {
      const arr = storageStr.split('/')
      if (Array.isArray(arr) && arr.length > 1) {
        return arr[1]?.slice(0, 6)
      }
    }
    return ''
  }
}

const storeRouterChange = new StoreRouterChange()
export default storeRouterChange
