import i18n from '@/i18n/locale'

export default [
  // 退款进度详情页
  {
    path: '/order/refundDetail',
    meta: {
      title: '退款进度详情页',
    },
    component: () =>
      import(
        /* webpackChunkName: "RefundDetail" */
        '@/views/Order/RefundDetail/index.vue'
      ),
  },
  // 首页
  {
    path: '/order/missOrder',
    meta: {
      title: '整单未收到',
    },
    component: () =>
      import(
        /* webpackChunkName: "order" */
        '@/views/Order/MissOrder/index'
      ),
  },
  {
    path: '/order/feedback',
    meta: {
      title: '少错餐',
    },
    component: () =>
      import(
        /* webpackChunkName: "order" */
        '@/views/Order/Feedback/index'
      ),
  },
  {
    path: '/order/ruleEducation',
    meta: {
      title: '规则说明页',
    },
    component: () =>
      import(
        /* webpackChunkName: "order" */
        '@/views/Order/Education/ruleEducation'
      ),
  },
  {
    path: '/order/reparation',
    meta: {
      title: '慢必赔页面',
    },
    component: () =>
      import(
        /* webpackChunkName: "order" */
        '@/views/Order/Reparation/index'
      ),
  },
  {
    path: '/order/cancelOrder',
    name: 'CancelOrder',
    meta: {
      title: 'cancelOrder',
    },
    component: () =>
      import(
        /* webpackChunkName: "order" */
        '@/views/Order/CancelOrder/index'
      ),
  },
  {
    path: '/order/cancelOrder/reason',
    name: 'CancelOrderReason',
    meta: {
      title: 'cancelOrder', // 取消理由选择页
    },
    component: () =>
      import(
        /* webpackChunkName: "order" */
        '@/views/Order/CancelOrder/Reason/index'
      ),
  },
  {
    path: '/order/cancelOrder/result',
    name: 'CancelOrderResult',
    meta: {
      title: 'cancelOrder', // 取消理由选择页
    },
    component: () =>
      import(
        /* webpackChunkName: "order" */
        '@/views/Order/CancelOrder/Result/index'
      ),
  },
  {
    path: '/order/appealToService',
    name: 'appealToService',
    meta: {
      title: 'appealToService',
    },
    component: () =>
      import(
        /* webpackChunkName: "order" */
        '@/views/Order/Feedback/AppealToService'
      ),
  },
  // 订单页面
  {
    path: '/orderDetail',
    name: 'orderDetail',
    meta: {
      title: i18n.t('查看订单详情'),
      keepAlive: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "order" */
        '@/views/OrderDetail/index'
      ),
  },
  // 首页
  {
    path: '/order',
    name: 'order',
    meta: {
      title: '订单列表',
      keepAlive: false,
    },
    component: () =>
      import(
        /* webpackChunkName: "order" */
        '@/views/Order/index'
      ),
  },
]
