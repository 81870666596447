<template>
  <div>
    <!-- 当icon有多个时候 -->
    <template v-if="data?.iconUrls">
      <div v-for="(item, index) in data?.iconUrls" :key="index" class="rich-iconUrls">
        <img :src="item" :alt="item" :width="data.width" :height="data.height" />
      </div>
    </template>
    <template v-if="data.iconUrl">
      <img :src="data.iconUrl" :alt="data.iconUrl" :width="data.width" :height="data.height" />
    </template>
  </div>
</template>

<script>
export default {
  name: 'TextPart',
  props: {
    // eslint-disable-next-line vue/require-default-prop
    data: Object,
  },
}
</script>
