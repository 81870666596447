/**
 * 客户端 和 前端 富文本中颜色兼容处理
 * argb 转 rgba 只适用于HEX形式的
 * exp：#CC000000 转为 #000000CC
 */
export function hexARGBGetRGBA(str) {
  if (str.indexOf('#') === 0 && str.length === 9) {
    return `#${str.substring(3)}${str.substring(1, 3)}`
  } else {
    return str
  }
}
