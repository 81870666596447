<template>
  <div class="sf-code">
    <div class="code">
      <VueQrcode :text="qrUrl" :size="size" :margin="10" class="qr-code" />
    </div>
    <div>
      <p class="title">{{ $t('FoodC_20240607_eLRx_hJRj') }}</p>
      <p class="desc">{{ $t('FoodC_20240607_RSyh_XJnN') }}</p>
    </div>
  </div>
</template>

<script>
import VueQrcode from 'vue-qr'
export default {
  name: 'SfQrCode',
  components: {
    VueQrcode,
  },
  data() {
    return {
      qrUrl: window.location.href,
      size: 178,
    }
  },
}
</script>

<style lang="scss" scoped>
.sf-code {
  margin: 0 auto;
  width: 100%;
  padding: 30px 60px;
  text-align: center;
  .code {
    width: 196px;
    height: 196px;
    background: #ededf2;
    border-radius: 22px;
    margin: 150px auto 22px auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .sf-icon {
    color: red;
    transform: rotate(180deg);
  }
  .qr-code {
    border-radius: 18px;
  }
  .title {
    height: 25px;
    font-family: DiDiSans-Pro-Bold;
    font-size: 18px;
    color: #000000;
    letter-spacing: 0;
    text-align: center;
  }
  .desc {
    height: 20px;
    font-family: DiDiSans-Pro-Regular;
    font-size: 14px;
    color: #000000;
    letter-spacing: 0;
    text-align: center;
  }
}
</style>
