export const APP_INFO = {
  APP_VERSION: '0.0.1',
}

export const COMMON_INFO = {
  TERMINAL_ID: 300318, // sf 端id
}

export const CASHIER_COMPONENT_TYPE = {
  managecard: 'managecard', // managecard 卡管理页
  payment: 'paymentfood', // paymentfood 支付页
  paymentmethods: 'paymentmethodsfood', // paymentmethodsfood 支付方式选择页
}

export const CASHIER_PAY_SATUS = {
  failed: 'failed', // 支付失败
  success: 'success', // 支付成功
  pending: 'pending', // 等待中
  timeout: 'timeout', // 支付超时
}
