<template>
  <div class="content">
    <div class="text-box">
      <p class="title" v-text="contentName"></p>
      <p class="title-num" :class="{ wraning: requiredWarning }" v-text="itemNumDesc"></p>
    </div>
    <span
      v-if="requireStatus === 'required'"
      class="require-status"
      :class="{ wraning: requiredWarning }"
      >{{ $t('必选') }}</span
    >
    <span v-else-if="requireStatus === 'completed'" class="complete-desc">{{ $t('已选择') }}</span>
  </div>
</template>

<script>
export default {
  name: 'ContentCom',
  props: {
    requiredWarning: Boolean,
    contentName: String,
    itemNumDesc: String,
    requireStatus: String,
  },
}
</script>

<style lang="scss" scoped>
.content {
  position: relative;
  display: table;
  width: 100%;
  height: 100%;
}
.text-box {
  display: table-cell;
  padding: 14px 0;
  width: 310px;
  vertical-align: middle;
}
.title {
  line-height: 20px;
  font-family: 'Aspira Medium';
  font-weight: 500;
  font-size: 16px;
  color: #000000;
}
.title-num {
  display: inline-block;
  margin-top: 4px;
  line-height: 12px;
  font-family: 'Aspira Light';
  font-weight: 200;
  font-size: 12px;
  color: #999999;
}
.require-status,
.complete-desc {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 6px;
  margin: auto;
  // float: right;
  // margin-right: 14px;
  // margin-top: 18px;
}
.require-status {
  padding: 4px 8px;
  height: 24px;
  line-height: 16px;
  font-family: 'Aspira Medium';
  font-weight: 500;
  font-size: 12px;
  color: #ffffff;
  text-align: center;
  background: #666666;
  border-radius: 3px;
}
.complete-desc {
  height: 24px;
  line-height: 24px;
  font-family: 'Aspira Medium';
  font-weight: 500;
  font-size: 14px;
  color: #999999;
  text-align: right;
}
// 放大动画
.require-status.wraning {
  background: #ff4e45;
  color: #ffffff;
  animation: tada 1000ms both;
}
.title-num.wraning {
  color: #ff4e45;
  transform-origin: left center;
  animation: tada 1000ms both;
}
@keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    transform: scale3d(1.2, 1.2, 1.2) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    transform: scale3d(1.2, 1.2, 1.2) rotate3d(0, 0, 1, -3deg);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}
@media screen and (max-width: 768px) {
  .text-box {
    padding: rem(25) 0;
    width: auto;
  }
  .title {
    line-height: rem(36);
    font-size: rem(36);
  }
  .title-num {
    margin-top: rem(10);
    line-height: rem(28);
    font-size: rem(28);
  }
  // .require-status,
  // .complete-desc {
  // margin-right: 0;
  // margin-top: rem(36);
  // }
  .require-status {
    padding: rem(10) rem(16);
    height: rem(50);
    line-height: rem(30);
    font-size: rem(24);
    border-radius: rem(6);
  }
  .complete-desc {
    height: rem(50);
    line-height: rem(50);
    font-size: rem(28);
  }
}
</style>
