export default [
  // 首页
  {
    path: '/brand/:brandName?/:brandId?',
    name: 'brand',
    meta: {
      title: '品牌导购页',
    },
    component: () =>
      import(
        /* webpackChunkName: "Brand" */
        '@/views/Brand/index'
      ),
  },
]
