<template>
  <div class="breadcrumb-wrapper">
    <div class="breadcrumb-scroll-box" ref="">
      <p
        class="breadcrumb-item"
        v-for="(item, index) in list"
        :key="index"
        :class="{'page': index === list.length - 1 }"
        @click="change(item)"
      >
        <span class="content">{{item[attrName]}}</span>
        <i class="icon-outline_entrance"></i>
      </p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Breadcrumb',
  props: {
    list: Array,
    attrName: {
      type: String,
      default: 'title',
    }
  },
  data() {
    return {

    }
  },
  methods: {
    change(item) {
      this.$emit('nav-change', item)
    }
  }
}
</script>
<style lang="scss" scoped >
.breadcrumb-wrapper {
  padding: 12px 24px;
  background: #fff;
}
.breadcrumb-scroll-box {
  font-size: 0;
}
.breadcrumb-item {
  display: inline-block;
  line-height: 20px;
  font-family: "Aspira light";
  font-weight: 200;
  font-size: 14px;
  color: #666;
  cursor: pointer;
  user-select: none;
}
.breadcrumb-item:hover {
  color: #000;
}
.breadcrumb-item .content {
  display: inline-block;
  vertical-align: middle
}
.breadcrumb-item i {
  font-size: 12px;
  vertical-align: middle
}
.breadcrumb-item.page {
  @include font-color();
}
.breadcrumb-item:last-child i {
  display: none;
}
@media screen and (max-width: 768px){
  .breadcrumb-wrapper {
    padding: rem(10) rem(40);
    overflow: hidden;
  }
  .breadcrumb-item {
    line-height: rem(36);
    font-size: rem(28);
  }
  .breadcrumb-scroll-box {
    line-height: rem(66);
    font-size: 0;
    white-space: nowrap;
    overflow-y: scroll;
  }
}
</style>