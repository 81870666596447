export function _parse(str) {
  const isJSONString = str.indexOf('{') === 0
  if (isJSONString) {
    return JSON.parse(str)
  }
  return str
}

export function parse(str) {
  let tag = '&em#'
  if (str && str.includes('&amp;em#')) {
    tag = '&amp;em#'
  } else if (str && str.includes('&json#')) {
    // 提单页中的卡支付可能是多种，所以需要解析成json
    tag = '&json#'
  }
  return str
    ?.split(tag)
    ?.filter(_ => _ !== '')
    .map(_parse)
}

export function isImage(data) {
  return data.type === 1
}

export function isSpacing(data) {
  return data.type === 2
}

export function isIcon(data) {
  return data.type === 3
}

export function isText(data) {
  return typeof data !== 'string' && data.type === undefined
}

export function isNormalText(data) {
  return typeof data === 'string'
}

export default {
  parse,
  isText,
  isSpacing,
  isImage,
  isIcon,
  isNormalText,
}
