<template>
  <i class="rich-text-icon" :class="iconclass" :style="style" />
</template>

<script>
import { hexARGBGetRGBA } from '@/utils/index'

export default {
  name: 'IconPart',
  props: {
    data: Object,
    isArgb: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    iconclass() {
      let { text } = this.data
      // 客户端增加了rf前缀 后续沟通如何统一 暂时先替换前缀
      text = text.replace(/^rf_icon(_|-)/, 'icon-')
      return text
    },
    style() {
      const { color, size } = this.data
      return {
        color: this.isArgb && color ? hexARGBGetRGBA(color) : color,
        'font-size': size + 'px',
        'vertical-align': 'middle',
      }
    },
  },
}
</script>
