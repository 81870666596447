import OmegaTracker from '@didi/omega-tracker-lite'
import config from '@/config/index'
import fingerprint from '@/utils/fingerprint'
import { requestCommonParams } from '@/lib/request/requestCommonParams'
import { is_mobile } from '@/utils/os'
import { getRecId } from '@/config/trackLog/func'
import { getLocalStorage } from '@/utils/localStorage'
import { sleep } from '@/utils/tools'
import { storeShopId } from '@/utils/storeRouterChange'
import { sourceType } from '@/utils/sourceEnum'
import { getPubFid } from '@/lib/wsg/getPubFid.js'
import { shopInfoPoi } from '@/utils/storeRouterChange'
import store from '@/store/modules/store.js'

export const omega = OmegaTracker.getTracker(config.omega)
window.Omega = omega
let isInit = false
export async function getFigerPrint() {
  if (!isInit) {
    await fingerprint?.init()
    isInit = true
  }
  return fingerprint?.get()
}
class OmegaUtil {
  constructor() {
    this.delayQueue = []
    this.delayTag = false
    this.isSetOmegaUid = false
  }
  async log({ eventId, eventLabel, attrs = {} }) {
    const commonAttrs = await this._getCommonParams(eventId)
    const isLogin = this._isLogin()
    const uid = this._getUID()

    if (uid && !this.isSetOmegaUid) {
      omega.setConfig({ userId: uid })
      this.isSetOmegaUid = true
      console.log('🚀 ~ OmegaUtil ~ log ~ setOmegaUid:', uid)
    }

    if (isLogin && !uid) {
      this.delayTag = true
      this.delayQueue.push({ eventId, eventLabel, attrs: { ...commonAttrs, ...attrs } })
      return
    }

    if (this.delayTag && this.delayQueue.length > 0) {
      this.delayTag = false
      this._sendLogStrategy()
    }

    this._trackEvent(eventId, eventLabel, { ...commonAttrs, ...attrs })
  }
  async _trackEvent(eventId, eventLabel, attrs) {
    if (config.env && config.env.isPro) {
      omega.trackEvent(eventId, eventLabel, attrs)
      return
    }
    console.log(`%c【测试埋点】 %c${eventLabel}`, 'color: green', 'color: blue', {
      eventId,
      eventLabel,
      ...attrs,
    })
  }
  async _sendLogStrategy() {
    const uid = this._getUID()
    const logs = this.delayQueue.splice(0, 5)

    logs.forEach(log => {
      let { eventId, eventLabel, attrs } = log
      attrs.uid = uid
      this._trackEvent(eventId, eventLabel, attrs)
    })

    if (this.delayQueue.length > 0) {
      await sleep(200)
      this._sendLogStrategy()
    }
  }
  async _getCommonParams(eventId) {
    const vuexShopId = store?.state?.vuexShopId || getLocalStorage(storeShopId) || -999
    const shopInfoPoiResult = getLocalStorage(shopInfoPoi)
    const { poiId, cityId, lat, lng, poiCountyId, poiCountyGroupId, countryCode } =
      requestCommonParams()

    const uid = this._isLogin() ? this._getUID() : -999
    const op_system = is_mobile() ? 'phone_web' : 'pc_web'
    const recId = this._getRecId(eventId)
    const commonAttrs = {
      poi_id: poiId ,
      pub_fid: await getPubFid(),
      poi_city_id: cityId || shopInfoPoiResult?.cityId || '', // 避免无城市id埋点报警
      poi_latitude: lat ,
      poi_longitude: lng ,
      pub_poi_county_id: poiCountyId ,
      pub_poi_county_group_id: poiCountyGroupId,
      pub_socialmedia_source: sourceType(),
      uid,
      op_system,
      // SF
      country_code: countryCode,
      shop_id: vuexShopId,
    }

    if (recId) {
      commonAttrs.rec_id = recId
    }

    return commonAttrs
  }
  _isLogin() {
    return getLocalStorage('isLogin')
  }
  _getUID() {
    return getLocalStorage('uid') || ''
  }
  _getRecId(eventId) {
    return getRecId(eventId) || ''
  }
}

const util = new OmegaUtil()

export function trackEvent(logData) {
  return util.log(logData)
}
