import api from '@/api'
import precision from '@/utils/precision'
import { BILL_INFO_TYPE } from '@/const/bill'
// import Vue from 'vue'
// import i18n from '@/i18n/locale'

function handleAlert(res) {
  // new Vue()?.$ralert(res?.alert?.title, i18n.t('提示'), {
  //   confirmButtonText: i18n.t('确定'),
  //   isType: true,
  //   center: true,
  //   callback: action => {
  //     // this.$message({
  //     //   type: 'info',
  //     //   message: `action: ${action}`,
  //     // })
  //   },
  // })
}
const state = {
  loading: false,
  recId: '',

  billAlertInfo: '', // 提单页动态信息

  billInfo: {}, // 提单页信息
  compList: [], // 组件列表
  compMap: {
    // 1: [], 组件类型映射表（根据type类型）
  },
  remark: null,
  cateInfo: null,
  alertInfo: {}, // alert信息
  priceMap: [], // 新价格列表
  checkInfoList: [], // 检查项列表
}

const mutations = {
  setBillInfo(state, data) {
    const { recId = '', sections = [], checkInfo = {} } = data
    const compList = []
    const compMap = {}

    sections.forEach(sectionItem => {
      if (sectionItem.components) {
        sectionItem.components.forEach(compItem => {
          compList.push(compItem)
        })
      }
    })
    if (compList.length > 0) {
      compList.forEach(item => {
        if (compMap[item.type]) {
          compMap[item.type].push(item)
        } else {
          compMap[item.type] = []
          compMap[item.type].push(item)
        }
      })
    }
    // 营销新增type 24的标题
    const filteredPriceData = (compList || []).filter(
      item => item.type === BILL_INFO_TYPE.PRICE_INFO || item.type === BILL_INFO_TYPE.PRICE_TITLE,
    )
    state.recId = recId
    state.billInfo = data
    state.compList = compList
    state.alertInfo = data?.alert || {}
    state.compMap = compMap
    state.priceMap = filteredPriceData
    state.remark = compMap[8] ? compMap[8][0] : null
    state.cateInfo = compMap[5] ? compMap[5][0] : null
    // 处理checkInfo数据
    state.checkInfoList = []
    if (checkInfo) {
      const checkList = checkInfo.checkList || []
      if (checkList.length > 0) {
        const checkList1 = checkList[0] // 地址信息、见面方式、预计送达时间、支付方式
        const checkList2 = checkList[1] // 下单菜品信息
        if (checkList1) {
          checkList1.data.forEach(item => {
            // 2:地址信息 3:见面方式 18:预计送达时间 4:支付方式
            if ([2, 3, 18, 4].includes(+item.type)) {
              state.checkInfoList.push({
                title: item?.content || [],
              })
            }
          })
        }

        if (checkList2) {
          if (+checkList2?.data[0]?.type === 5) {
            state.checkInfoList.push({
              title:
                [
                  {
                    text: checkList2?.name || '', // 菜品title
                  },
                ] || [],
              itemContent: checkList2?.data[0]?.content || [], // 菜品信息
            })
          }
        }
      }
    }
  },
  setBillAlertInfo(state, data) {
    const { alertInfo = {} } = data
    const { content = '' } = alertInfo
    state.billAlertInfo = content
  },
}

const actions = {
  /** EFO等活动专区 获取提单页信息 */
  async getBillInfoFastBuy({ state }, param) {
    try {
      let res = await api.apiFastBuy(param)
      if (res) {
        res = precision.transBillInfo(res)
      }
      return res
    } catch {}
    return false
  },
  /** @desc 获取提单页信息 */
  async getBillInfo({ commit, state, rootState }, param) {
    state.loading = true
    const currentAddressInfo = rootState?.common?.currentAddressInfo || {}
    const requestParams = { ...param }
    if (currentAddressInfo && currentAddressInfo.poi) {
      requestParams.lat = currentAddressInfo?.poi?.lat || ''
      requestParams.lng = currentAddressInfo?.poi?.lng || ''
    }
    try {
      let res = await api.apiBillInfo({
        ...requestParams,
      })
      if (res) {
        res = precision.transBillInfo(res)
      }
      if (res?.alert?.title) handleAlert(res)
      commit('setBillInfo', res)
    } catch {}

    state.loading = false
  },
  /** @desc 更新提单页信息 */
  async updateBillInfo({ commit, state, rootState }, param) {
    let updateBillStatus = true // 透出接口是否请求成功
    state.loading = true
    const currentAddressInfo = rootState?.common?.currentAddressInfo || {}
    const requestParams = { ...param }
    if (currentAddressInfo && currentAddressInfo.poi) {
      requestParams.lat = currentAddressInfo?.poi?.lat || ''
      requestParams.lng = currentAddressInfo?.poi?.lng || ''
    }

    try {
      let res = await api.apiBillUpdate({
        ...requestParams,
      })
      if (res) {
        res = precision.transBillInfo(res)
      }
      res = precision.transBillInfo(res)
      if (res?.alert?.title) handleAlert(res)
      commit('setBillInfo', res)
    } catch {
      updateBillStatus = false
    }
    state.loading = false
    return updateBillStatus
  },
  /** @desc 获取提单页动态信息 */
  async getBillAlert({ commit, state }, param) {
    state.loading = true
    try {
      const res = await api.apiBillAlert(param)
      commit('setBillAlertInfo', res)
    } catch {}
    state.loading = false
  },
  async recoveryBill({ commit, state }, { orderId, paymentError, shouldCancelOrder = true }) {
    // 先取消订单，再恢复购物车
    // 以恢复购物车的结果，为整个recovery的结果（取消订单）
    try {
      // 取消订单失败，不影响恢复购物车
      shouldCancelOrder && (await api.cancelOrder({ orderId, paymentError }))
    } catch (e) {}

    let res = await api.recoveryCart({ orderId })
    res = precision.transBillInfo(res)
    return res
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
