import Vue from 'vue'
import i18n from '@/i18n/locale'
import DishFood from './index.vue'

const ConfirmConstructor = Vue.extend(DishFood)
export default function Confirm(options) {
  const parentNode = document.body || this.$el
  const instance = new ConfirmConstructor({
    propsData: options,
    store: this.$store,
    i18n,
  }).$mount()
  parentNode.appendChild(instance.$el)
  const methods = {
    updateCartHook: fn => {
      // 添加更新菜品 钩子
      instance.addClickHook = fn
      return methods
    },
    closeHook: fn => {
      // 关闭组件 钩子
      instance.closeHook = fn
      return methods
    },
    onShowLoading: () => {
      // 开启 loading
      instance.onShowLoading()
      return methods
    },
    onCloseLoading: () => {
      // 关闭 loading
      instance.onCloseLoading()
      return methods
    },
    onClose: () => {
      // 关闭组件
      instance.onClose()
      return methods
    },
  }
  return methods
}
