import defaultConfig from './default'
import env from './env'

let config = {}
if (env.isDev) {
  // 开发环境
  config = require('./development').default
} else if (env.isTest) {
  // 提测环境
  config = require('./test').default
} else if (env.isPre) {
  // 预发环境
  config = require('./pre').default
} else if (env.isPro) {
  // 正式环境
  config = require('./online').default
} else {
  config = require('./online').default
}

export default Object.assign(defaultConfig, config)
