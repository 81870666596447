import { enc, AES, mode, pad, MD5 } from 'crypto-js'

/**
 * 加密方式:  AES-256-ECB
 */

const iv = enc.Utf8.parse('') // 密钥偏移量
const key = {
  sigBytes: 32,
  words: [
    1467174234, 1833780808, 2021214829, 1213815370, 1280929145, 1197695300, 1733971565, 1751339865,
  ],
}

// 解密方法
export function Decrypt(word) {
  const encryptedHexStr = enc.Base64.parse(word)
  const srcs = enc.Base64.stringify(encryptedHexStr)
  const decrypt = AES.decrypt(srcs, key, {
    iv,
    mode: mode.ECB,
    padding: pad.Pkcs7,
  })
  const decryptedStr = decrypt.toString(enc.Utf8)
  return decryptedStr.toString()
}

// 加密方法
export function Encrypt(word) {
  const srcs = enc.Utf8.parse(word)
  const encrypted = AES.encrypt(srcs, key, {
    iv,
    mode: mode.ECB,
    padding: pad.Pkcs7,
  })
  return enc.Base64.stringify(encrypted.ciphertext)
}

// md5加密
export function md5Encrypt(word) {
  return MD5(word).toString()
}
