import { apiMonitorLog } from '@/const/logs'
import i18n from '@/i18n/locale'
import { showStatus } from '@/lib/request/error-code'
import wsgEnv from '@/lib/wsg/wsgenv.js'
import axios from '@didi/dajax/dist/dajax'
import { getSign } from '@didi/wsgsig'
import Vue from 'vue'

let pending = [] // 声明一个数组用于存储每个ajax请求的取消函数和ajax标识
const CancelToken = axios.CancelToken
let removePending = config => {
  for (let p in pending) {
    if (pending[p].conf === config.url + '&' + config.method) {
      // 当当前请求在数组中存在时执行函数体
      pending[p].func() // 执行取消操作
      pending.splice(p, 1) // 把这条记录从数组中移除
    }
  }
}
// 给接口请求增加dd05签名的拦截器，需要在所有的请求拦截完成后再做加签动作，所以放在第一个第一个拦截器的位置，
// axios拦截器的执行顺序是相反的
axios.interceptors.request.use(
  config => {
    const res = JSON.parse(JSON.stringify(config))
    let contentType = res?.headers[res?.method]['Content-Type'] || ''
    let bodyString = config?.data
    const apiSign = getSign({
      bodyString,
      contentType,
      noDomainCheck: true,
    })
    config.params.wsgsig = apiSign
    return config
  },
  error => {
    return Promise.reject(error)
  },
)
// 添加请求拦截器
// axios.interceptors.request.use(
//   config => {
//     removePending(config) // 在一个ajax发送前执行一下取消操作
//     config.cancelToken = new CancelToken(c => {
//       pending.push({ conf: config.url + '&' + config.method, func: c })
//     })
//     return config
//   },
//   error => {
//     return Promise.reject(error)
//   },
// )

axios.interceptors.request.use(
  config => {
    config.metadata = { startTime: new Date() }
    return config
  },
  error => {
    return Promise.reject(error)
  },
)

// 添加响应拦截器
axios.interceptors.response.use(
  response => {
    response.config.metadata.endTime = new Date()

    const status = response.status
    let errmsg = ''
    if (status < 200 || status >= 300) {
      // 处理http错误，抛到业务代码 ========todo data格式
      errmsg = showStatus(status)
      if (typeof response.data === 'string') {
        response.data = {}
      } else {
        response.data.errmsg = errmsg
      }
      Vue.prototype.$message({
        message: errmsg,
      })
    }
    // 在一个ajax响应后再执行一下取消操作，把已经完成的请求从pending中移除
    removePending(response.config)

    // 埋点监控API
    apiMonitorLog(response)

    return response.data
  },
  error => {
    // 错误抛到业务代码  =======todo data格式
    error = {}
    error.errno = -1
    error.errmsg = i18n.t('网络异常，请稍后再试')
    return Promise.resolve(error)
  },
)
// 添加安全sdk请求拦截器
axios.interceptors.request.use(
  async config => {
    return wsgEnv.wsgEnvToServer(config)
  },
  error => {
    return Promise.reject(error)
  },
)
