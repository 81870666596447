import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Mustache from 'mustache'
import { i18nInit, i18nGet } from '@didi/i18n-plugin'
import emergency from './emergency'
import { getLanguageLong } from '@/utils/locale'

import zhCN from './zh-CN'
import enUS from './en-US'
import jaJP from './ja-JP'
// TODO 由于现在没有开放其他语言国家 所以暂时注释掉，如果放开请重新 npm run i18n 并且释放 i18n-external/index 文件的代码
import ptBR from './pt-BR'
import esMX from './es-MX'
import esCO from './es-CO'
import es419 from './es-419'

import externalI18n from '../i18n-external/index'

i18nInit(require('./i18n-info'))

const { zhCNExternal, enUSExternal, jaJPExternal, ptBRExternal, esMXExternal, es419External } =
  externalI18n

Object.assign(zhCN, zhCNExternal, emergency['zh-CN'])
Object.assign(enUS, enUSExternal, emergency['en-US'])
Object.assign(jaJP, jaJPExternal, emergency['ja-JP'])
Object.assign(ptBR, ptBRExternal, emergency['pt-BR'])
Object.assign(esMX, esMXExternal, emergency['es-MX'])
Object.assign(esCO, esMXExternal, esMX, esCO,emergency['es-CO'])
Object.assign(es419, es419External, esMX, es419,emergency['es-419'])

Vue.use(VueI18n)

// https://stackoverflow.com/questions/22910428/mustache-globally-disable-html-escaping/22910523
Mustache.escape = function (text) {
  return text
}

/**
 * 格式化自定制 {msg} world -> {{msg}} world，与翻译平台规范保持一致
 * http://i18n.xiaojukeji.com/help/copy-standard
 * https://pt-starimg.didistatic.com/static/starimg/img/twXWmK2vD01605164419507.png
 */
class CustomFormatter {
  interpolate(message, values) {
    // 觉得Mustache太重，可以实现一个简版
    return Mustache.render(message, values)
  }
}

// 单复数的情况，可以参考多元化方案：https://kazupon.github.io/vue-i18n/guide/pluralization.html
// 注意由于格式化自定制，单复数中也需要做响应的调整
// 'no apples | one apple | {count} apples' -> 'no apples | one apple | {{count}} apples'

// 多语言支持
export default new VueI18n({
  locale: getLanguageLong(), // 设置地区
  formatter: new CustomFormatter(), // 适配I18N翻译平台占位符 {{variable}}
  messages: {
    'en-US': i18nGet(enUS, 'en-us'),
    'zh-CN': i18nGet(zhCN, 'zh-cn'),
    'ja-JP': i18nGet(jaJP, 'ja-jp'),
    'pt-BR': i18nGet(ptBR, 'pt-br'),
    'es-MX': i18nGet(esMX, 'es-mx'),
    'es-CO': i18nGet(esCO, 'es-co'),
    'es-419': i18nGet(es419, 'es-419'),
  },
})
