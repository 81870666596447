// https://github.com/hilongjw/vue-lazyload
import Vue from 'vue'
import VueLazyload from 'vue-lazyload'

const defaultImg = require('../assets/images/default_img.png')

// const http2 = url => {
//   return url.replace('img0.didiglobal.com/static/', 'img0.didiglobal.com/static/')
// }

const imgConfig = (src, config) => {
  if (src.search(/\?/) !== -1) {
    return `${src}&${config}`
  }
  return `${src}?${config}`
}

const getSizeInfo = size => {
  let proportion

  switch (size) {
    case 'higher':
      proportion = 85
      break
    case 'medium':
      proportion = 70
      break
    case 'small':
      proportion = 50
      break
    case 'mini':
      proportion = 15
      break
    default:
      proportion = 100
      break
  }

  return proportion
}

/** Lazyload 配置文件 */
const lazyLoadConf = {
  preLoad: 1.3,
  error: defaultImg,
  loading: defaultImg,
  attempt: 1,
  filter: {
    // 支持http2
    // http2(listener, options) {
    //   listener.src = http2(listener.src)
    // },
    // 图片缩放
    size(listener) {
      if (!listener.src) {
        return
      }
      const imgSize = listener?.el?.dataset?.size || ''
      const height = listener?.el?.dataset?.height || ''
      const width = listener?.el?.dataset?.width || ''

      if (!imgSize) {
        if (height || width) {
          const giftTag = 'x-s3-process='
          const confList = ['image/resize', 'm_mfit']
          height && confList.push(`h_${height}`)
          width && confList.push(`w_${width}`)
          listener.src = imgConfig(listener.src, giftTag + confList.join())
        }
      } else {
        const proportion = getSizeInfo(imgSize)
        listener.src = imgConfig(listener.src, `x-s3-process=image/resize,p_${proportion}`)
      }
    },
    // webp格式
    webp(listener, options) {
      if (!options.supportWebp) {
        return
      }
      if (!listener.src) {
        return
      }
      if (listener.src.includes('x-s3-process')) {
        listener.src = listener.src + `,image/format,webp`
      } else {
        listener.src = imgConfig(listener.src, `x-s3-process=image/format,webp`)
      }
    },
  },
}

Vue.use(VueLazyload, lazyLoadConf)
