<!--
 * @Author: amywulan
 * @Date: 2024-05-21 20:32:33
 * @LastEditors: amywulan
 * @LastEditTime: 2024-06-11 15:18:40
 * @Description:
-->
<template>
  <p class="limit-txt-wrapper">
    <RichText :text="content" />
  </p>
</template>
<script>
import RichText from '@/components/RichText'

export default {
  name: 'LimitTxt',
  components: {
    RichText,
  },
  props: {
    content: {
      type: String,
      required: true,
      default: '',
    },
  },
  data() {
    return {}
  },
}
</script>
<style lang="scss">
.limit-txt-wrapper span {
  vertical-align: middle !important;
}
</style>
<style lang="scss" scoped>
.limit-txt-wrapper {
  font-family: 'Aspira Light';
  font-size: 14px;
  @include font-color();
}
@media screen and (max-width: 768px) {
  .limit-txt-wrapper {
    font-size: rem(28);
  }
}
</style>
