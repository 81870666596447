import api from '@/api'
import { GLOBAL_ADDRESS_CHANGE, eventBus } from '@/utils/eventBus'
import { getLocalStorage, setLocalStorage } from '@/utils/localStorage'
import { debounce } from 'lodash-es'

function temp(cb) {
  cb()
}
const DELAY = 1000
const debounceRequest = debounce(temp, DELAY)
let LATEST_REQUEST_COUNT = 0

const state = {
  // 请求loading
  loading: false,

  // 搜索地址的信息
  searchId: '', // search id
  poiList: [], // poi 列表
  address_strategy_version: '', // 地址策略版本

  // 创建地址信息
  addressInfos: {},

  // 获取地址列表数据
  addressStrategyVersion: '',
  deliveryAddress: {}, // 送餐地址
  locationAddress: {},
  historyAddressList: [], // 历史地址

  // lastPoi存储上一次的地址信息
  lastPoi: {},
  lastPoiAid: '',
  allAddressInfos: {}, // 所有地址信息
}
const mutations = {
  // 获取搜索地址的信息
  setAddressSearch(state, data) {
    const { searchId = '', poiList = [], address_strategy_version = '' } = data
    state.searchId = searchId
    state.poiList = poiList
    state.address_strategy_version = address_strategy_version
  },
  // 创建地址接口
  setAddressCreate(state, data) {
    state.addressInfos = { ...data }
  },
  setAddressDelete(state, data) {},
  // 获取地址信息
  getAddressList(state, data) {
    state.addressStrategyVersion = data?.addressStrategyVersion
    state.locationAddress = data?.locationAddress
    state.historyAddressList = data?.historyAddressList
    state.deliveryAddress = data?.deliveryAddress
  },
  setLoadingStatus(state, data) {
    state.loading = data.loading
  },
  setLastPoiState(state, { lastPoi, allAddressInfos }) {
    eventBus.$emit(GLOBAL_ADDRESS_CHANGE)
    state.lastPoi = lastPoi
    state.lastPoiAid = lastPoi?.aid || ''
    state.allAddressInfos = allAddressInfos || {}
    setLocalStorage('lastPoiAid', lastPoi?.aid || '')
    setLocalStorage('lastPoi', lastPoi || {})
    setLocalStorage('allAddressInfos', allAddressInfos || {})

    if (state?.lastPoi?.countryCode !== lastPoi?.countryCode) {
      location.reload()
    }
  },
  getLastPoiState(state) {
    state.lastPoi = getLocalStorage('lastPoi') || {}

    setLocalStorage('lastPoiAid', state.lastPoi?.aid || '')
    state.lastPoiAid = state.lastPoi?.aid || getLocalStorage('lastPoiAid') || ''
  },
  delPoiListState(state) {
    state.poiList = []
  },
}
const actions = {
  setLoading({ commit }, param) {
    commit('setLoadingStatus', param)
  },
  // 获取搜索地址的信息
  addressTextsearch({ commit, state }, param) {
    state.loading = true
    // 并发请求下，只保留匹配一致的结果
    ++LATEST_REQUEST_COUNT
    debounceRequest(async () => {
      const CURRENT_REQUEST_COUNT = LATEST_REQUEST_COUNT
      try {
        const res = await api.addressTextsearch(param)
        if (CURRENT_REQUEST_COUNT === LATEST_REQUEST_COUNT) {
          commit('setAddressSearch', res)
        }
      } catch (err) {
        if (CURRENT_REQUEST_COUNT === LATEST_REQUEST_COUNT) {
          commit('setAddressSearch', {})
        }
      }
      state.loading = false
    })
  },
  // 创建地址接口
  async addressCreate({ commit, state }, param) {
    state.loading = true
    try {
      const res = await api.addressCreate(param)
      commit('setAddressCreate', res)
    } catch (err) {}
    state.loading = false
  },

  // 更新地址接口
  async addressUpdate({ commit, state }, param) {
    state.loading = true
    try {
      const res = await api.addressUpdate(param)
      commit('setAddressCreate', res)
    } catch {}
    state.loading = false
  },

  // 删除地址接口
  async addressDelete({ commit, state }, param) {
    state.loading = true
    try {
      const res = await api.addressDelete(param)
      commit('setAddressDelete', res)
    } catch {}
    state.loading = false
  },

  // 获取地址信息
  async addressList({ commit, state }, param) {
    state.loading = true
    try {
      const res = await api.addressList(param)
      commit('getAddressList', res)
    } catch {}
    state.loading = false
  },
  // 存储lastPoi地址信息
  setLastPoi({ commit }, param) {
    commit('setLastPoiState', param)
  },
  // 获取lastPoi地址信息
  getLastPoi({ commit }) {
    commit('getLastPoiState')
  },
  // 删除当前本地查询的地址信息
  delPoiList({ commit }) {
    commit('delPoiListState')
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
