import commonStore from '@/store/modules/common'
import { META } from '@/config/countryConfig/list/page-title'
import { setSessionStorage, getSessionStorage } from '@/utils/sessionStorage'

/**
 * window.location.origin的兼容处理
 * 由于在Chrome window.location.origin 属性是支持的，但是在IE11不支持
 * @export
 * @returns String
 */
export function getUrlOrigin() {
  return (
    window.location.origin ||
    `${window.location.protocol}//${window.location.hostname}${
      window.location.port ? ':' + window.location.port : ''
    }`
  )
}

/**
 * 禁止H5 弹窗下边的页面滚动
 * 明确声明为不是被动的
 * 缺陷：会导致页面滚动到顶部
 */
export function stopScroll() {
  if (commonStore?.state?.isH5) {
    const sTop = document.documentElement.scrollTop || document.body.scrollTop
    document.body.className = document.body.className + ' c-global-parent-overflow'
    document.body.style.top = -sTop + 'px'
    document.body.dataset.top = sTop
    // document.addEventListener('touchmove', bodyScroll, {
    //   capture: false,
    //   passive: false,
    //   once: false,
    // })
  } else {
    document.body.className = document.body.className + ' c-global-parent-overflow'
  }
}
export function openScroll() {
  if (commonStore?.state?.isH5) {
    removeClass(document.body, 'c-global-parent-overflow')
    const top = document.body.dataset.top
    document.documentElement.scrollTop = top
    const sTop = document.documentElement.scrollTop || document.body.scrollTop
    !sTop && (document.body.scrollTop = top)
    document.removeEventListener('touchmove', bodyScroll, {
      capture: false,
      passive: false,
      once: false,
    })
  } else {
    removeClass(document.body, 'c-global-parent-overflow')
  }
}

function bodyScroll(e) {
  e.preventDefault()
}
function removeClass(el, name) {
  if (!el) return
  const className = el.className
  if (className) {
    const reg = new RegExp(name, 'g')
    el.className = className.replace(reg, '')
  }
}

/**
 * EFO  开关
 */
export function isOpenEFO() {
  const open = true
  return open
}

/**
 * 获取store页title
 */
export function addTitlePrefixToStore(title) {
  // return `${TITLE_PREFIX}${title}`
  return META.store.title.replace('[shop name]', title)
}

/**
 * 获取search页title
 */
export function addTitlePrefixToSearch(title) {
  return META.search.title.replace('KEYWORD', title)
}

/**
 * 获取跳转骑手链接 - 直接跳转骑手注册
 * @param {*} payload
 * @returns
 */
export function getDeliveryUrl(payload) {
  return `//www.didi-food.com/${payload.locale}/mobile-delivery/guide?clientType=796&country=${
    payload.country || ''
  }`
}

/**
 * 处理策略 存储策略
 * 示例为 特价菜限购超出限购提示策略
 * "strategy":{ "cycle":1, "num":1, "uniqueId":"eeafc83672cb35c68ee1f91edc3c4ad3_1" }
 */
export function isStrategyShow(key, strategy) {
  if (!strategy) {
    // delSessionStorage(key) 不清除原因：老客菜如果弹窗，后续再加特价菜会重复提示
    return true
  }
  const { num = 1, uniqueId } = strategy
  const storageObj = getSessionStorage(key)
  if (storageObj && storageObj.uniqueId === uniqueId && num <= storageObj.num) {
    return false
  } else {
    const preNum = !storageObj ? 0 : storageObj.uniqueId !== uniqueId ? 0 : storageObj?.num || 0
    setSessionStorage(key, { num: preNum + 1, uniqueId })
    return true
  }
}
