import { post } from '@/lib/request/index'
import { brand } from '../apiConstants'

// 品牌信息接口
export function getBrandInfo(param) {
  return post(brand.GET_BRANDINFO, { param }).catch(err => {
    return err
  })
}
// 品牌店铺列表接口
export function getBrandShopList(param) {
  return post(brand.GET_BRAND_SHOPLIST, { param }).catch(err => {
    return err
  })
}
