import api from '@/api'

const state = {
  loading: false,
  userInfo: {},
}

const mutations = {
  setUserInfo(state, data) {
    state.userInfo = data
  },
}

const actions = {
  /** @desc 更新个人信息 */
  async updateUserInfo({ commit, state }, param) {
    let res = {}
    state.loading = true
    try {
      res = await api.updateUserInfo(param)
      commit('setUserInfo', res)
    } catch {}
    state.loading = false
    return res
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
}
