import Vue from 'vue'
import { errImgBase64, errImgBannerBase64 } from './conf'

/** @desc 图片尺寸 */
Vue.directive('img-error', (el, binding) => {
  const { value } = binding
  const img = new Image()

  img.onerror = () => {
    if (value === 'banner') {
      el.src = errImgBannerBase64
    } else {
      el.src = errImgBase64
    }

    el.setAttribute('style', 'object-fit:cover')
  }

  img.src = el.src
})
