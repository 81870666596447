import store from "@/store/modules/store.js";
import { getLocalStorage } from '@/utils/localStorage'
import { needShopPoiApiList } from './const'
import { isEmpty } from "lodash-es";
/**
 * @desc 获取店铺参数
 */
export function requestShopParams() {
  const shopPoi =  store?.state?.shopPoi || {}

  const { lat, lng, countryCode } = shopPoi
  const cityID = shopPoi?.cityID || shopPoi?.cityId
  const countyID = shopPoi?.countyID || shopPoi?.countyId
  const countyGroupID = shopPoi?.countyGroupID || shopPoi?.countyGroupId
  const poiID = shopPoi?.poiID || shopPoi?.poiId

  const shopAddress =  {
    /** @desc 地址信息 **/
    countryCode: countryCode,
    countyId: countyID,
    countyGroupId: countyGroupID,
    cityId: cityID,
    lat: lat,
    lng: lng,
    poiId: poiID,
    /** @desc 地址未返回的参数 **/
    poiCityId: shopPoi?.poiCityId || cityID,
    poiCountyId: shopPoi?.poiCountyId || countyID,
    poiCountyGroupId: shopPoi?.poiCountyGroupId || countyGroupID,
    poiName: shopPoi?.poiName || shopPoi?.displayName,
    poiLat: shopPoi?.poiLat || lat,
    poiLng: shopPoi?.poiLng || lng,
  }

  for (let key in shopAddress) {
    if (shopAddress[key] === '' || shopAddress[key] === null || shopAddress[key] === undefined) {
        // 如果任何值都没有，则清掉，公参可以兜底
        delete shopAddress[key];
    }
  }

  return shopAddress
}


// 本地没有缓存地址
const noLastPoi = function() {
    const lastPoi = getLocalStorage('lastPoi') || {}
    return isEmpty(lastPoi)
}
/**
 * @desc 接口是否需要使用店铺地址信息参数
 */
export function needUseRequestShopParams(api) {
  // 如果链接不是这几个api， 直接return false
  if(!needShopPoiApiList.includes(api)) {
    return false
  }
  // 如果无本地缓存地址，return true 否则return false
  return noLastPoi()
}
