<template>
  <div class="line">
    <component
      :is="getPartName(part)"
      v-for="part in parts"
      :key="part.type"
      :data="part"
      :is-argb="isArgb"
    />
  </div>
</template>

<script>
import utils from './utils'
import TextPart from './TextPart'
import ImagePart from './ImagePart'
import SpacingPart from './SpacingPart'
import NormalTextPart from './NormalTextPart'
import IconPart from './IconPart'

export default {
  name: 'RichText',
  components: {
    TextPart,
    ImagePart,
    SpacingPart,
    NormalTextPart,
    IconPart,
  },
  props: {
    text: String,
    type: String, // 可以直接指定类型 同PartName
    isArgb: {
      type: Boolean,
      default: true, // web交易复用客户端接口，默认设为true
    },
  },
  computed: {
    utils() {
      return utils
    },
    parts() {
      return utils.parse(this.text)
      // return [
      //   {
      //     type: 1,
      //     iconUrl: 'https://developer.mozilla.org/favicon-48x48.97046865.png',
      //     width: 15,
      //     height: 15,
      //   },
      //   {
      //     type: 2,
      //     width: 20,
      //   },
      //
      //   '你节省了你节省了你节省了你节省了你节省了',
      //   {
      //     text: '10元',
      //     color: 'red',
      //     size: 20,
      //     stroke: 1,
      //     weight: 2,
      //   },
      //   '你节省了你节省了你节省了你节省了你节省了',
      //   {
      //     type: 1,
      //     iconUrl: 'https://developer.mozilla.org/favicon-48x48.97046865.png',
      //     width: 15,
      //     height: 15,
      //   },
      //   {
      //     text: '10元',
      //     color: 'red',
      //     size: 20,
      //     stroke: 1,
      //     weight: 2,
      //   },
      // ];
    },
  },
  methods: {
    getPartName(part) {
      if (this.type && this.type.indexOf('Part') !== -1) {
        return this.type
      }
      if (utils.isText(part)) {
        return 'TextPart'
      }
      if (utils.isImage(part)) {
        return 'ImagePart'
      }
      if (utils.isSpacing(part)) {
        return 'SpacingPart'
      }
      if (utils.isIcon(part)) {
        return 'IconPart'
      }
      if (utils.isNormalText(part)) {
        return 'NormalTextPart'
      }
      return 'NormalTextPart'
    },
  },
}
</script>

<style scoped lang="scss">
.line {
}
</style>
